import { useCallback } from 'react';

import { FetchResult, gql, MutationFunctionOptions, useMutation } from '@apollo/client';

import { CREATE_CONNECTION } from 'graphql/Accounts';
import { SCAN_RESOURCES } from 'graphql/Resources';
import { SUBMIT_SCANS } from 'graphql/SecurityFindings';
import { Connection, CreateConnectionData, CreateConnectionInput } from 'types';

export default function useOnboard(): [
  (ids: string[]) => void,
  (
    options?: MutationFunctionOptions<CreateConnectionData, CreateConnectionInput> | undefined,
  ) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>,
] {
  const [scanResources] = useMutation(SCAN_RESOURCES, {
    context: { clientName: 'resources' },
  });

  const [scanCompliance] = useMutation(SUBMIT_SCANS, {
    context: { clientName: 'findings' },
  });

  const [createConnection] = useMutation<CreateConnectionData, CreateConnectionInput>(
    CREATE_CONNECTION,
    {
      context: { clientName: 'accounts' },
      update(cache, { data }) {
        if (data?.createConnection) {
          cache.modify({
            fields: {
              listConnections(existing = {}): Connection[] {
                if (
                  (existing?.connections || []).some(
                    // eslint-disable-next-line no-underscore-dangle
                    (c: { __ref: string }) => c.__ref === `Connection:${data.createConnection.id}`,
                  )
                ) {
                  return existing;
                }

                const newConnectionRef = cache.writeFragment({
                  data: data.createConnection,
                  fragment: gql`
                    fragment NewConnection on Connection {
                      id
                      name
                      cloudId
                      health
                      createdAt
                      updatedAt
                      addedBy
                      provider
                    }
                  `,
                });
                return {
                  ...existing,
                  connections: [...(existing.connections || []), newConnectionRef],
                };
              },
            },
          });
        }
      },
    },
  );

  const submitScans = useCallback(
    async (ids: string[]) => {
      try {
        await Promise.all([
          scanResources({ variables: { cloudId: ids } }),
          scanCompliance({ variables: { cloudList: ids } }),
        ]);
      } catch (e) {
        // failed to submit scans
      }
    },
    [scanCompliance, scanResources],
  );

  return [submitScans, createConnection];
}
