import React, { useState, useEffect, useRef } from 'react';

import { Button, Popper, Paper, Snackbar } from '@cuda-networks/bds-core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import AccountFilter from 'components/common/AccountFilter';
import RBACButton from 'components/common/RBACButton';
import StatusMessage from 'components/common/StatusMessage';
import useAccountsFilter from 'hooks/useAccountsFilter';

export default function ScanButton({
  onScan,
  status,
  disabled = false,
  label = 'Scan',
}): JSX.Element {
  const anchorEl = useRef(null);
  const [open, setOpen] = useState(false);

  const { selectedAccounts, setAccounts, accountsList } = useAccountsFilter();

  const handleToggle = (): void => setOpen(!open);
  const handleClose = (): void => setOpen(false);
  const handleScan = (): void => {
    onScan((selectedAccounts || []).map(a => a.type));
  };

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const closeSnackbar = () => setSnackbarOpen(false);
  const [result, setResult] = useState({});
  useEffect(() => {
    if (status.succeeded) {
      setSnackbarOpen(true);
      setResult({
        status: 'success',
        message: status.result,
      });
    } else if (status.error) {
      setSnackbarOpen(true);
      setResult({
        status: 'error',
        message: status.error,
      });
    } else {
      setSnackbarOpen(false);
    }
  }, [status]);

  return (
    <>
      <RBACButton
        color="secondary"
        aria-label="split button"
        buttonGroup
        disabled={status.fetching || disabled}
        variant="contained"
      >
        <Button onClick={handleScan} disabled={!selectedAccounts?.length} color="secondary">
          {`${label}${
            (selectedAccounts?.length || selectedAccounts?.length === 0) &&
            selectedAccounts?.length !== accountsList?.length
              ? ` (${selectedAccounts.length})`
              : ''
          }`}
        </Button>
        <Button
          color="secondary"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select scan accounts"
          aria-haspopup="menu"
          onClick={handleToggle}
          buttonRef={anchorEl}
        >
          <ArrowDropDownIcon />
        </Button>
      </RBACButton>
      <Popper
        id={'scan-account-filter'}
        open={open}
        anchorEl={anchorEl.current}
        placement="bottom-start"
      >
        <Paper>
          <AccountFilter
            onClose={handleClose}
            accounts={accountsList}
            selected={selectedAccounts || []}
            onChange={setAccounts}
            disableCloseOnSelect
            disablePortal
            label=""
            open
            paddedTextField
          />
        </Paper>
      </Popper>
      <Snackbar
        open={snackbarOpen}
        onClose={closeSnackbar}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <StatusMessage
          status={result.status}
          message={result.message}
          removable
          handleDismiss={closeSnackbar}
          variant="filled"
        />
      </Snackbar>
    </>
  );
}
