import { useContext } from 'react';

import { AuthContext, AuthContextProps } from 'AuthProvider';

export default function useAuth(): AuthContextProps {
  const context = useContext<AuthContextProps | undefined>(AuthContext);

  if (!context) {
    throw new Error('Auth context not initialized.');
  }

  return context;
}
