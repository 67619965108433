import React from 'react';

import { useMutation } from '@apollo/client';

import { ScanButton } from 'components/SecurityRisks';
import { SCAN_RESOURCES } from 'graphql/Resources';
import useRequest from 'hooks/useRequest';

export default function ResourceScanButton({ ...scanButtonProps }): React.ReactElement {
  const [scan] = useMutation(SCAN_RESOURCES, {
    context: { clientName: 'resources' },
  });
  const [requestStatus, requestStarted, requestSucceeded, requestFailed] = useRequest();
  const handleScan = async (cloudList): void => {
    requestStarted();
    try {
      await scan({ variables: { cloudId: cloudList } });
      requestSucceeded('Resource detection request submitted.');
    } catch (e) {
      requestFailed('Failed to submit request for resource detection.');
    }
  };

  return (
    <ScanButton
      {...scanButtonProps}
      onScan={handleScan}
      status={requestStatus}
      label="Detect Resources"
    />
  );
}
