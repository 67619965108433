import { useCallback, useEffect, useState } from 'react';

import { parseQS } from 'utils/params';

const codec = require('json-url')('lzw');

export const compressFilter = async (data: object): Promise<string> => {
  const result = await codec.compress(data);
  return result;
};

export default function useCompressedFilter(filterName: string): any {
  const [filter, setFilter] = useState<object | null>(null);

  useEffect(() => {
    const parseFilter = async compressed => {
      const decompressed = await codec.decompress(compressed);

      setFilter(decompressed);
    };

    try {
      const parsedQS: {
        filter?: string;
      } = parseQS();
      if (parsedQS?.filter) {
        parseFilter(parsedQS.filter);
      } else if (sessionStorage.getItem(filterName)) {
        parseFilter(sessionStorage.getItem(filterName));
      } else {
        setFilter({});
      }
    } catch (e) {
      setFilter({});
    }
  }, [filterName]);

  const storeFilter = useCallback(
    (data: object): void => {
      codec.compress(data).then(result => {
        const parsedQS: {} = parseQS();
        const queryString = Object.entries(parsedQS)
          .filter(([key]) => key !== 'filter')
          .map(([key, value]) => `${key}=${value}`)
          .join('&');

        window.history.replaceState(
          {},
          '',
          `${window.location.pathname}?${queryString ? `${queryString}&` : ''}filter=${result}`,
        );

        sessionStorage.setItem(filterName, result);
      });
    },
    [filterName],
  );

  return [filter, storeFilter, compressFilter];
}
