import React, { useMemo } from 'react';

import {
  Button,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  LinearProgress,
} from '@cuda-networks/bds-core';
import { makeStyles } from '@material-ui/core/styles';
import AccountIcon from '@material-ui/icons/AccountCircle';
import { Link } from 'react-router-dom';

import StatusMessage from 'components/common/StatusMessage';
import { Account, BarracudaAccount, RequestStatus } from 'types';

const useStyles = makeStyles(
  theme => ({
    accountList: {
      border: '1px solid #BFBFBF',
    },
    listItem: {
      '&$selected': {
        backgroundColor: '#A6D0E5',
      },
      '&$disabled': {
        '&:hover': {
          backgroundColor: '#FFFFFF',
        },
        '& > div$listIcon': {
          color: '#5F5F5F',
        },
        pointerEvents: 'auto',
        opacity: 1,
        '& > div': {
          pointerEvents: 'none',
          opacity: 0.5,
        },
      },
      '&unclickable': {
        '&:hover': {
          backgroundColor: '#FFFFFF',
        },
        pointerEvents: 'none',
        opacity: 1,
        '& > div': {
          pointerEvents: 'none',
          opacity: 0.5,
        },
      },
    },
    listIcon: {
      color: theme.palette.primary.main,
      '& svg': {
        height: '48px',
        width: '48px',
      },
    },
    selected: {},
    disabled: {},
  }),
  {
    name: 'AccountsList',
  },
);

interface AccountsListProps {
  accounts: Account[];
  currentAccount?: BarracudaAccount;
  onAccountSetup: (account: Account) => void | Promise<void>;
  onSelect: (accountId: string) => void;
  onLogout: () => void;
  status: RequestStatus;
  action: 'trial' | 'setup' | 'manage';
}

export default function AccountsList(props: AccountsListProps): JSX.Element {
  const { accounts, currentAccount, onSelect, onLogout, status, onAccountSetup, action } = props;
  const classes = useStyles();

  const handleSelectAccount = (accountId: string) => (): void => onSelect(accountId);
  const handleAccountSetup = (account: Account) => (): void | Promise<void> =>
    onAccountSetup(account);

  const accountsList = useMemo(() => {
    return accounts?.filter(a => a.id)?.sort((a, b) => a.name.localeCompare(b.name)) || [];
  }, [accounts]);

  const setupAccounts = useMemo(() => {
    return accounts?.filter(a => a.setup)?.sort((a, b) => a.name.localeCompare(b.name)) || [];
  }, [accounts]);

  const notSetupAccounts = useMemo(() => {
    return accounts?.filter(a => !a.setup)?.sort((a, b) => a.name.localeCompare(b.name)) || [];
  }, [accounts]);

  return (
    <Grid container spacing={2}>
      <Grid item container xs={12} alignItems="center" direction="column">
        <Typography variant="h2" display="block">
          Your Accounts
        </Typography>
        {status.succeeded && <StatusMessage status="success" message={status.result} removable />}
        {status.error && <StatusMessage status="error" message={status.error} removable />}
      </Grid>
      <Grid item xs={12}>
        {status.fetching && <LinearProgress />}
        {action === 'trial' && Boolean(notSetupAccounts.length) && (
          <>
            <Typography variant="subtitle1" display="block">
              Choose an account to start your trial.
            </Typography>
            <List className={classes.accountList} disablePadding aria-label="Accounts">
              {notSetupAccounts.map(acc => {
                return (
                  <ListItem
                    className={classes.listItem}
                    classes={{
                      selected: classes.selected,
                      disabled: classes.disabled,
                    }}
                    button
                    key={acc.id}
                    disabled={!acc.setup || status.fetching}
                    selected={currentAccount && acc.id === currentAccount.id}
                  >
                    <ListItemIcon className={classes.listIcon}>
                      <AccountIcon />
                    </ListItemIcon>
                    <ListItemText primary={acc.name} />
                    <Button
                      color="secondary"
                      onClick={handleAccountSetup(acc)}
                      disabled={status.fetching}
                      variant="contained"
                    >
                      Start Trial
                    </Button>
                  </ListItem>
                );
              })}
            </List>
          </>
        )}
        {action === 'trial' && !notSetupAccounts.length && Boolean(setupAccounts.length) && (
          <>
            <Typography variant="subtitle1" display="block">
              None of your accounts are eligible for a trial. Please click an account to log in.
            </Typography>
            <List className={classes.accountList} disablePadding aria-label="Accounts">
              {setupAccounts.map(acc => {
                return (
                  <ListItem
                    className={classes.listItem}
                    classes={{
                      selected: classes.selected,
                      disabled: classes.disabled,
                    }}
                    button
                    key={acc.id}
                    disabled={!acc.setup || status.fetching}
                    selected={currentAccount && acc.id === currentAccount.id}
                    onClick={handleSelectAccount(acc.id)}
                  >
                    <ListItemIcon className={classes.listIcon}>
                      <AccountIcon />
                    </ListItemIcon>
                    <ListItemText primary={acc.name} />
                  </ListItem>
                );
              })}
            </List>
          </>
        )}
        {action === 'setup' && Boolean(accounts.length) && (
          <>
            <Typography variant="subtitle1" display="block">
              Choose an account to activate with your serial.
            </Typography>
            <List className={classes.accountList} disablePadding aria-label="Accounts">
              {accountsList.map(acc => {
                return (
                  <ListItem
                    className={classes.listItem}
                    classes={{
                      selected: classes.selected,
                      disabled: classes.disabled,
                    }}
                    button
                    key={acc.id}
                    disabled={status.fetching}
                    selected={currentAccount && acc.id === currentAccount.id}
                    onClick={handleAccountSetup(acc)}
                  >
                    <ListItemIcon className={classes.listIcon}>
                      <AccountIcon />
                    </ListItemIcon>
                    <ListItemText primary={acc.name} />
                  </ListItem>
                );
              })}
            </List>
          </>
        )}
        {action === 'manage' && Boolean(accounts.length) && (
          <>
            <Typography variant="subtitle1" display="block">
              Choose an account to start your trial or log in.
            </Typography>
            <List className={classes.accountList} disablePadding aria-label="Accounts">
              {setupAccounts.map(acc => {
                let secondaryText;
                if (currentAccount && acc.id === currentAccount.id) {
                  secondaryText = 'Signed In';
                }
                return (
                  <ListItem
                    className={classes.listItem}
                    classes={{
                      selected: classes.selected,
                      disabled: classes.disabled,
                    }}
                    button
                    key={acc.id}
                    disabled={!acc.setup || status.fetching}
                    selected={currentAccount && acc.id === currentAccount.id}
                    onClick={handleSelectAccount(acc.id)}
                  >
                    <ListItemIcon className={classes.listIcon}>
                      <AccountIcon />
                    </ListItemIcon>
                    <ListItemText primary={acc.name} secondary={secondaryText} />
                  </ListItem>
                );
              })}
              {notSetupAccounts.map(acc => {
                return (
                  <ListItem
                    className={classes.listItem}
                    classes={{
                      selected: classes.selected,
                      disabled: classes.disabled,
                    }}
                    button
                    key={acc.id}
                    disabled={!acc.setup || status.fetching}
                    selected={currentAccount && acc.id === currentAccount.id}
                  >
                    <ListItemIcon className={classes.listIcon}>
                      <AccountIcon />
                    </ListItemIcon>
                    <ListItemText primary={acc.name} />
                    <Button
                      color="secondary"
                      onClick={handleAccountSetup(acc)}
                      disabled={status.fetching}
                      variant="contained"
                    >
                      Start Trial
                    </Button>
                  </ListItem>
                );
              })}
            </List>
          </>
        )}
        {!accounts.length && (
          <Typography variant="subtitle1" display="block">
            No accounts exist for this user. Please finish setup at{' '}
            <a
              href={`${process.env.REACT_APP_LOGINAPP_URL}/new_user`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Barracuda Cloud Control
            </a>{' '}
            to continue.
          </Typography>
        )}
      </Grid>
      <Grid item container xs={12} justify="center">
        <Typography variant="caption">
          <Link to="/login" onClick={onLogout}>
            LOG OUT
          </Link>
        </Typography>
      </Grid>
    </Grid>
  );
}
