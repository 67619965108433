/* eslint-disable @typescript-eslint/ban-ts-ignore */
import React from 'react';
import './App.css';

import { BDSProvider } from '@cuda-networks/bds-core';
import LuxonUtils from '@date-io/luxon';
import { CssBaseline } from '@material-ui/core';
import { createTheme, Theme, ThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import mixpanel from 'mixpanel-browser';
import { Router } from 'react-router-dom';

import Apollo from 'ApolloProvider';
import { AuthProvider } from 'AuthProvider';
import { parseParams } from 'utils/params';

import MainRouter from './MainRouter';
import history from './siteHistory';

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN || '');

export const MixPanelContext = React.createContext(mixpanel);

parseParams();

function App(): JSX.Element {
  return (
    <BDSProvider useBdsTheme>
      <ThemeProvider
        theme={(theme): Theme =>
          createTheme({
            ...theme,
            props: {
              // @ts-ignore
              ...theme.props,
              MuiTextField: {
                variant: 'outlined',
                InputLabelProps: {
                  shrink: true,
                },
                InputProps: {
                  notched: false,
                },
              },
              MuiSnackbar: {
                disableWindowBlurListener: true,
                autoHideDuration: 5000,
              },
              MuiDialog: {
                disableBackdropClick: true,
              },
            },
            overrides: {
              // @ts-ignore
              ...theme.overrides,
              MuiTableCell: {
                root: {
                  padding: '6px 8px',
                },
              },
              MuiOutlinedInput: {
                input: {
                  padding: 8,
                },
              },
              MuiInputLabel: {
                outlined: {},
                shrink: {
                  transform: 'translate(4px, 2px) scale(0.75) !important',
                  position: 'inherit',
                },
              },
            },
          })
        }
      >
        <MixPanelContext.Provider value={mixpanel}>
          <MuiPickersUtilsProvider utils={LuxonUtils}>
            <div className="App">
              <CssBaseline />
              <Router history={history}>
                <AuthProvider>
                  <Apollo>
                    <MainRouter />
                  </Apollo>
                </AuthProvider>
              </Router>
            </div>
          </MuiPickersUtilsProvider>
        </MixPanelContext.Provider>
      </ThemeProvider>
    </BDSProvider>
  );
}

export default App;
