import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import clsx from 'clsx';

import AccountNameWithIcon from 'components/common/AccountNameWithIcon';
import { FindingLabel, ComplianceFromFinding } from 'components/SecurityFindings';
import { FindingSeverity } from 'types';

const useStyles = makeStyles(
  theme => ({
    wrapper: {
      cursor: 'pointer',
      padding: '6px 16px',
      // maxWidth: '350px',
      display: 'flex',
      alignItems: 'center',
      '& > div': {
        display: 'flex',
        maxWidth: 225,
      },
    },

    selected: {
      backgroundColor: '#E5F3FA',
    },

    count: {
      backgroundColor: '#7A869A',
      borderRadius: '4px',
      fontSize: '12px',
      lineHeight: '14px',
      height: '24px',
      color: '#FFFFFF',
      minWidth: '24px',
      alignItems: 'center',
      justifyContent: 'center',
      margin: '8px',
    },
    icon: {
      margin: '8px',
      color: '#7A869A',
    },

    account: {
      fontSize: '12px',
    },

    content: {
      flexDirection: 'column',
      flexGrow: 1,
      '& > div': {
        display: 'flex',
        margin: '2px 0',
        alignItems: 'center',
      },
    },

    severity: {
      marginRight: '20px',
    },
    title: {
      fontWeight: 500,
      fontSize: '12px',
      lineHeight: '16px',
    },
  }),
  {
    name: 'FindingGroup',
  },
);

interface FindingGroup {
  cloudId: string;
  count: number;
  generatorId: string;
  severity: string;
  title?: string;
  source?: string;
}

interface GroupRule {
  title: string;
  severity: FindingSeverity;
}

interface FindingGroupProps {
  group: FindingGroup;
  rule: GroupRule;
  selected: boolean;
}

export default function FindingGroup(props: FindingGroupProps): JSX.Element {
  const { group, rule, selected, ...other } = props;
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.wrapper, {
        [classes.selected]: selected,
      })}
      {...other}
    >
      <div className={classes.content}>
        <div>
          <FindingLabel
            className={classes.severity}
            severity={group.severity || rule.severity}
            noRounding
          />
          <AccountNameWithIcon className={classes.account} accountId={group.cloudId} small />
        </div>
        <div className={classes.title}>{rule.title}</div>
        <div style={{ fontSize: '0.8125rem' }}>
          <ComplianceFromFinding finding={group} chipped noVersion />
        </div>
      </div>
      <div className={classes.count}>{group.count}</div>
      <div className={classes.icon}>
        <ChevronRightIcon />
      </div>
    </div>
  );
}

FindingGroup.propTypes = {
  // status: PropTypes.object,
};

FindingGroup.defaultProps = {
  // status: {},
};
