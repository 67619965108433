import React, { useEffect, useState } from 'react';

import { Table, TableRow, TableCell, TableBody } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LaunchIcon from '@material-ui/icons/Launch';
import { Skeleton } from '@material-ui/lab';
import { Link } from 'react-router-dom';

import AccountNameWithIcon from 'components/common/AccountNameWithIcon';
import RBACButton from 'components/common/RBACButton';
import StatusMessage from 'components/common/StatusMessage';
import Timestamp from 'components/common/Timestamp';
import { ResourceDisplay } from 'components/Resources';
import { FindingLabel, ComplianceFromFinding, ControlResult } from 'components/SecurityFindings';
import { compressFilter } from 'hooks/useCompressedFilter';
import { filtersFromSelectedControls } from 'hooks/useFindingsFilter';
import { assetNames, assetTypes, regionNames } from 'lib';

import { riskInfoFromFinding, sourceFromFinding, workflowStatus } from './lib';

const useStyles = makeStyles(
  theme => ({
    actionContainer: {
      padding: '16px 24px',
      backgroundColor: theme.palette.background.default,
      borderBottom: '1px solid #DDDDDD',
      '& > button': {
        marginRight: 20,
      },
    },
    details: {
      padding: '16px 24px',
      overflowY: 'auto',
    },
    container: {
      padding: '15px',
      strong: {
        fontWeight: 500,
      },
    },
  }),
  {
    name: 'FindingDetails',
  },
);

export default function FindingDetails({
  finding,
  parentResource,
  handleSuppress,
  handleUnsuppress,
  suppressStatus,
  loading,
  resourceType,
}): JSX.Element {
  const classes = useStyles();

  const [filter, setFilter] = useState<string | null>(null);
  useEffect(() => {
    if (!finding.cloudId || !finding.generatorId) return;
    const generateFilter = async (): Promise<void> => {
      const { generatorId, cloudId } = finding;
      const source = sourceFromFinding(finding);

      if (source === 'Barracuda Compliance Scanner') {
        const rules = filtersFromSelectedControls([generatorId]);
        const filter = {};
        if (cloudId) {
          filter.accounts = {
            name: 'cloudId',
            value: [cloudId],
          };
        }
        if (rules) {
          filter.selectedRules = rules;
        }
        const result = await compressFilter(filter);
        setFilter(result);
      }
    };
    generateFilter();
  }, [finding]);

  const resourceName = finding?.resource?.name || '';
  const parentResourceName = parentResource?.name;
  const [resourceFilter, setResourceFilter] = useState<string | null>(null);
  useEffect(() => {
    const generateFilter = async (): Promise<void> => {
      const filter = {};

      if (finding.cloudId) {
        filter.accounts = {
          name: 'cloudId',
          value: [finding.cloudId],
        };
      }

      filter.nameQuery = {
        name: 'name',
        value: parentResourceName || resourceName || '',
        operator: 'contains',
      };
      const result = await compressFilter(filter);
      setResourceFilter(result);
    };
    generateFilter();
  }, [finding.cloudId, resourceName, parentResourceName]);

  const source = sourceFromFinding(finding);

  return (
    <>
      <div className={classes.actionContainer}>
        {suppressStatus.error && (
          <StatusMessage
            status="error"
            message={suppressStatus.error}
            style={{ margin: '8px 24px' }}
            removable
          />
        )}
        {(finding?.workflow?.status === 'NEW' || loading) && (
          <RBACButton
            color="secondary"
            disabled={suppressStatus.fetching || loading}
            processing={suppressStatus.fetching}
            onClick={handleSuppress}
            actionLabel="suppress this finding."
            style={{ marginRight: '8px' }}
            data-test-id="finding-suppress-button"
            variant="contained"
          >
            Suppress
          </RBACButton>
        )}
        {finding?.workflow?.status === 'SUPPRESSED' && (
          <RBACButton
            color="secondary"
            disabled={suppressStatus.fetching}
            processing={suppressStatus.fetching}
            onClick={handleUnsuppress}
            actionLabel="unsuppress this finding."
            style={{ marginRight: '8px' }}
            data-test-id="finding-unsuppress-button"
            variant="contained"
          >
            Unsuppress
          </RBACButton>
        )}
      </div>
      <div className={classes.details}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>{loading ? <Skeleton /> : 'Source'}</TableCell>
              <TableCell>
                {loading ? (
                  <Skeleton />
                ) : (
                  <span style={{ display: 'flex', alignItems: 'center' }}>
                    {source}
                    {finding?.externalLink && (
                      <a
                        href={finding.externalLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ display: 'inline-flex', alignItems: 'center', marginLeft: 8 }}
                        data-test-id="finding-view-external-link"
                      >
                        <LaunchIcon fontSize="small" />{' '}
                        {{
                          'Azure Security Center': 'View in Azure Portal',
                          'AWS Security Hub': 'View in AWS Console',
                        }?.[source] || 'View External Link'}
                      </a>
                    )}
                  </span>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{loading ? <Skeleton /> : 'Connection'}</TableCell>
              <TableCell>
                {loading ? (
                  <Skeleton />
                ) : (
                  <AccountNameWithIcon className={classes.account} accountId={finding?.cloudId} />
                )}
              </TableCell>
            </TableRow>
            {Boolean(finding?.resource) && (
              <TableRow>
                <TableCell>{loading ? <Skeleton /> : 'Resource'}</TableCell>
                <TableCell>
                  {loading ? (
                    <Skeleton />
                  ) : (
                    <>
                      {assetTypes.has(finding?.resource?.type) ||
                      assetTypes.has(parentResource?.type) ? (
                        <Link
                          to={`/resources?cloudId=${finding?.cloudId}&resourceId=${
                            finding?.resource?.parentId
                              ? finding?.resource?.parentId
                              : finding?.resource?.id
                          }&filter=${resourceFilter}`}
                          data-test-id="finding-resource-link"
                        >
                          <ResourceDisplay
                            provider={finding?.cloudProvider}
                            resource={finding?.resource}
                            noLeftPadding
                          />
                        </Link>
                      ) : (
                        <ResourceDisplay
                          provider={finding?.cloudProvider}
                          resource={finding?.resource}
                          noLeftPadding
                        />
                      )}
                    </>
                  )}
                </TableCell>
              </TableRow>
            )}
            {Boolean(
              finding?.cloudProvider === 'azure' && (finding?.resource?.id ?? '').split('/')?.[4],
            ) && (
              <TableRow>
                <TableCell>{loading ? <Skeleton /> : 'Resource Group'}</TableCell>
                <TableCell>
                  {loading ? <Skeleton /> : (finding?.resource?.id ?? '').split('/')?.[4]}
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell>{loading ? <Skeleton /> : 'Region'}</TableCell>
              <TableCell>
                {loading ? (
                  <Skeleton />
                ) : (
                  regionNames?.[finding?.resource?.region] ?? finding?.resource?.region
                )}
              </TableCell>
            </TableRow>
            {source !== 'Azure Security Center' && (
              <TableRow>
                <TableCell>{loading ? <Skeleton /> : 'Resource Type'}</TableCell>
                <TableCell>
                  {loading ? (
                    <Skeleton />
                  ) : (
                    assetNames[finding?.resource?.type] || finding?.resource?.type
                  )}
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell>{loading ? <Skeleton /> : 'Title'}</TableCell>
              <TableCell>
                {loading ? (
                  <Skeleton />
                ) : (
                  riskInfoFromFinding(finding)?.title || finding?.compliance?.title || ''
                )}
              </TableCell>
            </TableRow>
            {source === 'Barracuda Compliance Scanner' && (
              <TableRow>
                <TableCell>{loading ? <Skeleton /> : 'Control'}</TableCell>
                <TableCell>
                  {loading ? (
                    <Skeleton />
                  ) : (
                    <>
                      <ComplianceFromFinding finding={finding} />
                      {resourceType === 'risk' ? (
                        <Link
                          to={`/security-findings?filter=${filter}`}
                          style={{ marginLeft: 10 }}
                          data-test-id="finding-all-findings-link"
                        >
                          See all findings for this control
                        </Link>
                      ) : (
                        <Link
                          to={`/security-risks?filter=${filter}`}
                          style={{ marginLeft: 10 }}
                          data-test-id="finding-all-risks-link"
                        >
                          See all risks for this control
                        </Link>
                      )}
                    </>
                  )}
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell>{loading ? <Skeleton /> : 'Severity'}</TableCell>
              <TableCell>
                {loading ? (
                  <Skeleton />
                ) : (
                  <FindingLabel severity={finding?.severity?.label ?? ''} noRounding />
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{loading ? <Skeleton /> : 'Result'}</TableCell>
              <TableCell>
                {loading ? (
                  <Skeleton />
                ) : (
                  <ControlResult result={finding?.compliance?.status ?? ''} />
                )}
              </TableCell>
            </TableRow>
            {Boolean(finding?.compliance?.reason) && (
              <TableRow>
                <TableCell>{loading ? <Skeleton /> : 'Reason'}</TableCell>
                <TableCell>{loading ? <Skeleton /> : finding?.compliance?.reason}</TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell>{loading ? <Skeleton /> : 'Status'}</TableCell>
              <TableCell>
                {loading ? <Skeleton /> : workflowStatus(finding?.workflow?.status?.toUpperCase())}
              </TableCell>
            </TableRow>
            {finding?.firstObservedAt && (
              <TableRow>
                <TableCell>{loading ? <Skeleton /> : 'First Observed'}</TableCell>
                <TableCell>
                  {loading ? (
                    <Skeleton />
                  ) : (
                    <Timestamp ts={finding?.firstObservedAt} tsFormat="ISO" />
                  )}
                </TableCell>
              </TableRow>
            )}
            {finding?.lastObservedAt && (
              <TableRow>
                <TableCell>{loading ? <Skeleton /> : 'Last Observed'}</TableCell>
                <TableCell>
                  {loading ? (
                    <Skeleton />
                  ) : (
                    <Timestamp ts={finding?.lastObservedAt} tsFormat="ISO" />
                  )}
                </TableCell>
              </TableRow>
            )}
            {finding?.updatedAt && (
              <TableRow>
                <TableCell>{loading ? <Skeleton /> : 'Updated'}</TableCell>
                <TableCell>
                  {loading ? <Skeleton /> : <Timestamp ts={finding?.updatedAt} tsFormat="ISO" />}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </>
  );
}
