import React from 'react';

import { Button, Grid, TextField } from '@cuda-networks/bds-core';
import { ErrorMessage } from '@hookform/error-message';
import { FormHelperText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Controller, useForm } from 'react-hook-form';

import StatusMessage from 'components/common/StatusMessage';
import useMixpanel from 'hooks/useMixpanel';
import useRequest from 'hooks/useRequest';

const useStyles = makeStyles(
  theme => ({
    providerButton: {
      border: '2px solid transparent',
      cursor: 'pointer',
      padding: '8px',
      '&:hover': {
        borderRadius: '5px',
        borderColor: theme.palette.primary.main,
      },
    },
    container: {
      '& > div': {
        marginBottom: '20px',
      },
    },
    text: {
      fontSize: '1.2rem',
    },
    list: {
      padding: '0 0 0 24px',
      margin: 0,
      fontSize: '.75rem',
      '& li > p': {
        margin: '8px 0',
        '&$listItem': {
          margin: 0,
        },
      },
    },
    listItem: {
      fontSize: '1rem',
    },
  }),
  {
    name: 'ChooseProvider',
  },
);

const defaultValues = {
  subscriptionId: '',
  tenantId: '',
  clientId: '',
  clientSecret: '',
};
const required = ['subscriptionId', 'tenantId', 'clientId', 'clientSecret'];

export default function DeployAzureLegacy({ onSubmit }): JSX.Element {
  const classes = useStyles();
  const mixpanel = useMixpanel();

  const { handleSubmit, control, errors, formState } = useForm({
    defaultValues,
  });
  const { isDirty, dirtyFields } = formState;
  const valid = isDirty && required.every(f => dirtyFields?.[f]);

  const [addCloudStatus, addCloudStarted, addCloudSucceeded, addCloudFailed] = useRequest();
  const submit = async (form): void => {
    addCloudStarted();
    mixpanel.track('Submitted manual on-board', {
      subscriptionId: form.subscriptionId,
      tenantId: form.tenantId,
      clientId: form.clientId,
    });
    try {
      await onSubmit(form);
      addCloudSucceeded();
      mixpanel.track('Manual on-board succeeded', {
        subscriptionId: form.subscriptionId,
        tenantId: form.tenantId,
        clientId: form.clientId,
      });
    } catch (e) {
      addCloudFailed(
        e?.networkError?.result?.message || e?.message || 'Failed to create cloud connection',
      );
      mixpanel.track('Manual on-board failed', {
        subscriptionId: form.subscriptionId,
        tenantId: form.tenantId,
        clientId: form.clientId,
        error:
          e?.networkError?.result?.message || e?.message || 'Failed to create cloud connection',
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(submit)} noValidate>
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          {addCloudStatus?.error && (
            <StatusMessage status="error" message={addCloudStatus?.error} removable />
          )}
          {addCloudStatus?.succeeded && (
            <StatusMessage status="success" message="Successfully on-boarded 1 subscription(s)." />
          )}
          <Controller
            as={
              <TextField
                label="Subscription ID"
                required
                disabled={addCloudStatus?.fetching}
                fullWidth
                error={Boolean(errors?.subscriptionId)}
              />
            }
            name="subscriptionId"
            control={control}
            rules={{ required: 'Required' }}
          />
          <ErrorMessage as={<FormHelperText error />} errors={errors} name="subscriptionId" />
          <Controller
            as={
              <TextField
                label="Directory/Tenant ID"
                required
                disabled={addCloudStatus?.fetching}
                fullWidth
                error={Boolean(errors?.tenantId)}
              />
            }
            name="tenantId"
            control={control}
            rules={{ required: 'Required' }}
          />
          <ErrorMessage as={<FormHelperText error />} errors={errors} name="tenantId" />
          <Controller
            as={
              <TextField
                label="Application/Client ID"
                required
                disabled={addCloudStatus?.fetching}
                fullWidth
                error={Boolean(errors?.clientId)}
              />
            }
            name="clientId"
            control={control}
            rules={{ required: 'Required' }}
          />
          <ErrorMessage as={<FormHelperText error />} errors={errors} name="clientId" />
          <Controller
            as={
              <TextField
                label="Secret Key"
                required
                disabled={addCloudStatus?.fetching}
                fullWidth
                error={Boolean(errors?.clientSecret)}
              />
            }
            name="clientSecret"
            control={control}
            rules={{ required: 'Required' }}
          />
          <ErrorMessage as={<FormHelperText error />} errors={errors} name="clientSecret" />
        </Grid>
      </Grid>
      {!addCloudStatus.succeeded && (
        <Button type="submit" color="primary" autoFocus disabled={!valid}>
          Connect
        </Button>
      )}
    </form>
  );
}
