import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { useHistory, Switch, Route, Redirect } from 'react-router-dom';

import CloudConnections from 'components/CloudConnections';
import Dashboard from 'components/Dashboard';
import License from 'components/License';
import Page from 'components/Page';
import Reports from 'components/Reports';
import Resources from 'components/Resources';
import SecurityFindings from 'components/SecurityFindings';
import SecurityPolicy from 'components/SecurityPolicy';
import SecurityRisks from 'components/SecurityRisks';
import Users from 'components/Users';

const useStyles = makeStyles(
  theme => ({
    content: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      overflowX: 'hidden',
    },
    toolbar: {
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
  }),
  {
    name: 'MainContent',
  },
);

interface MainContentProps {
  setup: boolean;
}

const MainContent = (props: MainContentProps): JSX.Element => {
  const { setup } = props;
  const classes = useStyles();
  const history = useHistory();

  const handleGetStarted = (): void => history.push('/cloud-connections?getStarted=true');

  return (
    <div className={classes.content}>
      <div className={classes.toolbar} />
      <Switch>
        <Route
          exact
          path="/dashboard"
          render={(): JSX.Element => (
            <Page handleGetStarted={handleGetStarted} setup={setup} page="/dashboard">
              <Dashboard />
            </Page>
          )}
        />
        <Route
          exact
          path="/security-risks"
          render={(): JSX.Element => (
            <Page handleGetStarted={handleGetStarted} setup={setup} page="/security-risks">
              <SecurityRisks />
            </Page>
          )}
        />
        <Route
          exact
          path="/security-policies"
          render={(): JSX.Element => (
            <Page handleGetStarted={handleGetStarted} setup={setup} page="/security-policies">
              <SecurityPolicy />
            </Page>
          )}
        />
        <Route
          exact
          path="/security-findings"
          render={(): JSX.Element => (
            <Page handleGetStarted={handleGetStarted} setup={setup} page="/security-findings">
              <SecurityFindings />
            </Page>
          )}
        />
        <Route
          exact
          path="/cloud-connections"
          render={(): JSX.Element => (
            <Page handleGetStarted={handleGetStarted} setup={setup} page="/cloud-connections">
              <CloudConnections />
            </Page>
          )}
        />
        <Route
          exact
          path="/resources"
          render={(): JSX.Element => (
            <Page handleGetStarted={handleGetStarted} setup={setup} page="/resources">
              <Resources />
            </Page>
          )}
        />
        <Route
          exact
          path="/license"
          render={(): JSX.Element => (
            <Page handleGetStarted={handleGetStarted} setup={setup} page="/license">
              <License />
            </Page>
          )}
        />
        <Route
          exact
          path="/reports"
          render={(): JSX.Element => (
            <Page handleGetStarted={handleGetStarted} setup={setup} page="/reports">
              <Reports />
            </Page>
          )}
        />
        <Route
          exact
          path="/users"
          render={(): JSX.Element => (
            <Page handleGetStarted={handleGetStarted} setup={setup} page="/users">
              <Users />
            </Page>
          )}
        />
        <Redirect to="/dashboard" />
      </Switch>
    </div>
  );
};

export default MainContent;
