import { useState } from 'react';

import { parseQS } from 'utils/params';

const parsedQS = parseQS();

interface Pagination {
  page: number;
  size: number;
  total: number;
}

interface UserPaginationProps {
  paginationKey?: string;
}
export default function usePagination(
  props?: UserPaginationProps,
): [Pagination, (newPagination: Partial<Pagination>) => void] {
  const { paginationKey } = props || {};

  const [pagination, setPagination] = useState<Pagination>({
    page: paginationKey ? parseInt((parsedQS?.[paginationKey] as string) || '0', 10) : 0,
    size: 50,
    total: 0,
  });
  const handlePaginate = (newPagination: Partial<Pagination>): void => {
    const updatedPagination = {
      ...pagination,
      ...newPagination,
    };

    setPagination(updatedPagination);

    if (paginationKey) {
      const queryString = Object.entries(parsedQS)
        .filter(([key]) => key !== paginationKey)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');

      window.history.replaceState(
        {},
        '',
        `${window.location.pathname}?${queryString ? `${queryString}&` : ''}${paginationKey}=${
          updatedPagination.page
        }`,
      );
    }
  };

  return [pagination, handlePaginate];
}
