import React, { useEffect, useState } from 'react';

import { useQuery, useLazyQuery } from '@apollo/client';
import { CircularProgress, Grid } from '@cuda-networks/bds-core';
import { PieChart, Pie, Legend, Cell, Tooltip } from 'recharts';

import StatusMessage from 'components/common/StatusMessage';
import { LIST_CONNECTIONS } from 'graphql/Accounts';
import { SUMMARY_REPORT } from 'graphql/SecurityFindings';
import { providerFromCloudId } from 'lib';
import { ComplianceCounts, ComplianceGraphData, ListConnectionsData, Request } from 'types';
import pdf from 'utils/pdf';

const generateSeriesData = (results: ComplianceCounts): ComplianceGraphData[] => [
  {
    name: 'Compliant',
    color: '#95CC66',
    y: results.passed,
  },
  {
    name: 'Noncompliant',
    color: '#E34050',
    y: results.failed,
  },
  {
    name: 'Skipped',
    color: '#5bc0de',
    y: results.skipped,
  },
];

interface ComplianceSummaryReportProps {
  scanInfo: unknown;
  requestStatus: Request;
}

export default function ComplianceSummaryReport(
  props: ComplianceSummaryReportProps,
): React.ReactElement {
  const { scanInfo, requestStatus } = props;
  const [reportStatus, reportStarted, reportSucceeded, reportFailed] = requestStatus;

  const { data: listConnectionsData } = useQuery<ListConnectionsData>(LIST_CONNECTIONS, {
    context: { clientName: 'accounts' },
  });
  const { cloudId, scanType, policy, standards } = scanInfo;

  const [data, setData] = useState([]);

  const [standard, provider, version] = scanType.split(':');

  const [getData] = useLazyQuery(SUMMARY_REPORT, {
    fetchPolicy: 'no-cache',
    context: { clientName: 'findings' },
    notifyOnNetworkStatusChange: true,
    variables: {
      input: {
        cloudId,
        standard: standards.map(s => s?.split(':')?.[0]).filter(s => Boolean(s)),
      },
    },
    onCompleted: scanData => {
      const newData = data.concat(scanData.reportSummary);
      setData(newData);
      reportSucceeded();
    },
    onError: e => {
      reportFailed(e?.networkError?.result?.message || e?.message || 'Report generation failed.');
    },
  });

  useEffect(() => {
    reportStarted();
    setData([]);

    getData();
  }, [getData, reportStarted, scanInfo]);

  interface AggregatedFinding {
    failed?: number;
    passed?: number;
    error?: number;
  }

  interface ResultsCount {
    failed: number;
    passed: number;
    skipped: number;
  }

  const [aggregated, setAggregated] = useState<AggregatedFinding[] | null>(null);
  const [resultCounts, setResultCounts] = useState<ComplianceGraphData[] | null>(null);
  useEffect(() => {
    if (reportStatus.fetching || !data.length) {
      return;
    }

    const exclusionMap = standards.reduce((obj, s) => {
      const [standard, provider, version] = s.split(':');

      return (policy?.[standard]?.[provider]?.[version]?.exclusions || []).reduce(
        (policyMap, control) => ({
          ...policyMap,
          [`${standard}:${provider}:${version}:${control}`]: true,
        }),
        obj,
      );
    }, {});

    const aggregatedResults = data.reduce((obj, d) => {
      const { cloudId, control, count, standard, status, workflowStatus, version } = d;
      const provider = providerFromCloudId(cloudId);

      const key = `${standard}:${provider}:${version}`;
      const generatorId = `${standard}:${provider}:${version}:${control}`;
      const resultStatus = workflowStatus === 'SUPPRESSED' ? 'SUPPRESSED' : status;

      return {
        ...obj,
        [key]: {
          ...(obj?.[key] || {}),
          [control]: {
            ...(obj?.[key]?.[control] || {}),
            [resultStatus.toLowerCase()]: count,
            ...(exclusionMap?.[generatorId] ? { skipped: true } : {}),
          },
        },
      };
    }, {});

    const counts: ComplianceCounts = Object.entries(aggregatedResults).reduce(
      (counts, [key, results]) => {
        return Object.entries(results).reduce((obj, [control, result]) => {
          if (exclusionMap?.[`${key}:${control}`]) {
            return {
              ...obj,
              skipped: obj.skipped + 1,
            };
          }
          if (result.failed) {
            return {
              ...obj,
              failed: obj.failed + 1,
            };
          }

          return {
            ...obj,
            passed: obj.passed + 1,
          };
        }, counts);
      },
      {
        passed: 0,
        failed: 0,
        skipped: 0,
      },
    );

    setAggregated(aggregatedResults);
    setResultCounts(generateSeriesData(counts));
  }, [data, policy, provider, reportStatus.fetching, standard, standards, version]);

  useEffect(() => {
    if (!aggregated) {
      return;
    }

    const matchedCloud = (listConnectionsData?.listConnections?.connections || []).find(
      c => c.cloudId === cloudId,
    );

    pdf.generateComplianceSummary(
      {
        account: matchedCloud?.name ?? cloudId,
        time: Date.now(),
        cloudId,
        provider,
        scanType: standard,
      },
      aggregated,
      'complianceGraph',
    );
  }, [aggregated, cloudId, listConnectionsData, provider, standard]);

  return (
    <Grid container spacing={3} style={{ padding: '15px' }}>
      <Grid item xs={12}>
        {reportStatus.fetching ? (
          <CircularProgress />
        ) : (
          <>
            {reportStatus.error && (
              <StatusMessage status="error" message={reportStatus.error} removable />
            )}
            {reportStatus.succeeded && !data.length && (
              <StatusMessage status="info" message="No data available for this report." removable />
            )}
            <div style={{ width: '350px', height: '350px', display: 'none' }} id="complianceGraph">
              {resultCounts?.length ? (
                <PieChart width={350} height={350}>
                  <Pie
                    isAnimationActive={false}
                    data={resultCounts}
                    dataKey="y"
                    nameKey="name"
                    cx="40%"
                    cy="40%"
                    innerRadius={70}
                    outerRadius={90}
                  >
                    {resultCounts.map(entry => (
                      <Cell key={`cell-${entry.name}`} fill={entry.color} />
                    ))}
                  </Pie>
                  <Legend verticalAlign="top" align="right" layout="vertical" />
                  <Tooltip />
                </PieChart>
              ) : (
                'No data in this time range'
              )}
            </div>
          </>
        )}
      </Grid>
    </Grid>
  );
}
