import React, { useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';
import { Button, Grid, Divider } from '@cuda-networks/bds-core';
import { makeStyles } from '@material-ui/core/styles';

import LoadingIndicator from 'components/common/LoadingIndicator';
import PageTitle from 'components/common/PageTitle';
import { FindingGroup, RiskDetails, FindingsFilters } from 'components/SecurityFindings';
import { FindingsGraphQLFilters } from 'components/SecurityFindings/FindingsFilters';
import { riskInfoFromFinding } from 'components/SecurityFindings/lib';
import { SecurityScanButton } from 'components/SecurityRisks';
import { GROUPS, RISK_FINDINGS } from 'graphql/SecurityFindings';
import useRequest from 'hooks/useRequest';

const useStyles = makeStyles(
  theme => ({
    wrapper: {
      position: 'relative',
      flex: '1 1',
      borderTop: '1px solid #DDDDDD',
    },
    left: {
      width: '250px',
      height: '100%',
      position: 'absolute',
      borderRight: '1px solid #DDDDDD',
      zIndex: 1,
      [theme.breakpoints.up('md')]: {
        width: '350px',
      },
      display: 'flex',
      flexDirection: 'column',
      flex: '1 1',
      overflowY: 'hidden',
    },
    right: {
      flex: '1',
      position: 'absolute',
      height: '100%',
      width: '100%',
      paddingLeft: '250px',
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.up('md')]: {
        paddingLeft: '350px',
      },
    },

    filter: {
      '& > div': {
        margin: '0 8px 8px 0',
      },
      borderTop: '1px solid #DDDDDD',
      padding: 8,
    },

    groupList: {
      overflowY: 'auto',
      height: '100%',
    },
  }),
  {
    name: 'Findings',
  },
);

export default function SecurityRisks(): JSX.Element {
  const classes = useStyles();

  const [filters, setFilters] = useState<FindingsGraphQLFilters | null>(null);

  const [selectedGroup, setGroup] = useState(null);
  const [filterId, setFilterId] = useState(Date.now().toString());

  const groupsResponse = useQuery(GROUPS, {
    skip: !filters,
    variables: {
      cursor: { limit: 100 },
      filter: {
        orderBy: ['severity.index DESC'],
        id: filterId,
        where: [
          ...(filters?.filter || []),
          {
            name: 'compliance.status',
            value: 'FAILED',
          },
        ],
        // ...(filters?.filter?.length && { where: filters.filter }),
      },
      ...(Boolean(filters?.filters) && { filters: filters?.filters }),
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
    notifyOnNetworkStatusChange: true,
    context: { clientName: 'findings' },
  });

  const { data: groupsData, fetchMore } = groupsResponse;
  const [groupsStatus, groupsStart, groupsSucceeded, groupsFailed, groupsPaginate] = useRequest();
  useEffect(() => {
    const { networkStatus, error } = groupsResponse;

    if (
      !groupsStatus.paginating &&
      (networkStatus === 1 || networkStatus === 2 || networkStatus === 4)
    ) {
      groupsStart();
    } else if (networkStatus === 3) {
      groupsPaginate();
    } else if (networkStatus === 7) {
      groupsSucceeded();
    } else if (networkStatus === 8 || error) {
      groupsFailed(error?.message ?? 'Request failed');
    }
  }, [
    groupsFailed,
    groupsPaginate,
    groupsResponse,
    groupsStart,
    groupsSucceeded,
    groupsStatus.paginating,
  ]);

  const {
    loading: findingsLoading,
    error: findingsError,
    data: findingsData,
  } = useQuery(RISK_FINDINGS, {
    skip: !selectedGroup?.cloudId || !selectedGroup?.generatorId,
    variables: {
      cursor: { offset: 0 },
      filter: {
        orderBy: ['lastObservedAt DESC'],
        id: filterId,
        where: [
          ...(filters?.filter || []),
          {
            name: 'generatorId',
            value: [selectedGroup?.generatorId],
          },
          ...(selectedGroup?.title ? [{ name: 'title', value: selectedGroup?.title }] : []),
          {
            name: 'compliance.status',
            value: 'FAILED',
          },
          {
            name: 'severity.label',
            value: selectedGroup?.severity,
          },
          {
            name: 'cloudId',
            value: selectedGroup?.cloudId,
          },
        ],
      },
    },
    fetchPolicy: 'network-only',
    context: { clientName: 'findings' },
  });

  const handleRefresh = () => {
    setFilterId(Date.now().toString());
  };

  useEffect(() => {
    if (groupsStatus.fetching) {
      setGroup(null);
    } else if (!selectedGroup && groupsStatus.succeeded) {
      setGroup((groupsData?.groups ?? []).length ? groupsData.groups[0] : {});
    }
  }, [groupsData, groupsStatus, selectedGroup, setGroup]);

  const paginate = () => {
    if (groupsStatus.paginating || groupsStatus.fetching) {
      return;
    }

    fetchMore({
      variables: {
        cursor: { limit: 100, offset: (groupsData?.groups ?? []).length },
      },
    });
  };

  const handleScroll = event => {
    const bottom = event.target.scrollHeight - event.target.scrollTop <= event.target.clientHeight;
    if (bottom) {
      paginate();
    }
  };

  return (
    <>
      <PageTitle title="Security Risks">
        <div style={{ display: 'flex' }}>
          <Button
            color="secondary"
            onClick={handleRefresh}
            disabled={groupsStatus.fetching || groupsStatus.paginating}
            style={{ marginRight: '8px' }}
            data-test-id="risks-refresh-button"
            variant="contained"
          >
            Refresh
          </Button>
          <SecurityScanButton disabled={groupsStatus.fetching || groupsStatus.paginating} />
        </div>
      </PageTitle>
      <Grid className={classes.filter}>
        <FindingsFilters
          updateFilters={setFilters}
          hideSeverities
          hideResults
          hideStatuses
          // hideResourceTypes
          // hideResources
        />
      </Grid>
      <Grid container spacing={0} className={classes.wrapper}>
        <Grid item className={classes.left}>
          <Grid className={classes.groupList} onScroll={handleScroll}>
            {(groupsStatus.fetching || !filters) && <LoadingIndicator padded />}
            {!groupsStatus.fetching && Boolean((groupsData?.groups ?? []).length) && (
              <>
                {groupsData.groups.map((group, index) => (
                  <React.Fragment
                    key={`${group.cloudId}${group.generatorId}${group.severity}${group.title}`}
                  >
                    <FindingGroup
                      selected={
                        `${group.cloudId}${group.generatorId}${group.title}${group.severity}` ===
                        `${selectedGroup?.cloudId}${selectedGroup?.generatorId}${selectedGroup?.title}${selectedGroup?.severity}`
                      }
                      group={group}
                      rule={riskInfoFromFinding(group)}
                      onClick={() => setGroup(group)}
                      data-test-id={`risk-group-${index}`}
                    />
                    <Divider />
                  </React.Fragment>
                ))}
                <LoadingIndicator
                  padded
                  style={{ visibility: groupsStatus.paginating ? 'visible' : 'hidden' }}
                />
              </>
            )}
            {Boolean(
              !(groupsStatus.fetching || !filters) && !(groupsData?.groups ?? []).length,
            ) && <span style={{ padding: '12px 8px' }}>No risks found</span>}
          </Grid>
        </Grid>
        <Grid item className={classes.right}>
          {findingsLoading && <LoadingIndicator padded />}
          {selectedGroup?.cloudId && !findingsLoading && (
            <RiskDetails
              riskInfo={riskInfoFromFinding(selectedGroup)}
              findings={findingsData?.findings ?? []}
              error={findingsError}
              cloudId={selectedGroup?.cloudId}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}
