import React from 'react';

import { useQuery } from '@apollo/client';
import { Dialog, DialogContent } from '@cuda-networks/bds-core';

import DialogTitle from 'components/common/DialogTitle';
import StatusMessage from 'components/common/StatusMessage';
import { ResourceDetails } from 'components/Resources';
import { RESOURCE } from 'graphql/Resources';

interface ResourceDetailsDialogProps {
  resourceId: string;
  cloudId: string;
  onClose: () => void;
  open: boolean;
}

export default function ResourceDetailsDialog(
  props: ResourceDetailsDialogProps,
): React.ReactElement {
  const { resourceId, cloudId, onClose, open } = props;
  const { loading, data, error } = useQuery(RESOURCE, {
    skip: !resourceId,
    variables: {
      resourceId,
      cloudId,
    },
    fetchPolicy: 'no-cache',
    context: { clientName: 'resources' },
  });

  return (
    <Dialog maxWidth="sm" fullWidth onClose={onClose} open={open}>
      <DialogTitle handleClose={onClose}>Resource Details</DialogTitle>
      <DialogContent
        style={{
          padding: 0,
          display: 'flex',
          height: '100%',
          flexDirection: 'column',
          overflowY: 'hidden',
        }}
      >
        {error && <StatusMessage status="error" message="Failed to load resource info" />}
        <ResourceDetails resource={data?.resource ?? {}} loading={loading} />
      </DialogContent>
    </Dialog>
  );
}
