import React, { useState, useEffect } from 'react';

import { useQuery } from '@apollo/client';
import { colors, Tooltip } from '@cuda-networks/bds-core';
import { makeStyles } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/Error';
import clsx from 'clsx';

import { LIST_CONNECTIONS } from 'graphql/Accounts';
import { AWSIcon, AzureIcon } from 'icons';
import { providerFromCloudId } from 'lib';
import { ListConnectionsData } from 'types';

const useStyles = makeStyles(
  {
    accountCell: {
      display: 'flex',
      alignItems: 'center',
    },
    small: {
      '& > svg': {
        height: '18px',
      },
    },
    iconWrapper: {
      display: 'flex',
      position: 'relative',
      marginRight: '5px',
    },
    statusIcon: {
      height: '14px',
      width: '14px',
      position: 'absolute',
      left: '-6px',
      bottom: 0,
      color: colors.statusCritical,
    },
  },
  {
    name: 'AccountNameWithIcon',
  },
);

interface AccountNameWithIconProps {
  accountId: string;
  children?: JSX.Element | string;
  className?: string;
  small?: boolean;
  noTooltip?: boolean;
}

export default function AccountNameWithIcon(props: AccountNameWithIconProps): JSX.Element {
  const { children, className, accountId, small = false, noTooltip = false } = props;
  const classes = useStyles();
  const { data: listConnectionsData } = useQuery<ListConnectionsData>(LIST_CONNECTIONS, {
    context: { clientName: 'accounts' },
  });

  const [provider, setProvider] = useState(null);
  const [name, setName] = useState(null);
  const [status, setStatus] = useState<'HEALTHY' | 'UNHEALTHY'>('HEALTHY');

  useEffect(() => {
    const cloudProvider = providerFromCloudId(accountId);
    const matchedCloud = (listConnectionsData?.listConnections?.connections || []).find(
      c => c.cloudId === accountId,
    );

    setStatus(matchedCloud?.health === 'Good' ? 'HEALTHY' : 'UNHEALTHY');
    setName(matchedCloud?.name ?? accountId);
    setProvider(cloudProvider);
  }, [accountId, setProvider, setName, listConnectionsData]);

  return (
    <span
      className={clsx(classes.accountCell, className, { [classes.small]: small })}
      data-test-id="connection-name"
    >
      {provider && (
        <div className={classes.iconWrapper}>
          {
            {
              aws: <AWSIcon data-test-id="connection-icon-aws" />,
              azure: <AzureIcon data-test-id="connection-icon-azure" />,
            }[provider]
          }
          {status === 'UNHEALTHY' && (
            <Tooltip
              title="This Cloud Connection has an error. Go to the Cloud Connections page for more details."
              PopperProps={{
                'data-test-id': 'connection-health-tooltip',
              }}
              {...(noTooltip && { open: false })}
            >
              <ErrorIcon className={classes.statusIcon} data-test-id="connection-health-icon" />
            </Tooltip>
          )}
        </div>
      )}
      {name}
      {children}
    </span>
  );
}
