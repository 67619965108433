import React from 'react';

import { Card, Grid, CircularProgress, Divider } from '@cuda-networks/bds-core';
import { makeStyles } from '@material-ui/core/styles';
import GetAppIcon from '@material-ui/icons/GetApp';
import RefreshIcon from '@material-ui/icons/Refresh';
import SettingsIcon from '@material-ui/icons/Settings';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import SeparatePaginator from 'components/common/SeparatePaginator';
import ColumnChooser from 'components/common/table/ColumnChooser';
import HeaderButton from 'components/common/table/HeaderButton';

const useStyles = makeStyles(
  {
    tableRoot: {
      width: '100%',
      overflowX: 'auto',
      overflowY: 'hidden',
      margin: 0,
      '& table': {
        backgroundColor: '#FFFFFF',
        position: 'relative',
        '& thead': {
          '& tr': {
            height: '50px',
          },
        },
        '& tr': {
          height: '32px',
          borderLeft: '1px solid #EEEEEE',
          borderRight: '1px solid #EEEEEE',
        },
        '& td': {
          padding: '4px 12px',
        },
        '& th': {
          padding: '4px 12px',
        },
      },
    },
    paginateWrapper: {
      width: '100%',
      display: 'flex',
      border: '1px solid #DDDDDD',
    },
    paginateBot: {
      borderTop: 0,
      borderRadius: '0 0 2px 2px',
    },
    paginateTop: {
      borderBottom: 0,
      borderRadius: '2px 2px 0 0',
    },
    loading: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  {
    name: 'PaginatedTable',
  },
);

export default function PaginatedTable({
  children,
  handlePaginate,
  pagination,
  loading,
  exporting,
  columns,
  toggleColumn,
  handleExportToJSON,
  handleRefresh,
  rowsPerPageOptions,
  'data-test-id': dataTestId,
}): JSX.Element {
  const classes = useStyles();

  const handleChangePage = (event, page) => {
    handlePaginate({
      size: pagination.size,
      page,
    });
  };

  const handleChangeRowsPerPage = event => {
    const page = Math.floor((pagination.page * pagination.size) / event.target.value);

    handlePaginate({
      size: event.target.value,
      page,
    });
  };

  return (
    <div data-test-id={dataTestId}>
      <Grid
        container
        spacing={0}
        className={clsx(classes.paginateWrapper, classes.paginateTop)}
        justify="flex-end"
      >
        <Grid item xs={6} container>
          {Boolean(handleExportToJSON) && (
            <>
              <HeaderButton
                value="Export JSON"
                icon={<GetAppIcon />}
                onClick={handleExportToJSON}
                disabled={loading || exporting}
                loading={exporting}
                data-test-id="table-export-json"
              />
              <Divider orientation="vertical" flexItem />
            </>
          )}
          {Boolean(toggleColumn) && (
            <>
              <ColumnChooser
                toggleColumn={toggleColumn}
                columns={columns}
                value="Columns"
                icon={<SettingsIcon />}
                disabled={loading || exporting}
                data-test-id="table-column-chooser"
              />
              <Divider orientation="vertical" flexItem />
            </>
          )}
          {Boolean(handleRefresh) && (
            <>
              <HeaderButton
                value="Refresh"
                icon={<RefreshIcon />}
                onClick={handleRefresh}
                disabled={loading || exporting}
                data-test-id="table-refresh-button"
              />
              <Divider orientation="vertical" flexItem />
            </>
          )}
        </Grid>
        <Grid item xs={6} data-test-id="top-paginator-wrapper" container justify="flex-end">
          <SeparatePaginator
            count={pagination.total}
            page={pagination.page}
            rowsPerPage={pagination.size}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPageOptions={rowsPerPageOptions}
          />
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Card className={clsx(classes.tableRoot, { [classes.loading]: loading })} padding={false}>
            {loading ? <CircularProgress style={{ margin: '10px 0' }} /> : children}
          </Card>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={0}
        className={clsx(classes.paginateWrapper, classes.paginateBot)}
        justify="flex-end"
      >
        <Grid item xs={12} data-test-id="bottom-paginator-wrapper" container justify="flex-end">
          <SeparatePaginator
            count={pagination.total}
            page={pagination.page}
            rowsPerPage={pagination.size}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPageOptions={rowsPerPageOptions}
          />
        </Grid>
      </Grid>
    </div>
  );
}

PaginatedTable.propTypes = {
  loading: PropTypes.bool,
  columns: PropTypes.object,
};

PaginatedTable.defaultProps = {
  loading: false,
  columns: [],
};
