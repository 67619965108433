import React, { useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';
import {
  Button,
  Card,
  CardContent,
  TextField,
  Grid,
  Select,
  MenuItem,
  CircularProgress,
  MenuProps as BDSMenuProps,
} from '@cuda-networks/bds-core';
import { FormControl, InputLabel } from '@material-ui/core';
import { Autocomplete as MuiAutocomplete } from '@material-ui/lab';
import { v4 as uuidv4 } from 'uuid';

import AccountNameWithIcon from 'components/common/AccountNameWithIcon';
import Autocomplete from 'components/common/Autocomplete';
import PageTitle from 'components/common/PageTitle';
import TableFilter from 'components/common/TableFilter';
import { ComplianceSummaryReport, DetailedScanReport } from 'components/Reports';
import { controlFromGeneratorId } from 'components/SecurityFindings/lib';
import { LIST_POLICIES } from 'graphql/SecurityPolicy';
import useAccountsFilter from 'hooks/useAccountsFilter';
import useRequest from 'hooks/useRequest';
import { AWSIcon, AzureIcon } from 'icons';
import { providerFromCloudId } from 'lib';
import { CloudType, ReportScanInfo } from 'types';
import { ListPoliciesData } from 'types/graphql/policies';

const MenuProps: Partial<BDSMenuProps> = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: -7,
    horizontal: 10,
  },
  getContentAnchorEl: null,
  MenuListProps: {
    disablePadding: true,
  },
};

const reportTypes = [
  // {
  //   key: 'summary',
  //   label: 'Summary Report',
  // },
  {
    key: 'complianceSummary',
    label: 'Summary Report',
  },
  {
    key: 'detailedScan',
    label: 'Detailed Scan Report',
  },
];

const scanTypes = {
  aws: ['cis:aws:v1.2.0', 'nist:aws:v1.0.0', 'hipaa:aws:v1.0.0', 'pci_dss:aws:v1.0.0'] as const,
  azure: [
    'cis:azure:v1.1.0',
    'nist:azure:v1.0.0',
    'hipaa:azure:v1.0.0',
    'pci_dss:azure:v1.0.0',
  ] as const,
};

type ScanType = typeof scanTypes.aws[number] | typeof scanTypes.azure[number];
interface ScanInfo {
  type: ScanType;
  name: string;
  cloud: 'Microsoft Azure' | 'Amazon Web Services';
}

const scanTypeList: Record<CloudType, ScanInfo[]> = {
  azure: scanTypes.azure.map(s => ({
    type: s,
    name: controlFromGeneratorId(s, ['standard', 'version']),
    cloud: 'Microsoft Azure',
  })),
  aws: scanTypes.aws.map(s => ({
    type: s,
    name: controlFromGeneratorId(s, ['standard', 'version']),
    cloud: 'Amazon Web Services',
  })),
};

export default function Reports(): JSX.Element {
  const { selectedAccount, setAccount, accountsList } = useAccountsFilter();
  const [selectedStandards, setStandards] = useState<ScanInfo[]>([]);
  const [reportType, setReportType] = useState<string>(reportTypes[0].key);
  const [scanInfo, setScanInfo] = useState<ReportScanInfo>();
  const [scanTypeOptions, setScanTypeOptions] = useState<ScanInfo[]>([]);
  const [scanType, setScanType] = useState<ScanType>();

  const reportRequestStatus = useRequest();
  const [reportStatus] = reportRequestStatus;

  const { loading: policiesLoading, data: policiesData } = useQuery<ListPoliciesData>(
    LIST_POLICIES,
    {
      context: { clientName: 'findings' },
      fetchPolicy: 'network-only',
    },
  );

  const handleRunReport = (): void => {
    const cloudId = selectedAccount?.cloudId;

    const policy =
      policiesData?.listPolicies.find(p => p.scope.includes(cloudId ?? '')) ||
      policiesData?.listPolicies.find(
        p => p.default && p.provider === providerFromCloudId(cloudId),
      );

    if (!policy || !cloudId || !scanType) {
      return;
    }

    if (reportType === 'detailedScan') {
      setScanInfo({
        key: uuidv4(),
        reportType,
        scanType,
        cloudId,
        policy: policy.policy,
      });
    } else if (reportType === 'complianceSummary') {
      setScanInfo({
        key: uuidv4(),
        reportType,
        scanType,
        cloudId,
        policy: policy.policy,
        standards: selectedStandards.map(s => s.type),
      });
    }
  };

  useEffect(() => {
    if (selectedAccount) {
      const options = scanTypeList?.[selectedAccount.provider] || [];
      setScanTypeOptions(options);
      setScanType(options?.[0]?.type || null);
      setStandards(options);
    }
  }, [selectedAccount]);

  return (
    <>
      <PageTitle title="Reports" />
      <Grid container spacing={3} style={{ padding: '15px' }}>
        <Grid item xs={12}>
          {policiesLoading ? (
            <CircularProgress />
          ) : (
            <Card>
              <CardContent>
                <TableFilter>
                  <>
                    <FormControl disabled={reportStatus.fetching} variant="outlined">
                      <InputLabel>Report Name</InputLabel>
                      <Select
                        value={reportType}
                        onChange={(e): void => setReportType(e.target.value as string)}
                        MenuProps={MenuProps}
                        style={{ height: 35 }}
                      >
                        {reportTypes.map(option => (
                          <MenuItem key={option.key} value={option.key}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {(reportType === 'detailedScan' || reportType === 'complianceSummary') && (
                      <MuiAutocomplete
                        disabled={reportStatus.fetching}
                        options={accountsList}
                        getOptionLabel={(option): string => option?.name || ''}
                        disableClearable
                        renderOption={(option): JSX.Element => (
                          <AccountNameWithIcon accountId={option.cloudId} small />
                        )}
                        renderInput={(params): JSX.Element => {
                          return (
                            <TextField
                              {...params}
                              variant="outlined"
                              label="Cloud Connection"
                              fullWidth
                              margin="none"
                              InputLabelProps={{
                                shrink: true,
                                // style: {},
                              }}
                              inputProps={{
                                ...(params.inputProps || {}),
                                style: {
                                  padding: '8px',
                                },
                              }}
                              // eslint-disable-next-line react/jsx-no-duplicate-props
                              InputProps={{
                                ...(params.InputProps || {}),
                                notched: false,

                                startAdornment: selectedAccount?.cloudId
                                  ? {
                                      aws: <AWSIcon />,
                                      azure: <AzureIcon />,
                                    }[providerFromCloudId(selectedAccount?.cloudId)]
                                  : null,
                                style: {
                                  paddingTop: '0',
                                  paddingBottom: '0',
                                  minHeight: '32px',
                                  // '& > input': {
                                  //   padding: 8,
                                  // },
                                },
                              }}
                            />
                          );
                        }}
                        value={selectedAccount}
                        onChange={(e, value): void => {
                          setAccount(value);
                        }}
                        style={{
                          minWidth: 300,
                        }}
                      />
                    )}
                    {reportType === 'detailedScan' && (
                      <FormControl disabled={reportStatus.fetching} variant="outlined">
                        <InputLabel>Standard</InputLabel>
                        <Select
                          value={scanType}
                          onChange={(e): void => setScanType(e.target.value as ScanType)}
                          MenuProps={MenuProps}
                          style={{ height: 32 }}
                        >
                          {scanTypeOptions.map(option => (
                            <MenuItem key={option.type} value={option.type}>
                              {controlFromGeneratorId(option.type, ['standard', 'version'])}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                    {reportType === 'complianceSummary' && (
                      <Autocomplete
                        multiple
                        options={scanTypeOptions}
                        label="Standards"
                        selected={selectedStandards}
                        handleSelect={setStandards}
                        selectAll
                        renderOption={undefined}
                        countLabel={undefined}
                        style={undefined}
                        onClose={undefined}
                        paddedTextField={undefined}
                      />
                    )}
                    <FormControl>
                      <Button
                        disabled={reportStatus.fetching}
                        color="primary"
                        onClick={handleRunReport}
                        variant="contained"
                      >
                        Run Report
                      </Button>
                    </FormControl>
                  </>
                </TableFilter>
              </CardContent>
            </Card>
          )}
        </Grid>
      </Grid>
      {scanInfo?.reportType === 'detailedScan' && (
        <DetailedScanReport
          key={scanInfo.key}
          scanInfo={scanInfo}
          requestStatus={reportRequestStatus}
        />
      )}
      {scanInfo?.reportType === 'complianceSummary' && (
        <ComplianceSummaryReport
          key={scanInfo.key}
          scanInfo={scanInfo}
          requestStatus={reportRequestStatus}
        />
      )}
    </>
  );
}
