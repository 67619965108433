import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles(
  {
    wrapper: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      '& > button': {
        '& svg': {
          marginRight: '5px',
        },
        marginRight: '5px',
        '&:last-child': {
          marginRight: 0,
        },
      },
    },
    padding: {
      padding: '8px 15px',
    },
  },
  {
    name: 'PageButtonBar',
  },
);

export default function PageButtonBar({ children, padding, ...other }): JSX.Element {
  const classes = useStyles();

  return (
    <div className={clsx(classes.wrapper, { [classes.padding]: padding })} {...other}>
      {children}
    </div>
  );
}

PageButtonBar.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element])
    .isRequired,
};
