import { gql } from '@apollo/client';

export const FINDINGS = gql`
  query findings($filter: FilterInput, $cursor: CursorInput, $filters: [ComplianceFilter!]) {
    findings(filter: $filter, cursor: $cursor, filters: $filters) {
      cloudId
      compliance {
        status
      }
      resource {
        id
        region
        type
        name
        displayName
      }
      cloudProvider
      workflow {
        status
      }
      lastObservedAt
      firstObservedAt
      updatedAt
      hidden
      severity {
        label
      }
      id
      title
      source
      generatorId
      externalLink
    }
    count(filter: $filter, filters: $filters)
  }
`;

export const DETAILED_REPORT_FINDINGS = gql`
  query findings($filter: FilterInput, $cursor: CursorInput, $filters: [ComplianceFilter!]) {
    findings(filter: $filter, cursor: $cursor, filters: $filters) {
      cloudId
      compliance {
        status
        index
        control
      }
      resource {
        id
        region
        type
        name
        displayName
      }
      cloudProvider
      severity {
        label
      }
      id
      generatorId
    }
    count(filter: $filter, filters: $filters)
  }
`;

export const RISK_FINDINGS = gql`
  query findings($filter: FilterInput, $cursor: CursorInput, $filters: [ComplianceFilter!]) {
    findings(filter: $filter, cursor: $cursor, filters: $filters) {
      cloudId
      compliance {
        status
      }
      resource {
        id
        region
        type
        name
        displayName
        parentId
      }
      generatorId
      cloudProvider
      workflow {
        status
      }
      lastObservedAt
      firstObservedAt
      severity {
        label
      }
      updatedAt
      id
      title
      description
      source
      externalLink
    }
    count(filter: $filter, filters: $filters)
  }
`;

export const RISK_FINDING = gql`
  query finding($id: String!) {
    finding(id: $id) {
      cloudId
      compliance {
        status
        reason
      }
      resource {
        id
        region
        type
        name
        displayName
        parentId
      }
      generatorId
      cloudProvider
      workflow {
        status
      }
      lastObservedAt
      firstObservedAt
      severity {
        label
      }
      updatedAt
      id
      title
      source
      externalLink
    }
  }
`;

export const FINDING = gql`
  query finding($id: String!) {
    finding(id: $id) {
      cloudId
      compliance {
        status
        index
        control
        standard
        provider
        version
      }
      resource {
        id
        region
        type
        name
        displayName
      }
      cloudProvider
      workflow {
        status
      }
      lastObservedAt
      firstObservedAt
      updatedAt
      hidden
      severity {
        label
      }
      id
      generatorId
    }
  }
`;

export const FILTER_RESOURCES = gql`
  query resources($filter: FilterInput) {
    resources(filter: $filter) {
      type
      name
      provider
      id
      cloudId
      displayName
    }
  }
`;

export const RESOURCE_TYPES = gql`
  query resourceTypes($filter: FilterInput) {
    resourceTypes(filter: $filter) {
      type
      provider
    }
  }
`;

export const COUNT = gql`
  query findings($filter: FilterInput) {
    count(filter: $filter)
  }
`;

export const GROUPS = gql`
  query groups($filter: FilterInput, $cursor: CursorInput, $filters: [ComplianceFilter!]) {
    groups(filter: $filter, cursor: $cursor, filters: $filters) {
      generatorId
      cloudId
      count
      severity
      title
      source
    }
  }
`;

export const SUPPRESS_FINDING = gql`
  mutation Suppress($id: String!) {
    finding: suppress(id: $id) {
      id
      workflow {
        status
      }
      updatedAt
    }
  }
`;

export const UNSUPPRESS_FINDING = gql`
  mutation Unsuppress($id: String!) {
    finding: unsuppress(id: $id) {
      id
      workflow {
        status
      }
      updatedAt
    }
  }
`;

export const RESOLVE_FINDING = gql`
  mutation Resolve($id: String!) {
    finding: resolve(id: $id) {
      id
      workflow {
        status
      }
      updatedAt
    }
  }
`;

export const SUBMIT_SCANS = gql`
  mutation Scan($cloudList: [String!]!) {
    response: scan(cloudList: $cloudList) {
      msg
      error
    }
  }
`;

export const GROUPED_COUNT = gql`
  query counts($groupBy: [String!]!, $filter: FilterInput) {
    counts(groupBy: $groupBy, filter: $filter)
  }
`;

export const GRAPH_DATA = gql`
  query graph(
    $groupBy: [String!]!
    $filter: FilterInput
    $topGroupBy: [String!]!
    $topFilter: FilterInput
  ) {
    total: counts(groupBy: $groupBy, filter: $filter)
    top: counts(groupBy: $topGroupBy, filter: $topFilter)
  }
`;

export const SUMMARY_REPORT = gql`
  query summary($input: ReportInput!) {
    reportSummary(input: $input) {
      cloudId
      standard
      control
      status
      workflowStatus
      count
      version
    }
  }
`;
