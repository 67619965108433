import { useCallback, useEffect, useMemo, useState } from 'react';

import useAccountsFilter from 'hooks/useAccountsFilter';
import useCompressedFilter from 'hooks/useCompressedFilter';
import { resourceTypeList } from 'lib';

export interface ResourceFilter {
  accounts?: any;
  resourceType?: any;
  nameQuery?: any;
}

export default function useResourcesFilter(enabledFilters: {
  accounts: boolean;
  resourceTypes: boolean;
  nameQuery: boolean;
}): any {
  const [filter, storeFilter] = useCompressedFilter('csg-resources-filter');
  const [initialized, setInitialized] = useState(false);

  // accounts
  const { accountsList } = useAccountsFilter();
  const [selectedAccounts, setAccounts] = useState([]);
  // name query
  const [nameQuery, setNameQuery] = useState<string>('');
  // resource types
  const [selectedResourceTypes, setResourceTypes] = useState([]);

  useEffect(() => {
    if (!accountsList || !filter) {
      return;
    }

    const updateState = (storedFilter: ResourceFilter = {}): void => {
      // accounts
      setAccounts(
        storedFilter?.accounts?.value
          ? accountsList.filter(a => storedFilter?.accounts?.value.includes(a.type))
          : accountsList,
      );
      // name query
      setNameQuery(storedFilter?.nameQuery?.value || '');
      // resource types
      setResourceTypes(
        storedFilter?.resourceType
          ? resourceTypeList.filter(r => (storedFilter?.resourceType?.value || []).includes(r.type))
          : [...resourceTypeList],
      );

      setInitialized(true);
    };

    try {
      updateState(filter);
    } catch (e) {
      updateState();
    }
  }, [accountsList, filter]);

  const filterClauses = useMemo(() => {
    if (!initialized) {
      return;
    }
    const clauses: {
      accounts: any;
      resourceType?: any;
      nameQuery?: any;
    } = {
      accounts: {
        name: 'cloudId',
        value: (selectedAccounts || []).map(a => a.type),
      },
    };

    const response = {};

    if (selectedResourceTypes.length !== resourceTypeList.length && enabledFilters.resourceTypes) {
      clauses.resourceType = {
        name: 'type',
        value: (selectedResourceTypes || []).map(t => t.type),
      };
    }

    if (nameQuery && enabledFilters.nameQuery) {
      clauses.nameQuery = {
        name: 'name',
        value: nameQuery,
        operator: 'contains',
      };
    }

    storeFilter({ ...clauses });

    response.filter = Object.values(clauses);

    return response;
  }, [
    initialized,
    selectedAccounts,
    selectedResourceTypes,
    enabledFilters.resourceTypes,
    enabledFilters.nameQuery,
    nameQuery,
    storeFilter,
  ]);

  const resetFilters = useCallback(() => {
    setAccounts([...accountsList]);
    setResourceTypes([...resourceTypeList]);
    setNameQuery('');
  }, [accountsList]);

  return {
    selectedResourceTypes,
    setResourceTypes,
    selectedAccounts,
    setAccounts,
    nameQuery,
    setNameQuery,

    initialized,

    resetFilters,
    filterClauses,
  };
}
