import { useMemo } from 'react';

import { useQuery } from '@apollo/client';

import { LIST_CONNECTIONS } from 'graphql/Accounts';
import { providerFromCloudId } from 'lib';
import { ListConnectionsData } from 'types';

export default function useCloudNames() {
  const { data: listConnectionsData } = useQuery<ListConnectionsData>(LIST_CONNECTIONS, {
    context: { clientName: 'accounts' },
  });

  const cloudNames = useMemo(
    () =>
      (listConnectionsData?.listConnections?.connections || []).reduce(
        (obj, c) => ({
          ...obj,
          [c.cloudId]: {
            name: c.name,
            provider: providerFromCloudId(c.cloudId),
          },
        }),
        {},
      ),
    [listConnectionsData],
  );

  return cloudNames;
}
