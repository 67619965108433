import React, { useState } from 'react';

import { useMutation, useApolloClient } from '@apollo/client';
import { Dialog } from '@cuda-networks/bds-core';
import { makeStyles } from '@material-ui/core/styles';

import {
  ChooseProvider,
  Compliance,
  DeployAWS,
  DeployAzure,
  Finished,
} from 'components/CloudConnections/OnboardWizard';
import DialogTitle from 'components/common/DialogTitle';
import { LIST_CONNECTIONS } from 'graphql/Accounts';
import { SCAN_RESOURCES } from 'graphql/Resources';
import { SUBMIT_SCANS } from 'graphql/SecurityFindings';
import useRequest from 'hooks/useRequest';
import { providerFromCloudId } from 'lib';
import { ListConnectionsData } from 'types';

const useStyles = makeStyles(
  theme => ({
    dialog: {
      minWidth: 400,
      minHeight: 200,
      maxWidth: 750,
      width: '95%',
    },
  }),
  {
    name: 'DeployFirewallWizard',
  },
);

interface OnboardWizardProps {
  open: boolean;
  handleClose: () => void;
  handleSucceed: (provider: string) => void;
}

export default function OnboardCloudWizard(props: OnboardWizardProps): JSX.Element {
  const client = useApolloClient();
  const { open, handleClose, handleSucceed } = props;
  const classes = useStyles();

  const [step, setStep] = useState('chooseProvider');
  const [stepHistory, setStepHistory] = useState(['chooseProvider']);
  const [account, setAccount] = useState('');

  const chooseProvider = newStep => (): void => {
    setStep(newStep);
    setStepHistory([...stepHistory, newStep]);
  };

  const [scanResources] = useMutation(SCAN_RESOURCES, {
    context: { clientName: 'resources' },
  });

  const [scanSecurity] = useMutation(SUBMIT_SCANS, {
    context: { clientName: 'findings' },
  });

  const [submitStatus, submitStarted, , submitFailed] = useRequest();
  const showFinished = async (added): void => {
    setStep('finished');
    submitStarted();
    try {
      await Promise.all([
        client.query<ListConnectionsData>({
          query: LIST_CONNECTIONS,
          fetchPolicy: 'network-only',
          context: { clientName: 'accounts' },
        }),
        scanResources({ variables: { cloudId: added.map(c => c.cloud_id) } }),
        scanSecurity({ variables: { cloudList: added.map(c => c.cloud_id) } }),
      ]);
    } catch (e) {
      submitFailed('Cloud connection added, but failed to submit resource and security scans.');
    }

    handleSucceed(added.some(c => providerFromCloudId(c.cloud_id) === 'aws') ? 'aws' : 'azure');
  };

  const prevStep = (): void => {
    if (stepHistory.length > 1) {
      const newHistory = stepHistory.slice(0, stepHistory.length - 1);

      setStep(newHistory[newHistory.length - 1]);
      setStepHistory(newHistory);
    }
  };

  const reset = (): void => {
    setAccount('');
    setStep('chooseProvider');
    setStepHistory(['chooseProvider']);
  };

  return (
    <>
      <Dialog
        open={open}
        classes={{
          paper: classes.dialog,
        }}
        maxWidth="md"
        fullWidth
        onExited={reset}
        onClose={handleClose}
        disableEscapeKeyDown
      >
        <div id="test" />
        <DialogTitle>New Cloud Connection</DialogTitle>
        {
          {
            chooseProvider: (
              <ChooseProvider handleNext={chooseProvider} handleClose={handleClose} />
            ),
            aws: (
              <DeployAWS
                handleNext={showFinished}
                handlePrev={prevStep}
                handleClose={handleClose}
              />
            ),
            azure: (
              <DeployAzure
                handleNext={showFinished}
                handlePrev={prevStep}
                handleClose={handleClose}
                showPrompt
              />
            ),
            finished: <Finished status={submitStatus} handleClose={handleClose} />,
            compliance: <Compliance account={account} handleNext={showFinished} />,
          }[step]
        }
      </Dialog>
    </>
  );
}
