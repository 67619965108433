import React from 'react';

import { Reference, StoreObject, useMutation } from '@apollo/client';

import ConfirmDelete from 'components/ConfirmDelete';
import { DELETE_CONNECTION } from 'graphql/Accounts';
import useRequest from 'hooks/useRequest';
import { Connection, DeleteConnectionData, DeleteConnectionInput } from 'types';

interface DetailsProps {
  connection: Connection;
  open: boolean;
  handleClose: () => void;
}

export default function DeleteConnectionDialog(props: DetailsProps): JSX.Element {
  const { connection, open, handleClose } = props;

  const [deleteStatus, deleteStarted, deleteSucceeded, deleteFailed] = useRequest();

  const [deleteConnection] = useMutation<DeleteConnectionData, DeleteConnectionInput>(
    DELETE_CONNECTION,
    {
      context: { clientName: 'accounts' },
      update(cache, { data }) {
        if (data?.deleteConnection.id) {
          cache.modify({
            fields: {
              listConnections(existing = {}, { readField }): Connection[] {
                return {
                  ...existing,
                  connections: (existing.connections || []).filter(
                    (c: Reference | StoreObject | undefined) =>
                      readField('id', c) !== data?.deleteConnection.id,
                  ),
                };
              },
            },
          });
        }
      },
    },
  );

  const handleConfirm = async (): Promise<void> => {
    deleteStarted();
    try {
      await deleteConnection({
        variables: {
          input: {
            connectionId: connection.id,
          },
        },
      });

      deleteSucceeded();
      handleClose();
    } catch (e) {
      deleteFailed(
        e?.networkError?.result?.message || e?.message || 'Failed to delete cloud connection.',
      );
    }
  };

  return (
    <ConfirmDelete
      title="Delete Cloud Connection"
      handleConfirm={handleConfirm}
      status={deleteStatus}
      open={open}
      handleClose={handleClose}
    >
      <>
        <p>
          Are you sure you want to delete <strong>{connection.name}</strong>?
        </p>
        <p>
          Clicking <strong>Delete</strong> will stop all monitoring and alerting related to this
          connection.
        </p>
        <p>Note: The deletion process could take up to 5 minutes to complete.</p>
      </>
    </ConfirmDelete>
  );
}
