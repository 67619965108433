import React from 'react';

import { Tooltip, Typography } from '@cuda-networks/bds-core';
import { Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import ActionCell from 'components/common/ActionCell';
import { EditButton, DeleteButton } from 'components/common/Buttons';
import ConnectionDisplay from 'components/common/ConnectionDisplay';
import NoResults from 'components/common/table/NoResults';
import Timestamp from 'components/common/Timestamp';
import { Connection } from 'types';

const useStyles = makeStyles(
  {
    table: {
      '& td': {
        whiteSpace: 'nowrap',
      },
    },
    tooltip: {
      backgroundColor: '#FFFFFF',
      color: 'rgba(0, 0, 0, 0.87)',
      padding: '15px',
      boxShadow: '0 2px 5px 0 rgba(0,0,0,0.26), 0 2px 10px 0 rgba(0,0,0,0.16)',
    },
  },
  {
    name: 'CloudTable',
  },
);

interface CloudTableProps {
  connections: Connection[];
  handleEdit(connection: Connection): void;
  handleDelete(connection: Connection): void;
}

export default function CloudTable(props: CloudTableProps): JSX.Element {
  const { connections, handleEdit, handleDelete } = props;
  const classes = useStyles();

  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell>Alias</TableCell>
          <TableCell>Id</TableCell>
          <TableCell>Date Added</TableCell>
          <TableCell>Added By</TableCell>
          <TableCell align="center">Connection State</TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {connections.length ? (
          connections.map(connection => (
            <TableRow key={connection.id}>
              <TableCell>
                <ConnectionDisplay connection={connection} />
              </TableCell>
              <TableCell>{connection.cloudId}</TableCell>
              <TableCell>
                <Timestamp ts={connection.createdAt} tsFormat="ISO" />
              </TableCell>
              <TableCell>{connection.addedBy}</TableCell>
              <TableCell align="center">
                {connection.health === 'Bad' ? (
                  <Tooltip
                    title={
                      <>
                        <Typography variant="body2" style={{ fontWeight: 500, marginBottom: 10 }}>
                          Connection Error
                        </Typography>
                        <Typography variant="body2">
                          Cloud Security Guardian encountered a problem while trying to scan your
                          connected connection. Please verify your connection details.
                        </Typography>
                      </>
                    }
                    classes={{ tooltip: classes.tooltip }}
                    arrow
                  >
                    <span style={{ color: '#0088cf', cursor: 'pointer' }}>Error</span>
                  </Tooltip>
                ) : (
                  'Good'
                )}
              </TableCell>
              <ActionCell>
                <DeleteButton
                  onClick={handleDelete(connection)}
                  actionLabel="delete cloud connections."
                />
                <EditButton
                  onClick={handleEdit(connection)}
                  actionLabel="edit cloud connections."
                />
              </ActionCell>
            </TableRow>
          ))
        ) : (
          <NoResults colSpan={5} value="You have not added any Cloud Connections" />
        )}
      </TableBody>
    </Table>
  );
}
