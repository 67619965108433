import React, { useState } from 'react';

import { CircularProgress, Typography, Snackbar } from '@cuda-networks/bds-core';
import { Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import AccountNameWithIcon from 'components/common/AccountNameWithIcon';
import ActionCell from 'components/common/ActionCell';
import { EditButton } from 'components/common/Buttons';
import NoResults from 'components/common/table/NoResults';
import { AccountPolicyDialog } from 'components/SecurityPolicy';
import useDialog from 'hooks/useDialog';
import StatusMessage from 'components/common/StatusMessage';

const useStyles = makeStyles(
  {
    chip: {
      margin: '2px',
      height: '24px',
    },
    catCount: {
      fontWeight: 'bold',
    },
    providerTitle: {
      display: 'flex',
      justifyContent: 'space-between',
      '& svg': {
        marginRight: '5px',
      },
      '& > span': {
        display: 'flex',
        alignItems: 'center',
      },
      marginBottom: '15px',
    },
  },
  {
    name: 'CompliancePolicy',
  },
);

export default function AccountTable({ accounts, loading, provider }): JSX.Element {
  const classes = useStyles();

  const [dialogOpen, openDialog, closeDialog] = useDialog();
  const [selected, setSelected] = useState(null);

  const [message, setMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const onCloseDialog = (): void => {
    closeDialog();
    setSelected(null);
  };
  const onOpenDialog = account => (): void => {
    setSelected(account);
    openDialog();
  };

  return (
    <>
      <Typography variant="h2" className={classes.providerTitle}>
        <span>Cloud Connections</span>
      </Typography>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>Cloud Connection</TableCell>
            <TableCell>Policy</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {loading && (
            <TableRow>
              <TableCell colSpan={3} style={{ textAlign: 'center' }}>
                <CircularProgress />
              </TableCell>
            </TableRow>
          )}
          {Boolean(!loading && accounts.length) &&
            accounts.map(account => (
              <TableRow key={account?.account?.cloudId}>
                <TableCell>
                  <AccountNameWithIcon accountId={account?.account?.cloudId} />
                </TableCell>
                <TableCell>{account?.policy?.name}</TableCell>
                <ActionCell>
                  <EditButton
                    onClick={onOpenDialog(account)}
                    data-test-id="policy-edit-policy"
                    actionLabel="edit cloud connection policy."
                  />
                </ActionCell>
              </TableRow>
            ))}
          {Boolean(!loading && !accounts.length) && (
            <NoResults colSpan={3} value="No policies configured" />
          )}
        </TableBody>
      </Table>
      {dialogOpen && (
        <AccountPolicyDialog
          open={dialogOpen}
          handleClose={closeDialog}
          onClose={onCloseDialog}
          handleSuccess={msg => {
            closeDialog();
            setMessage(msg);
            setSnackbarOpen(true);
          }}
          account={selected?.account ?? {}}
          policy={selected?.policy ?? {}}
          provider={provider}
        />
      )}
      <Snackbar open={snackbarOpen} onClose={() => setSnackbarOpen(false)}>
        <StatusMessage
          status="success"
          variant="filled"
          message={message}
          removable
          handleDismiss={() => setSnackbarOpen(false)}
        />
      </Snackbar>
    </>
  );
}

AccountTable.propTypes = {
  provider: PropTypes.oneOf(['aws', 'azure']).isRequired,
  loading: PropTypes.bool,
};

AccountTable.defaultProps = {
  loading: false,
};
