import React, { useEffect } from 'react';

import { Button } from '@cuda-networks/bds-core';

import AccountFilter from 'components/common/AccountFilter';
import Autocomplete from 'components/common/Autocomplete';
import DebouncedTextField from 'components/common/DebouncedTextField';
import TableFilter from 'components/common/TableFilter';
import { ResourcesAutocomplete } from 'components/Resources';
import { ControlTree } from 'components/SecurityFindings';
import { severityList, statusList, resultList, sourceList } from 'components/SecurityFindings/lib';
import useAccountsFilter from 'hooks/useAccountsFilter';
import useFindingsFilter from 'hooks/useFindingsFilter';
import useRules from 'hooks/useRules';

export type Filters =
  | 'query'
  | 'accounts'
  | 'controls'
  | 'resourceTypes'
  | 'resources'
  | 'statuses'
  | 'results'
  | 'severities';

export interface FindingsGraphQLFilters {
  filter?: any[];
  filters?: any[];
}
export interface FindingsFiltersProps {
  loading?: boolean;
  updateFilters(filters: FindingsGraphQLFilters): void;
  reset?(): void;
  hideQuery?: boolean;
  hideAccounts?: boolean;
  hideControls?: boolean;
  hideResourceTypes?: boolean;
  hideResources?: boolean;
  hideStatuses?: boolean;
  hideResults?: boolean;
  hideSeverities?: boolean;
  hideSources?: boolean;
}

export default function FindingsFilters(props: FindingsFiltersProps): JSX.Element {
  const {
    loading = false,
    updateFilters,
    reset,
    hideQuery = false,
    hideAccounts = false,
    hideControls = false,
    hideResourceTypes = false,
    hideResources = false,
    hideStatuses = false,
    hideResults = false,
    hideSeverities = false,
    hideSources = false,
  } = props;
  const { accountsList } = useAccountsFilter();
  const [rulesList] = useRules();

  const {
    selectedSeverity,
    setSeverity,
    selectedRules,
    setRules,
    selectedResults,
    setResults,
    selectedStatus,
    setStatus,
    selectedResourceTypes,
    setResourceTypes,
    selectedAccounts,
    setAccounts,
    query,
    setQuery,
    selectedResource,
    setResource,
    selectedSources,
    setSources,

    resourceTypes,

    initialized,

    resetFilters,
    filterClauses,
  } = useFindingsFilter({
    query: !hideQuery,
    accounts: !hideAccounts,
    controls: !hideControls,
    resourceTypes: !hideResourceTypes,
    resources: !hideResources,
    statuses: !hideStatuses,
    results: !hideResults,
    severities: !hideSeverities,
    sources: !hideSources,
  });

  useEffect(() => {
    updateFilters(filterClauses);
  }, [filterClauses, updateFilters]);

  return (
    <>
      <TableFilter>
        <>
          {!hideQuery && (
            <DebouncedTextField
              disabled={loading || !initialized}
              fullWidth
              label="Search"
              placeholder="Search control title and description"
              margin="none"
              autoFocus
              style={{ width: '300px' }}
              defaultValue={query}
              data-test-id="findings-filters-search"
              onChange={setQuery}
            />
          )}
          {!hideAccounts && (
            <AccountFilter
              disabled={loading || !initialized}
              accounts={accountsList}
              selected={selectedAccounts}
              onChange={setAccounts}
              data-test-id="findings-filters-connections"
              processing={false}
            />
          )}
          {!hideSources && (
            <Autocomplete
              disabled={loading || !initialized}
              multiple
              options={sourceList}
              label="Sources"
              selected={selectedSources}
              handleSelect={setSources}
              selectAll
              data-test-id="findings-filters-sources"
              getOptionSelected={(option, value): boolean => option.type === value.type}
            />
          )}
          {!hideControls && (
            <ControlTree
              processing={loading || !initialized}
              selectedControls={selectedRules}
              updateControls={setRules}
              controlsList={rulesList}
              data-test-id="findings-filters-controls"
            />
          )}
          {!hideResourceTypes && (
            <Autocomplete
              disabled={loading || !initialized}
              multiple
              selectAll
              options={resourceTypes}
              label="Resource Types"
              groupBy={option => option.cloud}
              selected={selectedResourceTypes}
              handleSelect={setResourceTypes}
              data-test-id="findings-filters-resource-types"
              getOptionSelected={(option, value): boolean =>
                option.type === value.type && option.cloud === value.cloud
              }
            />
          )}
          {!hideResources && (
            <ResourcesAutocomplete
              disabled={loading || !initialized}
              selected={selectedResource}
              setSelected={setResource}
              data-test-id="findings-filters-resource"
              selectedAccounts={selectedAccounts}
            />
          )}
          {!hideSeverities && (
            <Autocomplete
              disabled={loading || !initialized}
              multiple
              selectAll
              selected={selectedSeverity}
              options={severityList}
              label="Severities"
              handleSelect={setSeverity}
              data-test-id="findings-filters-severities"
              getOptionSelected={(option, value): boolean =>
                option.type === value.type && option.cloud === value.cloud
              }
            />
          )}
          {!hideResults && (
            <Autocomplete
              disabled={loading || !initialized}
              multiple
              selectAll
              selected={selectedResults}
              options={resultList}
              label="Results"
              handleSelect={setResults}
              data-test-id="findings-filters-results"
              getOptionSelected={(option, value): boolean =>
                option.type === value.type && option.cloud === value.cloud
              }
            />
          )}
          {!hideStatuses && (
            <Autocomplete
              disabled={loading || !initialized}
              multiple
              selectAll
              selected={selectedStatus}
              options={statusList}
              label="Status"
              handleSelect={setStatus}
              data-test-id="findings-filters-statuses"
              getOptionSelected={(option, value): boolean =>
                option.type === value.type && option.cloud === value.cloud
              }
            />
          )}
        </>
      </TableFilter>
      <Button
        variant="text"
        style={{ backgroundColor: 'transparent' }}
        color="primary"
        size="small"
        data-test-id="findings-filters-reset"
        onClick={() => {
          resetFilters();
          reset && reset();
        }}
      >
        Clear Filters
      </Button>
    </>
  );
}
