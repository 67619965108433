import React, { useState, useMemo } from 'react';

import { Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import PropTypes from 'prop-types';

import AccountNameWithIcon from 'components/common/AccountNameWithIcon';
import PaginatedTable from 'components/common/PaginatedTable';
import NoResults from 'components/common/table/NoResults';
import TableHeaderCell from 'components/common/table/TableHeaderCell';
import { ResourceDisplay, ResourceDetailsDialog } from 'components/Resources';
import useDialog from 'hooks/useDialog';
import { assetNames, providerFromCloudId, regionNames } from 'lib';
import { parseQS } from 'utils/params';

export default function ResourcesTable({
  resources,
  handleSort,
  sort,
  ...other
}): React.ReactElement {
  const parsedQS = parseQS();
  const [selectedFinding, setSelectedFinding] = useState<object | null>(
    parsedQS.resourceId && parsedQS.cloudId
      ? { cloudId: parsedQS.cloudId, resourceId: parsedQS.resourceId }
      : null,
  );
  const [dialogOpen, openDialog, closeDialog] = useDialog(Boolean(selectedFinding?.resourceId));
  const selectFinding = finding => {
    setSelectedFinding(finding);
    openDialog();
  };
  const deselectFinding = (): void => {
    closeDialog();
    setSelectedFinding(null);
  };

  const colDefs = {
    resource: {
      sortable: false,
      sortLabel: 'resource.id',
      display: true,
      label: 'Resource',
      render: f => (
        <ResourceDisplay
          provider={providerFromCloudId(f?.cloudId)}
          resource={f}
          onClick={() => selectFinding(f)}
        />
      ),
    },
    type: {
      sortable: false,
      sortLabel: 'resource.type',
      display: true,
      label: 'Type',
      render: f => assetNames?.[f?.type] ?? f?.type,
    },
    region: {
      sortable: false,
      sortLabel: 'resource.location',
      display: true,
      label: 'Region',
      render: f => regionNames?.[f?.location] ?? f?.location,
    },
    account: {
      sortable: true,
      sortLabel: 'cloudId',
      display: true,
      label: 'Connection',
      render: f => <AccountNameWithIcon accountId={f?.cloudId} />,
    },
  };
  const cols = Object.keys(colDefs);
  const [colStatus, setColStatus] = useState(
    cols.reduce(
      (obj, col): void => ({
        ...obj,
        [col]: {
          label: colDefs?.[col]?.label,
          display: colDefs?.[col]?.display,
        },
      }),
      {},
    ),
  );
  const toggleColumn = (col, display) => {
    setColStatus({
      ...colStatus,
      [col]: {
        ...(colStatus?.[col] ?? {}),
        display,
      },
    });
  };
  const activeColumns = useMemo(
    () => Object.keys(colStatus).filter(key => colStatus?.[key]?.display),
    [colStatus],
  );

  return (
    <>
      <PaginatedTable
        columns={colStatus}
        toggleColumn={toggleColumn}
        rowsPerPageOptions={[50]}
        {...other}
      >
        <Table>
          <TableHead>
            <TableRow>
              {activeColumns.map(c => (
                <TableHeaderCell
                  key={c}
                  field={colDefs?.[c] ?? {}}
                  sort={sort}
                  handleSort={handleSort}
                  // className={colDefs?.[c]?.className ?? {}}
                  {...(colDefs?.[c]?.props ?? {})}
                />
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Boolean(resources?.length) &&
              resources.map(finding => (
                <TableRow key={finding.id}>
                  {activeColumns.map(c => (
                    <TableCell
                      key={`${finding.id}-${c}`}
                      // className={colDefs?.[c]?.className ?? {}}
                      {...(colDefs?.[c]?.props ?? {})}
                    >
                      {colDefs[c]?.render(finding)}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            {!resources?.length && <NoResults colSpan={activeColumns.length} />}
          </TableBody>
        </Table>
      </PaginatedTable>
      {dialogOpen && (
        <ResourceDetailsDialog
          open={dialogOpen}
          resourceId={selectedFinding?.resourceId}
          cloudId={selectedFinding?.cloudId}
          onClose={deselectFinding}
        />
      )}
    </>
  );
}

ResourcesTable.propTypes = {
  resources: PropTypes.array,
  handleSort: PropTypes.func.isRequired,
  sort: PropTypes.shape({
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
  }).isRequired,
};

ResourcesTable.defaultProps = {
  resources: [],
};
