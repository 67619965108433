import React from 'react';

import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
  ApolloProvider,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import mixpanel from 'mixpanel-browser';

import { hasProp } from 'lib';
import useAuth from 'hooks/useAuth';

const allowedClients = ['findings', 'resources', 'accounts'] as const;
type ClientName = typeof allowedClients[number];

export function offsetLimitPagination(keyArgs) {
  return {
    keyArgs,
    merge(existing, incoming, { args }) {
      const merged = existing && hasProp(args?.cursor || {}, 'offset') ? existing.slice(0) : [];
      const start = args?.cursor?.offset || 0;
      const end = start + incoming.length;
      for (let i = 0; i < start; ++i) {
        merged[i] = merged[i] || null;
      }
      for (let i = start; i < end; ++i) {
        merged[i] = incoming[i - start];
      }
      return merged;
    },
  };
}

const findingsLink = createHttpLink({
  uri: '/graphql/findings',
});
const resourcesLink = createHttpLink({
  uri: '/graphql/resources',
});
const accountsLink = createHttpLink({
  uri: '/graphql/accounts',
});

const authLink = setContext(async (_, { headers }) => {
  const token = localStorage.getItem('csg-token');

  // TODO check if token is about to expire and try to renew

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const linkByClient: Record<ClientName, ApolloLink> = {
  findings: findingsLink,
  resources: resourcesLink,
  accounts: accountsLink,
};

const link = new ApolloLink(operation => {
  const client: ClientName = operation.getContext().clientName;

  return linkByClient[client].request(operation);
});

interface ApolloProps {
  children: React.ReactNode;
}

function Apollo(props: ApolloProps): JSX.Element {
  const { children } = props;
  const { signOut } = useAuth();

  const errorLink = onError(({ operation, graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      if (graphQLErrors.some(e => e?.extensions?.code === 'UNAUTHENTICATED')) {
        mixpanel.track('Session Expired');
        signOut({ oidcLogout: false, urlRedirect: true });
      }
    }
    if (networkError) {
      // @ts-ignore
      if (networkError.statusCode === 401) {
        mixpanel.track('Session Expired');
        signOut({ oidcLogout: false, urlRedirect: true });
        // Auth.logout(true);
      }
      console.log(`[Network error]: ${networkError}`);
    }
  });

  const client = new ApolloClient({
    cache: new InMemoryCache({
      typePolicies: {
        AdminSerial: {
          keyFields: ['serial'],
        },
        CloudAccount: {
          keyFields: ['cloud_id'],
        },
        FindingResource: {
          keyFields: ['id', 'region'],
        },
        Query: {
          fields: {
            groups: offsetLimitPagination(['filter']),
            findings: offsetLimitPagination(['filter', 'filters']),
            resources: offsetLimitPagination(['filter']),
          },
        },
      },
    }),
    link: ApolloLink.from([errorLink, authLink, link]),
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}

export default Apollo;
