import React, { useEffect, useState, useMemo } from 'react';

import { useApolloClient } from '@apollo/client';
import { Card, CardContent, Button, Grid, TextField } from '@cuda-networks/bds-core';
import { FormControl } from '@material-ui/core';

import { AccountsTable, AccountDetailsDialog } from 'components/Admin';
import PageTitle from 'components/common/PageTitle';
import TableFilter from 'components/common/TableFilter';
import { ACCOUNTS, ACCOUNT } from 'graphql/Admin';
import useRequest from 'hooks/useRequest';

export default function Account(): JSX.Element {
  const [accountId, setAccountId] = useState('');
  const [showDetails, setShowDetails] = useState(false);

  const client = useApolloClient();
  const [accounts, setAccounts] = useState([]);
  const [loadStatus, loadStarted, loadSucceeded, loadFailed] = useRequest();
  useEffect((): void => {
    loadStarted();
    async function fetchData(): void {
      try {
        await client.clearStore();
        const { data } = await client.query({
          query: ACCOUNTS,
          // fetchPolicy: 'network-only',
          context: {
            clientName: 'accounts',
          },
        });

        setAccounts(data?.adminAccounts ?? []);
        loadSucceeded();
      } catch (e) {
        loadFailed(e?.networkError?.result?.message || e?.message || 'Unknown error');
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [lookupStatus, lookupStarted, lookupSucceeded, lookupFailed] = useRequest();
  const handleDetails = async accountId => {
    lookupStarted();
    setShowDetails(true);

    try {
      // TODO: read from cache
      const { data } = await client.query({
        query: ACCOUNT,
        variables: {
          input: { accountId },
        },
        context: {
          clientName: 'accounts',
        },
      });

      lookupSucceeded(data?.adminAccount || {});
    } catch (e) {
      lookupFailed(e?.networkError?.result?.message || e?.message || 'Account lookup failed');
    }
  };

  const [pagination, setPagination] = useState({
    page: 0,
    size: 50,
  });
  const paginatedAccounts = useMemo(() => {
    const { page, size } = pagination;
    const start = page * size;

    return accounts.slice(start, start + size);
  }, [pagination, accounts]);
  const handlePaginate = newPagination => setPagination(newPagination);

  return (
    <>
      <PageTitle title="Accounts" />
      <Grid container direction="column" spacing={3}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <TableFilter>
                <>
                  <TextField
                    label="Account Id Lookup"
                    value={accountId}
                    onChange={event => setAccountId(event.target.value)}
                    margin="none"
                    style={{ minWidth: '300px' }}
                    disabled={lookupStatus.fetching}
                  />
                  <FormControl>
                    <Button
                      color="primary"
                      disabled={!accountId || lookupStatus.fetching}
                      onClick={() => handleDetails(accountId)}
                    >
                      Lookup
                    </Button>
                  </FormControl>
                </>
              </TableFilter>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <AccountsTable
            accounts={paginatedAccounts}
            pagination={{
              ...pagination,
              total: accounts.length,
            }}
            handlePaginate={handlePaginate}
            status={loadStatus}
          />
        </Grid>
      </Grid>
      <AccountDetailsDialog
        open={showDetails}
        handleClose={() => setShowDetails(false)}
        status={lookupStatus}
      />
    </>
  );
}
