import React from 'react';

import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';

import AccountNameWithIcon from 'components/common/AccountNameWithIcon';
import ConfirmDelete from 'components/ConfirmDelete';
import { DELETE_POLICY, LIST_POLICIES } from 'graphql/SecurityPolicy';
import useRequest from 'hooks/useRequest';

export default function PolicyDeleteDialog({
  policy,
  handleClose,
  handleSuccess,
  ...other
}): JSX.Element {
  const [handleDeletePolicy] = useMutation(DELETE_POLICY, {
    update(cache, { data: { deletePolicy } }) {
      const { listPolicies } = cache.readQuery({ query: LIST_POLICIES });

      cache.writeQuery({
        query: LIST_POLICIES,
        data: { listPolicies: listPolicies.filter(p => p.id !== deletePolicy) },
      });
    },
    context: { clientName: 'findings' },
  });

  const [requestStatus, requestStarted, requestSucceeded, requestFailed] = useRequest();
  const handleConfirm = async (): void => {
    requestStarted();
    try {
      await handleDeletePolicy({ variables: { id: policy.id } });
      requestSucceeded();
      handleSuccess();
    } catch (e) {
      requestFailed(e?.networkError?.result?.message || e?.message || 'Failed to delete policy');
    }
  };

  return (
    <ConfirmDelete
      title="Delete Policy"
      status={requestStatus}
      handleConfirm={handleConfirm}
      handleClose={handleClose}
      requestLabel="SECURITY_DELETE_POLICY"
      confirmLabel="Delete"
      data-test-id="policy-delete-policy-dialog"
      open
      {...other}
    >
      <div
        style={{
          marginBottom: '5px',
        }}
      >
        Are you sure you want to delete <b>{policy?.name}</b>?
      </div>
      {Boolean(policy?.scope?.length) && (
        <div
          style={{
            marginBottom: '5px',
          }}
        >
          These accounts will be switched to the <b>Default</b> policy:
        </div>
      )}
      <ul
        style={{
          marginBlockEnd: 0,
          marginBlockStart: 0,
          paddingInlineStart: '10px',
        }}
      >
        {(policy?.scope ?? []).map(acc => (
          <AccountNameWithIcon accountId={acc} />
        ))}
      </ul>
    </ConfirmDelete>
  );
}

PolicyDeleteDialog.propTypes = {
  policy: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  handleClose: PropTypes.func.isRequired,
};
