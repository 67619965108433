import React, { useState } from 'react';

import { Tooltip } from '@cuda-networks/bds-core';
import { makeStyles } from '@material-ui/core/styles';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import clsx from 'clsx';

import StatusMessage from 'components/common/StatusMessage';
import { AWSIcon, AzureIcon } from 'icons';
import { zeroes, regions as availableRegions, lat2y, lon2x } from 'lib';
import { CloudType, LocationCount, RequestStatus } from 'types';

const useStyles = makeStyles(
  {
    regionMap: {
      position: 'relative',
      '&:before': {
        content: '""',
        display: 'block',
        paddingTop: '48.896%',
        backgroundPosition: '0 0',
        backgroundImage: 'url(/images/world.png)',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        opacity: 0.6,
      },
    },
    region: {
      position: 'absolute',
      left: 0,
      top: 0,
      width: '1px',
      height: '1px',
      backgroundColor: 'red',
      overflow: 'visible',
      zIndex: 10,
      cursor: 'default',
    },
    aws: {
      backgroundColor: '#e49234',
    },
    azure: {
      backgroundColor: '#40ade8',
    },
    danger: {
      backgroundColor: '#DD1E31',
    },
    marker: {
      display: 'block',
      width: '12px',
      height: '12px',
      borderRadius: '12px',
      border: '2px solid #fff',
      transform: 'translate(-50%, -50%)',
      boxSizing: 'initial',
      '&:hover': {
        animation: 'pulse 2s infinite',
      },
    },

    toggle: {
      position: 'absolute',
      top: 0,
      right: 0,
    },
    disabledToggle: {
      '& path': {
        fill: 'grey',
      },
    },
  },
  {
    name: 'RegionMap',
  },
);

export interface RegionMapProps {
  regions: LocationCount[];
  status: RequestStatus;
}
export default function RegionMap(props: RegionMapProps): JSX.Element {
  const { regions, status } = props;
  const classes = useStyles();
  const [providers, setProviders] = useState<CloudType[]>(['aws', 'azure']);
  const [providersMap, setProvidersMap] = useState<Record<CloudType, boolean>>({
    aws: true,
    azure: true,
  });

  const handleProviders = (
    _event: React.MouseEvent<HTMLElement, MouseEvent>,
    newProviders: CloudType[],
  ): void => {
    setProviders(newProviders);
    setProvidersMap(
      newProviders.reduce<Record<CloudType, boolean>>(
        (obj, provider) => ({ ...obj, [provider]: true }),
        {} as Record<CloudType, boolean>,
      ),
    );
  };

  return (
    <>
      {status.error && <StatusMessage status="error" message="Error loading region data" />}
      <div className={classes.regionMap}>
        <ToggleButtonGroup
          aria-label="providers"
          value={providers}
          onChange={handleProviders}
          className={classes.toggle}
        >
          <ToggleButton
            value="aws"
            aria-label="aws"
            className={!providersMap?.aws ? classes.disabledToggle : undefined}
          >
            <AWSIcon />
          </ToggleButton>
          <ToggleButton
            value="azure"
            aria-label="azure"
            className={!providersMap?.azure ? classes.disabledToggle : undefined}
          >
            <AzureIcon />
          </ToggleButton>
        </ToggleButtonGroup>
        {regions
          .filter(r => {
            const { provider, location } = r;
            const region = availableRegions?.[provider]?.[location];

            return Boolean(region && providersMap?.[provider]);
          })
          .map(r => {
            const { provider, location } = r;
            const region = availableRegions?.[provider]?.[location];

            const coordinates = {
              x: lon2x(region.lon) + 180,
              y: lat2y(region.lat) + 90,
            };

            const regionX =
              coordinates.x >= zeroes.x
                ? (coordinates.x - zeroes.x) / 360
                : (360 - zeroes.x + coordinates.x) / 360;
            const regionY =
              coordinates.y >= zeroes.y
                ? (coordinates.y - zeroes.y) / 190
                : (180 - zeroes.y + coordinates.y) / 190;

            const style = {
              left: `${regionX * 100}%`,
              top: `${100 - regionY * 100}%`,
            };

            return (
              <div className={classes.region} style={style} key={`${provider}-${location}`}>
                <Tooltip title={region?.label || location}>
                  <div
                    className={clsx(classes.marker, classes[provider], {
                      [classes.danger]: false,
                      // (region.issues || []).length,
                    })}
                  />
                </Tooltip>
              </div>
            );
          })}
      </div>
    </>
  );
}
