import React from 'react';

import { Card, CardContent, Button, Grid, CircularProgress } from '@cuda-networks/bds-core';
import { CloudSecurityGuardian } from '@cuda-networks/bds-core/dist/Logos/Products';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import StatusMessage from 'components/common/StatusMessage';
import useAuth from 'hooks/useAuth';
import { RequestStatus } from 'types';

const useStyles = makeStyles(
  theme => ({
    page: {
      overflow: 'auto',
      height: '100%',
      backgroundSize: 'cover',
      backgroundImage: 'url(/images/background.jpg)',
      [theme.breakpoints.up('md')]: {
        padding: '48px 128px',
      },
      [theme.breakpoints.down('sm')]: {
        padding: '48px',
      },
      [theme.breakpoints.down('xs')]: {
        padding: '12px',
      },
      display: 'flex',
      justifyContent: 'center',
    },
    card: {
      lineHeight: '24px',
      fontSize: '14px',
      color: '#000000',
      height: 'max-content',
    },
    loading: {
      flexDirection: 'column',
      alignItems: 'center',
    },
    loadingCard: {
      backgroundColor: '#FFFFFF',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '20px',
    },
    noPadding: {
      padding: 0,
    },
  }),
  {
    name: 'SeparatePage',
  },
);

const defaultStatus: RequestStatus = {
  fetching: false,
  succeeded: true,
};

interface SeparatePageProps {
  children: React.ReactNode;
  loadStatus?: RequestStatus;
  noPadding?: boolean;
}

export default function SeparatePage(props: SeparatePageProps): JSX.Element {
  const { children, loadStatus = defaultStatus, noPadding = false } = props;
  const classes = useStyles();

  const { signOut: handleLogout } = useAuth();

  return (
    <div
      className={clsx(classes.page, {
        [classes.loading]: loadStatus.fetching || loadStatus.error,
      })}
    >
      {loadStatus.succeeded ? (
        <Card className={classes.card}>
          <CardContent className={clsx({ [classes.noPadding]: noPadding })}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                {children}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ) : (
        <div className={classes.loadingCard}>
          <CloudSecurityGuardian style={{ height: 56 }} />
          {loadStatus.fetching && <CircularProgress />}
          {loadStatus.error && (
            <>
              <StatusMessage status="error" message={loadStatus.error} removable={false} />
              <Button variant="text" onClick={handleLogout}>
                Log Out
              </Button>
            </>
          )}
        </div>
      )}
    </div>
  );
}
