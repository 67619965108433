import React from 'react';

import { useQuery } from '@apollo/client';
import { IconButton } from '@cuda-networks/bds-core';
import MenuIcon from '@material-ui/icons/Menu';

import { NavDrawerItem } from 'components/NavDrawer';
import { COUNT } from 'graphql/SecurityFindings';
import useAccountsFilter from 'hooks/useAccountsFilter';
import { pages } from 'navigation';
import { NavigationPageTypes } from 'types';

interface NavDrawerProps {
  handleClick?: () => void;
  nav: NavigationPageTypes[];
  temporary?: boolean;
  open: boolean;
  hideLabels?: boolean;
  drawerWidth?: number;
}

export default function NavDrawer(props: NavDrawerProps): React.ReactElement {
  const { handleClick, nav, temporary = false, open, hideLabels = false, drawerWidth } = props;
  // TODO disable in admin
  const { accountsList } = useAccountsFilter();

  const { data } = useQuery(COUNT, {
    skip: !accountsList?.length,
    variables: {
      filter: {
        where: [
          {
            name: 'cloudId',
            value: (accountsList || []).map(a => a.cloudId),
          },
          {
            name: 'compliance.status',
            value: ['FAILED'],
          },
        ],
      },
    },
    fetchPolicy: 'network-only',
    pollInterval: 60000,
    context: { clientName: 'findings' },
  });

  const noLabel = hideLabels && !open;

  return (
    <>
      {temporary && (
        <div>
          <IconButton color="inherit" aria-label="Open drawer" onClick={handleClick}>
            <MenuIcon />
          </IconButton>
        </div>
      )}
      <div>
        {nav.map(name => {
          const page = pages[name];

          return (
            <div
              key={page.path}
              style={{
                width: drawerWidth,
              }}
            >
              <NavDrawerItem noLabel={noLabel} page={page} open={open} count={data?.count || 0} />
            </div>
          );
        })}
      </div>
    </>
  );
}
