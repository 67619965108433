import React, { useState } from 'react';

import { Button, Chip, CircularProgress, Typography, Snackbar } from '@cuda-networks/bds-core';
import { Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import VisibilityIcon from '@material-ui/icons/Visibility';
import PropTypes from 'prop-types';

import ActionCell from 'components/common/ActionCell';
import { EditButton, DeleteButton } from 'components/common/Buttons';
import RBACButton from 'components/common/RBACButton';
import StatusMessage from 'components/common/StatusMessage';
import NoResults from 'components/common/table/NoResults';
import { policyControlTitle } from 'components/SecurityFindings/lib';
import { PolicyEditorDialog, PolicyDeleteDialog } from 'components/SecurityPolicy';
import useAuth from 'hooks/useAuth';
import useDialog from 'hooks/useDialog';

const useStyles = makeStyles(
  {
    chip: {
      margin: '2px',
      height: '24px',
    },
    catCount: {
      fontWeight: 'bold',
    },
    providerTitle: {
      display: 'flex',
      justifyContent: 'space-between',
      '& svg': {
        marginRight: '5px',
      },
      '& > span': {
        display: 'flex',
        alignItems: 'center',
      },
      marginBottom: '15px',
    },
  },
  {
    name: 'CompliancePolicy',
  },
);

export default function PolicyTable({ policies, rules, provider, loading }): JSX.Element {
  const classes = useStyles();

  const { account } = useAuth();

  const [policyDialogOpen, openPolicyDialog, closePolicyDialog] = useDialog();
  const [deleteDialogOpen, openDeleteDialog, closeDeleteDialog] = useDialog();

  const [selected, setSelected] = useState(null);

  const onClosePolicyDialog = (): void => {
    closePolicyDialog();
    setSelected(null);
  };
  const handleOpenPolicyDialog = policy => (): void => {
    setSelected(policy);
    openPolicyDialog();
  };
  const onCloseDeleteDialog = (): void => {
    closeDeleteDialog();
    setSelected(null);
  };
  const handleOpenDeleteDialog = policy => (): void => {
    setSelected(policy);
    openDeleteDialog();
  };
  const handleAddPolicy = (): void => {
    handleOpenPolicyDialog({
      provider,
    })();
  };

  const [message, setMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  return (
    <>
      <Typography variant="h2" className={classes.providerTitle}>
        <span>Policies</span>
        <RBACButton
          color="secondary"
          variant="contained"
          onClick={handleAddPolicy}
          actionLabel="add security policies."
        >
          <NoteAddIcon />
          Add Policy
        </RBACButton>
      </Typography>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>Policy Name</TableCell>
            <TableCell>Rules</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {loading && (
            <TableRow>
              <TableCell colSpan={3} style={{ textAlign: 'center' }}>
                <CircularProgress />
              </TableCell>
            </TableRow>
          )}
          {Boolean(!loading && policies.length) &&
            policies.map(policy => (
              <TableRow key={policy.id}>
                <TableCell>{policy.name}</TableCell>
                <TableCell>
                  {Object.keys(rules).map(compliance => (
                    <Chip
                      className={classes.chip}
                      key={`${policy.id}-${compliance}`}
                      label={
                        <>
                          {`${policyControlTitle(compliance)}: `}
                          <span className={classes.catCount}>{`${
                            Object.keys(rules[compliance]).length -
                            (policy?.policy?.[compliance]?.exclusions ?? []).length
                          }`}</span>
                          <span>{` of ${Object.keys(rules[compliance]).length}`}</span>
                        </>
                      }
                    />
                  ))}
                </TableCell>
                <ActionCell>
                  {!policy.default && (
                    <DeleteButton
                      onClick={handleOpenDeleteDialog(policy)}
                      label="Delete"
                      data-test-id="policy-delete-policy"
                    />
                  )}
                  {(account?.role || 'readonly')?.toLowerCase() === 'readonly' ? (
                    <Button
                      variant="text"
                      onClick={handleOpenPolicyDialog(policy)}
                      data-test-id="policy-view-policy"
                    >
                      <VisibilityIcon />
                      View
                    </Button>
                  ) : (
                    <EditButton
                      onClick={handleOpenPolicyDialog(policy)}
                      data-test-id="policy-edit-policy"
                    />
                  )}
                  {/* <IconButton
                    size="small"
                    onClick={openMenu(policy)}
                    aria-controls="action-menu"
                    aria-haspopup="true"
                    data-test-id={`nsg-more-options-${policy.id}`}
                  >
                    <MoreVertIcon />
                  </IconButton> */}
                </ActionCell>
              </TableRow>
            ))}
          {Boolean(!loading && !policies.length) && (
            <NoResults colSpan={3} value="No policies configured" />
          )}
        </TableBody>
      </Table>
      {policyDialogOpen && (
        <PolicyEditorDialog
          open={policyDialogOpen}
          handleClose={closePolicyDialog}
          onClose={onClosePolicyDialog}
          policy={selected}
          rules={rules}
          provider={provider}
          disabled={
            (account?.role || account?.tokenData?.context?.role || 'readonly')?.toLowerCase() ===
            'readonly'
          }
          handleSuccess={msg => {
            closePolicyDialog();
            setMessage('Policy updated');
            setSnackbarOpen(true);
          }}
        />
      )}
      {deleteDialogOpen && (
        <PolicyDeleteDialog
          open={deleteDialogOpen}
          handleClose={closeDeleteDialog}
          onClose={onCloseDeleteDialog}
          policy={selected}
          handleSuccess={msg => {
            closeDeleteDialog();
            setMessage('Policy deleted');
            setSnackbarOpen(true);
          }}
        />
      )}
      <Snackbar open={snackbarOpen} onClose={() => setSnackbarOpen(false)}>
        <StatusMessage
          status="success"
          variant="filled"
          message={message}
          removable
          handleDismiss={() => setSnackbarOpen(false)}
        />
      </Snackbar>
    </>
  );
}

PolicyTable.propTypes = {
  policies: PropTypes.array,
  provider: PropTypes.oneOf(['aws', 'azure']).isRequired,
  loading: PropTypes.bool,
};

PolicyTable.defaultProps = {
  policies: [],
  loading: false,
};
