import React, { useEffect, useMemo, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { TextField } from '@cuda-networks/bds-core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';

import FormDialog from 'components/common/FormDialog';
import { UPDATE_POLICY, LIST_POLICIES } from 'graphql/SecurityPolicy';
import useRequest from 'hooks/useRequest';
import { expandAccountPolicy, expandPolicy } from './lib';

export default function AccountPolicyDialog({
  account,
  policy,
  handleClose,
  provider,
  handleSuccess,
  ...other
}): JSX.Element {
  const { loading: policiesLoading, data: policiesData } = useQuery(
    // TODO error: policiesError,
    LIST_POLICIES,
    {
      context: { clientName: 'findings' },
    },
  );

  const [selected, setSelected] = useState({});

  const [updateOldPolicy] = useMutation(UPDATE_POLICY, {
    context: { clientName: 'findings' },
  });
  const [updateNewPolicy] = useMutation(UPDATE_POLICY, {
    context: { clientName: 'findings' },
  });

  const [modifyPolicyStatus, modifyPolicyStarted, modifyPolicySucceeded, modifyPolicyFailed] =
    useRequest();
  const handleSave = async (): void => {
    modifyPolicyStarted();

    try {
      const newPolicy = {
        name: selected?.name,
        provider: selected?.provider,
        id: selected?.id,
        policy: selected?.policy,
        scope: [...selected.scope, account.cloudId],
      };
      const oldPolicy = {
        name: policy?.name,
        provider: policy?.provider,
        id: policy?.id,
        policy: expandAccountPolicy(policy?.policy),
        scope: policy.scope.filter(a => a !== account.cloudId),
      };

      if (oldPolicy?.name !== 'Default') {
        await updateOldPolicy({ variables: { policy: oldPolicy } });
      }
      if (newPolicy?.name !== 'Default') {
        await updateNewPolicy({ variables: { policy: newPolicy } });
      }

      modifyPolicySucceeded(`Successfully changed from '${oldPolicy.name}' to '${newPolicy.name}'`);
      handleSuccess(`Successfully changed from '${oldPolicy.name}' to '${newPolicy.name}'`);
    } catch (e) {
      modifyPolicyFailed(
        e?.networkError?.result?.message || e?.message || 'Failed to change policy',
      );
    }
  };

  useEffect(() => {
    setSelected(policy);
  }, [policy]);

  const options = useMemo(
    (): void => (policiesData?.listPolicies ?? []).filter(p => p.provider === provider),
    [policiesData, provider],
  );

  return (
    <FormDialog
      title="Cloud Connection Policy"
      submitLabel="Save Changes"
      handleConfirm={handleSave}
      status={modifyPolicyStatus}
      valid={selected?.id !== policy?.id}
      handleClose={handleClose}
      maxWidth="sm"
      data-test-id="policy-account-policy-dialog"
      {...other}
    >
      <Autocomplete
        disabled={policiesLoading || modifyPolicyStatus.fetching}
        id="ruleset-combo"
        options={options}
        getOptionSelected={(option, value): boolean => option.id === value.id}
        getOptionLabel={(option): string => option?.name || ''}
        disableClearable
        data-test-id="move-nsg-dropdown"
        renderInput={(params): JSX.Element => (
          <TextField
            {...params}
            required
            variant="outlined"
            label="Security Policy"
            margin="dense"
            fullWidth
            data-test-id="policy-account-policy-selected"
          />
        )}
        value={selected}
        renderOption={(option): JSX.Element => <span data-test-id={option}>{option.name}</span>}
        onChange={(e, value): void => {
          setSelected(value);
        }}
      />
    </FormDialog>
  );
}

AccountPolicyDialog.propTypes = {
  ruleset: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
};
