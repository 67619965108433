import { gql } from '@apollo/client';

export const USERS = gql`
  query users {
    users {
      createdAt
      email
      id
      name
      pending
    }
  }
`;

export const USER = gql`
  query user($input: GetUserInput!) {
    user(input: $input) {
      createdAt
      email
      id
      name
      pending
      role
    }
  }
`;

export const CREATE_USER = gql`
  mutation createUser($input: CreateUserInput!) {
    createUser(input: $input) {
      id
      email
      role
      pending
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      name
      role
    }
  }
`;

export const DELETE_USER = gql`
  mutation deleteUser($input: DeleteUserInput!) {
    deleteUser(input: $input) {
      id
    }
  }
`;
