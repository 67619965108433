import { hasProp } from 'lib';

const colors = ['#0088CE', '#00B098', '#F05336', '#9253A1', '#FDB913'];

export const graphInfo = (total, top, label, settings, defaultObj, topOrder, cloudNames) => {
  let response = {
    total: [],
    top: {},
  };
  if (total && top) {
    response.total = Object.entries(
      total
        .filter(d => hasProp(settings, d?.[label]))
        .reduce(
          (obj, d) => ({
            ...obj,
            [d[label]]: (obj?.[d[label]] || 0) + d.count,
          }),
          {},
        ),
    ).map(([value, count]) => ({
      [label]: value,
      count,
      ...settings[value],
    }));
    response.top = {
      segments: Object.entries(settings).map(([key, val]) => ({
        key,
        ...val,
      })),
      data: Object.entries(
        top.reduce(
          (obj, c) => ({
            ...obj,
            [c.cloudId]: {
              ...(obj[c.cloudId] || {
                ...defaultObj,
              }),
              [c?.[label]]: c.count,
            },
          }),
          {},
        ),
      )
        .map(([cloudId, data]) => ({
          cloudId,
          name: cloudNames?.[cloudId]?.name || cloudId,
          ...data,
        }))
        .sort((a, b) => topOrder?.[a.cloudId] - topOrder?.[b.cloudId]),
    };
  }

  return response;
};

export const severityGraph = (total, top, topOrder, cloudNames) => {
  return graphInfo(
    total,
    top,
    'label',
    {
      HIGH: {
        name: 'High',
        color: '#E34050',
      },
      MEDIUM: {
        name: 'Medium',
        color: '#EEAA48',
      },
      LOW: {
        name: 'Low',
        color: '#5F5F5F',
      },
    },
    {
      HIGH: 0,
      LOW: 0,
      MEDIUM: 0,
    },
    topOrder,
    cloudNames,
  );
};
export const controlGraph = (total, top, topOrder, cloudNames) => {
  return graphInfo(
    total,
    top,
    'standard',
    {
      cis: {
        name: 'CIS',
        color: colors[0],
      },
      hipaa: {
        name: 'HIPAA',
        color: colors[1],
      },
      nist: {
        name: 'NIST',
        color: colors[2],
      },
      pci_dss: {
        name: 'PCI DSS',
        color: colors[3],
      },
    },
    {
      cis: 0,
      hipaa: 0,
      nist: 0,
      pci_dss: 0,
    },
    topOrder,
    cloudNames,
  );
};
export const providerGraph = (total, top, topOrder, cloudNames) => {
  return graphInfo(
    total,
    top,
    'provider',
    {
      aws: {
        name: 'AWS',
        color: '#FF9900',
      },
      azure: {
        name: 'Azure',
        color: '#0078d4',
      },
    },
    {
      aws: 0,
      azure: 0,
    },
    topOrder,
    cloudNames,
  );
};

export const resultGraph = (total, top, topOrder, cloudNames) => {
  return graphInfo(
    total,
    top,
    'status',
    {
      PASSED: {
        name: 'Passed',
        color: '#95CC66',
      },
      FAILED: {
        name: 'Failed',
        color: '#E34050',
      },
    },
    {
      PASSED: 0,
      FAILED: 0,
    },
    topOrder,
    cloudNames,
  );
};
