import React, { useState } from 'react';

import { Menu, MenuItem, Checkbox } from '@cuda-networks/bds-core';
import { FormControlLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import HeaderButton from 'components/common/table/HeaderButton';

const useStyles = makeStyles(
  theme => ({
    menuHeader: {
      pointerEvents: 'none',
      padding: '8px 16px',
    },
    wrapper: {
      display: 'inline-flex',
      verticalAlign: 'middle',
    },
    button: {
      backgroundColor: '#FFFFFF',
    },
  }),
  {
    name: 'ColumnChooser',
  },
);

export default function ColumnChooser({
  loading,
  icon,
  value,
  columns,
  toggleColumn,
  ...others
}): JSX.Element {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const closeMenu = () => {
    setAnchorEl(null);
  };
  const openMenu = e => {
    setAnchorEl(e.currentTarget);
  };

  return (
    <div className={classes.wrapper}>
      <HeaderButton
        loading={loading}
        icon={icon}
        value={value}
        onClick={openMenu}
        className={anchorEl ? classes.button : null}
        {...others}
      />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        getContentAnchorEl={null}
        disableAutoFocusItem
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div className={classes.menuHeader}>Customize Table Columns</div>
        {Object.entries(columns).map(([key, val]): void => (
          <MenuItem className={classes.menuOptions} key={key}>
            <FormControlLabel
              data-test-id="formControlCheckbox"
              key={key}
              classes={{
                root: classes.formRoot,
                label: classes.formLabel,
              }}
              control={
                <Checkbox
                  id={`column-${key}`}
                  // color="primary"
                  checked={val.display}
                  value={key}
                  className={classes.checkbox}
                  onChange={(event, checked) => toggleColumn(key, checked)}
                />
              }
              label={<span data-test-id="formLabel">{val.label}</span>}
            />
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

ColumnChooser.propTypes = {
  loading: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  icon: PropTypes.element.isRequired,
};

ColumnChooser.defaultProps = {
  loading: false,
};
