import React from 'react';

import { Tooltip, colors } from '@cuda-networks/bds-core';
import { makeStyles } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/Error';
import clsx from 'clsx';

import { AWSIcon, AzureIcon } from 'icons';
import { CloudType, Connection } from 'types';

const useStyles = makeStyles(
  {
    accountCell: {
      display: 'flex',
      alignItems: 'center',
    },
    small: {
      '& > svg': {
        height: '18px',
      },
    },
    iconWrapper: {
      display: 'flex',
      position: 'relative',
      marginRight: '5px',
    },
    statusIcon: {
      height: '14px',
      width: '14px',
      position: 'absolute',
      left: '-6px',
      bottom: 0,
      color: colors.statusCritical,
    },
  },
  {
    name: 'ConnectionDisplay',
  },
);

interface ConnectionDisplayProps {
  connection: Connection;
  small?: boolean;
  noTooltip?: boolean;
}

export default function ConnectionDisplay(props: ConnectionDisplayProps): JSX.Element {
  const { connection, small = false, noTooltip = false } = props;
  const classes = useStyles();

  return (
    <span className={clsx(classes.accountCell, { [classes.small]: small })}>
      <div className={classes.iconWrapper}>
        {
          {
            aws: <AWSIcon />,
            azure: <AzureIcon />,
          }[connection.cloudId.split(':')[0] as CloudType]
        }
        {connection.health !== 'Good' && (
          <Tooltip
            title="This Cloud Connection has an error. Go to the Cloud Connections page for more details."
            {...(noTooltip && { open: false })}
          >
            <ErrorIcon className={classes.statusIcon} />
          </Tooltip>
        )}
      </div>
      {connection.name}
    </span>
  );
}
