import AccountPolicyDialog from './AccountPolicyDialog';
import AccountTable from './AccountTable';
import PolicyCatSelector from './PolicyCatSelector';
import PolicyDeleteDialog from './PolicyDeleteDialog';
import PolicyEditor from './PolicyEditor';
import PolicyEditorDialog from './PolicyEditorDialog';
import PolicyTable from './PolicyTable';
import SecurityPolicy from './SecurityPolicy';

export default SecurityPolicy;

export {
  AccountPolicyDialog,
  AccountTable,
  PolicyCatSelector,
  PolicyDeleteDialog,
  PolicyEditor,
  PolicyEditorDialog,
  PolicyTable,
};
