import React, { useEffect, useState, useMemo } from 'react';

import { useQuery } from '@apollo/client';
import { Button, Grid } from '@cuda-networks/bds-core';

import PageTitle from 'components/common/PageTitle';
import PaginatedTable from 'components/common/PaginatedTable';
import RBACButton from 'components/common/RBACButton';
import { UsersTable, UserAddDialog, UserEditDialog, UserDeleteDialog } from 'components/Users';
import { USERS } from 'graphql/Users';
import useAuth from 'hooks/useAuth';
import useDialog from 'hooks/useDialog';
import usePagination from 'hooks/usePagination';

export default function Users(): JSX.Element {
  const { account } = useAuth();

  const {
    data: usersData,
    refetch: refetchUsers,
    loading: usersLoading,
  } = useQuery(USERS, {
    context: { clientName: 'accounts' },
  });

  const [pagination, handlePaginate] = usePagination();

  useEffect(() => {
    handlePaginate({
      total: (usersData?.users ?? []).length,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersData]);

  const users = useMemo(() => {
    return (usersData?.users ?? []).slice(
      pagination.page * pagination.size,
      pagination.page * pagination.size + pagination.size,
    );
  }, [pagination, usersData]);

  const [selectedUser, setSelectedUser] = useState({});

  const [addDialogOpen, openAddDialog, closeAddDialog] = useDialog();
  const handleAdd = () => {
    openAddDialog();

    setSelectedUser({
      email: '',
      role: 'ReadOnly',
      edit: false,
    });
  };

  const [editDialogOpen, openEditDialog, closeEditDialog] = useDialog();
  const handleEdit =
    ({ email }) =>
    (): void => {
      setSelectedUser({
        email,
      });
      openEditDialog();
    };

  const [deleteDialogOpen, openDeleteDialog, closeDeleteDialog] = useDialog();
  const handleDelete = user => (): void => {
    setSelectedUser(user);
    openDeleteDialog();
  };

  const handleRefresh = (): void => {
    handlePaginate({
      page: 0,
    });
    refetchUsers();
  };

  return (
    <>
      <PageTitle title="User Management">
        <div style={{ display: 'flex' }}>
          <Button
            color="secondary"
            onClick={handleRefresh}
            disabled={usersLoading}
            style={{ marginRight: '8px' }}
            variant="contained"
          >
            Refresh
          </Button>
          <RBACButton
            color="primary"
            onClick={handleAdd}
            disabled={usersLoading}
            actionLabel="add users."
            roles={['administrator']}
            variant="contained"
          >
            Add User
          </RBACButton>
        </div>
      </PageTitle>
      <Grid container spacing={3} style={{ padding: '15px' }}>
        <Grid item xs={12}>
          <PaginatedTable
            handlePaginate={handlePaginate}
            pagination={pagination}
            rowsPerPageOptions={[50]}
            loading={usersLoading}
          >
            <UsersTable
              users={users}
              currentUser={account?.email || ''}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
            />
          </PaginatedTable>
        </Grid>
      </Grid>
      {deleteDialogOpen && (
        <UserDeleteDialog
          user={selectedUser}
          open={deleteDialogOpen}
          handleClose={closeDeleteDialog}
        />
      )}
      {addDialogOpen && (
        <UserAddDialog user={selectedUser} open={addDialogOpen} handleClose={closeAddDialog} />
      )}
      {editDialogOpen && (
        <UserEditDialog user={selectedUser} open={editDialogOpen} handleClose={closeEditDialog} />
      )}
    </>
  );
}
