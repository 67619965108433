import React from 'react';

import { CircularProgress } from '@cuda-networks/bds-core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles(
  {
    indicator: {
      display: 'flex',
      alignItems: 'center',
      '& > div': {
        marginRight: '5px',
      },
    },
    padded: {
      padding: '12px',
    },
  },
  {
    name: 'LoadingIndicator',
  },
);

export default function StatusMessage({ message, className, padded, ...other }): JSX.Element {
  const classes = useStyles();

  return (
    <span className={clsx(classes.indicator, className, { [classes.padded]: padded })} {...other}>
      <CircularProgress />
      {message}
    </span>
  );
}

StatusMessage.propTypes = {
  message: PropTypes.string,
  className: PropTypes.string,
  padded: PropTypes.bool,
};

StatusMessage.defaultProps = {
  message: null,
  className: null,
  padded: false,
};
