import React, { useState, useEffect } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Radio,
  RadioGroup,
  TextField,
} from '@cuda-networks/bds-core';
import { FormControlLabel, FormControl } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LineIcon from '@material-ui/icons/LineWeight';
import LinkIcon from '@material-ui/icons/Link';
import OpenIcon from '@material-ui/icons/OpenInNew';

import DialogTitle from 'components/common/DialogTitle';
import StatusMessage from 'components/common/StatusMessage';
import { SerialType, RequestStatus, AddSerial } from 'types';

const useStyles = makeStyles(
  {
    dialog: {
      minWidth: 400,
      minHeight: 200,
      maxWidth: 750,
      width: '95%',
      '& form': {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
      },
    },

    licenseLabel: {
      fontWeight: 400,
    },
    typeDetails: {
      marginLeft: '31px',
    },
    textField: {
      display: 'flex',
      alignItems: 'flex-end',
      '& svg': {
        height: '32px',
        marginRight: '5px',
      },
    },
  },
  {
    name: 'AddLicenseDialog',
  },
);

interface AddLicenseDialogProps {
  status: RequestStatus;
  handleConfirm: (data: AddSerial) => Promise<void>;
  handleDialogClose: () => void;
  open: boolean;
}

export default function AddLicenseDialog(props: AddLicenseDialogProps): React.ReactElement {
  const { status, handleConfirm, handleDialogClose, open } = props;
  const classes = useStyles();

  const [licenseType, setLicenseType] = useState<SerialType>('cloud');
  const [valid, setValid] = useState(false);
  const [serial, setSerial] = useState('');
  const [code, setCode] = useState('');

  const handleTypeChange = (_event: React.ChangeEvent<HTMLInputElement>, value: string): void => {
    setLicenseType(value as SerialType);
  };

  const handleSave = (event: React.FormEvent<HTMLFormElement>): void => {
    handleConfirm({
      serial,
      linkingCode: code,
    });
    event.preventDefault();
  };

  const openAws = (): void => {
    window.open(
      'https://aws.amazon.com/marketplace/pp/Barracuda-Networks-Inc-Barracuda-Cloud-Security-Gu/B07KF2X7QJ',
      '_blank',
    );
  };

  const openAzure = (): void => {
    window.open(
      'https://azuremarketplace.microsoft.com/en-us/marketplace/apps/barracudanetworks.barracuda-csg-metered?tab=Overview',
      '_blank',
    );
  };

  useEffect(() => {
    if (licenseType === 'reseller' && serial && code) {
      setValid(true);
      return;
    }

    setValid(false);
  }, [licenseType, serial, code, setValid]);

  return (
    <Dialog
      classes={{
        paper: classes.dialog,
      }}
      maxWidth="md"
      onClose={handleDialogClose}
      open={open}
    >
      <DialogTitle>Add License</DialogTitle>
      <form onSubmit={handleSave}>
        <DialogContent>
          {status.error && <StatusMessage status="error" message={status.error} />}
          <FormControl required component="div">
            <RadioGroup
              aria-label="license type"
              name="license"
              value={licenseType}
              onChange={handleTypeChange}
            >
              <FormControlLabel
                value="cloud"
                disabled={status.fetching}
                control={<Radio color="primary" />}
                label={<div className={classes.licenseLabel}>Pay-as-you-go</div>}
              />
              {licenseType === 'cloud' && (
                <div className={classes.typeDetails}>
                  <div>
                    Subscribe to Cloud Security Guardian through your cloud service provider
                  </div>
                  <div>
                    <Button variant="text" onClick={openAws}>
                      Amazon Web Services
                      <OpenIcon />
                    </Button>
                    <Button variant="text" onClick={openAzure}>
                      Microsoft Azure
                      <OpenIcon />
                    </Button>
                  </div>
                </div>
              )}
              <FormControlLabel
                value="reseller"
                control={<Radio color="primary" />}
                label={
                  <div className={classes.licenseLabel}>Purchased through a Barracuda reseller</div>
                }
              />
              {licenseType === 'reseller' && (
                <div className={classes.typeDetails}>
                  <div>
                    To activate a purchase, enter the serial number and linking code provided by
                    Barracuda or your reseller.
                  </div>
                  <div className={classes.textField}>
                    <LineIcon />
                    <TextField
                      label="Serial Number"
                      required
                      value={serial}
                      onChange={(
                        event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
                      ): void => setSerial(event.target.value)}
                      fullWidth
                      margin="none"
                      disabled={status.fetching}
                    />
                  </div>
                  <div className={classes.textField}>
                    <LinkIcon />
                    <TextField
                      label="Linking Code"
                      required
                      value={code}
                      onChange={(
                        event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
                      ): void => setCode(event.target.value)}
                      fullWidth
                      margin="none"
                      disabled={status.fetching}
                      size="medium"
                    />
                  </div>
                </div>
              )}
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDialogClose}
            color="secondary"
            disabled={status.fetching}
            variant="contained"
          >
            Cancel
          </Button>
          <Button color="primary" type="submit" autoFocus disabled={!valid} variant="contained">
            Add License
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
