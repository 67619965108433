import React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@cuda-networks/bds-core';
import { makeStyles } from '@material-ui/core/styles';

import DialogTitle from 'components/common/DialogTitle';
import StatusMessage from 'components/common/StatusMessage';
import { RequestStatus } from 'types';

const useStyles = makeStyles(
  theme => ({
    title: {
      backgroundColor: theme.palette.primary.main,
      color: '#FFFFFF',
      '& > h2': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
      '& button': {
        color: '#FFFFFF',
      },
    },
    deleteButton: {
      '&:disabled': {
        backgroundColor: '#C87079',
      },
    },
  }),
  {
    name: 'ConfirmDelete',
  },
);

interface ConfirmDeleteProps {
  children?: React.ReactNode;
  status: RequestStatus;
  title: string;
  message?: React.ReactNode;
  confirmLabel?: string;
  handleClose: () => void;
  handleConfirm: () => void;
  open?: boolean;
}

export default function ConfirmDelete(props: ConfirmDeleteProps): JSX.Element {
  const {
    children,
    status,
    title,
    message,
    confirmLabel = 'Delete',
    handleClose,
    handleConfirm,
    open = false,
    ...other
  } = props;
  const classes = useStyles();

  const handleDialogClose = (): void => {
    if (handleClose) {
      handleClose();
    }
  };

  return (
    <Dialog open={open} maxWidth="xs" onClose={handleClose} {...other}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {status.error && <StatusMessage status="error" message={status.error} removable />}
        {status.succeeded && status.result && (
          <StatusMessage status="success" message={status.result} removable />
        )}
        {children || <DialogContentText>{message}</DialogContentText>}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleDialogClose}
          color="secondary"
          variant="contained"
          disabled={status.fetching}
          data-test-id="delete-dialog-cancel"
        >
          Cancel
        </Button>
        <Button
          onClick={handleConfirm}
          className={classes.deleteButton}
          isCaution
          variant="contained"
          autoFocus
          data-test-id="delete-dialog-confirm"
        >
          {confirmLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
