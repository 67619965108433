import React from 'react';

import { useMutation, useQuery } from '@apollo/client';

import { UserFormDialog } from 'components/Users';
import { USER, UPDATE_USER } from 'graphql/Users';
import useQueryRequest from 'hooks/useQueryRequest';
import useRequest from 'hooks/useRequest';
import { User } from 'types';

interface UserEditDialogProps {
  user: User;
  open: boolean;
  handleClose: () => void;
}

export default function UserEditDialog(props: UserEditDialogProps): JSX.Element {
  const { user, open, handleClose } = props;

  const userQuery = useQuery(USER, {
    skip: !user?.email,
    context: { clientName: 'accounts' },
    variables: {
      input: {
        id: user?.email,
      },
    },
  });
  const { data: userData } = userQuery;
  const [loadUserStatus] = useQueryRequest(userQuery);

  const [userStatus, userStarted, userSucceeded, userFailed] = useRequest();
  const [updateUser] = useMutation(UPDATE_USER, {
    context: { clientName: 'accounts' },
  });

  const handleConfirm = async (updatedUser: User): Promise<void> => {
    userStarted();
    try {
      const { data } = await updateUser({
        variables: {
          input: {
            id: updatedUser.email,
            name: updatedUser.name,
            role: updatedUser.role,
          },
        },
      });

      if (data?.updateUser === null) {
        throw new Error('Failed to update user');
      }

      userSucceeded();
      handleClose();
    } catch (e) {
      userFailed(e?.networkError?.result?.message || e?.message || 'Failed to update user');
    }
  };

  return (
    <UserFormDialog
      user={userData?.user || {}}
      open={open}
      handleClose={handleClose}
      status={userStatus}
      handleConfirm={handleConfirm}
      loadStatus={loadUserStatus}
      edit
    />
  );
}
