import React, { useState, useMemo, useEffect } from 'react';

import { useMutation } from '@apollo/client';
import { Switch, Divider, Typography, Snackbar } from '@cuda-networks/bds-core';
import {
  Table,
  TableRow,
  TableCell,
  TableBody,
  FormGroup,
  FormControlLabel,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import AccountNameWithIcon from 'components/common/AccountNameWithIcon';
import RBACButton from 'components/common/RBACButton';
import StatusMessage from 'components/common/StatusMessage';
import {
  FindingLabel,
  FindingDetailsDialog,
  ComplianceFromFinding,
  RiskDetailsTable,
} from 'components/SecurityFindings';
import { SUPPRESS_FINDING } from 'graphql/SecurityFindings';
import { compressFilter } from 'hooks/useCompressedFilter';
import useDialog from 'hooks/useDialog';
import { filtersFromSelectedControls } from 'hooks/useFindingsFilter';
import useRequest from 'hooks/useRequest';
import { RiskDetailsInfo } from 'types';

import { sourceFromFinding } from './lib';
import RemediateDialog from './RemediateDialog';

const useStyles = makeStyles(
  () => ({
    account: {
      display: 'flex',
      padding: '8px 24px',
      alignItems: 'center',
      minHeight: '64px',
      justifyContent: 'space-between',
    },
    details: {
      '& > div': {
        padding: '16px 24px',
      },
      '& $tabs': {
        padding: '0 24px',
      },
      overflowY: 'auto',
    },
    tabs: {},

    table: {
      '& td': {
        fontSize: '12px',
        border: 0,
        verticalAlign: 'text-top',
        // '&$label': {
        //   fontSize: '14px',
        // }
      },
      marginBottom: '24px',
    },

    label: {
      lineHeight: '32px',
      width: '100px',
      fontWeight: 500,
    },
    largeLabel: {
      fontSize: '14px',
      lineHeight: '16px',
      fontWeight: 500,
      marginBottom: '4px',
    },
    value: {
      lineHeight: '14px',
      marginBottom: '14px',
    },
    largeValue: {
      fontSize: '12px',
      lineHeight: '20px',
      marginBottom: '24px',
      '&:last-child': {
        marginBottom: 0,
      },
    },

    switchLabel: {
      fontSize: '14px',
    },

    actionWrapper: {
      paddingBottom: 0,
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      '& > div': {
        display: 'flex',
      },
    },
    actionButton: {
      marginRight: '8px',
      height: 'fit-content',
    },
  }),
  {
    name: 'RiskDetails',
  },
);

interface RiskDetailsProps {
  findings: any[];
  riskInfo: RiskDetailsInfo;
  cloudId: string;
  error: any;
}

export default function RiskDetails(props: RiskDetailsProps): JSX.Element {
  const { findings, riskInfo, cloudId, error } = props;
  const classes = useStyles();

  const [filter, setFilter] = useState<string | null>(null);
  useEffect(() => {
    const generateFilter = async (): Promise<void> => {
      const source = sourceFromFinding(riskInfo);

      if (source === 'Barracuda Compliance Scanner') {
        const rules = filtersFromSelectedControls([riskInfo.generatorId]);
        const filter = {};
        if (cloudId) {
          filter.accounts = {
            name: 'cloudId',
            value: [cloudId],
          };
        }
        if (rules) {
          filter.selectedRules = rules;
        }

        const result = await compressFilter(filter);
        setFilter(result);
      }
    };
    generateFilter();
  });

  const [hidden] = useState({});
  const [suppressed, setSuppressed] = useState(false);
  const [selectedFindings, setSelectedFindings] = useState(null);

  const [suppressFinding] = useMutation(SUPPRESS_FINDING, {
    context: { clientName: 'findings' },
  });
  const [suppressStatus, suppressStarted, suppressSucceeded, suppressFailed] = useRequest();
  const handleSuppress = async (): void => {
    suppressStarted();
    try {
      const checkedFindings = Object.entries(selectedFindings).filter(([, checked]) => checked);

      const response = await Promise.all(
        checkedFindings.map(([id]) =>
          suppressFinding({ variables: { id } }).catch(e => {
            return {
              error:
                e?.networkError?.result?.message || e?.message || 'Failed to suppress findings',
            };
          }),
        ),
      );

      const errored = response.filter(r => Boolean(r?.error));

      if (errored.length === response.length) {
        throw new Error(errored?.[0].error) || 'Failed   to suppress findings';
      }

      if (!errored.length) {
        suppressSucceeded(`Suppressed ${response.length - errored.length} findings`);
      } else {
        suppressSucceeded(
          `Suppressed ${response.length - errored.length} findings.  ${
            errored.length
          } findings failed.`,
        );
      }
    } catch (e) {
      suppressFailed(
        e?.networkError?.result?.message || e?.message || 'Failed to suppress finding',
      );
    }
  };
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const closeSnackbar = () => setSnackbarOpen(false);
  const [result, setResult] = useState({});
  useEffect(() => {
    if (suppressStatus.succeeded) {
      setSnackbarOpen(true);
      setResult({
        status: 'success',
        message: suppressStatus.result,
      });
    } else {
      setSnackbarOpen(false);
    }
  }, [suppressStatus]);

  const selectedCount = useMemo(() => {
    return Object.values(selectedFindings ?? {}).filter(finding => finding).length;
  }, [selectedFindings]);
  const updateChecked = (updated, value) => {
    setSelectedFindings({
      ...selectedFindings,
      ...updated.reduce((obj, id): void => ({ ...obj, [id]: value }), {}),
    });
  };
  useEffect(() => {
    setSelectedFindings(
      (findings ?? []).reduce(
        (obj, finding): void => ({
          ...obj,
          [finding.id]: false,
        }),
        {},
      ),
    );
  }, [findings]);

  const [remediateDialogOpen, openRemediateDialog, closeRemediateDialog] = useDialog();

  const [selectedFinding, setSelectedFinding] = useState(null);
  const [detailsDialogOpen, openDetailsDialog, closeDetailsDialog] = useDialog();
  const selectFinding = finding => {
    setSelectedFinding(finding);
    openDetailsDialog();
  };
  const deselectFinding = () => {
    closeDetailsDialog();
    closeRemediateDialog();
    setSelectedFinding(null);
  };

  const findingsList = useMemo(
    () =>
      findings.filter(f => !hidden[f?.id] && (suppressed || f.workflow.status !== 'SUPPRESSED')),
    [findings, hidden, suppressed],
  );

  const handleRemediate = () => {
    openRemediateDialog();
  };

  const source = sourceFromFinding(riskInfo);

  const description =
    riskInfo?.description || (findings || []).find(f => Boolean(f?.description))?.description;
  return (
    <>
      <div className={classes.account}>
        <AccountNameWithIcon accountId={cloudId} />
      </div>
      <Divider />
      <div className={classes.details}>
        <div>
          <div className={classes.largeLabel}>{source}</div>
          <Table className={clsx(classes.table, classes.largeValue)} padding="none">
            <TableBody>
              <TableRow>
                <TableCell className={classes.label}>Title</TableCell>
                <TableCell className={classes.value}>{riskInfo.title}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.label}>Severity</TableCell>
                <TableCell className={classes.value}>
                  <FindingLabel noRounding severity={riskInfo.severity} />
                </TableCell>
              </TableRow>
              {source === 'Barracuda Compliance Scanner' && (
                <>
                  <TableRow>
                    <TableCell className={classes.label}>Standard</TableCell>
                    <TableCell className={classes.value}>
                      <ComplianceFromFinding finding={riskInfo} fullName noStandard noRuleId />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.label}>Control</TableCell>
                    <TableCell className={classes.value}>
                      <ComplianceFromFinding finding={riskInfo} noStandard noVersion />
                      <Link
                        to={`/security-findings?filter=${filter}`}
                        style={{ marginLeft: 10 }}
                        data-test-id="risk-all-findings-link"
                      >
                        See all findings for this control
                      </Link>
                    </TableCell>
                  </TableRow>
                </>
              )}
            </TableBody>
          </Table>
          {description && (
            <>
              <div className={classes.largeLabel}>Description</div>
              <div
                className={classes.largeValue}
                dangerouslySetInnerHTML={{ __html: description }}
              />
            </>
          )}
          {riskInfo.rationale && (
            <>
              <div className={classes.largeLabel}>Rationale</div>
              <div
                className={classes.largeValue}
                dangerouslySetInnerHTML={{ __html: riskInfo.rationale }}
              />
            </>
          )}
          {error && (
            <StatusMessage
              status="error"
              message="Failed to load resource information"
              removable={false}
            />
          )}
        </div>
        {Boolean(!error && findings?.length && selectedFindings) && (
          <>
            <div className={classes.actionWrapper}>
              {suppressStatus.error && (
                <StatusMessage
                  status="error"
                  message={suppressStatus.error}
                  style={{ margin: '8px 24px' }}
                  removable
                />
              )}
              <div>
                <RBACButton
                  color="secondary"
                  disabled={!selectedCount}
                  processing={suppressStatus.fetching}
                  onClick={handleSuppress}
                  className={classes.actionButton}
                  actionLabel="suppress findings."
                  data-test-id="risk-suppress-button"
                  variant="contained"
                  size="small"
                >
                  Suppress
                </RBACButton>
                {Boolean(Object.keys(riskInfo?.remediation || {}).length) && (
                  <RBACButton
                    color="secondary"
                    disabled={!selectedCount}
                    processing={suppressStatus.fetching}
                    onClick={handleRemediate}
                    className={classes.actionButton}
                    actionLabel="remediate findings."
                    data-test-id="risk-remediate-button"
                    variant="contained"
                    size="small"
                  >
                    Remediate
                  </RBACButton>
                )}
              </div>
              <div style={{ flexDirection: 'column' }}>
                <>
                  <FormGroup
                    row
                    style={{
                      alignItems: 'center',
                      minWidth: '250px',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography variant="body1">Show suppressed</Typography>
                    <FormControlLabel
                      classes={{
                        label: classes.switchLabel,
                      }}
                      control={
                        <Switch
                          onChange={e => setSuppressed(e.target.checked)}
                          name="show-suppressed"
                          color="primary"
                          size="small"
                          data-test-id="risks-suppressed-switch"
                        />
                      }
                      label={suppressed ? 'Yes' : 'No'}
                    />
                  </FormGroup>
                </>
              </div>
            </div>

            <RiskDetailsTable
              findings={findingsList ?? []}
              selected={selectedFindings ?? {}}
              handleCheck={updateChecked}
              handleSelect={selectFinding}
              selectedCount={selectedCount}
              generatorId={riskInfo?.generatorId}
            />
          </>
        )}
      </div>
      {detailsDialogOpen && (
        <FindingDetailsDialog
          open={detailsDialogOpen}
          findingId={selectedFinding?.id}
          handleClose={closeDetailsDialog}
          onClose={deselectFinding}
          riskInfo={riskInfo}
          resourceType="risk"
        />
      )}
      {remediateDialogOpen && (
        <RemediateDialog
          open={remediateDialogOpen}
          findingId={selectedFinding?.id}
          handleClose={closeRemediateDialog}
          onClose={deselectFinding}
          rule={riskInfo}
        />
      )}
      <Snackbar
        open={snackbarOpen}
        onClose={closeSnackbar}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <StatusMessage
          status={result.status}
          message={result.message}
          removable
          handleDismiss={closeSnackbar}
          variant="filled"
        />
      </Snackbar>
    </>
  );
}

RiskDetails.propTypes = {
  status: PropTypes.object,
};

RiskDetails.defaultProps = {
  status: {},
};
