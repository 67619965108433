import React from 'react';

import { Tooltip } from '@cuda-networks/bds-core';
import { makeStyles } from '@material-ui/core/styles';

import { StatusIcon } from 'components/CloudConnections';
import { ConnectionStatus } from 'types';

const useStyles = makeStyles(
  () => ({
    wrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    image: {
      marginRight: 5,
      height: 20,
    },
  }),
  {
    name: 'EntityDisplay',
  },
);

const icons = {
  '/providers/Microsoft.Management/managementGroups': 'general-11-Management-Groups.svg',
  '/subscriptions': 'general-2-Subscriptions.svg',
  '/tenant': 'Azure-Active-Directory.svg',
} as const;

type IconType = keyof typeof icons;

interface EntityDisplayProps {
  label: string;
  status?: ConnectionStatus;
  type: IconType;
}

export default function EntityDisplay(props: EntityDisplayProps): JSX.Element {
  const { label, status, type } = props;
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <img src={`/images/icons/azure/${icons[type]}`} alt={type} className={classes.image} />
      {label}
      {status && (
        <Tooltip title={status.message}>
          <div>
            <StatusIcon status={status.status} />
          </div>
        </Tooltip>
      )}
    </div>
  );
}
