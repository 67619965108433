import React from 'react';

import { Badge, Typography, Chip, Tooltip } from '@cuda-networks/bds-core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';

import { PageDefintion } from 'types';

const useStyles = makeStyles(
  theme => ({
    container: {
      padding: '12px 24px',
      height: '48px',
      display: 'flex',
      backgroundColor: 'rgb(255,255,255)',
      textDecoration: 'none',
      alignItems: 'center',
      justifyContent: 'flex-start',
      color: '#000006',
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: 'rgba(158,158,158,0.2)',
        boxShadow: 'none',
      },
      cursor: 'pointer',
    },
    noLabel: {
      justifyContent: 'center',
    },
    selected: {
      backgroundColor: '#DDDDDD',
      '& > span': {
        fontWeight: 500,
      },
      pointerEvents: 'none',
    },
    value: {
      marginLeft: '24px',
      color: 'rgba(0, 0, 0, 0.87)',
    },

    primaryBadge: {
      backgroundColor: theme.palette.primary.main,
      color: '#FFFFFF',
      padding: '4px',
    },

    badge: {
      fontSize: '.5rem',
    },

    badgeChip: {
      maxHeight: '20px',
      fontSize: '.75rem',
      '& > span': {
        padding: '0 4px',
      },
    },

    label: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  }),
  {
    name: 'DrawerItem',
  },
);

interface DrawerItemProps {
  noLabel: boolean;
  page: PageDefintion;
  open: boolean;
  count?: number;
}

export default function DrawerItem(props: DrawerItemProps): JSX.Element {
  const { noLabel, page, open, count = 0 } = props;
  const classes = useStyles();

  const { path, title, icon, id } = page;

  const content = (
    <>
      <Tooltip
        title={page.title}
        placement="right"
        disableHoverListener={open}
        disableFocusListener={open}
        disableTouchListener={open}
      >
        <div style={{ height: 24, width: 24 }}>
          {page.badge && noLabel && count ? (
            <Badge
              badgeContent={count}
              color="primary"
              max={999}
              classes={{
                colorPrimary: clsx(classes.badge, classes.primaryBadge),
              }}
            >
              {icon}
            </Badge>
          ) : (
            icon
          )}
        </div>
      </Tooltip>
      <div className={classes.label}>
        <Typography variant="button" className={classes.value}>
          {title}
        </Typography>
        {Boolean(page.badge && count) && (
          <Chip
            label={count}
            size="small"
            className={clsx(classes.primaryBadge, classes.badgeChip)}
          />
        )}
      </div>
    </>
  );

  return (
    <NavLink
      className={clsx(classes.container, { [classes.noLabel]: noLabel })}
      activeClassName={classes.selected}
      exact
      data-test-id={`navigation-${id}`}
      to={path}
    >
      {content}
    </NavLink>
  );
}
