import React from 'react';

import { DateTime } from 'luxon';
import PropTypes from 'prop-types';

const Timestamp = ({
  ts,
  tsFormat,
  timezone,
  format,
  outputFormat = 'DATETIME_MED_WITH_SECONDS',
  ...others
}) => {
  let parsed;

  switch (tsFormat) {
    case 'format':
      parsed = DateTime.fromFormat(ts, format);
      break;
    case 'milli':
      parsed = DateTime.fromMillis(ts);
      break;
    case 'unix':
      parsed = DateTime.fromSeconds(ts);
      break;
    case 'ISO':
      parsed = DateTime.fromISO(ts);
      break;
    case 'SQL':
      parsed = DateTime.fromSQL(ts);
      break;
    default:
      break;
  }

  return (
    ts && (
      <span {...others}>
        {parsed.setZone(timezone).toLocaleString({ ...DateTime?.[outputFormat], hour12: false })}
      </span>
    )
  );
};

Timestamp.propTypes = {
  ts: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  timezone: PropTypes.string,
  tsFormat: PropTypes.string,
  format: PropTypes.string,
};
Timestamp.defaultProps = {
  ts: null,
  timezone: 'America/New_York',
  tsFormat: 'unix',
  format: null,
};

export default Timestamp;
