import React, { createContext, FC, useEffect, useState } from 'react';

import { ApolloClient, createHttpLink, InMemoryCache, useMutation } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { DateTime } from 'luxon';
import { User, UserManager } from 'oidc-client';
import { useHistory } from 'react-router-dom';

import { GENERATE_ACCOUNT_TOKEN } from 'graphql/Auth';
import useMixpanel from 'hooks/useMixpanel';
import { BarracudaAccount } from 'types';
import { parseQS } from 'utils/params';

interface SignInArgs {
  login_hint: string;
}
type AuthStatus = 'authenticated' | 'loading' | 'unauthenticated';

export interface AuthContextProps {
  signIn: (args?: SignInArgs) => Promise<void>;
  signOut: (params?: { oidcLogout?: boolean; urlRedirect?: boolean }) => Promise<void>;
  user?: User;
  account?: BarracudaAccount;
  updateAccount: (token: string) => Promise<void>;
  userManager: UserManager;
  status: AuthStatus;
}

export type CSG_ROLE = 'Administrator' | 'Manager' | 'Billing' | 'ReadOnly';
export interface CSGToken {
  exp: number;
  context: {
    account_id: string;
    account_name: string;
    email: string;
    role: CSG_ROLE;
  };
}

export const AuthContext = createContext<AuthContextProps | undefined>(undefined);

const accountsLink = createHttpLink({
  uri: '/graphql/accounts',
});

const authLink = setContext((_, { headers }) => {
  const token = sessionStorage.getItem('la-token');

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const authClient = new ApolloClient({
  link: authLink.concat(accountsLink),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
    },
    mutate: {
      fetchPolicy: 'no-cache',
    },
  },
});

const parseToken = (token: string): CSGToken | null => {
  let tokenData: CSGToken | null = null;
  try {
    const parts = String(token).split('.');
    if (parts[1]) {
      const obj = JSON.parse(window.atob(parts[1]));
      if (typeof obj !== 'object') {
        throw new Error('Decoded token is not valid JSON');
      }

      tokenData = obj as unknown as CSGToken;
    } else {
      throw new Error('No data at index 1. Probably invalid JWT format.');
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('Token decoding error: ', e);
  }
  return tokenData;
};

export interface AuthProviderProps {
  children: React.ReactElement;
}

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [status, setStatus] = useState<AuthStatus>('loading');
  const history = useHistory();
  const mixpanel = useMixpanel();

  const [user, setUser] = useState<User>();
  const [account, setAccount] = useState<BarracudaAccount>();
  const [userManager] = useState<UserManager>(
    new UserManager({
      // TODO: this authority for dev and qa only works when connected to vpn-all and with CORS disabled
      authority: process.env.REACT_APP_LOGINAPP_AUTHORITY,
      // eslint-disable-next-line @typescript-eslint/camelcase
      client_id: process.env.REACT_APP_LOGINAPP_CLIENT_ID,
      // eslint-disable-next-line @typescript-eslint/camelcase
      redirect_uri: `${window.location.origin}/oidc`,
      // eslint-disable-next-line @typescript-eslint/camelcase
      response_type: 'code',
      automaticSilentRenew: false,
      scope: 'openid basic profile email',
      loadUserInfo: false,
    }),
  );

  const [generateToken] = useMutation(GENERATE_ACCOUNT_TOKEN, {
    context: { clientName: 'accounts' },
    client: authClient,
  });

  useEffect(() => {
    const updateAccessToken = async (userData: User): Promise<void> => {
      sessionStorage.setItem('la-token', userData.access_token);

      let apiToken;
      try {
        const response = await generateToken();

        apiToken = response.data.generateAccountAccessToken.api_token;
      } catch (e) {
        setStatus('unauthenticated');
        userManager.removeUser();
        userManager.clearStaleState();
        localStorage.removeItem('csg-token');
        return;
      } finally {
        sessionStorage.removeItem('la-token');
      }

      const tokenData = parseToken(apiToken);

      if (!tokenData) {
        setStatus('unauthenticated');
        userManager.removeUser();
        userManager.clearStaleState();
        localStorage.removeItem('csg-token');
        return;
      }

      localStorage.setItem('csg-token', apiToken || '');

      setAccount({
        accountName: tokenData.context.account_name,
        name: userData.profile.name || '',
        email: tokenData.context.email,
        id: tokenData.context.account_id,
        role: tokenData.context.role,
      });

      setUser(userData);
      mixpanel.alias(tokenData.context.email);
      setStatus('authenticated');
    };

    const checkToken = async (userData: User): Promise<void> => {
      const token = localStorage.getItem('csg-token');

      const tokenData = parseToken(token || '');
      if (tokenData) {
        const expiration = DateTime.fromSeconds(tokenData.exp);
        const current = DateTime.now().plus({ hours: 6 });

        if (current > expiration) {
          await updateAccessToken(userData);
          return;
        }

        setAccount({
          accountName: tokenData.context.account_name,
          name: userData.profile.name || '',
          email: tokenData.context.email,
          id: tokenData.context.account_id,
          role: tokenData.context.role,
        });

        setUser(userData);
        mixpanel.alias(tokenData.context.email);
        setStatus('authenticated');
      } else {
        setStatus('unauthenticated');
        userManager.removeUser();
        userManager.clearStaleState();
        localStorage.removeItem('csg-token');
      }
    };

    const getUserData = async (): Promise<void> => {
      // eslint-disable-next-line no-restricted-globals
      const searchParams = new URLSearchParams(location.search);
      if (searchParams.get('code')) {
        const userData = await userManager.signinCallback();
        await updateAccessToken(userData);

        let redirectPath = sessionStorage.getItem('csg_redirect') || '/dashboard';
        if (sessionStorage.getItem('csg-trial')) {
          redirectPath = '/accounts/trial';
        }
        sessionStorage.removeItem('csg_redirect');
        sessionStorage.removeItem('csg-trial');

        history.push(redirectPath);

        return;
      }

      let userData = await userManager.getUser();

      if (userData) {
        checkToken(userData);

        return;
      }

      try {
        userData = await userManager.signinSilent();
        await updateAccessToken(userData);

        return;
        // eslint-disable-next-line no-empty
      } catch (e) {}

      setStatus('unauthenticated');
      userManager.removeUser();
      userManager.clearStaleState();
      localStorage.removeItem('csg-token');
    };

    getUserData();
  }, [generateToken, history, mixpanel, userManager]);

  const updateAccount = async (token: string): Promise<void> => {
    const tokenData = parseToken(token);

    if (!tokenData) {
      throw new Error('Error switching account');
    }

    localStorage.setItem('csg-token', token || '');

    setAccount({
      accountName: tokenData.context.account_name,
      name: tokenData.context.email,
      email: tokenData.context.email,
      id: tokenData.context.account_id,
      role: tokenData.context.role,
    });
  };

  const signIn = async (args?: SignInArgs): Promise<void> => {
    const params: { redirect?: string } = parseQS();

    if (params.redirect) {
      sessionStorage.setItem('csg_redirect', params.redirect);
    }

    await userManager.signinRedirect(args);
  };

  const signOut = async (params?: {
    oidcLogout?: boolean;
    urlRedirect?: boolean;
  }): Promise<void> => {
    const { oidcLogout = true, urlRedirect = false } = params || {};
    await userManager.removeUser();

    const signOutPromise = new Promise(resolve => {
      const i = document.createElement('iframe');
      i.style.display = 'none';
      i.onload = async (): Promise<void> => {
        resolve(null);
      };
      i.src = `${process.env.REACT_APP_LOGINAPP_URL}/logout`;
      document.body.appendChild(i);
    });

    if (oidcLogout) {
      await signOutPromise;
    }

    setStatus('unauthenticated');
    userManager.clearStaleState();
    localStorage.removeItem('csg-token');

    mixpanel.reset();

    history.push(`/login${urlRedirect ? `?redirect=${window.location.pathname}` : ''}`);
  };

  return (
    <AuthContext.Provider
      value={{ signIn, signOut, user, account, updateAccount, status, userManager }}
    >
      {children}
    </AuthContext.Provider>
  );
};
