import React from 'react';

import { Typography } from '@cuda-networks/bds-core';
import { makeStyles } from '@material-ui/core/styles';

import ProviderButton from 'components/common/ProviderButton';
import { AWSIcon, AzureIcon } from 'icons';

const useStyles = makeStyles(
  {
    actionBar: {
      display: 'flex',
      alignItems: 'center',
      '&>div': {
        marginRight: '10px',
        border: 'none',
        boxShadow: 'none',
        minWidth: '150px',
      },
      '&>button': {
        marginRight: '5px',
        '&:last-child': {
          marginRight: '0',
        },
      },
    },
  },
  {
    name: 'CompliancePolicy',
  },
);

export default function CompliancePolicy({ provider, setProvider }): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.actionBar}>
      <ProviderButton name="aws" selected={provider === 'aws'} onClick={() => setProvider('aws')}>
        <AWSIcon />
        <Typography variant="button">Amazon Web Services</Typography>
      </ProviderButton>
      <ProviderButton
        name="azure"
        selected={provider === 'azure'}
        onClick={() => setProvider('azure')}
      >
        <AzureIcon />
        <Typography variant="button">Microsoft Azure</Typography>
      </ProviderButton>
    </div>
  );
}
