import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  {
    wrapper: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'flex-end',
      '& > div': {
        marginRight: '8px',
        marginBottom: '8px',
      },
      '& > button': {
        marginRight: '8px',
        marginBottom: '8px',
      },
    },
  },
  {
    name: 'TableFilter',
  },
);

export default function Alerts({ children }): JSX.Element {
  const classes = useStyles();

  return <div className={classes.wrapper}>{children}</div>;
}
