import { useMutation, useApolloClient } from '@apollo/client';

import { SETUP_ACCOUNT, LIST_ACCOUNTS } from 'graphql/Accounts';
import { SetupAccount, ListAccountsData, SetupAccountInput, SetupAccountData } from 'types';

export default function useAccountSetup(): [(input: SetupAccount) => Promise<boolean>] {
  const client = useApolloClient();
  const [handleSetup] = useMutation<SetupAccountData, SetupAccountInput>(SETUP_ACCOUNT, {
    context: {
      clientName: 'accounts',
    },
  });

  const checkAccount = async (accountId: string): Promise<{ success: boolean }> => {
    const response = await client
      .query<ListAccountsData>({
        query: LIST_ACCOUNTS,
        fetchPolicy: 'network-only',
        context: {
          clientName: 'accounts',
        },
      })
      .then(r => ({
        success: (r?.data?.accounts ?? []).some(a => a.id === accountId && a.setup),
      }))
      .catch(_e => ({
        success: false,
      }));

    return response;
  };

  const setup = async (input: SetupAccount): Promise<boolean> => {
    let success = false;

    try {
      await handleSetup({
        variables: {
          input,
        },
      });
      success = true;
    } catch (e) {
      let count = 0;

      while (count < 5) {
        // eslint-disable-next-line no-await-in-loop
        await new Promise(resolve => {
          setTimeout(resolve, 5000);
        });
        // eslint-disable-next-line no-await-in-loop
        const result = await checkAccount(input.id);

        if (!result.success) {
          count += 1;
        } else {
          success = true;
          break;
        }
      }
    }

    return success;
  };

  return [setup];
}
