import React, { useState, useEffect } from 'react';

import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import FormDialog from 'components/common/FormDialog';
import { PolicyEditor } from 'components/SecurityPolicy';
import { expandPolicy } from 'components/SecurityPolicy/lib';
import { LIST_POLICIES, CREATE_POLICY, UPDATE_POLICY } from 'graphql/SecurityPolicy';
import useRequest from 'hooks/useRequest';

export default function PolicyEditorDialog({
  policy,
  rules,
  handleClose,
  disabled = false,
  handleSuccess,
  ...other
}): JSX.Element {
  const [editedPolicy, setPolicy] = useState({});

  useEffect(() => {
    const benchmarks = Object.keys(rules).reduce(
      (obj, benchmark): void => ({
        ...obj,
        [benchmark]: Object.entries(rules[benchmark]).reduce(
          (ruleObj, [ruleId, rule]): void => ({
            ...ruleObj,
            [rule.rule_id]: !(policy?.policy?.[benchmark]?.exclusions ?? []).includes(ruleId),
          }),
          {},
        ),
      }),
      {},
    );

    const name = policy?.name ?? '';

    setPolicy({
      name,
      benchmarks,
      default: Boolean(policy.default),
    });
  }, [policy, rules]);

  const [createPolicy] = useMutation(CREATE_POLICY, {
    update(cache, { data }) {
      const { listPolicies } = cache.readQuery({ query: LIST_POLICIES });

      cache.writeQuery({
        query: LIST_POLICIES,
        data: { listPolicies: listPolicies.concat([data.createPolicy]) },
      });
    },
    context: { clientName: 'findings' },
  });
  const [updatePolicy] = useMutation(UPDATE_POLICY, {
    context: { clientName: 'findings' },
  });

  const [requestStatus, requestStarted, requestSucceeded, requestFailed] = useRequest();
  const handleSave = async (): void => {
    const updatedPolicy = {
      name: editedPolicy.name,
      provider: policy.provider,
      id: policy?.id ?? uuidv4(),
      scope: policy?.scope ?? [],
      policy: expandPolicy(editedPolicy?.benchmarks ?? {}),
    };

    requestStarted();
    try {
      if (policy.id) {
        await updatePolicy({ variables: { policy: updatedPolicy } });
      } else {
        await createPolicy({ variables: { policy: updatedPolicy } });
      }

      requestSucceeded();
      handleSuccess();
    } catch (e) {
      requestFailed(
        e?.networkError?.result?.message ||
          e?.message ||
          `Failed to ${policy.id ? 'update' : 'create'} policy`,
      );
    }
  };

  const isNew = !policy?.id;

  return (
    <FormDialog
      title={disabled ? 'View Policy' : isNew ? 'Add Policy' : 'Edit Policy'}
      handleConfirm={disabled ? null : handleSave}
      status={requestStatus}
      requestLabel={isNew ? 'COMPLIANCE_CREATE_POLICY' : 'COMPLIANCE_PATCH_POLICY'}
      valid={Boolean(editedPolicy.name)}
      submitLabel={isNew ? 'Add' : 'Save Changes'}
      closeLabel={disabled ? 'Close' : 'Cancel'}
      handleClose={handleClose}
      {...other}
    >
      <PolicyEditor
        policy={editedPolicy}
        rules={rules}
        handlePolicyUpdate={setPolicy}
        disabled={disabled}
      />
    </FormDialog>
  );
}

PolicyEditorDialog.propTypes = {
  policy: PropTypes.object,
};

PolicyEditorDialog.defaultProps = {
  policy: {},
};
