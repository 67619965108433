import React, { useEffect, useState } from 'react';

import { Tooltip, IconButton, Badge } from '@cuda-networks/bds-core';
import { makeStyles } from '@material-ui/core/styles';
import AnnouncementOutlinedIcon from '@material-ui/icons/AnnouncementOutlined';

const useStyles = makeStyles(
  {
    button: {
      margin: '8px',
      '&:hover': {
        backgroundColor: 'rgba(158,158,158,0.2)',
      },
    },
  },
  {
    name: 'NoticeableWidget',
  },
);

export default function NoticeableWidget(): JSX.Element {
  const classes = useStyles();

  const [count, setCount] = useState<number>(0);
  useEffect(() => {
    const widgetId = 'DZtMCV9J5Yj5CXmQLYg8';

    async function setup() {
      await window.noticeable.destroy('widget', widgetId);

      window.noticeable.on('widget:publication:unread_count:changed', widgetId, e => {
        setCount(e?.detail?.value || 0);
      });
      window.noticeable.render('widget', widgetId);
    }

    setup();
  }, []);

  return (
    <>
      <Tooltip title="Announcements" placement="left">
        <IconButton color="inherit" aria-label="Announcements" className={classes.button}>
          <Badge badgeContent={count} color="error" max={999}>
            <AnnouncementOutlinedIcon />
          </Badge>
        </IconButton>
      </Tooltip>
    </>
  );
}
