import React from 'react';

import StatusMessage from 'components/common/StatusMessage';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    this.setState({
      hasError: true,
    });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <StatusMessage status="error" message="Something went wrong." removable={false} />;
    }

    return this.props.children;
  }
}
