import React from 'react';

import { Typography } from '@cuda-networks/bds-core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  {
    wrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '8px 15px',
    },
    title: {
      color: '#5F5F5F',
      marginRight: 15,
    },
  },
  {
    name: 'PageTitle',
  },
);

interface PageTitleProps {
  title: string;
  children?: JSX.Element;
}

export default function PageTitle(props: PageTitleProps): JSX.Element {
  const { title, children } = props;
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <div className={classes.title}>
        <Typography variant="h1">{title}</Typography>
      </div>
      {children}
    </div>
  );
}
