import React from 'react';

import { CircularProgress, colors } from '@cuda-networks/bds-core';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';

import { OnboardingStatus } from 'types';

interface StatusIconProps {
  status: OnboardingStatus;
}

export default function StatusIcon(props: StatusIconProps): JSX.Element {
  const { status } = props;

  return (
    <>
      {status === 'connecting' && (
        <CircularProgress size={20} style={{ marginLeft: 10 }} variant="indeterminate" />
      )}
      {status === 'succeeded' && (
        <CheckIcon style={{ height: 20, width: 20, marginLeft: 10, color: colors.statusSuccess }} />
      )}
      {status === 'failed' && (
        <ClearIcon
          style={{ height: 20, width: 20, marginLeft: 10, color: colors.statusCritical }}
        />
      )}
    </>
  );
}
