// @ts-nocheck
import React, { useEffect, useState, useRef } from 'react';

import { useQuery, useLazyQuery } from '@apollo/client';
import { CircularProgress, Grid } from '@cuda-networks/bds-core';
import { PieChart, Pie, Legend, Cell, Tooltip } from 'recharts';

import StatusMessage from 'components/common/StatusMessage';
import { controlFromGeneratorId, nameFromId } from 'components/SecurityFindings/lib';
import { LIST_CONNECTIONS } from 'graphql/Accounts';
import { DETAILED_REPORT_FINDINGS } from 'graphql/SecurityFindings';
import { ListConnectionsData } from 'types';
import pdf from 'utils/pdf';
import rules from 'utils/rules.json';

const generateSeriesData = (results): { name: string; color: string; y: number } => [
  {
    name: 'High',
    color: '#d9534f',
    y: results.high,
  },
  {
    name: 'Medium',
    color: '#f0ad4e',
    y: results.medium,
  },
  {
    name: 'Low',
    color: '#DDDDDD',
    y: results.low,
  },
  {
    name: 'Passed',
    color: '#5cb85c',
    y: results.passed,
  },
];

export default function DetailedScanReport({ scanInfo, requestStatus }): JSX.Element {
  const [reportStatus, reportStarted, reportSucceeded, reportFailed] = requestStatus;

  const { data: listConnectionsData } = useQuery<ListConnectionsData>(LIST_CONNECTIONS, {
    context: { clientName: 'accounts' },
  });
  const { cloudId, scanType, policy } = scanInfo;

  const [data, setData] = useState([]);

  const [standard, provider, version] = scanType.split(':');
  const controlRules = useRef(rules?.[standard]?.[provider]?.[version] || {});
  const exclusions = useRef(policy?.[standard]?.[provider]?.[version]?.exclusions || []);

  const [getScan] = useLazyQuery(DETAILED_REPORT_FINDINGS, {
    fetchPolicy: 'no-cache',
    context: { clientName: 'findings' },
    notifyOnNetworkStatusChange: true,
    variables: {
      cursor: { limit: 100, offset: 0 },
      filter: {
        where: [
          {
            name: 'cloudId',
            value: [cloudId],
          },
          {
            name: 'generatorId',
            value: Object.values(controlRules.current).map(r => r.rule_id),
          },
        ],
      },
    },
    onCompleted: scanData => {
      const newData = data.concat(scanData.findings);
      setData(newData);
      if (newData.length >= scanData?.count) {
        reportSucceeded();
      } else {
        getScan({
          variables: {
            cursor: { limit: 100, offset: newData.length },
            // filter: filterClause,
          },
        });
      }
    },
    onError: e => {
      reportFailed(e?.networkError?.result?.message || e?.message || 'Report generation failed.');
    },
  });

  useEffect(() => {
    reportStarted();
    setData([]);

    getScan();
  }, [getScan, reportStarted, scanInfo]);

  const [scores, setScores] = useState([]);
  const [counts, setCounts] = useState([]);
  const [results, setResults] = useState(null);
  const [dataSet, setDataSet] = useState(null);

  useEffect(() => {
    if (reportStatus.fetching || !data.length) {
      return;
    }

    const exclusionMap = exclusions.current.reduce(
      (obj, id) => ({
        ...obj,
        [`${standard}:${provider}:${version}:${id}`]: true,
      }),
      {},
    );

    const combinedFindings = data.reduce((obj, f) => {
      if (exclusionMap?.[f?.generatorId]) {
        obj[f?.generatorId] = {
          description: controlRules.current?.[f.compliance.control].title,
          failReason: 'Skipped due to policy',
          offenders: {},
          index: controlRules.current?.[f.compliance.control].index,
          result: 'SKIPPED',
          ruleId: controlFromGeneratorId(f.generatorId, ['control', 'ruleId']),
          generatorId: f.generatorId,
          skipped: true,
        };
      }

      const { resource } = f;
      const label = resource?.displayName || resource?.name || nameFromId(resource?.id, provider);

      const severity = f.severity.label.toLowerCase();
      const result = obj[f.generatorId]
        ? obj[f.generatorId]
        : {
            description: controlRules.current?.[f.compliance.control].title,
            failReason: '',
            offenders: {},
            index: controlRules.current?.[f.compliance.control].index,
            result: f.compliance.status === 'PASSED' ? 'passed' : severity,
            severity,
            ruleId: controlFromGeneratorId(f.generatorId, ['control', 'ruleId']),
            generatorId: f.generatorId,
          };

      if (f.compliance.status !== 'PASSED') {
        result.offenders = {
          ...result.offenders,
          [resource?.region]: [...(result?.offenders?.[resource?.region] ?? []), label],
        };
      }
      if (result.result === 'passed') {
        result.result = f.compliance.status === 'PASSED' ? 'passed' : severity;
        result.severity = severity;
      }

      obj[f.generatorId] = result;
      return obj;
    }, {});

    setDataSet(Object.values(combinedFindings));
  }, [data, reportStatus.fetching, provider, standard, version]);

  useEffect(() => {
    if (reportStatus.fetching || !dataSet) {
      return;
    }

    const failed = dataSet
      .filter(f => f.result !== 'passed' && !f.skipped)
      .sort((a, b) => a.index - b.index);
    const passed = dataSet
      .filter(f => f.result === 'passed' && !f.skipped)
      .sort((a, b) => a.index - b.index);
    const skipped = dataSet.filter(f => f.skipped).sort((a, b) => a.index - b.index);

    setScores([
      {
        key: 'Passed',
        count: passed.length,
      },
      {
        key: 'Failed',
        count: failed.length,
      },
      ...(standard === 'cis'
        ? [
            {
              key: 'Score',
              count: passed.reduce((count, result) => {
                const [, , , ruleId] = result.generatorId.split(':');
                if (controlRules.current?.[ruleId]?.scored) {
                  return count + 1;
                }
                return count;
              }, 0),
            },
          ]
        : []),
    ]);

    const results = dataSet.reduce(
      (obj, r) => ({
        ...obj,
        [r.result]: (obj?.[r.result] ?? 0) + 1,
      }),
      {
        high: 0,
        medium: 0,
        low: 0,
        passed: 0,
      },
    );

    setCounts(generateSeriesData(results));
    setResults(failed.concat(passed.concat(skipped)));
  }, [dataSet, reportStatus.fetching, standard]);

  useEffect(() => {
    if (!results) {
      return;
    }

    const matchedCloud = (listConnectionsData?.listConnections?.connections || []).find(
      c => c.cloudId === cloudId,
    );

    pdf.generateDetails(
      {
        account: matchedCloud?.name || cloudId,
        time: Date.now(),
        cloudId,
        provider,
        scanType: standard,
        exclusions: exclusions.current,
      },
      results,
      'complianceGraph',
    );
  }, [cloudId, listConnectionsData, provider, results, standard]);

  return (
    <Grid container spacing={3} style={{ padding: '15px' }}>
      <Grid item xs={12}>
        {reportStatus.fetching ? (
          <CircularProgress />
        ) : (
          <>
            {reportStatus.error && (
              <StatusMessage status="error" message={reportStatus.error} removable />
            )}
            {reportStatus.succeeded && !data.length && (
              <StatusMessage status="info" message="No data available for this report." removable />
            )}
            <div style={{ width: '350px', height: '350px', display: 'none' }} id="complianceGraph">
              {counts.length ? (
                <PieChart width={350} height={350}>
                  <Pie
                    isAnimationActive={false}
                    data={counts}
                    dataKey="y"
                    nameKey="name"
                    cx="40%"
                    cy="40%"
                    innerRadius={70}
                    outerRadius={90}
                  >
                    {counts.map(entry => (
                      <Cell key={`cell-${entry.name}`} fill={entry.color} />
                    ))}
                  </Pie>
                  <Legend verticalAlign="top" align="right" layout="vertical" />
                  <Tooltip />
                </PieChart>
              ) : (
                'No data in this time range'
              )}
            </div>
            <div style={{ width: '210px', height: '52px', display: 'none' }} id="complianceScores">
              {scores.map(score => (
                <div
                  key={score.key}
                  style={{
                    display: 'inline-block',
                    width: '64px',
                    border: '1px solid #DDDDDD',
                    borderRadius: '1px',
                    padding: '5px 10px',
                    textAlign: 'center',
                    margin: '3px',
                  }}
                >
                  <div
                    style={{
                      fontSize: '16px',
                      lineHeight: '18px',
                      marginBottom: '4px',
                      fontWeight: 'bold',
                    }}
                  >
                    {score.count}
                  </div>
                  <div
                    style={{
                      fontSize: '10px',
                      lineHeight: '12px',
                      fontWeight: 'bold',
                      color: '#7F7F7F',
                    }}
                  >
                    {score.key.toUpperCase()}
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </Grid>
    </Grid>
  );
}
