import { useState } from 'react';

import { RequestStatus } from 'types';

interface UseDialogProps {
  open?: boolean;
}
export default function useDialog(
  props?: UseDialogProps,
): [boolean, () => void, () => void, RequestStatus, (status: RequestStatus) => void] {
  const { open = false } = props || {};
  const [dialogOpen, setDialogOpen] = useState(open);
  const [dialogStatus, setDialogStatus] = useState<RequestStatus>({
    fetching: false,
    succeeded: false,
  });

  const openDialog = (): void => setDialogOpen(true);
  const closeDialog = (): void => {
    setDialogOpen(false);
    setDialogStatus({
      fetching: false,
      succeeded: false,
    });
  };

  return [dialogOpen, openDialog, closeDialog, dialogStatus, setDialogStatus];
}
