import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { FindingLabel } from 'components/SecurityFindings';
import { controlFromGeneratorId } from 'components/SecurityFindings/lib';

const useStyles = makeStyles(
  {
    rule: {
      marginRight: '4px',
      color: '#000000',
    },
  },
  {
    name: 'ComplianceFromId',
  },
);

interface ComplianceFromFindingProps {
  finding: {
    generatorId?: string;
    source?: string;
  };
  noStandard?: boolean;
  noRuleId?: boolean;
  noVersion?: boolean;
  chipped?: boolean;
  fullName?: boolean;
  noSource?: boolean;
}

export default function ComplianceFromFinding(props: ComplianceFromFindingProps): JSX.Element {
  const { finding, noStandard, noRuleId, noVersion, chipped, fullName, noSource } = props;
  const { generatorId = '', source = '' } = finding;
  const classes = useStyles();

  const controlFormat = [
    ...(fullName ? ['fullName'] : []),
    ...(noStandard ? [] : ['standard']),
    ...(noVersion ? [] : ['version']),
    ...(noRuleId ? [] : ['ruleId']),
  ];

  let value;
  if (['Azure Security Center', 'AWS Security Hub'].includes(source)) {
    value = noSource ? '' : source;
  } else {
    value = controlFromGeneratorId(generatorId, controlFormat);
  }

  return chipped ? (
    <FindingLabel className={classes.rule} severity="LOW" value={value} noUpperCase />
  ) : (
    <span>{value}</span>
  );
}
