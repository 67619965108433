import React from 'react';

import { IconButton, DialogTitle } from '@cuda-networks/bds-core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';

const useStyles = makeStyles(
  theme => ({
    title: {
      backgroundColor: theme.palette.primary.main,
      color: '#FFFFFF',
      padding: '8px 12px',
      '& > h2': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        color: '#FFFFFF',
      },
      '& button': {
        color: '#FFFFFF',
      },
    },
  }),
  {
    name: 'StyledDialogTitle',
  },
);

export default function StyledDialogTitle({ children, handleClose }): JSX.Element {
  const classes = useStyles();

  return (
    <DialogTitle className={classes.title}>
      {children}
      {handleClose && (
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      )}
    </DialogTitle>
  );
}

StyledDialogTitle.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  handleClose: PropTypes.func,
};

StyledDialogTitle.defaultProps = {
  handleClose: null,
};
