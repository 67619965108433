import React from 'react';

import { Button } from '@cuda-networks/bds-core';

import useAuth from 'hooks/useAuth';

export default function LoginForm(): React.ReactElement {
  const { signIn, status } = useAuth();

  return (
    <Button
      size="medium"
      type="submit"
      color="primary"
      variant="contained"
      fullWidth
      disabled={status === 'loading'}
      data-test-id="signin-submit"
      onClick={(): void => {
        signIn();
      }}
    >
      Sign In
    </Button>
  );
}
