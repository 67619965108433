import React from 'react';

import { Link } from '@cuda-networks/bds-core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { typeToIcon, nameFromResource } from 'components/SecurityFindings/lib';
import { CloudType, Resource } from 'types';

const useStyles = makeStyles(
  {
    wrapper: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      wordBreak: 'break-word',
      '& > img': {
        height: '24px',
        flex: '0 0 24px',
        marginRight: '5px',
      },
      '& > $noLabel': {
        height: '24px',
        flex: '0 0 24px',
        marginRight: '5px',
      },
      '& > svg': {
        color: '#ffffff',
        marginRight: '5px',
      },
    },
    noLabel: {},
    link: {
      textAlign: 'left',
    },
  },
  {
    name: 'ResourceDisplay',
  },
);

interface ResourceDisplayProps {
  provider: CloudType;
  resource: Resource;
  className?: string;
  onClick?: (event: unknown) => void;
  noLeftPadding?: boolean;
}

export default function ResourceDisplay(props: ResourceDisplayProps): React.ReactElement {
  const { provider, resource, className, onClick, noLeftPadding = false } = props;
  const classes = useStyles();

  let icon = typeToIcon?.[provider]?.[resource?.type];
  if (typeof icon === 'object' && icon !== null) {
    icon = resource?.kind ? icon[resource.kind] : icon.default;
  }

  const label = nameFromResource(resource, provider);

  return (
    <div className={clsx(classes.wrapper, className)}>
      {icon ? (
        <img src={`/images/icons/${provider}/${icon}`} alt={resource?.type} />
      ) : (
        <div className={clsx({ [classes.noLabel]: !noLeftPadding })} />
      )}

      {onClick ? (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <Link
          component="button"
          variant="body2"
          onClick={onClick}
          className={classes.link}
          data-test-id={`resource-link-${resource.id}`}
        >
          {label}
        </Link>
      ) : (
        label
      )}
    </div>
  );
}
