import React, { useState, useEffect, useCallback } from 'react';

import { TextField, TextFieldProps } from '@cuda-networks/bds-core';

const debounce = require('lodash.debounce');

export interface DebouncedTextFieldProps extends Omit<TextFieldProps, 'onChange' | 'defaultValue'> {
  onChange(controls: string[] | null): void;
  defaultValue?: string;
  period?: number;
  leading?: boolean;
}

export default function DebouncedTextField(props: DebouncedTextFieldProps): JSX.Element {
  const {
    onChange,
    defaultValue = '',
    period = 1000,
    leading = false,
    InputLabelProps = {},
    ...other
  } = props;
  const [pendingValue, setPendingValue] = useState(defaultValue);

  useEffect(() => {
    setPendingValue(defaultValue);
  }, [defaultValue]);

  const debouncedUpdate = useCallback(
    debounce(onChange, period, {
      leading,
    }),
    [onChange],
  );

  useEffect(() => {
    debouncedUpdate(pendingValue);
    debouncedUpdate.flush();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedUpdate]);

  return (
    <TextField
      {...other}
      InputLabelProps={{
        shrink: true,
        ...InputLabelProps,
      }}
      value={pendingValue}
      onChange={(e): void => {
        setPendingValue(e.target.value);
        debouncedUpdate(e.target.value);
      }}
    />
  );
}
