import React, { useEffect, useState } from 'react';

import { useMutation, useApolloClient } from '@apollo/client';
import { Button, Grid, CircularProgress } from '@cuda-networks/bds-core';
import { CloudSecurityGuardian } from '@cuda-networks/bds-core/dist/Logos/Products';
import { makeStyles } from '@material-ui/core/styles';
import { DateTime } from 'luxon';
import { useHistory, Switch, Route, Redirect } from 'react-router-dom';

import SeparatePage from 'components/common/SeparatePage';
import StatusMessage from 'components/common/StatusMessage';
import { LIST_ACCOUNTS, SUBMIT_MARKETO, ADD_SERIAL, ADD_PARTNER } from 'graphql/Accounts';
import { CREATE_USER } from 'graphql/Users';
import useAccountSetup from 'hooks/useAccountSetup';
import useAccountSwitch from 'hooks/useAccountSwitch';
import useAuth from 'hooks/useAuth';
import useMixpanel from 'hooks/useMixpanel';
import useRequest from 'hooks/useRequest';
import {
  AccountRegistrationForm,
  MarketoInput,
  PartnerInfo,
  ListAccountsData,
  Account,
  AddSerialData,
  AddSerialInput,
  SubmitMarketoInput,
  AddPartnerInput,
} from 'types';
import { getPartnerInfo, removePartnerInfo, removeSerialInfo, getSerialInfo } from 'utils/params';

import AccountsList from './AccountsList';
import CompleteRegistration from './CompleteRegistration';

const useStyles = makeStyles(
  theme => ({
    wrapper: {
      // maxWidth: '540px',
    },
    page: {
      overflow: 'auto',
      height: '100%',
      backgroundSize: 'cover',
      backgroundImage: 'url(/images/background.jpg)',
      [theme.breakpoints.up('md')]: {
        padding: '48px 128px',
      },
      [theme.breakpoints.down('sm')]: {
        padding: '48px',
      },
      [theme.breakpoints.down('xs')]: {
        padding: '12px',
      },
      display: 'flex',
      justifyContent: 'center',
    },
    card: {
      lineHeight: '24px',
      fontSize: '14px',
      color: '#000000',
    },
    soloCard: {
      // width: '100%',
      height: 'max-content',
    },

    loading: {
      flexDirection: 'column',
      alignItems: 'center',
    },
    loadingCard: {
      backgroundColor: '#FFFFFF',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '20px',
    },
  }),
  {
    name: 'Register',
  },
);

type RegisterSteps =
  | 'marketo'
  | 'setupAccount'
  | 'switchAccount'
  | 'partnerUser'
  | 'partner'
  | 'serial';

export default function Register(): JSX.Element {
  const classes = useStyles();
  const client = useApolloClient();
  const history = useHistory();
  const mixpanel = useMixpanel();
  const { account, signOut, status: authStatus } = useAuth();
  const [switchAccount, switchAccountStatus] = useAccountSwitch();

  const { serial, linkingCode } = getSerialInfo();

  const [partnerInfo] = useState<PartnerInfo>(getPartnerInfo());
  const [accounts, setAccounts] = useState<Account[]>();
  const [selectedAccount, setSelectedAccount] = useState<Account>();

  const [setupAccount] = useAccountSetup();
  const [submitMarketo] = useMutation<unknown, SubmitMarketoInput>(SUBMIT_MARKETO, {
    context: { clientName: 'accounts' },
  });
  const [createUser] = useMutation(CREATE_USER, {
    context: { clientName: 'accounts' },
  });
  const [addSerial] = useMutation<AddSerialData, AddSerialInput>(ADD_SERIAL, {
    context: { clientName: 'accounts' },
  });
  const [addPartner] = useMutation<unknown, AddPartnerInput>(ADD_PARTNER, {
    context: { clientName: 'findings' },
  });

  // load accounts
  const [loadStatus, loadStarted, loadSucceeded, loadFailed] = useRequest({
    fetching: true,
  });
  useEffect((): void => {
    loadStarted();
    async function fetchData(): Promise<void> {
      try {
        const accountsData = await client.query<ListAccountsData>({
          query: LIST_ACCOUNTS,
          context: { clientName: 'accounts' },
        });

        setAccounts(accountsData.data.accounts);

        loadSucceeded();
      } catch (e) {
        loadFailed(e?.networkError?.result?.message || e?.message || 'Unknown error');
      }
    }

    if (authStatus === 'authenticated') {
      fetchData();
    } else {
      loadSucceeded();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sendMarketo = async (payload: AccountRegistrationForm, user: string): Promise<void> => {
    const { partnerId, partnerEmail, campaignId, pid, vid } = partnerInfo;

    const input: MarketoInput = {
      email: user,
      firstName: payload.firstName,
      lastName: payload.lastName,
      phone: payload.phoneNumber,
      postalCode: payload.postalCode,
      company: payload.company,
      country: payload.countryCode,
      state: payload.state,
      partnerId,
      partnerEmail,
      campaignId,
      pid,
      vid,
    };

    try {
      await submitMarketo({
        variables: {
          input,
        },
      });
    } catch (e) {
      console.log('Marketo failed');
    }
  };

  const [registerStatus, setRegisterStatus] = useState<Partial<Record<RegisterSteps, boolean>>>();
  const [completeStatus, completeStarted, completeSucceeded, completeFailed] = useRequest();
  const handleCompleteRegistration = async (payload: AccountRegistrationForm): Promise<void> => {
    completeStarted();

    const updatedStatus = { ...registerStatus };

    if (!account || !selectedAccount) {
      completeFailed('Account setup failed');
      return;
    }

    try {
      // setup account
      if (!registerStatus?.setupAccount) {
        await setupAccount({
          id: selectedAccount.id,
        });
        updatedStatus.setupAccount = true;
      }

      // switch account
      await switchAccount(selectedAccount.id);

      // marketo
      if (!registerStatus?.marketo) {
        await sendMarketo(payload, account?.email);
        mixpanel.track('Submitted Marketo', payload);
        updatedStatus.marketo = true;
      }

      // add partner user if checked
      const { partnerId, partnerEmail, campaignId, pid, vid } = partnerInfo;
      if (partnerEmail && payload.shareWithPartner) {
        await createUser({
          variables: {
            input: {
              email: partnerEmail,
              role: 'ReadOnly',
              expiration: DateTime.utc().plus({ days: 14 }).toFormat('yyyy-LL-dd'),
            },
          },
        }).catch(() => {
          mixpanel.track('Failed to add partner user', {
            accountId: selectedAccount.id,
            partnerEmail,
            expiration: DateTime.utc().plus({ days: 14 }).toFormat('yyyy-LL-dd'),
          });
        });
      }

      // add partner
      if (partnerId || partnerEmail || campaignId || pid || vid) {
        await addPartner({
          variables: {
            input: JSON.stringify({
              email: account?.email,
              company: payload.company,
              firstName: payload.firstName,
              lastName: payload.lastName,
              partnerId,
              partnerEmail,
              campaignId,
              pid,
              vid,
            }),
          },
        })
          .then(() => {
            removePartnerInfo();
          })
          .catch(() => {
            mixpanel.track('Failed to add partner information', {
              email: account?.email,
              company: payload.company,
              firstName: payload.firstName,
              lastName: payload.lastName,
              partnerId,
              partnerEmail,
              campaignId,
              pid,
              vid,
            });
          });
      }

      // add serial if exists
      const { serial, linkingCode } = getSerialInfo();

      if (serial && linkingCode) {
        await addSerial({
          variables: {
            input: {
              serial,
              linkingCode,
            },
          },
        })
          .then(() => {
            removeSerialInfo();
          })
          .catch(() => {
            mixpanel.track('Failed to activate serial', {
              accountId: selectedAccount?.id,
              serial,
              linkingCode,
            });
          });
      }

      completeSucceeded();
    } catch (e) {
      completeFailed(e?.networkError?.result?.message || e?.message || 'Account setup failed');
      setRegisterStatus(updatedStatus);
    }
  };
  const handleAccountTrial = (acc: Account): void => {
    setSelectedAccount(acc);
    history.push('/accounts/complete');
  };
  const handleAccountSetup = async (acc: Account): Promise<void> => {
    completeStarted();
    const { serial, linkingCode } = getSerialInfo();

    if (!acc.setup) {
      handleAccountTrial(acc);
      completeSucceeded();
      return;
    }

    if (!serial || !linkingCode) {
      await switchAccount(acc.id);
      completeSucceeded();
      return;
    }

    await switchAccount(acc.id);

    try {
      await addSerial({
        variables: {
          input: {
            serial,
            linkingCode,
          },
        },
      });
    } catch (e) {
      mixpanel.track('Failed to activate serial', {
        accountId: selectedAccount?.id,
        serial,
        linkingCode,
      });
      completeFailed('Failed to activate serial');
      return;
    }
    removeSerialInfo();

    completeSucceeded();
  };

  return (
    <SeparatePage loadStatus={loadStatus}>
      <>
        {loadStatus.succeeded ? (
          <Grid container spacing={1} className={classes.wrapper}>
            <Grid item xs={12}>
              <Switch>
                <Route
                  exact
                  path="/accounts/complete"
                  render={(): React.ReactNode => {
                    return selectedAccount ? (
                      <CompleteRegistration
                        onSubmit={handleCompleteRegistration}
                        status={completeStatus}
                        userInfo={{
                          email: account?.email || '',
                          firstName: account?.name?.split(' ')?.[0] || '',
                          lastName: account?.name?.split(' ')?.[1] || '',
                          company: selectedAccount?.name || '',
                        }}
                        partnerEmail={partnerInfo?.partnerEmail}
                        serial={Boolean(serial) && Boolean(linkingCode)}
                        onLogout={signOut}
                      />
                    ) : (
                      <Redirect
                        to={{
                          pathname: '/accounts',
                          search: window.location.search,
                        }}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/accounts"
                  render={(): React.ReactNode => (
                    <AccountsList
                      accounts={accounts ?? []}
                      onSelect={switchAccount}
                      status={switchAccountStatus}
                      onAccountSetup={handleAccountTrial}
                      onLogout={signOut}
                      action="manage"
                      currentAccount={account}
                    />
                  )}
                />
                <Route
                  exact
                  path="/accounts/trial"
                  render={(): React.ReactNode => (
                    <AccountsList
                      accounts={accounts ?? []}
                      onSelect={switchAccount}
                      status={completeStatus}
                      onAccountSetup={handleAccountSetup}
                      onLogout={signOut}
                      action="trial"
                    />
                  )}
                />
                <Route
                  exact
                  path="/accounts/setup"
                  render={(): React.ReactNode => (
                    <AccountsList
                      accounts={accounts ?? []}
                      onSelect={switchAccount}
                      status={completeStatus}
                      onAccountSetup={handleAccountSetup}
                      onLogout={signOut}
                      action="setup"
                    />
                  )}
                />
                <Redirect to="/accounts" />
              </Switch>
            </Grid>
          </Grid>
        ) : (
          <div className={classes.loadingCard}>
            <CloudSecurityGuardian style={{ height: 56 }} />
            {loadStatus.fetching && <CircularProgress />}
            {loadStatus.error && (
              <>
                <StatusMessage status="error" message={loadStatus.error} removable={false} />
                <Button
                  variant="text"
                  onClick={(): void => {
                    signOut();
                  }}
                >
                  Log Out
                </Button>
              </>
            )}
          </div>
        )}
      </>
    </SeparatePage>
  );
}
