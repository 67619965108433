import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { DeployAzure } from 'components/CloudConnections/OnboardWizard';
import SeparatePage from 'components/common/SeparatePage';

const useStyles = makeStyles(
  () => ({
    connector: {
      width: 748,
    },
  }),
  {
    name: 'ChooseProvider',
  },
);

export default function AzureConnector(): JSX.Element {
  const classes = useStyles();

  return (
    <SeparatePage noPadding>
      <div className={classes.connector}>
        <DeployAzure standalone />
      </div>
    </SeparatePage>
  );
}
