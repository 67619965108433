import React, { useMemo } from 'react';

import { useQuery } from '@apollo/client';
import { Skeleton } from '@material-ui/lab';

import StatusMessage from 'components/common/StatusMessage';
import { LicenseInfo } from 'components/License';
import { LIST_SERIALS } from 'graphql/Accounts';
import useQueryRequest from 'hooks/useQueryRequest';
import { ListSerialsData, Serial } from 'types';

export default function License(): JSX.Element {
  const serialsQuery = useQuery<ListSerialsData>(LIST_SERIALS, {
    context: { clientName: 'accounts' },
    fetchPolicy: 'network-only',
  });
  const [status] = useQueryRequest(serialsQuery);

  const activeSerial = useMemo(
    () =>
      (serialsQuery?.data?.serials || []).reduce<Partial<Serial>>((responseSerial, serial) => {
        const currentStatus = serial.status;

        if (
          currentStatus === 'TRIAL' ||
          (currentStatus === 'ACTIVE' && responseSerial.status !== 'TRIAL') ||
          (responseSerial.status !== 'ACTIVE' && responseSerial.status !== 'TRIAL')
        ) {
          return {
            ...serial,
          };
        }
        return responseSerial;
      }, {}),
    [serialsQuery],
  );

  return (
    <>
      {status.fetching && (
        <>
          <Skeleton variant="text" width="100%" height={40} />
          <Skeleton variant="text" width="100%" height={90} />
          <Skeleton variant="text" width={250} height={16} />
          <Skeleton variant="text" width={250} height={48} />
          <Skeleton variant="text" width={250} height={16} />
          <Skeleton variant="text" width={250} height={16} />
        </>
      )}
      {status.error && <StatusMessage status="error" message="Error loading license data" />}
      {status.succeeded && <LicenseInfo license={activeSerial} />}
    </>
  );
}
