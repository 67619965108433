import React, { useEffect, useState } from 'react';

import { Popover, TextField, IconButton } from '@cuda-networks/bds-core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

import StandardTree from 'components/SecurityFindings/StandardTree';
import tree from 'utils/rulesTree.json';

const getParentIds = (arr, node) => {
  if (Array.isArray(node.children)) {
    return [...arr, ...node.children.reduce(getParentIds, []), node.id];
  }

  return arr;
};

const treeIds: string[] = getParentIds([], tree);

export interface ControlTreeProps {
  selectedControls?: string[];
  updateControls: (controls: string[]) => void;
  controlsList: string[];
  processing: boolean;
  'data-test-id': string;
}

const generateLabel = (selectedCount: number, controlCount: number): string =>
  selectedCount === controlCount ? 'All' : `${selectedCount} of ${controlCount}`;

export default function ControlTree(props: ControlTreeProps): JSX.Element {
  const {
    selectedControls = [],
    updateControls,
    controlsList,
    processing,
    'data-test-id': dataTestId,
  } = props;
  const [selectedMap, setSelectedMap] = useState(null);

  const [label, setLabel] = useState(
    generateLabel(
      selectedControls?.length ? selectedControls.length : controlsList.length,
      controlsList.length,
    ),
  );

  useEffect(() => {
    const controls = (selectedControls.length ? selectedControls : controlsList).reduce(
      (obj, id) => ({
        ...obj,
        [id]: 'checked',
      }),
      {},
    );

    const checkParentIds = (obj, node) => {
      if (Array.isArray(node.children)) {
        let status: string | undefined;

        node.children.forEach(child => {
          const nodeResp = checkParentIds(obj, child);

          if (!status) {
            status = nodeResp;
          } else if (status !== nodeResp) {
            status = 'indeterminate';
          }
        });

        obj[node.id] = status;
        return status;
      }

      return controls?.[node.id] ? 'checked' : 'unchecked';
    };
    checkParentIds(controls, tree);

    setSelectedMap(controls);
    setLabel(
      generateLabel(
        (selectedControls.length ? selectedControls : controlsList).length,
        controlsList.length,
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedControls]);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = e => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleExiting = (): void => {
    const controls = Object.entries(selectedMap)
      .filter(([id, val]) => val === 'checked' && !treeIds.includes(id))
      .map(([id]) => id);

    setLabel(generateLabel(controls.length, controlsList.length));
    updateControls(controls);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div data-test-id={dataTestId}>
      <TextField
        label="Controls"
        onClick={handleClick}
        fullWidth
        value={label}
        style={{ cursor: 'pointer' }}
        disabled={processing}
        margin="none"
        InputProps={{
          notched: false,
          endAdornment: (
            <IconButton
              size="small"
              style={{
                padding: '2px',
                position: 'absolute',
                right: '0',
                marginRight: '-2px',
              }}
            >
              {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </IconButton>
          ),
        }}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        onExiting={handleExiting}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {selectedMap && (
          <StandardTree
            tree={tree}
            selectedMap={selectedMap}
            setSelectedMap={setSelectedMap}
            handleClose={handleClose}
          />
        )}
      </Popover>
    </div>
  );
}
