import { useMemo } from 'react';

import { useQuery } from '@apollo/client';

import { GRAPH_DATA } from 'graphql/SecurityFindings';
import useCloudNames from 'hooks/useCloudNames';
import useQueryRequest from 'hooks/useQueryRequest';

export default function useDashboardGraph(groupBy, parse, topOrder, filter, topFilter): any {
  const cloudNames = useCloudNames();

  const response = useQuery(GRAPH_DATA, {
    skip: !filter || !topFilter,
    variables: {
      groupBy: [groupBy],
      filter,
      topGroupBy: [groupBy, 'cloudId'],
      topFilter,
    },
    fetchPolicy: 'no-cache',
    context: { clientName: 'findings' },
  });
  const [status] = useQueryRequest(response);
  const { data } = response;

  const parsedData = useMemo(() => {
    if (!data) {
      return {};
    }

    return parse(data?.total || [], data?.top || [], topOrder, cloudNames);
  }, [cloudNames, data, parse, topOrder]);

  return [parsedData, status];
}
