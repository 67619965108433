import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles(
  theme => ({
    root: {
      whiteSpace: 'nowrap',
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        marginRight: '5px',
      },
    },

    capitalize: {
      textTransform: 'capitalize',
    },

    success: {
      '& svg': {
        color: '#5cb85c',
      },
    },
    other: {
      '& svg': {
        color: '#5bc0de',
      },
    },
    warning: {
      '& svg': {
        color: '#f0ad4e',
      },
    },
    failure: {
      '& svg': {
        color: '#d9534f',
      },
    },
    default: {
      '& svg': {
        color: '#DDDDDD',
      },
    },
  }),
  {
    name: 'ResultLabel',
  },
);

export default function ResultLabel({
  children,
  className,
  result,
  padding,
  capitalize,
}): JSX.Element {
  const classes = useStyles();

  return (
    <span
      className={clsx(classes.root, className, {
        [classes[result]]: true,
        [classes.capitalize]: capitalize,
      })}
    >
      {{
        success: <CheckIcon />,
        failure: <ClearIcon />,
        warning: <WarningIcon />,
        other: <InfoIcon />,
      }[result] ?? <InfoIcon />}
      {children}
    </span>
  );
}

ResultLabel.propTypes = {
  result: PropTypes.string,
  padding: PropTypes.bool,
  capitalize: PropTypes.bool,
};

ResultLabel.defaultProps = {
  result: 'info',
  padding: true,
  capitalize: false,
};
