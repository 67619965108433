import React, { useState, useEffect, MouseEvent } from 'react';

import {
  Button,
  AppBar as BDSAppBar,
  Divider,
  Menu,
  MenuItem,
  Tooltip,
  Toolbar,
  Typography,
  IconButton,
  RadioGroup,
  Radio,
  CircularProgress,
} from '@cuda-networks/bds-core';
import { CloudSecurityGuardianRev } from '@cuda-networks/bds-core/dist/Logos/Products';
import { FormControlLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AccountIcon from '@material-ui/icons/AccountCircle';
import GroupIcon from '@material-ui/icons/Group';
import HelpIcon from '@material-ui/icons/HelpOutline';
import LockIcon from '@material-ui/icons/LockOpen';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import SettingsIcon from '@material-ui/icons/Settings';
import clsx from 'clsx';

import StatusMessage from 'components/common/StatusMessage';
import { MenuIcon } from 'icons';
import { Account, BarracudaAccount, RequestStatus } from 'types';

import NoticeableWidget from './NoticeableWidget';

const drawerWidth = 300;
const closedWidth = 72;

const useStyles = makeStyles(
  theme => ({
    menuSettingsIcon: {
      height: '42px',
      width: '42px',
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'rgba(0, 0, 0, 0.54)',
    },
    button: {
      margin: '8px',
      '&:hover': {
        backgroundColor: 'rgba(158,158,158,0.2)',
      },
    },
    toolbarButton: {
      color: '#FFFFFF',
      textTransform: 'none',
      '& svg': {
        marginRight: 5,
      },
    },
    divider: {
      backgroundColor: '#FFFFFF',
      margin: '12px 0',
    },
    userMenu: {
      '& svg': {
        height: '42px',
        width: '42px',
        padding: '9px 9px 9px 0',
      },
    },
    appBar: {
      backgroundColor: '#212121',
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarOpened: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      width: closedWidth,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    hide: {
      display: 'none',
    },
    logo: {
      display: 'flex',
      justifyContent: 'flex-start',
      flex: 1,
    },
    openedLogo: {
      marginLeft: 24,
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
  }),
  {
    name: 'AppBar',
  },
);

type MenuName = 'user' | 'account';
type AnchorEls = Record<MenuName, EventTarget & HTMLElement>;

interface AppBarProps {
  account: BarracudaAccount;
  accounts: Account[];
  showGetStarted: boolean;
  drawerOpen: boolean;
  switchAccountStatus: RequestStatus;

  handleAccountSelect: (acct: Account) => void;
  handleGetStarted: () => void;
  handleDrawerToggle: () => void;
  handleSignOut: () => Promise<void>;
  handleManageAccounts: () => void;
}

export default function AppBar(props: AppBarProps): JSX.Element {
  const {
    account,
    accounts,
    handleAccountSelect,
    handleGetStarted,
    showGetStarted,
    handleDrawerToggle,
    drawerOpen,
    handleSignOut,
    handleManageAccounts,
    switchAccountStatus,
  } = props;
  const classes = useStyles();

  const [selectedAccount, setSelectedAccount] = useState<string>();
  const [anchorEls, setAnchorEls] = useState<AnchorEls | null>();
  const openMenu =
    (menu: MenuName) =>
    (e: MouseEvent<HTMLElement>): void => {
      setAnchorEls({ [menu]: e.currentTarget } as AnchorEls);
    };
  const handleClose = (): void => setAnchorEls(null);

  const [setupAccounts, setSetupAccounts] = useState<Account[]>([]);
  const [setupRequiredAccounts, setSetupRequiredAccounts] = useState<Account[]>([]);

  useEffect(() => {
    setSetupAccounts((accounts ?? []).filter(a => a.setup && !(a.id === account.id)));
    setSetupRequiredAccounts((accounts ?? []).filter(a => !a.setup && !(a.id === account.id)));
  }, [account, accounts]);

  return (
    <BDSAppBar
      position="fixed"
      className={clsx(classes.appBar, { [classes.appBarOpened]: drawerOpen })}
      elevation={0}
    >
      <Toolbar disableGutters style={{ backgroundColor: '#002D3B' }}>
        <div className={clsx(classes.menuButton, { [classes.hide]: drawerOpen })}>
          <IconButton
            className={classes.button}
            color="inherit"
            aria-label="Open drawer"
            onClick={handleDrawerToggle}
          >
            <MenuIcon />
          </IconButton>
        </div>
        <div className={clsx(classes.logo, { [classes.openedLogo]: drawerOpen })}>
          <CloudSecurityGuardianRev style={{ height: 40 }} />
        </div>
        {showGetStarted && (
          <Button onClick={handleGetStarted} color="inherit">
            <NewReleasesIcon style={{ marginRight: '8px' }} />
            Get Started
          </Button>
        )}
        <div id="noticeable-widget">
          <NoticeableWidget />
        </div>
        <Tooltip title="Documentation" placement="left">
          <IconButton
            className={classes.button}
            color="inherit"
            aria-label="Documentation"
            onClick={(): void => {
              window.open('https://campus.barracuda.com/doc/78155894/', '_blank');
            }}
          >
            <HelpIcon />
          </IconButton>
        </Tooltip>
        <Divider orientation="vertical" flexItem className={classes.divider} />
        <Button
          variant="text"
          aria-controls="account-menu"
          aria-haspopup="true"
          onClick={openMenu('account')}
          className={clsx(classes.toolbarButton, classes.button)}
        >
          <GroupIcon />
          {`Account: ${account.accountName}`}
        </Button>
        <Menu
          id="account-menu"
          anchorEl={anchorEls?.account}
          keepMounted
          open={Boolean(anchorEls?.account)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          getContentAnchorEl={null}
          transformOrigin={{
            vertical: -7,
            horizontal: 10,
          }}
          MenuListProps={{
            disablePadding: true,
          }}
        >
          {switchAccountStatus.error && (
            <StatusMessage status="error" message={switchAccountStatus.error} />
          )}

          <RadioGroup
            aria-label="account"
            name="account"
            value={account.id}
            onChange={(event): void => {
              handleClose();
              const acct = accounts.find(a => a.id === event.target.value);

              if (acct) {
                setSelectedAccount(acct.id);
                handleAccountSelect(acct);
              }
            }}
          >
            {account && (
              <MenuItem key={account.id} disabled>
                <FormControlLabel
                  value={account.id}
                  control={<Radio style={{ cursor: 'not-allowed' }} />}
                  style={{ cursor: 'not-allowed' }}
                  disabled
                  label={
                    <>
                      {account.accountName}
                      <Typography variant="caption" component="div">
                        {account.id}
                      </Typography>
                    </>
                  }
                />
              </MenuItem>
            )}
            <Divider />
            {setupAccounts.map(acct => (
              <MenuItem
                key={acct.id}
                disabled={switchAccountStatus.fetching}
                style={{ height: '42px' }}
              >
                {selectedAccount === acct.id && switchAccountStatus.fetching ? (
                  <>
                    <CircularProgress size={20} style={{ marginRight: '11px' }} />
                    <Typography variant="body1" color="inherit">
                      {acct.name}
                    </Typography>
                  </>
                ) : (
                  <FormControlLabel value={acct.id} control={<Radio />} label={acct.name} />
                )}
              </MenuItem>
            ))}
          </RadioGroup>
          <MenuItem
            disabled={switchAccountStatus.fetching}
            onClick={(): void => {
              handleClose();
              handleManageAccounts();
            }}
          >
            <FormControlLabel
              control={
                <span className={classes.menuSettingsIcon}>
                  <SettingsIcon />
                </span>
              }
              label="Manage Accounts"
            />
          </MenuItem>
          {Boolean(setupRequiredAccounts.length) && (
            <div>
              <Divider />
              <MenuItem disabled>
                <Typography variant="body1">Not Set Up</Typography>
              </MenuItem>
              {setupRequiredAccounts.map(acct => (
                <MenuItem
                  key={acct.id}
                  disabled={switchAccountStatus.fetching}
                  onClick={(): void => {
                    handleClose();
                    handleAccountSelect(acct);
                  }}
                >
                  <FormControlLabel
                    control={
                      <span className={classes.menuSettingsIcon}>
                        <SettingsIcon />
                      </span>
                    }
                    label={acct.name}
                  />
                </MenuItem>
              ))}
            </div>
          )}
        </Menu>
        <Divider orientation="vertical" flexItem className={classes.divider} />
        <Button
          variant="text"
          aria-controls="user-menu"
          aria-haspopup="true"
          onClick={openMenu('user')}
          className={clsx(classes.toolbarButton, classes.button)}
        >
          <AccountIcon />
          {account.email}
        </Button>
        <Menu
          id="user-menu"
          anchorEl={anchorEls?.user}
          keepMounted
          open={Boolean(anchorEls?.user)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          getContentAnchorEl={null}
          transformOrigin={{
            vertical: -7,
            horizontal: 10,
          }}
          MenuListProps={{
            disablePadding: true,
          }}
        >
          <MenuItem
            onClick={async (): Promise<void> => {
              await handleSignOut();
              handleClose();
            }}
            className={classes.userMenu}
          >
            <LockIcon />
            <Typography variant="body1">Log Out</Typography>
          </MenuItem>
        </Menu>
      </Toolbar>
    </BDSAppBar>
  );
}
