import React, { useEffect } from 'react';

import { MenuItem, TextField, CircularProgress } from '@cuda-networks/bds-core';
import { ErrorMessage } from '@hookform/error-message';
import { FormHelperText } from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';

import FormDialog from 'components/common/FormDialog';
import FormSelect from 'components/common/FormSelect';
import StatusMessage from 'components/common/StatusMessage';
import { RequestStatus, User } from 'types';

const roles = [
  {
    label: 'Administrator',
    key: 'Administrator',
  },
  {
    label: 'Manager',
    key: 'Manager',
  },
  {
    label: 'Billing',
    key: 'Billing',
  },
  {
    label: 'ReadOnly',
    key: 'ReadOnly',
  },
];

interface UserFormDialogProps {
  user: User;
  status: RequestStatus;
  loadStatus: RequestStatus;
  handleConfirm: (user: User) => void;
  edit?: boolean;
  open: boolean;
  handleClose: () => void;
}

export default function UserFormDialog(props: UserFormDialogProps): JSX.Element {
  const { user, status, loadStatus, handleConfirm, edit = false, open, handleClose } = props;
  const { handleSubmit, control, errors, formState, reset } = useForm();
  const { isDirty } = formState;

  useEffect(() => {
    reset({
      name: user.name,
      email: user.email,
      role: user.role,
    });
  }, [user, reset]);

  const onSubmit = (data): void => {
    handleConfirm({
      ...user,
      ...data,
    });
  };

  return (
    <FormDialog
      title={edit ? 'Edit User' : 'Add User'}
      submitLabel={edit ? 'Save Changes' : 'Add User'}
      handleConfirm={handleSubmit(onSubmit)}
      status={status}
      valid={isDirty}
      maxWidth="sm"
      open={open}
      handleClose={handleClose}
    >
      {loadStatus.fetching && <CircularProgress />}
      {loadStatus.error && (
        <StatusMessage status="error" message={loadStatus.error} removable={false} />
      )}
      {loadStatus.succeeded && (
        <>
          {!edit && (
            <>
              <Controller
                as={
                  <TextField
                    label="Email Address"
                    required
                    disabled={status.fetching}
                    fullWidth
                    name="email"
                    margin="none"
                    error={Boolean(errors?.email)}
                    style={{ marginRight: 0 }}
                  />
                }
                name="email"
                control={control}
                rules={{ required: 'Required' }}
              />
              <ErrorMessage as={<FormHelperText error />} errors={errors} name="email" />
            </>
          )}
          <Controller
            as={
              <FormSelect
                label="Role"
                required
                disabled={status.fetching}
                fullWidth
                name="role"
                error={Boolean(errors?.role)}
              >
                {roles.map(option => (
                  <MenuItem key={option.key} value={option.key}>
                    {option.label}
                  </MenuItem>
                ))}
              </FormSelect>
            }
            name="role"
            control={control}
            rules={{ required: 'Required' }}
          />
          <ErrorMessage as={<FormHelperText error />} errors={errors} name="role" />
        </>
      )}
    </FormDialog>
  );
}
