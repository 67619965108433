export const unsupported = [
  'cis:aws:v1.2.0:1.15',
  'cis:aws:v1.2.0:1.17',
  'cis:aws:v1.2.0:1.18',
  'cis:azure:v1.1.0:3.4',
  'cis:azure:v1.1.0:3.5',
];

export const flattenRules = (
  rulesDef,
  providers = ['aws', 'azure'],
  benchmarks = ['cis', 'nist', 'hipaa', 'pci_dss', 'bsbp'],
): any =>
  Object.entries(rulesDef)
    .filter(([bKey, _bVal]) => benchmarks.includes(bKey))
    .reduce(
      (bObj, [bKey, bVal]) => ({
        ...bObj,
        ...Object.entries(bVal)
          .filter(([pKey, _pVal]) => providers.includes(pKey))
          .reduce(
            (pObj, [pKey, pVal]) => ({
              ...pObj,
              ...Object.entries(pVal).reduce(
                (vObj, [vKey, vVal]) => ({
                  ...vObj,
                  [`${bKey}:${pKey}:${vKey}`]: Object.entries(vVal)
                    .filter(
                      ([rKey, _rVal]) => !unsupported.includes(`${bKey}:${pKey}:${vKey}:${rKey}`),
                    )
                    .reduce(
                      (rObj, [rKey, rVal]) => ({
                        ...rObj,
                        [rKey]: rVal,
                      }),
                      {},
                    ),
                }),
                {},
              ),
            }),
            {},
          ),
      }),
      {},
    );

// expand policy back into the { cis: aws: v1.1.0: { exclusions: ['1.1'] } } format
export const expandPolicy = (rulesDef): any =>
  Object.entries(rulesDef).reduce((obj, [bKey, bVal]) => {
    const [benchmark, provider, version] = bKey.split(':');

    return {
      ...obj,
      [benchmark]: {
        ...(obj?.[benchmark] ?? {}),
        [provider]: {
          ...(obj?.[benchmark]?.[provider] ?? {}),
          [version]: {
            exclusions: Object.entries(bVal)
              .filter(([_rule, enabled]) => !enabled)
              .map(([rule, _enabled]) => rule.split(':')[3]),
          },
        },
      },
    };
  }, {});

export const expandAccountPolicy = (rulesDef): any =>
  Object.entries(rulesDef).reduce((obj, [bKey, bVal]) => {
    const [benchmark, provider, version] = bKey.split(':');

    return {
      ...obj,
      [benchmark]: {
        ...(obj?.[benchmark] ?? {}),
        [provider]: {
          ...(obj?.[benchmark]?.[provider] ?? {}),
          [version]: {
            exclusions: bVal?.exclusions || [],
          },
        },
      },
    };
  }, {});
