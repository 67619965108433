import React, { ReactElement } from 'react';

import { Grid, Typography } from '@cuda-networks/bds-core';

import { AWSIcon, AzureIcon } from 'icons';
import { CloudType } from 'types';

interface Props {
  cloudType: CloudType;
}

export default function DeployCloudHeader({ cloudType }: Props): ReactElement {
  return (
    <Grid container alignItems="center">
      {
        {
          azure: <AzureIcon style={{ height: 32, width: 32, marginRight: 5 }} />,
          aws: <AWSIcon style={{ height: 32, width: 32, marginRight: 5 }} />,
        }[cloudType]
      }
      <Typography variant="h2">
        {
          {
            azure: 'Microsoft Azure Account',
            aws: 'Amazon Web Services Account',
          }[cloudType]
        }
      </Typography>
    </Grid>
  );
}
