import { gql } from '@apollo/client';

export const ACCOUNTS = gql`
  query adminAccounts {
    adminAccounts {
      accountId
      accountName
      dateAdded
      health
    }
  }
`;

export const ACCOUNT = gql`
  query adminAccount($input: GetAdminAccountInput!) {
    adminAccount(input: $input) {
      accountId
      accountName
      dateAdded
      health
    }
  }
`;

export const SERIALS = gql`
  query adminSerials {
    serials: adminSerials {
      serial
      accountId
      type
      addedAt
      expiresAt
      status
    }
  }
`;

export const EXTEND_SERIAL = gql`
  mutation extendSerial($input: ExtendSerialInput!) {
    serial: extendSerial(input: $input) {
      serial
      accountId
      type
      addedAt
      expiresAt
      status
    }
  }
`;
