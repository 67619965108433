import React from 'react';

import { Reference, StoreObject, useMutation } from '@apollo/client';

import ConfirmDelete from 'components/ConfirmDelete';
import { DELETE_USER } from 'graphql/Users';
import useRequest from 'hooks/useRequest';
import { User } from 'types';

interface UserDeleteDialogProps {
  user: User;
  open: boolean;
  handleClose: () => void;
}

export default function UserDeleteDialog(props: UserDeleteDialogProps): React.ReactElement {
  const { user, open, handleClose } = props;

  const [deleteStatus, deleteStarted, deleteSucceeded, deleteFailed] = useRequest();

  const [deleteUser] = useMutation(DELETE_USER, {
    context: { clientName: 'accounts' },
    update(cache, { data: { deleteUser: deleted } }) {
      cache.modify({
        fields: {
          users(existing = [], { readField }): User[] {
            return existing.filter(
              (u: Reference | StoreObject | undefined) => readField('id', u) !== deleted.id,
            );
          },
        },
      });
    },
  });
  const handleConfirm = async (): Promise<void> => {
    deleteStarted();
    try {
      await deleteUser({
        variables: {
          input: {
            id: user.id,
          },
        },
      });

      deleteSucceeded();
      handleClose();
    } catch (e) {
      deleteFailed(e?.networkError?.result?.message || e?.message || 'Failed to remove user');
    }
  };

  return (
    <ConfirmDelete
      title="Remove User"
      message={`Are you sure you want to remove the user ${user.email}?`}
      handleConfirm={handleConfirm}
      status={deleteStatus}
      open={open}
      handleClose={handleClose}
      confirmLabel="Remove"
    />
  );
}
