import React from 'react';

import { Button, CircularProgress } from '@cuda-networks/bds-core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(
  theme => ({
    logButtons: {
      minHeight: '46px',
      cursor: 'pointer',
      color: '#5F5F5F',
      fontSize: '12px',
      lineHeight: '14px',
    },
    label: {
      '& > svg': {
        marginRight: '10px',
      },
    },
  }),
  {
    name: 'HeaderButton',
  },
);

export default function HeaderButton({ loading, icon, value, ...others }): JSX.Element {
  const classes = useStyles();

  return (
    <Button
      classes={{
        root: classes.logButtons,
        label: classes.label,
      }}
      variant="text"
      disabled={loading}
      {...others}
    >
      {icon}
      {loading && <CircularProgress data-test-id="btn-loading" style={{ position: 'absolute' }} />}
      {value}
    </Button>
  );
}

HeaderButton.propTypes = {
  loading: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  icon: PropTypes.element.isRequired,
};

HeaderButton.defaultProps = {
  loading: false,
};
