import { useEffect, useState } from 'react';

import { parseQS } from 'utils/params';

export default function useTableSort(
  defaultSort: { order: string; orderBy: string },
  sortName?: string,
): any {
  const parsedQS: any = parseQS();

  let initial = defaultSort;
  if (sortName && parsedQS?.sort) {
    try {
      initial = JSON.parse(parsedQS?.sort);
    } catch (e) {
      initial = defaultSort;
    }
  } else if (sortName && sessionStorage.getItem(sortName)) {
    try {
      initial = JSON.parse(sessionStorage.getItem(sortName) || '');
    } catch (e) {
      initial = defaultSort;
    }
  }

  const [sort, setSort] = useState(initial);

  useEffect(() => {
    const updatedParsedQS = parseQS();

    if (sortName) {
      const queryString = Object.entries(updatedParsedQS)
        .filter(([key]) => key !== 'sort')
        .map(([key, value]) => `${key}=${value}`)
        .join('&');

      window.history.replaceState(
        {},
        '',
        `${window.location.pathname}?${queryString ? `${queryString}&` : ''}sort=${JSON.stringify(
          sort,
        )}`,
      );

      sessionStorage.setItem(sortName, JSON.stringify(sort));
    }
  }, [sort, sortName]);

  const handleSort = orderBy => (): void => {
    let order = 'desc';
    if (orderBy === sort.orderBy) {
      order = sort.order === 'desc' ? 'asc' : 'desc';
    }
    setSort({
      orderBy,
      order,
    });
  };

  return [sort, handleSort];
}
