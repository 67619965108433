import React from 'react';

import { Button, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import PropTypes from 'prop-types';

import PaginatedTable from 'components/common/PaginatedTable';
import StatusMessage from 'components/common/StatusMessage';
import NoResults from 'components/common/table/NoResults';
import Timestamp from 'components/common/Timestamp';

export default function SerialsTable({ serials, status, handleDetails, ...other }): JSX.Element {
  return (
    <>
      {status.error && (
        <StatusMessage
          status="error"
          message={status.error}
          removable={false}
          style={{ marginBottom: '24px' }}
        />
      )}
      <PaginatedTable loading={status.fetching} {...other}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Serial</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Account ID</TableCell>
              <TableCell>Bill Type</TableCell>
              <TableCell>Date Added</TableCell>
              <TableCell>Expiration</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {serials.length ? (
              serials.map(serial => (
                <TableRow key={serial.serial}>
                  <TableCell>{serial.serial}</TableCell>
                  <TableCell>{serial.status}</TableCell>
                  <TableCell>{serial.accountId}</TableCell>
                  <TableCell>{serial.type}</TableCell>
                  <TableCell>
                    <Timestamp ts={serial.addedAt} tsFormat="ISO" />
                  </TableCell>
                  <TableCell>
                    <Timestamp ts={serial.expiresAt} tsFormat="ISO" />
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      variant="text"
                      size="small"
                      onClick={() => handleDetails(serial.serial)}
                    >
                      Details
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <NoResults colSpan={7} value="No serials." />
            )}
          </TableBody>
        </Table>
      </PaginatedTable>
    </>
  );
}

SerialsTable.propTypes = {
  status: PropTypes.shape({
    fetching: PropTypes.bool,
    error: PropTypes.string,
    succeeded: PropTypes.bool,
  }).isRequired,
  serials: PropTypes.arrayOf(
    PropTypes.shape({
      serial_name: PropTypes.string,
      serial_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      date_added: PropTypes.string,
    }),
  ).isRequired,
  handleDetails: PropTypes.func.isRequired,
};
