import React from 'react';

import PropTypes from 'prop-types';

import AccountNameWithIcon from 'components/common/AccountNameWithIcon';
import Autocomplete from 'components/common/Autocomplete';

export default function AccountFilter({
  accounts,
  selected,
  onChange,
  onClose,
  processing,
  multiple,
  ...other
}) {
  return (
    <Autocomplete
      disabled={processing}
      multiple={multiple}
      options={accounts}
      selected={selected}
      selectAll={multiple}
      handleSelect={onChange}
      label="Connections"
      onClose={onClose}
      renderOption={option => (
        <AccountNameWithIcon accountId={option.type} small value={option.type} />
      )}
      {...other}
    />
  );
}

AccountFilter.propTypes = {
  accounts: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }),
  ),
  selected: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }),
  ),
  onChange: PropTypes.func.isRequired,
  processing: PropTypes.bool,
  multiple: PropTypes.bool,
  onClose: PropTypes.func,
};

AccountFilter.defaultProps = {
  accounts: [],
  selected: [],
  processing: false,
  multiple: true,
  onClose: null,
};
