import React, { useState, useEffect } from 'react';

import { Dialog, DialogContent, Tabs, Tab, Tooltip } from '@cuda-networks/bds-core';

import DialogTitle from 'components/common/DialogTitle';
import { Remediation } from 'components/SecurityFindings';

interface DetailsProps {
  findingId: string;
  handleClose: () => void;
  rule: any;
  open: boolean;
  onClose: () => void;
}

export default function RemediateDialog(props: DetailsProps): JSX.Element {
  const { handleClose, onClose, rule, open } = props;

  const [tab, setTab] = useState('cli');
  const updateTab = (e, tab): void => setTab(tab);

  useEffect(() => {
    setTab(rule?.remediation?.console ? 'console' : 'cli');
  }, [rule]);

  return (
    <Dialog maxWidth="md" fullWidth disableBackdropClick open={open} onClose={onClose}>
      <DialogTitle handleClose={handleClose}>Remediate Findings</DialogTitle>
      <DialogContent
        style={{
          padding: 0,
          display: 'flex',
          height: '550px',
          flexDirection: 'column',
          overflowY: 'hidden',
        }}
      >
        <Tabs
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          onChange={updateTab}
          scrollButtons="auto"
          variant="scrollable"
        >
          {rule?.remediation?.console && (
            <Tab
              key="console"
              label="Console Remediation"
              value="console"
              data-test-id="findings-console-remediation"
            />
          )}
          {rule?.remediation?.cli && (
            <Tab
              key="cli"
              label="CLI Remediation"
              value="cli"
              data-test-id="findings-console-remediation"
            />
          )}
          {(rule?.remediation?.cli || rule?.remediation?.console) && (
            <Tooltip
              title="Cloud Security Guardian has insufficient permissions to make changes to this account."
              arrow
              PopperProps={{
                'data-test-id': 'findings-automated-remediation-tooltip',
              }}
            >
              <span>
                <Tab key="automatic" label="Automated Remediation" value="automatic" disabled />
              </span>
            </Tooltip>
          )}
        </Tabs>
        {tab === 'cli' && (
          <Remediation remediation={rule.remediation.cli} generatorId={rule.generatorId} />
        )}
        {tab === 'console' && (
          <Remediation remediation={rule.remediation.console} generatorId={rule.generatorId} />
        )}
      </DialogContent>
    </Dialog>
  );
}
