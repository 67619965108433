import React, { useEffect, useMemo, useState } from 'react';

import {
  Button,
  TextField,
  Checkbox,
  Typography,
  ListSubheader,
  Divider,
} from '@cuda-networks/bds-core';
import { makeStyles } from '@material-ui/core/styles';
import { Autocomplete as MaterialAutocomplete } from '@material-ui/lab';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles(
  {
    checkbox: {
      display: 'flex',
      alignItems: 'center',
      flex: 1,
      '& > span': {
        marginRight: '5px',
        padding: 0,
      },
    },
    optionWrapper: {
      padding: '6px 16px',
      width: '100%',
      display: 'flex',
    },
    optionValue: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      '&:hover': {
        '& > $onlyButton': {
          visibility: 'visible',
        },
      },
      '& > $onlyButton': {
        visibility: 'hidden',
        minWidth: 'unset',
        // padding: '1px',
        lineHeight: '1rem',
      },
    },
    onlyButton: {},
    listbox: {
      paddingTop: 0,
      paddingBottom: 0,
      '& > ul': {
        margin: 0,
      },
    },
    paper: {
      marginTop: 0,
    },
    option: {
      padding: 0,
      '&[aria-selected="true"]': {
        backgroundColor: '#FFFFFF',
      },
      '&[data-focus="true"]': {
        backgroundColor: '#FFFFFF',
        '& > $optionWrapper': {
          backgroundColor: 'rgba(0, 0, 0, 0.08)',
        },
      },
    },
    tag: {
      margin: '0 3px',
      height: '22px',
      '& > svg': {
        height: '18px',
        width: '18px',
      },
    },
    input: {
      paddingRight: '28px',
    },
  },
  {
    name: 'Autocomplete',
  },
);

const renderGroup = (params): any[] => {
  if (params.group) {
    return [
      <ListSubheader key={params.key} component="div" style={{ position: 'static' }}>
        {params.group}
      </ListSubheader>,
      params.children,
    ];
  }

  return [params.children];
};

export default function Autocomplete({
  options,
  selected,
  selectAll,
  handleSelect,
  label,
  multiple,
  renderOption,
  countLabel,
  style,
  onClose,
  paddedTextField,
  ...other
}): JSX.Element {
  const classes = useStyles();

  const allOptions = useMemo(
    () => [
      ...(selectAll
        ? [
            {
              name: 'Select All',
              type: 'selectAll',
            },
          ]
        : []),
      ...options,
    ],
    [options, selectAll],
  );

  const [pendingValue, setPendingValue] = useState(selected);

  useEffect(() => {
    setPendingValue(selected);
  }, [selected]);

  const [inputValue, setInputValue] = useState('');

  const renderTags = (values): JSX.Element | string => {
    let renderTag;

    if (multiple) {
      if (values.length === 0) {
        renderTag = 'None';
      } else if (values.length === options.length) {
        renderTag = 'All';
      } else if (values.length === 1) {
        const option = values[0];
        renderTag = renderOption(option);
      } else {
        renderTag = `${values.length} of ${options.length}`;
      }
    }

    return countLabel ? (
      <>
        <span style={{ marginRight: '8px', fontSize: '16px' }}>{countLabel}:</span>
        <strong>{renderTag}</strong>
      </>
    ) : (
      <span style={{ marginRight: '8px', fontSize: '16px' }}>{renderTag}</span>
    );
  };

  const [open, setOpen] = useState<boolean>(false);
  const handleOpen = (): void => {
    setOpen(true);
    setPendingValue(selected);
  };
  const handleClose = (event, reason, value): void => {
    if (reason === 'toggleInput') {
      return;
    }
    setInputValue('');
    handleSelect(value || pendingValue);
    if (onClose) {
      onClose();
    }
    setOpen(false);
  };

  return (
    <MaterialAutocomplete
      style={{
        minWidth: 300,
        ...style,
      }}
      classes={{
        listbox: classes.listbox,
        paper: classes.paper,
        tag: classes.tag,
        option: classes.option,
        input: classes.inputRoot,
      }}
      multiple={multiple}
      options={allOptions}
      getOptionLabel={option => option?.name || ''}
      getOptionSelected={(option, value): boolean => option.type === value.type}
      disableClearable
      disableCloseOnSelect={multiple}
      includeInputInList
      // ListboxComponent={ListboxComponent}
      renderGroup={renderGroup}
      renderInput={params => (
        <TextField
          {...params}
          ref={params.InputProps.ref}
          variant="outlined"
          label={label}
          margin="none"
          inputProps={{
            ...(params.inputProps || {}),
            style: {
              padding: '8px',
            },
          }}
          autoFocus
          style={paddedTextField ? { padding: '0 10px', margin: '10px 0' } : {}}
          InputProps={{
            ...(params.InputProps || {}),
            notched: false,
            startAdornment: (params.InputProps || {}).startAdornment || renderTags([]),
            style: {
              paddingTop: '0',
              paddingBottom: '0',
              minHeight: '32px',
            },
          }}
          data-test-id="dropdown-selected"
        />
      )}
      open={open}
      onOpen={handleOpen}
      onClose={(e, reason) => {
        setInputValue('');
        handleClose(e, reason);
      }}
      renderTags={renderTags}
      renderOption={(option, params) => {
        if (option.type === 'selectAll') {
          return (
            <div style={{ display: 'flex', margin: '8px' }}>
              <Button
                variant="text"
                size="small"
                onClick={() => setPendingValue(options)}
                style={{ minWidth: 'auto' }}
              >
                All
              </Button>
              <Divider orientation="vertical" flexItem />
              <Button
                variant="text"
                size="small"
                onClick={() => setPendingValue([])}
                style={{ minWidth: 'auto' }}
              >
                None
              </Button>
            </div>
          );
        }
        return (
          <span className={clsx(classes.optionValue, classes.optionWrapper)}>
            <span className={classes.checkbox} data-test-id={`dropdown-${option.type}`}>
              {multiple && <Checkbox color="primary" checked={params.selected} />}
              <Typography variant="body2">{renderOption(option)}</Typography>
            </span>
            {multiple && (
              <Button
                className={classes.onlyButton}
                variant="text"
                name="only"
                onClick={() => handleClose(null, null, [option])}
                onKeyDown={() => handleClose(null, null, [option])}
                style={{ zIndex: 1 }}
              >
                <span name="only">Only</span>
              </Button>
            )}
          </span>
        );
      }}
      inputValue={inputValue}
      value={pendingValue}
      onChange={(e, newValue) => {
        if (e.target.getAttribute('name') === 'only') {
          return;
        }
        if (multiple && newValue.find(val => val.type === 'selectAll')) {
          return;
        }
        setPendingValue(newValue);
      }}
      onInputChange={(e, value, reason) => {
        if (reason !== 'reset') {
          setInputValue(value);
        }
      }}
      {...other}
    />
  );
}

Autocomplete.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }),
  ),
  selected: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }),
  ),
  selectAll: PropTypes.bool,
  label: PropTypes.string.isRequired,
  handleSelect: PropTypes.func.isRequired,
  countLabel: PropTypes.string,
  multiple: PropTypes.bool,
  renderOption: PropTypes.func,
  style: PropTypes.object,
};

Autocomplete.defaultProps = {
  options: [],
  selectAll: false,
  selected: [],
  multiple: true,
  style: {},
  countLabel: null,
  renderOption: option => option.name,
};
