import React, { useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';
import { Card, CardContent, Grid } from '@cuda-networks/bds-core';

import PageTitle from 'components/common/PageTitle';
import StatusMessage from 'components/common/StatusMessage';
import { ResourcesTable, ResourceScanButton, ResourcesFilters } from 'components/Resources';
import { RESOURCES } from 'graphql/Resources';
import usePagination from 'hooks/usePagination';
import useTableSort from 'hooks/useTableSort';

import { ResourceFilter } from './ResourcesFilters';

export default function Resources(): React.ReactElement {
  const [filters, setFilters] = useState<ResourceFilter | null>(null);

  const [processing, setProcessing] = useState(true);
  const [pagination, handlePaginate] = usePagination('page');
  const [sort, handleSort] = useTableSort(
    {
      orderBy: 'name',
      order: 'desc',
    },
    'csg-resources-sort',
  );
  const [filterId, setFilterId] = useState(Date.now().toString());

  const {
    loading: resourcesLoading,
    data: resourcesData,
    networkStatus,
    fetchMore,
    error: resourcesError,
  } = useQuery(RESOURCES, {
    skip: !filters,
    variables: {
      cursor: { limit: 50, offset: pagination.page * pagination.size },
      filter: {
        orderBy: [`${sort.orderBy} ${sort.order.toUpperCase()}`],
        id: filterId,
        ...(filters?.filter?.length && { where: filters.filter }),
      },
    },
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-only',
    notifyOnNetworkStatusChange: true,
    context: { clientName: 'resources' },
  });

  const handleRefresh = () => {
    setProcessing(true);
    setFilterId(Date.now().toString());
  };

  useEffect(() => {
    // variables changed or refresh called - reset pagination
    if (networkStatus === 2 || networkStatus === 4) {
      handlePaginate({
        page: 0,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [networkStatus]);

  const [resources, setResources] = useState([]);

  useEffect(() => {
    setProcessing(true);

    if (!resourcesData || resourcesLoading) {
      return;
    }

    const data = (resourcesData?.resources ?? []).slice(
      pagination.page * pagination.size,
      pagination.page * pagination.size + pagination.size,
    );

    if (data.some(d => !d && d !== undefined)) {
      return;
    }

    setResources(data);
    setProcessing(false);
  }, [resourcesData, resourcesLoading, pagination]);

  useEffect(() => {
    if (resourcesLoading) {
      setProcessing(true);
    }
  }, [resourcesLoading]);

  const paginate = newPagination => {
    const offset = newPagination.page * newPagination.size;
    const limit = newPagination.size;
    const count = resourcesData.resources.length;
    const { count: total } = resourcesData;

    handlePaginate(newPagination);

    if (
      count >= Math.min(total, offset + limit) &&
      resourcesData.resources.slice(offset, offset + limit).every(a => Boolean(a))
    ) {
      return;
    }

    fetchMore({
      variables: {
        cursor: { limit: 50, offset },
      },
    });
  };

  const loading = (processing || resourcesLoading) && !resourcesError;

  return (
    <>
      <PageTitle title="Resources">
        <div>
          <ResourceScanButton disabled={resourcesLoading} />
        </div>
      </PageTitle>
      <Grid container spacing={3} style={{ padding: '15px' }}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <ResourcesFilters
                updateFilters={setFilters}
                reset={() => handlePaginate({ page: 0 })}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          {resourcesError && (
            <StatusMessage status="error" message="Failed to load resources" removable />
          )}
          <ResourcesTable
            resources={resources}
            handlePaginate={paginate}
            pagination={{
              ...pagination,
              total: resourcesData?.count ?? 0,
            }}
            handleSort={handleSort}
            sort={sort}
            loading={loading}
            handleRefresh={handleRefresh}
          />
        </Grid>
      </Grid>
    </>
  );
}
