import React from 'react';

import { TableCell } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { SortAZIcon, SortZAIcon, NoSortIcon } from 'icons';

const useStyles = makeStyles(
  {
    sortable: {
      display: 'flex',
      alignItems: 'center',
      // justifyContent: 'space-between',
      cursor: 'pointer',
    },
  },
  {
    name: 'TableHeaderCell',
  },
);

export default function TableHeaderCell({ field, sort, handleSort, ...other }): JSX.Element {
  const classes = useStyles();

  return (
    <TableCell {...other}>
      {field.sortable ? (
        <span
          className={classes.sortable}
          onClick={handleSort(field.sortLabel)}
          onKeyDown={handleSort(field.sortLabel)}
          role="button"
          data-test-id={`table-header-sort-${(field.label || '').toLowerCase()}`}
        >
          {field.label}
          {sort.orderBy === field.sortLabel ? (
            {
              asc: <SortZAIcon />,
              desc: <SortAZIcon />,
            }[sort.order]
          ) : (
            <NoSortIcon />
          )}
        </span>
      ) : (
        field.label
      )}
    </TableCell>
  );
}

TableHeaderCell.propTypes = {
  field: PropTypes.shape({
    label: PropTypes.string.isRequired,
    sortLabel: PropTypes.string,
  }),
  sort: PropTypes.shape({
    orderBy: PropTypes.string,
    order: PropTypes.oneOf(['asc', 'desc']),
  }),
  handleSort: PropTypes.func,
};

TableHeaderCell.defaultProps = {
  field: {
    label: '',
  },
  sort: {},
  handleSort: null,
};
