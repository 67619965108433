// @ts-nocheck
import React, { useEffect, useMemo, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { ManagementGroupsAPI } from '@azure/arm-managementgroups';
import { ManagementGroup } from '@azure/arm-managementgroups/esm/models';
import { TokenCredentials } from '@azure/ms-rest-js';
import { AuthenticationResult, PublicClientApplication } from '@azure/msal-browser';
import {
  Button,
  TextField,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from '@cuda-networks/bds-core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Autocomplete, Skeleton } from '@material-ui/lab';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import EntityDisplay from 'components/AzureConnection/EntityDisplay';
import StatusMessage from 'components/common/StatusMessage';
import { StandardTree } from 'components/SecurityFindings';
import { CREATE_AUTH_TOKEN, LIST_CONNECTIONS } from 'graphql/Accounts';
import useMixpanel from 'hooks/useMixpanel';
import useOnboard from 'hooks/useOnboard';
import { ContentCopyIcon } from 'icons';
import {
  Connection,
  ConnectionResult,
  ConnectionStatus,
  CreateAuthTokenData,
  ListConnectionsData,
  OnboardingStatus,
  TreeSelectNode,
} from 'types';

import { DeployCloudHeader, DeployAzureLegacy } from '.';
import {
  AzureTreeInput,
  onboardGroup,
  clientId,
  getGroupsToOnboard,
  subsUnderGroup,
  getNodeById,
  getDisabledReason,
  getResultMessage,
} from './lib';

const msalConfig = {
  auth: {
    clientId,
    authority: 'https://login.microsoftonline.com/common',
    knownAuthorities: [],
    redirectUri: `${window.location.origin}/connectors/azure`,
    postLogoutRedirectUri: `${window.location.origin}/logout`,
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
};

const useStyles = makeStyles(
  {
    loginButton: {
      height: 'fit-content',
      padding: 0,
    },
    buttonsWrapper: {
      display: 'flex',
      alignItems: 'center',
      '&>button': {
        marginRight: 8,
      },
      marginBottom: 16,
    },
    accordion: {
      boxShadow: 'none',
      '&:not(:last-child)': {
        borderBottom: 0,
      },
      '&:before': {
        display: 'none',
      },
      '&$expanded': {
        border: '1px solid rgba(0, 0, 0, .125)',
        margin: 'auto',
      },
    },
    accordionSummary: {
      minHeight: 48,
      flexDirection: 'row-reverse',
      paddingLeft: 0,
      '&$expanded': {
        minHeight: 48,
      },
    },
    accordionSummaryContent: {
      flexWrap: 'wrap',
      '&$expanded': {
        margin: '12px 0',
        '&>$accordionSubtitle': {
          display: 'block',
        },
      },
    },
    expandIcon: {
      alignSelf: 'flex-start',
    },
    accordionSubtitle: {
      display: 'none',
    },
    expanded: {},

    dialogActions: {
      justifyContent: 'space-between',
      padding: '8px 24px',
    },
  },
  {
    name: 'DeployAzure',
  },
);

const msalLoginInstance = new PublicClientApplication(msalConfig);

export const sleep = (delay: number): Promise<any> =>
  new Promise(resolve => setTimeout(resolve, delay));

interface Tenant {
  tenantId: string;
  displayName: string;
}
interface DeployAzureProps {
  handleClose?(): void;
  showPrompt?: boolean;
  standalone?: boolean;
}

export default function DeployAzure(props: DeployAzureProps): JSX.Element {
  const { handleClose, showPrompt = true, standalone = false } = props;
  const classes = useStyles();
  const mixpanel = useMixpanel();

  const [submitScans, createConnection] = useOnboard();

  const [started, setStarted] = useState<boolean>(!showPrompt);
  const [loading, setLoading] = useState<boolean>(true);
  const [switching, setSwitching] = useState<boolean>(false);

  const [result, setResult] = useState<ConnectionResult>();

  const [loadingError, setLoadingError] = useState<string>();

  const [selectedMap, setSelectedMap] = useState<TreeSelectNode>({});
  const [tenantsList, setTenantsList] = useState<Tenant[]>([]);
  const [accessToken, setToken] = useState<string>('');
  const [connectError, setConnectError] = useState<string>();
  const [connectWarning, setConnectWarning] = useState<string>();
  const [mgList, setMgList] = useState<ManagementGroup[]>([]);
  const [selectedTenant, selectTenant] = useState({});
  const [username, setUsername] = useState<string>(
    sessionStorage.getItem('csg-azure-username') || '',
  );
  const [connectStatus, setConnectStatus] = useState<Record<string, ConnectionStatus>>();
  const [token, setTokenResp] = useState<AuthenticationResult>();

  const [generateToken, { data }] = useMutation<CreateAuthTokenData>(CREATE_AUTH_TOKEN, {
    context: { clientName: 'accounts' },
  });

  useEffect(() => {
    if (!standalone) {
      generateToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [standalone]);

  const { data: listConnectionsData } = useQuery<ListConnectionsData>(LIST_CONNECTIONS, {
    context: { clientName: 'accounts' },
    skip: standalone,
  });

  useEffect(() => {
    async function fetchData(): Promise<void> {
      try {
        const tokenResponse = await msalLoginInstance.acquireTokenPopup({
          scopes: ['https://management.core.windows.net//user_impersonation'],
          prompt: 'select_account',
        });
        setTokenResp(tokenResponse);

        setUsername(tokenResponse.account?.username || '');
        sessionStorage.setItem('csg-azure-username', tokenResponse.account?.username || '');

        setToken(tokenResponse.accessToken);

        const tenants = await fetch('https://management.azure.com/tenants?api-version=2020-01-01', {
          headers: {
            Authorization: `Bearer ${tokenResponse.accessToken}`,
            'Content-Type': 'application/json',
          },
        })
          .then(res => res.json())
          .then(json => json?.value || []);

        selectTenant(tenants.find(t => t.tenantId === tokenResponse.tenantId) || tenants[0]);
        setTenantsList(tenants.sort((a, b) => a.displayName.localeCompare(b.displayName)));
        mixpanel.track('Acquired user impersonation token');
      } catch (e) {
        setLoading(false);
        setLoadingError('Failed to authenticate with the tenant.');
        mixpanel.track('Error acquiring user impersonation token');
      }
    }

    if (started) {
      fetchData();
    }
  }, [mixpanel, started]);

  useEffect(() => {
    async function fetchData(): Promise<void> {
      try {
        const creds = new TokenCredentials(token?.accessToken);
        const mgClient = new ManagementGroupsAPI(creds);

        const mgs = await mgClient.entities.list();

        setMgList(mgs);
        setLoading(false);
        setSwitching(false);
        mixpanel.track('Listed entities');
      } catch (e) {
        setLoadingError('Error loading tenant information from Microsoft.');
        mixpanel.track('Failed to list entities');
      }
    }

    if (token?.accessToken) {
      fetchData();
    }
  }, [mixpanel, token]);

  useEffect(() => {
    async function fetchData(): Promise<void> {
      try {
        mixpanel.track(`Switching to tenant ${selectedTenant.tenantId}`);
        const tokenResponse = await msalLoginInstance.acquireTokenSilent({
          scopes: ['https://management.core.windows.net//user_impersonation'],
          authority: `https://login.microsoftonline.com/${selectedTenant.tenantId}`,
          account: token.account,
          forceRefresh: true,
        });
        setToken(tokenResponse.accessToken);
        setTokenResp(tokenResponse);
      } catch (e) {
        try {
          const tokenResponse = await msalLoginInstance.acquireTokenPopup({
            scopes: ['https://management.core.windows.net//user_impersonation'],
            authority: `https://login.microsoftonline.com/${selectedTenant.tenantId}`,
            loginHint: token?.account?.username,
            // prompt: 'none',
          });
          setToken(tokenResponse.accessToken);
          setTokenResp(tokenResponse);
        } catch (e) {
          // failed to switch tenant
          setMgList([]);
          mixpanel.track(`Failed to switch to tenant ${selectedTenant.tenantId}`);
        } finally {
          setSwitching(false);
        }
      }
    }

    if (selectedTenant?.tenantId && selectedTenant?.tenantId !== token?.tenantId) {
      setResult();
      setSwitching(true);
      setMgList([]);
      fetchData();
    }
  }, [mixpanel, selectedTenant, token]);

  const tree: AzureTreeInput | null = useMemo(() => {
    const connections: Connection[] = listConnectionsData?.listConnections?.connections || [];

    const addChildren = (node: AzureTreeInput, parentId: string | null): AzureTreeInput => {
      const children = mgList.filter(mg => mg.parent.id === parentId);

      if (children.length) {
        return {
          ...node,
          children: children.map(c => {
            const isTenant =
              c.id === `/providers/Microsoft.Management/managementGroups/${c.tenantId}`;

            const disabledReason = getDisabledReason(c, connections);
            return addChildren(
              {
                name: (
                  <EntityDisplay
                    label={c.displayName}
                    status={connectStatus?.[c.id]}
                    type={c.type}
                  />
                ),
                id: c.id,
                parent: isTenant ? c.tenantId : parentId,
                disabled: Boolean(disabledReason),
                disabledReason,
              },
              c.id,
            );
          }),
        };
      }

      return node;
    };

    const tenant = mgList.find(
      mg => mg.id === `/providers/Microsoft.Management/managementGroups/${mg.tenantId}`,
    );

    if (!tenant) {
      return null;
    }

    const disabledReason = getDisabledReason(tenant, connections);

    const result = addChildren(
      {
        name: (
          <EntityDisplay
            label={
              tenantsList.find(t => t.tenantId === tenant.tenantId)?.displayName ||
              tenant.displayName
            }
            status={connectStatus?.[`/tenant:${tenant.tenantId}`]}
            type="/tenant"
          />
        ),
        id: tenant.tenantId,
        parent: null,
        disabled: Boolean(disabledReason),
        disabledReason,
      },
      null,
    );
    setLoading(false);
    setSwitching(false);

    return result;
  }, [mgList, listConnectionsData, connectStatus, tenantsList]);

  async function onboardTenant(tenantId: string, prompt = false): Promise<string> {
    const msalTenantConfig = {
      auth: {
        clientId,
        authority: `https://login.microsoftonline.com/${tenantId}`,
        knownAuthorities: [],
        redirectUri: `${window.location.origin}/connectors/azure`,
        postLogoutRedirectUri: `${window.location.origin}/logout`,
        navigateToLoginRequestUrl: true,
      },
      cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false,
      },
    };
    const msalTenantInstance = new PublicClientApplication(msalTenantConfig);

    let tokenResponse;

    try {
      mixpanel.track(`Onboarding tenant as ${prompt ? 'admin' : 'user'}`, {
        tenantId,
      });
      tokenResponse = await msalTenantInstance.acquireTokenPopup({
        scopes: ['.default'],
        ...(prompt && { prompt: 'consent' }),
        loginHint: username,
      });
    } catch (promptError) {
      if (prompt) {
        try {
          tokenResponse = await msalTenantInstance.acquireTokenPopup({
            scopes: ['.default'],
            loginHint: username,
          });
          if (promptError?.errorCode === 'access_denied') {
            mixpanel.track('Failed to onboard tenant due to permissions.', {
              tenantId,
            });
            setConnectWarning('Global admin required for some permissions.');
          } else {
            mixpanel.track('Failed to onboard tenant due to unknown error.', {
              tenantId,
            });
            setConnectWarning('Failed to authenticate with the tenant.  Some checks may not work.');
          }
        } catch (noPromptError) {
          throw new Error('Failed to authenticate with the tenant.');
        }
      }
    }

    if (!tokenResponse?.accessToken) {
      mixpanel.track('Failed to acquire token for tenant', {
        tenantId,
      });
      throw new Error('Failed to authenticate with the tenant.');
    }

    let spResp: { value?: { id: string }[] } = {};
    let cnt = 0;
    while (!spResp?.value?.length && cnt < 10) {
      // eslint-disable-next-line no-await-in-loop
      if (cnt) await sleep(15000);
      cnt += 1;
      // eslint-disable-next-line no-await-in-loop
      spResp = await fetch(
        `https://graph.microsoft.com/v1.0/servicePrincipals?$filter=appId eq '${clientId}'`,
        {
          headers: {
            Authorization: `Bearer ${tokenResponse.accessToken}`,
            'Content-Type': 'application/json',
          },
        },
      )
        .then(res => res.json())
        .catch(() => {
          // failed possibly due to timing issue
        });
    }

    if (!spResp?.value?.[0]?.id) {
      mixpanel.track('Failed to fetch service principal', {
        tenantId,
      });
      throw new Error('servicePrincipalFailed');
    }

    return spResp.value[0].id;
  }

  async function createConnections(
    tenantId: string,
    adminConsent = false,
  ): Promise<Record<string, ConnectionStatus>> {
    const statuses: Record<string, ConnectionStatus> = {};
    let groupIds: string[] = [];

    try {
      const node = getNodeById(tree, tenantId);
      groupIds = getGroupsToOnboard([], node, selectedMap);
      const principalId = await onboardTenant(tenantId, adminConsent);
      if (adminConsent) {
        statuses[`/tenant:${tenantId}`] = {
          status: 'succeeded',
        };
      }

      await Promise.all(
        groupIds.map(async id => {
          const result = await onboardGroup(id, principalId, accessToken);

          if (result.status === 'succeeded') {
            mixpanel.track('Created role for management group/subscription', {
              entityId: id,
              servicePrincipalId: principalId,
            });
            const groupNode = getNodeById(tree, id);
            const subs = subsUnderGroup(groupNode, selectedMap);

            await Promise.all(
              subs.map(async s => {
                const cloud = mgList.find(mg => mg.id === s);

                const connection = {
                  subscriptionId: cloud.id.split('/')[2],
                  name: cloud.displayName,
                  provider: 'azure',
                  tenantId,
                };
                const credentials = {
                  servicePrincipalId: principalId,
                  clientId,
                };

                try {
                  await createConnection({
                    variables: {
                      input: {
                        ...connection,
                        credentials,
                      },
                    },
                  });

                  mixpanel.track('On-boarding succeeded', {
                    ...connection,
                  });
                  statuses[s] = {
                    status: 'succeeded',
                  };
                } catch (e) {
                  mixpanel.track('On-boarding succeeded', {
                    ...connection,
                  });
                  statuses[s] = {
                    status: 'failed',
                  };
                }
              }),
            );
          } else {
            mixpanel.track('Failed to create role for management group/subscription', {
              entityId: id,
              servicePrincipalId: principalId,
              error: result.message,
            });
          }
          statuses[id] = result;
        }),
      );
    } catch (e) {
      if (e.message === 'servicePrincipalFailed') {
        statuses.servicePrincipalFailed = {
          status: 'failed',
        };
      }
      if (adminConsent) {
        statuses[`/tenant:${tenantId}`] = {
          status: 'failed',
        };
      }
      groupIds.forEach(id => {
        statuses[id] = {
          status: 'failed',
        };
      });
    }

    return statuses;
  }

  async function handleConnect(): Promise<void> {
    if (!Object.values(selectedMap).some(v => v === 'checked')) {
      setConnectError('You must select at least one Tenant, Management Group or Subscription.');
      return;
    }

    const groupsToOnboard = getGroupsToOnboard([], tree, selectedMap);

    // tenants that were selected that we should attempt to onboard with admin consent
    const tenantsToOnboard = tenantsList
      .filter(t => selectedMap?.[t.tenantId] === 'checked')
      .map(t => t.tenantId);
    // tenants that were not selected that we onboard with no permissions in order to get service principal
    const tenantsNoConsent = Object.entries(
      mgList.reduce(
        (obj: Record<string, boolean>, mg) => ({
          ...obj,
          [mg.tenantId]: obj?.[mg.tenantId] || selectedMap?.[mg.id] === 'checked',
        }),
        {},
      ),
    )
      .filter(([_id, checked]) => checked)
      .filter(([id]) => selectedMap?.[id] !== 'checked')
      .map(([id]) => id);

    let connectionStatus: Record<string, ConnectionStatus> = groupsToOnboard.reduce(
      (obj, id) => ({ ...obj, [id]: { status: 'connecting' } }),
      {},
    );
    tenantsToOnboard.forEach(t => {
      connectionStatus[`/tenant:${t}`] = { status: 'connecting' };
    });
    setConnectStatus(connectionStatus);

    // onboard with admin consent prompt
    await Promise.all(
      tenantsToOnboard.map(async (tenantId: string) => {
        const statuses = await createConnections(tenantId, true);
        connectionStatus = {
          ...connectionStatus,
          ...statuses,
        };
        setConnectStatus(connectionStatus);
      }),
    );
    // onboard without admin consent prompt
    await Promise.all(
      tenantsNoConsent.map(async (tenantId: string) => {
        const statuses = await createConnections(tenantId, false);
        connectionStatus = {
          ...connectionStatus,
          ...statuses,
        };
        setConnectStatus(connectionStatus);
      }),
    );

    if (connectionStatus?.servicePrincipalFailed) {
      setResult({
        status: 'error',
        message: 'This process did not complete. Please try again.',
      });
      return;
    }

    const results: Record<OnboardingStatus, string[]> = Object.entries(connectionStatus).reduce(
      (obj, [id, s]) => ({
        ...obj,
        [s.status]: [...(obj?.[s.status] || []), id],
      }),
      {},
    );

    if (!standalone) {
      submitScans(
        (results?.succeeded || [])
          .filter(s => s.startsWith('/subscriptions'))
          .map(s => s?.split('/')?.[2])
          .filter(s => Boolean(s))
          .map(s => `azure:${s}`),
      );
    }

    setResult(getResultMessage(results));
  }

  function handleLoginClick(): void {
    mixpanel.track('Clicked "Sign in with Microsoft');
    setLoading(true);
    setStarted(true);
  }

  async function legacySubmit(form: {
    subscriptionId: string;
    tenantId: string;
    clientId: string;
    clientSecret: string;
  }): Promise<void> {
    const { subscriptionId, tenantId, clientId, clientSecret } = form;

    await createConnection({
      variables: {
        input: {
          subscriptionId,
          provider: 'azure',
          tenantId,
          credentials: {
            clientSecret,
            clientId,
          },
        },
      },
    });
    setResult({});

    if (!standalone) {
      submitScans([`azure:${subscriptionId}`]);
    }
  }

  const [manualExpanded, setManualExpanded] = useState<boolean>(false);
  function changeManualExpanded(_e, expanded: boolean): void {
    mixpanel.track(`${expanded ? 'Opened' : 'Closed'} manual connection settings`);
    setManualExpanded(expanded);
  }

  return (
    <>
      <DialogContent style={{ minHeight: 300 }}>
        <Grid container className={classes.container}>
          <Grid item xs={12}>
            <DeployCloudHeader cloudType="azure" />
          </Grid>
          {started ? (
            <Grid item xs={12}>
              {loadingError && <StatusMessage status="error" message={loadingError} />}
              {!loadingError && (
                <>
                  <>
                    {loading ? (
                      <>
                        <Skeleton style={{ height: 20 }} />
                        <Skeleton style={{ height: 20 }} />
                      </>
                    ) : (
                      <Typography variant="body2" component="div">
                        Select the management groups and/or subscriptions you want to connect to
                        Barracuda Cloud Security Guardian.
                      </Typography>
                    )}
                    {connectWarning && (
                      <StatusMessage status="warning" message={connectWarning} removable />
                    )}
                    {connectError && (
                      <StatusMessage status="error" message={connectError} removable />
                    )}
                    {Boolean(result && result.message) && (
                      <StatusMessage status={result.status} message={result.message} />
                    )}
                    {loading ? (
                      <Skeleton style={{ height: 35 }} />
                    ) : (
                      <Autocomplete
                        options={tenantsList}
                        getOptionLabel={(option): string => option?.displayName || ''}
                        disableClearable
                        data-test-id="move-nsg-dropdown"
                        disabled={switching}
                        style={{ width: 300 }}
                        renderInput={(params): JSX.Element => (
                          <TextField
                            {...params}
                            required
                            variant="outlined"
                            margin="dense"
                            inputProps={{
                              ...(params.inputProps || {}),
                              style: {
                                padding: '2px 4px',
                                fontSize: 14,
                              },
                            }}
                            data-test-id="selected-tenant"
                          />
                        )}
                        value={selectedTenant}
                        renderOption={(option): JSX.Element => (
                          <span data-test-id={option} style={{ fontSize: 14 }}>
                            {option.displayName}
                          </span>
                        )}
                        onChange={(e, value): void => {
                          selectTenant(value);
                        }}
                      />
                    )}
                    {loading || switching ? (
                      <>
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                      </>
                    ) : (
                      <>
                        {tree && tree?.id ? (
                          <StandardTree
                            tree={tree}
                            selectedMap={selectedMap}
                            setSelectedMap={setSelectedMap}
                            width={700}
                            height={400}
                            handleClose={() => {}}
                            openByDefault
                            updateParentsOnSelect={false}
                            disableChildren
                          />
                        ) : (
                          <StatusMessage
                            status="info"
                            message="No groups and/or subscriptions found for this Microsoft account and tenant."
                          />
                        )}
                      </>
                    )}
                  </>
                </>
              )}
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Typography variant="body2" style={{ marginBottom: 10 }}>
                Sign into your Microsoft account to give Barracuda Cloud Security Guardian read-only
                access to your Azure tenants and/or subscriptions. Cloud Security Guardian will not
                have permission to make any changes or deploy any resources.
              </Typography>
              <Typography variant="body2" style={{ marginBottom: 10 }}>
                Alternatively, click <strong>Copy Link</strong> to copy and send a link to an Azure
                admin who can make this connection.
              </Typography>
              <div className={classes.buttonsWrapper}>
                <Button
                  className={classes.loginButton}
                  onClick={handleLoginClick}
                  disabled={Boolean(result)}
                >
                  <img
                    src="/images/azure/ms-symbollockup_signin_light.svg"
                    alt="Sign in with Microsoft"
                  />
                </Button>
                {!standalone && (
                  <>
                    or
                    <CopyToClipboard
                      text={`${window.location.origin}/connectors/azure?connectionToken=${data?.createAuthToken?.token}`}
                    >
                      <Button
                        variant="text"
                        disabled={Boolean(result)}
                        onClick={() => mixpanel.track('Clicked "Copy Link"')}
                      >
                        <ContentCopyIcon />
                        Copy Link
                      </Button>
                    </CopyToClipboard>
                  </>
                )}
              </div>
              {!standalone && (
                <Accordion
                  square
                  classes={{ root: classes.accordion, expanded: classes.expanded }}
                  expanded={manualExpanded}
                  onChange={changeManualExpanded}
                >
                  <AccordionSummary
                    classes={{
                      root: classes.accordionSummary,
                      content: classes.accordionSummaryContent,
                      expanded: classes.expanded,
                      expandIcon: classes.expandIcon,
                    }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="legacy-onboarding-content"
                    id="legacy-onboarding-header"
                  >
                    <Typography variant="body1">Manual Connection Method</Typography>
                    <Typography
                      variant="subtitle2"
                      className={classes.accordionSubtitle}
                      component="p"
                    >
                      Use this section to create permissions manually.{' '}
                      <a
                        href="https://campus.barracuda.com/doc/94537659/"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        Learn how to create an Azure connection manually.
                      </a>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <DeployAzureLegacy onSubmit={legacySubmit} />
                  </AccordionDetails>
                </Accordion>
              )}
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions classes={{ root: classes.dialogActions }}>
        <a
          href="https://campus.barracuda.com/doc/78808235/"
          rel="noopener noreferrer"
          target="_blank"
        >
          Learn how to add an Azure account.
        </a>
        <div>
          {!standalone && (
            <Button
              onClick={handleClose}
              color="secondary"
              style={{ marginRight: 8 }}
              variant="contained"
            >
              {result ? 'Close' : 'Cancel'}
            </Button>
          )}
          {!loadingError && !result && started && (
            <Button onClick={handleConnect} color="primary" autoFocus variant="contained">
              Connect
            </Button>
          )}
        </div>
      </DialogActions>
    </>
  );
}
