import React, { useEffect, useState } from 'react';

import { Button, Typography, Grid, TextField, Select, Checkbox } from '@cuda-networks/bds-core';
import { ErrorMessage } from '@hookform/error-message';
import { FormControl, FormHelperText, FormControlLabel } from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PersonIcon from '@material-ui/icons/Person';
import PhoneIcon from '@material-ui/icons/Phone';
import WorkIcon from '@material-ui/icons/Work';
import { Controller, useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';

import StatusMessage from 'components/common/StatusMessage';
import { EulaDialog, FormRow } from 'components/Register';
import { states, countryCodes } from 'lib';
import { AccountRegistrationForm, RequestStatus } from 'types';

interface CompleteRegistrationProps {
  userInfo: {
    email?: string;
    firstName?: string;
    lastName?: string;
    company?: string;
  };
  status: RequestStatus;
  onSubmit: (payload: AccountRegistrationForm) => Promise<void>;
  onLogout: () => Promise<void>;
  partnerEmail?: string;
  serial?: boolean;
}

export default function CompleteRegistration(props: CompleteRegistrationProps): JSX.Element {
  const { userInfo, status, onSubmit: submitRegistration, partnerEmail, serial = false } = props;
  const history = useHistory();
  const { handleSubmit, control, errors, watch } = useForm({
    mode: 'onBlur',
    defaultValues: {
      email: '',
      firstName: '',
      lastName: '',
      company: '',
      phoneNumber: '',
      countryCode: 'US',
      postalCode: '',
      state: '',
      password: '',
      passwordConfirm: '',
      shareWithPartner: true,
      ...userInfo,
    },
  });
  const watchCountryCode: 'CA' | 'US' = watch('countryCode');

  const [agreed, setAgreed] = useState(false);
  const [showEula, setShowEula] = useState(false);
  const [form, setForm] = useState({});
  const [showZip, setShowZip] = useState(false);
  const [countryStates, setStates] = useState([]);

  useEffect(() => {
    setShowZip(['US', 'CA', 'DE', 'AU', 'IN', 'GB', 'CN', 'IT'].includes(watchCountryCode));
    setStates(states[watchCountryCode] ?? []);
  }, [watchCountryCode]);

  const handleAccept = (): void => {
    setShowEula(false);
    setAgreed(true);
    submitRegistration(form);
  };

  const onSubmit = (data): void => {
    setForm(data);
    if (agreed) {
      handleAccept();
    } else {
      setShowEula(true);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Grid container spacing={2} style={{ maxWidth: '900px' }}>
          <Grid xs={12} item container justify="center" style={{ textAlign: 'center' }}>
            <Typography variant="h2">
              {serial
                ? 'Sign up for Barracuda Cloud Security Guardian'
                : 'Sign up for a 30-day trial of Barracuda Cloud Security Guardian'}
            </Typography>
            {status.succeeded && (
              <StatusMessage status="success" message={status.result} removable />
            )}
            {status.error && <StatusMessage status="error" message={status.error} removable />}
          </Grid>
          <Grid xs={12} sm={6} item container direction="column" justify="flex-start">
            <Grid item style={{ marginBottom: '16px' }}>
              <Typography variant="h6">Remove security as a burden in the cloud.</Typography>
              <Typography variant="caption">
                Developers like the speed and flexibility of the cloud—but many fail due to cloud
                security challenges. Cloud Security Guardian manages security for them,
                automatically.
              </Typography>
            </Grid>
            <Grid item style={{ marginBottom: '16px' }}>
              <Typography variant="h6">
                Leverage industry best practices for security compliance.
              </Typography>
              <Typography variant="caption">
                Cloud Security Guardian is fully CIS Benchmarks-certified — their benchmarks are
                incorporated as pre-loaded policies in Cloud Security Guardian. Policies for PCI DSS
                and HIPAA are also included.
              </Typography>
            </Grid>
            <Grid item style={{ marginBottom: '16px' }}>
              <Typography variant="h6">Get integrated, full-stack cloud security.</Typography>
              <Typography variant="caption">
                Only Barracuda Networks integrates cloud security management with perimeter and
                network security solutions—i.e., control, data, and management plane security
                together in a single solution.
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} container direction="column">
            <FormRow>
              <EmailIcon />
              <Grid item direction="column" xs={12}>
                <Controller
                  as={
                    <TextField
                      variant="outlined"
                      required
                      placeholder="Email address"
                      fullWidth
                      name="email"
                      disabled
                      error={Boolean(errors?.email)}
                      margin="none"
                    />
                  }
                  name="email"
                  control={control}
                  rules={{
                    required: 'Required',
                    pattern: {
                      value: /.+@.+\..+/,
                      message: 'Invalid email address',
                    },
                  }}
                />
                <ErrorMessage as={<FormHelperText error />} errors={errors} name="email" />
              </Grid>
            </FormRow>
            <FormRow>
              <PersonIcon />
              <Grid item direction="column" xs={6} style={{ marginRight: '8px' }}>
                <Controller
                  as={
                    <TextField
                      variant="outlined"
                      required
                      placeholder="First Name"
                      disabled
                      fullWidth
                      name="firstName"
                      error={Boolean(errors?.firstName)}
                      margin="none"
                      style={{ marginRight: '8px' }}
                    />
                  }
                  name="firstName"
                  control={control}
                  rules={{
                    required: 'Required',
                  }}
                />
                <ErrorMessage as={<FormHelperText error />} errors={errors} name="firstName" />
              </Grid>
              <Grid item direction="column" xs={6}>
                <Controller
                  as={
                    <TextField
                      variant="outlined"
                      required
                      placeholder="Last Name"
                      disabled
                      fullWidth
                      name="lastName"
                      error={Boolean(errors?.lastName)}
                      margin="none"
                    />
                  }
                  name="lastName"
                  control={control}
                  rules={{
                    required: 'Required',
                  }}
                />
                <ErrorMessage as={<FormHelperText error />} errors={errors} name="lastName" />
              </Grid>
            </FormRow>
            <FormRow>
              <WorkIcon />
              <Grid item direction="column" xs={12}>
                <Controller
                  as={
                    <TextField
                      variant="outlined"
                      required
                      placeholder="Company Name"
                      disabled
                      fullWidth
                      name="company"
                      error={Boolean(errors?.company)}
                      margin="none"
                    />
                  }
                  name="company"
                  control={control}
                  rules={{
                    required: 'Required',
                  }}
                />
                <ErrorMessage as={<FormHelperText error />} errors={errors} name="company" />
              </Grid>
            </FormRow>
            <FormRow>
              <PhoneIcon />
              <Grid item direction="column" xs={12}>
                <Controller
                  as={
                    <TextField
                      variant="outlined"
                      required
                      placeholder="Phone Number"
                      disabled={status.fetching}
                      fullWidth
                      name="phoneNumber"
                      error={Boolean(errors?.phoneNumber)}
                      margin="none"
                    />
                  }
                  name="phoneNumber"
                  control={control}
                  rules={{
                    required: 'Required',
                    pattern: {
                      value: /^[0-9+ ()-]{1,21}$/,
                      message: 'Invalid phone number',
                    },
                  }}
                />
                <ErrorMessage as={<FormHelperText error />} errors={errors} name="phoneNumber" />
              </Grid>
            </FormRow>
            <FormRow>
              <LocationOnIcon />
              <Controller
                as={
                  <FormControl
                    margin="none"
                    required
                    fullWidth
                    error={Boolean(errors?.countryCode)}
                  >
                    <Select
                      variant="outlined"
                      native
                      inputProps={{
                        autoComplete: 'country',
                      }}
                      disabled={status.fetching}
                      placeholder="State"
                    >
                      {countryCodes.map(country => (
                        <option
                          value={country.value}
                          key={country.value}
                        >{`${country.label} (${country.value})`}</option>
                      ))}
                    </Select>
                  </FormControl>
                }
                name="countryCode"
                control={control}
                rules={{ required: 'Required' }}
              />
              <ErrorMessage as={<FormHelperText error />} errors={errors} name="countryCode" />
            </FormRow>
            {(Boolean(countryStates.length) || showZip) && (
              <FormRow>
                <LocationOnIcon />
                {Boolean(countryStates.length) && (
                  <Grid
                    item
                    direction="column"
                    style={{
                      flexGrow: 1,
                      marginRight: showZip ? '8px' : 0,
                    }}
                  >
                    <Controller
                      as={
                        <FormControl margin="none" error={Boolean(errors?.state)}>
                          <Select
                            variant="outlined"
                            native
                            inputProps={{
                              autoComplete: 'address-level1',
                            }}
                            disabled={status.fetching}
                          >
                            <option value="" key="select-a-state" disabled selected>
                              State
                            </option>
                            {countryStates.map(state => (
                              <option
                                value={state.value}
                                key={state.value}
                              >{`${state.label} (${state.value})`}</option>
                            ))}
                          </Select>
                        </FormControl>
                      }
                      name="state"
                      control={control}
                      rules={{ required: 'Required' }}
                    />
                    <ErrorMessage as={<FormHelperText error />} errors={errors} name="state" />
                  </Grid>
                )}
                {showZip && (
                  <Grid item direction="column">
                    <Controller
                      as={
                        <TextField
                          variant="outlined"
                          required
                          autoComplete="postal-code"
                          disabled={status.fetching}
                          error={Boolean(errors?.postalCode)}
                          placeholder="Postal Code"
                          margin="none"
                        />
                      }
                      name="postalCode"
                      control={control}
                      rules={{ required: 'Required' }}
                    />
                    <ErrorMessage as={<FormHelperText error />} errors={errors} name="postalCode" />
                  </Grid>
                )}
              </FormRow>
            )}
            {partnerEmail && (
              <FormRow>
                <Controller
                  render={({ onChange, onBlur, value, name }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          onBlur={onBlur}
                          onChange={e => onChange(e.target.checked)}
                          checked={value}
                          name={name}
                        />
                      }
                      label={
                        <Typography variant="caption">
                          {`Give my Barracuda partner, ${partnerEmail}, read-only access to my scan
                          results for 14 days so they can discuss the results with me.`}
                        </Typography>
                      }
                    />
                  )}
                  name="shareWithPartner"
                  control={control}
                />
              </FormRow>
            )}
            <div style={{ marginLeft: '32px' }}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                type="submit"
                disabled={status.fetching}
              >
                Complete Registration
              </Button>
            </div>
          </Grid>
        </Grid>
      </form>
      <Grid item container xs={12} justify="center">
        <Button
          variant="text"
          size="small"
          color="primary"
          onClick={(): void => {
            history.goBack();
          }}
        >
          <Typography variant="caption">CANCEL</Typography>
        </Button>
      </Grid>
      <EulaDialog
        open={showEula}
        onAccept={handleAccept}
        onClose={(): void => setShowEula(false)}
      />
    </>
  );
}
