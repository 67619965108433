import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import { SWITCH_ACCOUNT } from 'graphql/Auth';
import useRequest from 'hooks/useRequest';
import { RequestStatus } from 'types';
import { parseQS } from 'utils/params';

import useAuth from './useAuth';
import useMixpanel from './useMixpanel';

export default function useAccountSwitch(): [(accountId: string) => Promise<void>, RequestStatus] {
  const history = useHistory();
  const [switchAccount] = useMutation(SWITCH_ACCOUNT, { context: { clientName: 'accounts' } });
  const mixpanel = useMixpanel();
  const { updateAccount } = useAuth();

  const [switchAccountStatus, switchAccountStarted, switchAccountSucceeded, switchAccountFailed] =
    useRequest();
  const switchAuthAccount = async (accountId: string): Promise<void> => {
    switchAccountStarted();
    try {
      const { data } = await switchAccount({
        variables: {
          input: {
            // eslint-disable-next-line @typescript-eslint/camelcase
            account_id: accountId,
          },
        },
      });

      await updateAccount(data.authenticateAccount.api_token);

      switchAccountSucceeded();

      mixpanel.track(`Switched to account ${data.authenticateAccount?.account_name}`);

      const parsedQS = parseQS();
      const queryString = Object.entries(parsedQS)
        .filter(([key]) => key !== 'redirect')
        .map(([key, value]) => `${key}=${value}`)
        .join('&');
      const path = parsedQS.redirect || '/dashboard';

      history.push(`${path}?${queryString}`);
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      const error = e?.networkError?.result?.message || e?.message || 'Account switch failed';
      switchAccountFailed(error);
    }
  };

  return [switchAuthAccount, switchAccountStatus];
}
