/* eslint-disable import/order */
import React from 'react';

import { Button, ButtonGroup, Tooltip } from '@cuda-networks/bds-core';
import useAuth from 'hooks/useAuth';

export default function RBACButton({
  children,
  roles = ['administrator', 'manager'],
  actionLabel = 'do this.',
  buttonGroup = false,
  disabled,
  ...buttonProps
}): JSX.Element {
  const { account } = useAuth();

  const allowed = roles.includes((account?.role || 'readonly')?.toLowerCase());

  const button = buttonGroup ? (
    <ButtonGroup {...buttonProps} disabled={disabled || !allowed}>
      {children}
    </ButtonGroup>
  ) : (
    <Button {...buttonProps} disabled={disabled || !allowed}>
      {children}
    </Button>
  );

  return (
    <>
      {allowed ? (
        button
      ) : (
        <Tooltip
          title={`You do not have sufficient permissions on this account to ${actionLabel}`}
          arrow
        >
          <span style={{ display: 'inline-block' }}>{button}</span>
        </Tooltip>
      )}
    </>
  );
}
