import React, { useEffect, useState } from 'react';

import { Table, TableRow, TableCell, TableBody } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
import { Link } from 'react-router-dom';

import AccountNameWithIcon from 'components/common/AccountNameWithIcon';
import JSONTree from 'components/common/JSONTree';
import { ResourceDisplay } from 'components/Resources';
import { compressFilter } from 'hooks/useCompressedFilter';
import { assetNames, providerFromCloudId, regionNames } from 'lib';
import { GraphQLFilter, Resource } from 'types';

const useStyles = makeStyles(
  theme => ({
    actionContainer: {
      padding: '16px 24px',
      backgroundColor: theme.palette.background.default,
      borderBottom: '1px solid #DDDDDD',
      '& > button': {
        marginRight: 20,
      },
    },
    details: {
      padding: '16px 24px',
      overflowY: 'auto',
    },
  }),
  {
    name: 'ResourceDetails',
  },
);

interface ResourceDetailsProps {
  resource: Resource;
  loading: boolean;
}

export default function ResourceDetails(props: ResourceDetailsProps): React.ReactElement {
  const { resource, loading } = props;
  const classes = useStyles();

  const [filter, setFilter] = useState<string>();
  useEffect(() => {
    if (!resource.cloudId || !resource.resourceId) return;

    const generateFilter = async (): Promise<void> => {
      const newFilter: Record<string, GraphQLFilter | string> = {};

      if (resource.cloudId) {
        newFilter.accounts = {
          name: 'cloudId',
          value: [resource.cloudId],
        };
      }
      if (resource.resourceId) {
        newFilter.resource = {
          name: 'resource.id',
          value: resource.resourceId,
        };
      }
      if (resource.name) {
        newFilter.resourceQuery = resource.name;
      }

      const result = await compressFilter(newFilter);
      setFilter(result);
    };

    generateFilter();
  }, [resource.cloudId, resource.id, resource.name, resource.resourceId]);

  return (
    <>
      <div className={classes.details}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>{loading ? <Skeleton /> : 'Connection'}</TableCell>
              <TableCell>
                {loading ? <Skeleton /> : <AccountNameWithIcon accountId={resource?.cloudId} />}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{loading ? <Skeleton /> : 'Resource'}</TableCell>
              <TableCell>
                {loading ? (
                  <Skeleton />
                ) : (
                  <ResourceDisplay
                    provider={providerFromCloudId(resource?.cloudId)}
                    resource={resource}
                    noLeftPadding
                  />
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{loading ? <Skeleton /> : 'Region'}</TableCell>
              <TableCell>
                {loading ? <Skeleton /> : regionNames?.[resource?.location] ?? resource?.location}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{loading ? <Skeleton /> : 'Type'}</TableCell>
              <TableCell>
                {loading ? <Skeleton /> : assetNames?.[resource?.type] ?? resource?.type}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2}>
                {loading ? (
                  <Skeleton />
                ) : (
                  <>
                    <Link to={`/security-risks?filter=${filter}`} style={{ marginLeft: 10 }}>
                      See all risks for this resource
                    </Link>
                    <Link to={`/security-findings?filter=${filter}`} style={{ marginLeft: 10 }}>
                      See all findings for this resource
                    </Link>
                  </>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2}>
                {loading ? (
                  <Skeleton />
                ) : (
                  <JSONTree
                    rootName="More Details"
                    data={resource?.details ?? {}}
                    style={{ fontSize: '.8125rem' }}
                  />
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </>
  );
}
