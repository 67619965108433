import React, { useEffect, useState } from 'react';

import { Collapse, IconButton } from '@cuda-networks/bds-core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { Alert } from '@material-ui/lab';
import clsx from 'clsx';

import { ResultStatus } from 'types';

const useStyles = makeStyles(
  {
    animation: {
      width: '100%',
      '& > div': {
        width: '100%',
      },
    },
    wrapper: {
      alignItems: 'center',
      margin: '10px 0',
      boxShadow: 'none',
    },
    message: {
      padding: 0,
    },
  },
  {
    name: 'StatusMessage',
  },
);

interface StatusMessageProps {
  className?: string | undefined;
  message: React.ReactNode;
  status: ResultStatus;
  removable?: boolean;
  handleDismiss?: () => void;
  variant?: 'filled' | 'standard';
}

export default function StatusMessage(props: StatusMessageProps): JSX.Element {
  const {
    className = undefined,
    message,
    status,
    removable = false,
    handleDismiss,
    variant = 'standard',
  } = props;
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  useEffect(() => setOpen(Boolean(message)), [message]);

  const dismissError = (): void => {
    setOpen(false);
    if (handleDismiss) {
      handleDismiss();
    }
  };

  return (
    <Collapse in={open} classes={{ wrapper: classes.animation }}>
      <Alert
        severity={status}
        variant={variant}
        action={
          removable && (
            <IconButton aria-label="close" color="inherit" size="small" onClick={dismissError}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          )
        }
        className={clsx(className, classes.wrapper)}
        classes={{
          message: classes.message,
        }}
      >
        {message}
      </Alert>
    </Collapse>
  );
}
