import React from 'react';

import { Button, Grid, DialogActions, DialogContent } from '@cuda-networks/bds-core';

import LoadingIndicator from 'components/common/LoadingIndicator';
import StatusMessage from 'components/common/StatusMessage';

export default function Finished({ status, handleClose }) {
  return (
    <>
      <DialogContent style={{ display: 'flex', alignItems: 'center' }}>
        <Grid container item>
          {status.fetching && (
            <LoadingIndicator padded message="Submitting resource and security scans..." />
          )}
          {status.succeeded && <StatusMessage status="success" message={status.result} />}
          {status.error && <StatusMessage status="error" message={status.error} />}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus disabled={status.fetching}>
          Close
        </Button>
      </DialogActions>
    </>
  );
}
