import React, { useState, useMemo } from 'react';

import { Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import PropTypes from 'prop-types';

import AccountNameWithIcon from 'components/common/AccountNameWithIcon';
import PaginatedTable from 'components/common/PaginatedTable';
import NoResults from 'components/common/table/NoResults';
import TableHeaderCell from 'components/common/table/TableHeaderCell';
import Timestamp from 'components/common/Timestamp';
import { ResourceDisplay } from 'components/Resources';
import {
  FindingLabel,
  ControlResult,
  FindingDetailsDialog,
  ComplianceFromFinding,
} from 'components/SecurityFindings';
import useDialog from 'hooks/useDialog';

import { riskInfoFromFinding, sourceFromFinding, workflowStatus } from './lib';
import { regionNames } from 'lib';

export default function SecurityFindingsTable({
  findings,
  handleSort,
  sort,
  ...other
}): JSX.Element {
  const [selectedFinding, setSelectedFinding] = useState(null);
  const [dialogOpen, openDialog, closeDialog] = useDialog();
  const selectFinding = (f): void => {
    setSelectedFinding(f);
    openDialog();
  };
  const deselectFinding = (): void => {
    closeDialog();
    setSelectedFinding(null);
  };

  const colDefs = {
    resource: {
      sortable: false,
      sortLabel: 'resource.id',
      display: true,
      label: 'Resource',
      render: (f): JSX.Element => (
        <ResourceDisplay
          provider={f?.cloudProvider}
          resource={f?.resource}
          onClick={(): void => selectFinding(f)}
        />
      ),
    },
    source: {
      sortable: false,
      display: true,
      label: 'Source',
      render: (f): string => sourceFromFinding(f),
    },
    region: {
      sortable: false,
      sortLabel: 'resource.region',
      display: true,
      label: 'Region',
      render: (f): string => regionNames?.[f?.resource?.region] ?? f?.resource?.region,
    },
    title: {
      sortable: false,
      display: true,
      label: 'Title',
      render: (f): string => riskInfoFromFinding(f)?.title || f?.title || '',
    },
    standard: {
      sortable: true,
      sortLabel: 'compliance.standard,compliance.provider,compliance.index',
      display: false,
      label: 'Control',
      render: (f): JSX.Element => <ComplianceFromFinding finding={f} noVersion noSource />,
    },
    severity: {
      sortable: true,
      sortLabel: 'severity.index',
      display: true,
      label: 'Severity',
      render: (f): JSX.Element => <FindingLabel severity={f?.severity?.label ?? ''} noRounding />,
    },
    result: {
      sortable: true,
      sortLabel: 'compliance.status',
      display: true,
      label: 'Result',
      render: (f): JSX.Element => <ControlResult result={f?.compliance?.status ?? ''} />,
    },
    status: {
      sortable: true,
      sortLabel: 'workflow.status',
      display: true,
      label: 'Status',
      render: (f): string => workflowStatus(f?.workflow?.status?.toUpperCase()),
    },
    account: {
      sortable: true,
      sortLabel: 'cloudId',
      display: true,
      label: 'Connection',
      render: (f): JSX.Element => <AccountNameWithIcon accountId={f?.cloudId} />,
    },
    firstObservedAt: {
      sortable: true,
      sortLabel: 'firstObservedAt',
      display: false,
      label: 'First Observed',
      render: (f): JSX.Element => <Timestamp ts={f?.firstObservedAt} tsFormat="ISO" />,
    },
    lastObservedAt: {
      sortable: true,
      sortLabel: 'lastObservedAt',
      display: true,
      label: 'Last Observed',
      render: (f): JSX.Element => <Timestamp ts={f?.lastObservedAt} tsFormat="ISO" />,
    },
    updatedAt: {
      sortable: true,
      sortLabel: 'updatedAt',
      display: false,
      label: 'Updated',
      render: (f): JSX.Element => <Timestamp ts={f?.updatedAt} tsFormat="ISO" />,
    },
  };
  const cols = Object.keys(colDefs);
  const [colStatus, setColStatus] = useState(
    cols.reduce(
      (obj, col): void => ({
        ...obj,
        [col]: {
          label: colDefs?.[col]?.label,
          display: colDefs?.[col]?.display,
        },
      }),
      {},
    ),
  );
  const toggleColumn = (col, display): void => {
    setColStatus({
      ...colStatus,
      [col]: {
        ...(colStatus?.[col] ?? {}),
        display,
      },
    });
  };
  const activeColumns = useMemo(
    () => Object.keys(colStatus).filter(key => colStatus?.[key]?.display),
    [colStatus],
  );

  return (
    <>
      <PaginatedTable
        columns={colStatus}
        toggleColumn={toggleColumn}
        rowsPerPageOptions={[50]}
        data-test-id="security-findings-table"
        {...other}
      >
        <Table>
          <TableHead>
            <TableRow>
              {activeColumns.map(c => (
                <TableHeaderCell
                  key={c}
                  field={colDefs?.[c] ?? {}}
                  sort={sort}
                  handleSort={handleSort}
                  // className={colDefs?.[c]?.className ?? {}}
                  {...(colDefs?.[c]?.props ?? {})}
                />
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Boolean(findings?.length) &&
              findings.map((finding, index) => (
                <TableRow key={finding.id} data-test-id={`table-row-${index}`}>
                  {activeColumns.map(c => (
                    <TableCell
                      key={`${finding.id}-${c}`}
                      // className={colDefs?.[c]?.className ?? {}}
                      {...(colDefs?.[c]?.props ?? {})}
                    >
                      {colDefs[c]?.render(finding)}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            {!findings?.length && <NoResults colSpan={activeColumns.length} />}
          </TableBody>
        </Table>
      </PaginatedTable>
      {dialogOpen && (
        <FindingDetailsDialog
          open={dialogOpen}
          findingId={selectedFinding?.id}
          handleClose={closeDialog}
          onClose={deselectFinding}
          riskInfo={riskInfoFromFinding(selectedFinding)}
          resourceType="finding"
        />
      )}
    </>
  );
}

SecurityFindingsTable.propTypes = {
  findings: PropTypes.array,
  handleSort: PropTypes.func.isRequired,
  sort: PropTypes.shape({
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
  }).isRequired,
};

SecurityFindingsTable.defaultProps = {
  findings: [],
};
