import { gql, makeVar } from '@apollo/client';

import { BarracudaAccount } from 'types';

export const accountVar = makeVar<BarracudaAccount | null>(null);

export const GENERATE_ACCOUNT_TOKEN = gql`
  mutation generateAccountAccessToken {
    generateAccountAccessToken {
      api_token
    }
  }
`;

export const SWITCH_ACCOUNT = gql`
  mutation authenticateAccount($input: AuthenticateAccountInput!) {
    authenticateAccount(input: $input) {
      account_id
      account_name
      api_token
      mfa
      name
      role
    }
  }
`;
