import React, { useEffect } from 'react';

import { Grid, Typography, Link } from '@cuda-networks/bds-core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import PageTitle from 'components/common/PageTitle';
import ErrorBoundary from 'components/ErrorBoundary';
import { pages } from 'navigation';

const useStyles = makeStyles(
  {
    noCloudsText: {
      fontSize: '1.6em',
      color: '#888888',
      marginBottom: '16px',
    },
    getStartedText: {
      fontSize: '2.0em',
      color: '#888888',
    },
    padded: {
      padding: '0 15px 15px 15px',
    },
  },
  {
    name: 'Page',
  },
);

export default function Page({
  page,
  children,
  setup,
  handleGetStarted,
  padding = false,
}): JSX.Element {
  const classes = useStyles();
  const info = pages[page];

  useEffect(() => {
    document.title = `${info.title} - Cloud Security Guardian`;
  }, [info.title]);

  return (
    <>
      {!setup && info.accountRequired ? (
        <>
          <PageTitle title={info.title} />
          <Grid container item alignItems="center" direction="column">
            <Typography variant="body1" className={classes.noCloudsText}>
              You do not have any Cloud Connections configured.
            </Typography>
            <Typography variant="body1" className={classes.getStartedText}>
              <Link component="span" onClick={handleGetStarted} style={{ cursor: 'pointer' }}>
                Get Started
              </Link>
              &nbsp;with Cloud Security Guardian.
            </Typography>
          </Grid>
        </>
      ) : (
        <ErrorBoundary key={page}>
          {padding ? (
            <div className={clsx({ [classes.padded]: padding })}>{children}</div>
          ) : (
            children
          )}
        </ErrorBoundary>
      )}
    </>
  );
}
