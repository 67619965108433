import React from 'react';

import * as CoreIcons from '@cuda-networks/bds-core/dist/Icons/Core';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CloudIcon from '@material-ui/icons/Cloud';
import GroupIcon from '@material-ui/icons/Group';
import Person from '@material-ui/icons/Person';
import PolicyIcon from '@material-ui/icons/Policy';
import StorageIcon from '@material-ui/icons/Storage';
import VerifiedUser from '@material-ui/icons/VerifiedUser';

import { HowToRegIcon } from 'icons';
import { PageDefintion, NavigationPageTypes } from 'types';

export const pages: Record<string, PageDefintion> = {
  '/login': {
    title: 'Login',
    path: '/login',
  },
  '/admin/accounts': {
    title: 'Accounts',
    path: '/admin/accounts',
    id: 'admin-accounts',
    icon: <GroupIcon />,
    accountRequired: false,
  },
  '/admin/serials': {
    title: 'Serial Numbers',
    path: '/admin/serials',
    id: 'admin-serials',
    icon: <AttachMoneyIcon />,
    accountRequired: false,
  },
  '/admin/users': {
    title: 'Users',
    path: '/admin/users',
    id: 'admin-users',
    icon: <Person />,
    accountRequired: false,
  },
  '/admin/allowlist': {
    title: 'Allow List',
    path: '/admin/allowlist',
    id: 'admin-allowlist',
    icon: <HowToRegIcon />,
    accountRequired: false,
  },

  '/dashboard': {
    title: 'Dashboard',
    path: '/dashboard',
    id: 'dashboard',
    icon: <CoreIcons.Dashboard />,
    accountRequired: true,
  },
  '/security-findings': {
    title: 'Security Findings',
    path: '/security-findings',
    id: 'security-findings',
    icon: <AssignmentIcon />,
    accountRequired: true,
  },
  '/security-risks': {
    title: 'Security Risks',
    path: '/security-risks',
    id: 'security-risks',
    icon: <VerifiedUser />,
    badge: true,
    accountRequired: true,
  },
  '/reports': {
    title: 'Reports',
    path: '/reports',
    id: 'reports',
    icon: <CoreIcons.Assessment />,
    accountRequired: true,
  },
  '/security-policies': {
    title: 'Security Policies',
    path: '/security-policies',
    id: 'security-policies',
    icon: <PolicyIcon />,
    accountRequired: true,
  },
  '/resources': {
    title: 'Resources',
    path: '/resources',
    id: 'resources',
    icon: <StorageIcon />,
    accountRequired: true,
  },

  '/cloud-connections': {
    title: 'Cloud Connections',
    path: '/cloud-connections',
    id: 'cloud-connections',
    icon: <CloudIcon />,
    accountRequired: false,
  },
  '/license': {
    title: 'License Management',
    path: '/license',
    id: 'license',
    icon: <CoreIcons.Billing />,
    accountRequired: false,
  },
  '/users': {
    title: 'User Management',
    path: '/users',
    id: 'users',
    icon: <CoreIcons.UserGroup />,
    accountRequired: false,
  },
};

export const nav: NavigationPageTypes[] = [
  '/dashboard',
  '/security-risks',
  '/security-findings',
  '/reports',
  '/security-policies',
  '/resources',
  '/cloud-connections',
  '/license',
  '/users',
];

export const adminNav: NavigationPageTypes[] = [
  '/admin/accounts',
  // '/admin/users',
  '/admin/serials',
  // '/admin/allowlist',
];
