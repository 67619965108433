/* eslint-disable react/no-danger */
import React from 'react';

import { Button } from '@cuda-networks/bds-core';
import { makeStyles } from '@material-ui/core/styles';

import Well from 'components/common/Well';

const useStyles = makeStyles(
  () => ({
    wrapper: {
      padding: '16px 24px',
      display: 'flex',
      overflowY: 'hidden',
    },
    well: {
      overflowY: 'auto',
      width: '100%',
    },
  }),
  {
    name: 'Remediation',
  },
);

interface RemediationProps {
  remediation: string;
  generatorId?: string;
}

export default function Remediation(props: RemediationProps): React.ReactElement {
  const { remediation, generatorId } = props;
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Well className={classes.well}>
        {generatorId?.startsWith('bsbp') ? (
          <>
            <div dangerouslySetInnerHTML={{ __html: remediation }} />
            <Button
              style={{ marginTop: 10 }}
              onClick={(): void => {
                window.open('https://waas.barracudanetworks.com/start-trial/', '_blank');
              }}
            >
              Start a free trial of Barracuda WAF-as-a-Service
            </Button>
          </>
        ) : (
          <div dangerouslySetInnerHTML={{ __html: remediation }} />
        )}
      </Well>
    </div>
  );
}
