import React from 'react';

import { Button, Dialog, DialogActions, DialogContent } from '@cuda-networks/bds-core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import DialogTitle from 'components/common/DialogTitle';
import StatusMessage from 'components/common/StatusMessage';

const useStyles = makeStyles(
  theme => ({
    dialog: {
      minWidth: 400,
      width: '95%',
    },
    form: {
      overflowY: 'auto',
      marginBottom: '48px',
    },
    actions: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
    },
    fullWidthContent: {
      padding: '20px 0',
    },
  }),
  {
    name: 'FormDialog',
  },
);

export default function FormDialog({
  title,
  children,
  handleConfirm,
  status,
  resetForm,
  submitLabel,
  closeLabel,
  valid,
  onExited,
  fullWidthContent,
  handleClose,
  ...other
}): JSX.Element {
  const classes = useStyles();

  const handleSubmit = event => {
    event.preventDefault();
    handleConfirm();
  };

  const handleExit = () => {
    if (onExited) {
      onExited();
    }
    if (resetForm) {
      resetForm();
    }
  };

  return (
    <Dialog
      classes={{
        paper: classes.dialog,
      }}
      maxWidth="md"
      onExited={handleExit}
      onClose={handleClose}
      {...other}
    >
      <DialogTitle>{title}</DialogTitle>
      <form onSubmit={handleSubmit} className={classes.form} noValidate>
        <DialogContent
          classes={{
            root: clsx({ [classes.fullWidthContent]: fullWidthContent }),
          }}
        >
          {status.error && (
            <StatusMessage
              status="error"
              message={status.error}
              style={{ margin: '8px 24px' }}
              removable
            />
          )}
          {status.succeeded && status.result && (
            <StatusMessage status="success" message={status.result} removable />
          )}
          {children}
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button
            onClick={handleClose}
            color="secondary"
            disabled={status.fetching}
            data-test-id="dialog-button-close"
            variant="contained"
          >
            {closeLabel}
          </Button>
          {handleConfirm && (
            <Button
              onClick={handleConfirm}
              color="primary"
              autoFocus
              disabled={!valid}
              data-test-id="dialog-button-submit"
              variant="contained"
            >
              {submitLabel}
            </Button>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
}

FormDialog.props = {
  title: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitLabel: PropTypes.string,
  closeLabel: PropTypes.string,
};

FormDialog.defaultProps = {
  submitLabel: 'Save',
  closeLabel: 'Cancel',
};
