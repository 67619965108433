import React, { useState } from 'react';

import { useQuery, useMutation } from '@apollo/client';
import { Dialog, DialogContent, Tabs, Tab, Tooltip } from '@cuda-networks/bds-core';

import DialogTitle from 'components/common/DialogTitle';
import StatusMessage from 'components/common/StatusMessage';
import { FindingDetails, Remediation } from 'components/SecurityFindings';
import { RESOURCE } from 'graphql/Resources';
import { RISK_FINDING, SUPPRESS_FINDING, UNSUPPRESS_FINDING } from 'graphql/SecurityFindings';
import useRequest from 'hooks/useRequest';
import { RiskDetailsInfo } from 'types';

interface DetailsProps {
  findingId: string;
  onClose: () => void;
  riskInfo: RiskDetailsInfo;
  open: boolean;
  resourceType: 'risk' | 'finding';
}

export default function FindingDetailsDialog(props: DetailsProps): JSX.Element {
  const { findingId, onClose, riskInfo, open, resourceType } = props;
  const { loading, data, error } = useQuery(RISK_FINDING, {
    skip: !findingId,
    variables: {
      id: findingId,
    },
    fetchPolicy: 'cache-first',
    context: { clientName: 'findings' },
  });
  const { data: parentData, loading: parentLoading } = useQuery(RESOURCE, {
    skip: !data?.finding?.resource?.parentId,
    variables: {
      cloudId: data?.finding?.cloudId,
      resourceId: data?.finding?.resource?.parentId,
    },
    fetchPolicy: 'cache-first',
    context: { clientName: 'resources' },
  });

  const [tab, setTab] = useState('details');
  const updateTab = (e, tab): void => setTab(tab);

  const [suppressStatus, suppressStarted, suppressSucceeded, suppressFailed] = useRequest();
  const [suppressFinding] = useMutation(SUPPRESS_FINDING, {
    context: { clientName: 'findings' },
  });
  const [unsuppressFinding] = useMutation(UNSUPPRESS_FINDING, {
    context: { clientName: 'findings' },
  });
  const handleSuppress = (suppress: boolean) => async (): void => {
    suppressStarted();
    try {
      if (suppress) {
        await suppressFinding({ variables: { id: findingId } });
        suppressSucceeded('Finding suppressed');
      } else {
        await unsuppressFinding({ variables: { id: findingId } });
        suppressSucceeded('Finding unsuppressed');
      }
    } catch (e) {
      suppressFailed(
        e?.networkError?.result?.message ||
          `Failed to ${suppress ? 'suppress' : 'unsuppress'} finding`,
      );
    }
  };

  return (
    <Dialog maxWidth="md" fullWidth disableBackdropClick open={open} onClose={onClose}>
      <DialogTitle handleClose={onClose}>
        {resourceType === 'risk' ? 'Risk Details' : 'Finding Details'}
      </DialogTitle>
      <DialogContent
        style={{
          padding: 0,
          display: 'flex',
          height: '600px',
          flexDirection: 'column',
          overflowY: 'hidden',
        }}
      >
        {error && <StatusMessage status="error" message="Failed to load resource info" />}
        <Tabs
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          onChange={updateTab}
          scrollButtons="auto"
          variant="scrollable"
        >
          <Tab
            key="details"
            label="Details"
            value="details"
            disabled={loading}
            data-test-id="finding-details"
          />
          {riskInfo?.remediation?.console && (
            <Tab
              key="console"
              label="Console Remediation"
              value="console"
              disabled={loading}
              data-test-id="findings-console-remediation"
            />
          )}
          {data?.finding?.externalLink &&
            ['Azure Security Center', 'AWS Security Hub'].includes(riskInfo?.source) && (
              <Tab
                key="console"
                label="Console Remediation"
                value="externalLink"
                disabled={loading}
                data-test-id="findings-console-remediation"
              />
            )}
          {riskInfo?.remediation?.cli && (
            <Tab
              key="cli"
              label="CLI Remediation"
              value="cli"
              disabled={loading}
              data-test-id="findings-cli-remediation"
            />
          )}
          {(riskInfo?.remediation?.cli || riskInfo?.remediation?.console) && (
            <Tooltip
              title="Cloud Security Guardian has insufficient permissions to make changes to this account."
              arrow
              PopperProps={{
                'data-test-id': 'findings-automated-remediation-tooltip',
              }}
            >
              <span>
                <Tab key="automatic" label="Automated Remediation" value="automatic" disabled />
              </span>
            </Tooltip>
          )}
        </Tabs>
        {tab === 'details' && (
          <FindingDetails
            loading={loading || parentLoading}
            finding={data?.finding ?? {}}
            parentResource={parentData?.resource}
            handleSuppress={handleSuppress(true)}
            handleUnsuppress={handleSuppress(false)}
            suppressStatus={suppressStatus}
            resourceType={resourceType}
          />
        )}
        {tab === 'cli' && (
          <Remediation remediation={riskInfo.remediation.cli} generatorId={riskInfo.generatorId} />
        )}
        {tab === 'externalLink' && (
          <Remediation
            remediation={`To remediate this alert, click <a href=${data?.finding?.externalLink} target='_blank' rel='noopener noreferrer'>here to access it in ${riskInfo.source}</a> The Remediation section will link to remediation instructions.`}
            generatorId={riskInfo.generatorId}
          />
        )}
        {tab === 'console' && (
          <Remediation
            remediation={riskInfo.remediation.console}
            generatorId={riskInfo.generatorId}
          />
        )}
      </DialogContent>
    </Dialog>
  );
}
