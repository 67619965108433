import React, { useEffect, useMemo, useState } from 'react';

import { Tabs, Tab, TextField } from '@cuda-networks/bds-core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { policyControlTitle } from 'components/SecurityFindings/lib';
import { PolicyCatSelector } from 'components/SecurityPolicy';

const useStyles = makeStyles(
  {
    policyName: {
      paddingBottom: '20px',
    },
    defaultWarning: {
      backgroundColor: '#ebc063',
      borderColor: '#e8b64c',
      color: '#71510f',
      alignItems: 'center',
      display: 'flex',
      padding: '10px',
      marginBottom: '10px',
    },
    tab: {
      textTransform: 'none',
      minWidth: 80,
    },
  },
  {
    name: 'PolicyEditor',
  },
);

export default function PolicyEditor({
  rules,
  policy,
  handlePolicyUpdate,
  disabled = false,
}): JSX.Element {
  const classes = useStyles();

  const [benchmark, setBenchmark] = useState(null);
  const [benchmarks, setBenchmarks] = useState([]);

  useEffect(() => {
    const types = Object.keys(rules);
    setBenchmark(types[0]);
    setBenchmarks(types);
  }, [rules]);

  const selectedCount = useMemo(
    () => Object.values(policy?.benchmarks?.[benchmark] ?? {}).filter(rule => rule).length,
    [policy, benchmark],
  );
  const allCheckedStatus = useMemo(() => {
    if (selectedCount === 0) {
      return 'unchecked';
    }
    if (selectedCount === Object.keys(rules?.[benchmark] ?? {}).length) {
      return 'checked';
    }

    return 'indeterminate';
  }, [selectedCount, benchmark, rules]);

  const updateBenchmark = (e, type): void => setBenchmark(type);
  const updatePolicy = (updatedRules, value): void =>
    handlePolicyUpdate({
      ...policy,
      benchmarks: {
        ...policy.benchmarks,
        [benchmark]: {
          ...policy.benchmarks[benchmark],
          ...updatedRules.reduce((obj, rule): void => ({ ...obj, [rule]: value }), {}),
        },
      },
    });
  const updateName = (e): void =>
    handlePolicyUpdate({
      ...policy,
      name: e.target.value,
    });
  // const handleParamUpdate = rule => event => {
  //   updatePolicy({
  //     ...policy,
  //     params: {
  //       ...policy.params,
  //       [rule]: event.target.value,
  //     },
  //   });
  // };

  return (
    <>
      <TextField
        label="Policy Name"
        required
        value={policy.name}
        onChange={updateName}
        className={classes.policyName}
        fullWidth
        disabled={policy.default || disabled}
        margin="none"
      />

      <Tabs
        value={benchmark}
        indicatorColor="primary"
        textColor="primary"
        onChange={updateBenchmark}
      >
        {benchmarks.map(rule => (
          <Tab
            key={rule}
            label={`${policyControlTitle(rule)}`}
            classes={{
              root: classes.tab,
            }}
            value={rule}
          />
        ))}
      </Tabs>

      <PolicyCatSelector
        allCheckedStatus={allCheckedStatus}
        rules={Object.values(rules?.[benchmark] ?? {})}
        policy={policy?.benchmarks?.[benchmark] ?? {}}
        benchmark={benchmark}
        handlePolicyUpdate={updatePolicy}
        selectedCount={selectedCount}
        disabled={disabled}
        // handleParamUpdate={handleParamUpdate}
      />
    </>
  );
}

PolicyEditor.propTypes = {
  rules: PropTypes.object.isRequired,
  policy: PropTypes.shape({
    name: PropTypes.string.isRequired,
    default: PropTypes.bool.isRequired,
    benchmarks: PropTypes.object.isRequired,
  }).isRequired,
  handlePolicyUpdate: PropTypes.func.isRequired,
};

PolicyEditor.defaultProps = {};
