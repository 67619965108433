import React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  CircularProgress,
} from '@cuda-networks/bds-core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import DialogTitle from 'components/common/DialogTitle';
import JSONTree from 'components/common/JSONTree';
import StatusMessage from 'components/common/StatusMessage';

const useStyles = makeStyles(
  {
    dialog: {
      width: '70%',
    },
  },
  {
    name: 'AccountDetailsDialog',
  },
);

export default function AccountDetailsDialog({ open, handleClose, status }): JSX.Element {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      maxWidth="md"
      classes={{
        paper: classes.dialog,
      }}
      onClose={handleClose}
    >
      <DialogTitle handleClose={handleClose}>Account Details</DialogTitle>
      <DialogContent style={{ minHeight: '84px' }}>
        {status.fetching && <CircularProgress />}
        {status.error && <StatusMessage status="error" message={status.error} removable={false} />}
        {status.succeeded && (
          <JSONTree rootName="Account Details" data={status.result} initiallyExpanded />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

AccountDetailsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  status: PropTypes.shape({
    fetching: PropTypes.bool,
    error: PropTypes.string,
    succeeded: PropTypes.bool,
    response: PropTypes.object,
  }).isRequired,
};
