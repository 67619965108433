import React from 'react';

import { TableCell } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  {
    wrapper: {
      textAlign: 'right',
      whiteSpace: 'nowrap',
    },
  },
  {
    name: 'ActionCell',
  },
);

export default function ActionCell({ children }): JSX.Element {
  const classes = useStyles();

  return <TableCell className={classes.wrapper}>{children}</TableCell>;
}
