import React from 'react';

import { TableRow, TableCell } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(
  {
    noResults: {
      color: 'rgba(0,0,0,0.5)',
      fontSize: '12px',
      lineHeight: '14px',
      textAlign: 'center',
    },
  },
  {
    name: 'NoResults',
  },
);

export default function NoResults({ colSpan, value }): JSX.Element {
  const classes = useStyles();

  return (
    <TableRow>
      <TableCell className={classes.noResults} colSpan={colSpan}>
        {value}
      </TableCell>
    </TableRow>
  );
}
NoResults.propTypes = {
  colSpan: PropTypes.number.isRequired,
  value: PropTypes.string,
};
NoResults.defaultProps = {
  value: 'No results',
};
