import { useState, useEffect, useMemo } from 'react';

import { useQuery } from '@apollo/client';

import { LIST_CONNECTIONS } from 'graphql/Accounts';
import { Connection, ListConnectionsData } from 'types';

interface UserAccountsFilterProps {
  initial?: string[];
}
export default function useAccountsFilter(props?: UserAccountsFilterProps): {
  selectedAccounts?: Connection[];
  setAccounts: (accounts: Connection[]) => void;
  selectedAccount?: Connection;
  setAccount: (account: Connection) => void;
  accountsList: Connection[];
} {
  const { initial = [] } = props || {};
  const { data: listConnectionsData } = useQuery<ListConnectionsData>(LIST_CONNECTIONS, {
    context: { clientName: 'accounts' },
  });

  const accountsList = useMemo(
    () =>
      (listConnectionsData?.listConnections?.connections || [])
        .map(c => ({
          ...c,
          cloudType: c?.cloudId?.split(':')?.[0] || '',
          // eslint-disable-next-line @typescript-eslint/camelcase
          cloud_id: c.cloudId,
          type: c.cloudId,
          name: c.name || '',
        }))
        .sort((a, b) => {
          if (a.cloudType === b.cloudType) {
            return a.name.localeCompare(b.name);
          }

          return a.cloudType.localeCompare(b.cloudType);
        }),
    [listConnectionsData],
  );

  const [selectedAccounts, setAccounts] = useState<Connection[]>();
  const [selectedAccount, setAccount] = useState<Connection>();

  useEffect(() => {
    const initialAccounts = (initial || []).length
      ? accountsList.filter(a => initial.includes(a.type))
      : [...accountsList];

    setAccounts(initialAccounts);
    setAccount(initialAccounts[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountsList]);

  return {
    selectedAccounts,
    setAccounts,
    selectedAccount,
    setAccount,
    accountsList,
  };
}
