import { useState, useCallback } from 'react';

import { Request, RequestStatus } from 'types';

const defaultRequest: RequestStatus = {
  fetching: false,
  paginating: false,
  succeeded: false,
};

export default function useRequest(defaultValues = {}): Request {
  const [requestStatus, setRequestStatus] = useState({
    ...defaultRequest,
    ...defaultValues,
  });

  const requestStarted = useCallback(
    (): void =>
      setRequestStatus({
        fetching: true,
        paginating: false,
        succeeded: false,
      }),
    [],
  );

  const requestSucceeded = useCallback(
    (result?: React.ReactNode): void =>
      setRequestStatus({
        fetching: false,
        paginating: false,
        succeeded: true,
        result,
      }),
    [],
  );

  const requestFailed = useCallback(
    (error: string): void =>
      setRequestStatus({
        fetching: false,
        paginating: false,
        error,
        succeeded: false,
      }),
    [],
  );

  const requestMore = useCallback(
    (): void =>
      setRequestStatus({
        fetching: false,
        paginating: true,
        succeeded: false,
      }),
    [],
  );

  const requestReset = useCallback((): void => setRequestStatus(defaultRequest), []);

  return [
    requestStatus,
    requestStarted,
    requestSucceeded,
    requestFailed,
    requestMore,
    requestReset,
  ];
}
