import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import clsx from 'clsx';

import RBACButton from './RBACButton';

const useDeleteStyles = makeStyles(
  theme => ({
    text: {
      color: theme.palette.error.main,
    },
  }),
  {
    name: 'DeleteButton',
  },
);

export function DeleteButton({ className, children, label = 'Delete', ...props }): JSX.Element {
  const classes = useDeleteStyles();
  return (
    <RBACButton variant="text" className={clsx(classes.text, className)} {...props}>
      {children || (
        <>
          <DeleteIcon />
          {label}
        </>
      )}
    </RBACButton>
  );
}

const useEditStyles = makeStyles(
  theme => ({
    text: {
      color: theme.palette.info.main,
    },
  }),
  {
    name: 'EditButton',
  },
);

export function EditButton({ className, children, label = 'Edit', ...props }): JSX.Element {
  const classes = useEditStyles();
  return (
    <RBACButton variant="text" className={clsx(classes.text, className)} {...props}>
      {children || (
        <>
          <EditIcon />
          {label}
        </>
      )}
    </RBACButton>
  );
}
