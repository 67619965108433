import React from 'react';

import { Button, CircularProgress } from '@cuda-networks/bds-core';
import { CloudSecurityGuardian } from '@cuda-networks/bds-core/dist/Logos/Products';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import StatusMessage from 'components/common/StatusMessage';
import useAuth from 'hooks/useAuth';
import { RequestStatus } from 'types';

const useStyles = makeStyles(
  {
    wrapper: {
      display: 'flex',
      height: '100%',
    },
    loading: {
      flexDirection: 'column',
      alignItems: 'center',
      paddingTop: '20%',
    },
  },
  {
    name: 'LoadingScreen',
  },
);

interface LoadingScreenProps {
  status: RequestStatus;
}

export default function LoadingScreen(props: LoadingScreenProps): JSX.Element {
  const { status } = props;
  const classes = useStyles();

  const { signOut } = useAuth();

  return (
    <div className={clsx(classes.wrapper, classes.loading)}>
      <CloudSecurityGuardian style={{ height: 56 }} />
      {status.fetching && <CircularProgress />}
      {status.error && (
        <>
          <div style={{ width: '50%', minWidth: '400px' }}>
            <StatusMessage status="error" message={status.error} removable={false} />
          </div>
          <Button translate="Log Out" variant="text" onClick={signOut}>
            Log Out
          </Button>
        </>
      )}
    </div>
  );
}
