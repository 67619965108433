import React from 'react';

import { Typography } from '@cuda-networks/bds-core';
import { makeStyles } from '@material-ui/core/styles';

import StatusLabel from 'components/common/StatusLabel';
import { DisplayStatus, Serial, SerialStatus } from 'types';
import Timestamp from 'components/common/Timestamp';

const useStyles = makeStyles(
  {
    list: {
      listStyle: 'none',
      padding: 0,
      margin: 0,
      '& > li': {
        padding: '5px 10px',
      },
    },
    serial: {
      backgroundColor: '#fafafa',
      borderTop: '1px solid #EEEEEE',
      borderBottom: '1px solid #EEEEEE',
      height: 90,
    },
    header: {
      display: 'flex',
      '& > img': {
        height: '24px',
      },
      backgroundColor: '#FFFFFF',
    },
    timeInfo: {
      display: 'flex',
      flexWrap: 'wrap',
      '& > span': {
        flex: '0 0 50%',
        lineHeight: '22px',
      },
    },
  },
  {
    name: 'LicenseInfo',
  },
);

const statusTypes: Record<SerialStatus, DisplayStatus> = {
  TERMINATED: 'error',
  ACTIVE: 'success',
  TRIAL: 'success',
  EXPIRED: 'error',
};

interface LicenseInfoProps {
  license: Serial;
}

export default function LicenseInfo(props: LicenseInfoProps): React.ReactElement {
  const { license } = props;
  const classes = useStyles();

  return (
    <ul className={classes.list}>
      <li className={classes.header}>
        {license.type === 'aws' && (
          <img src="/images/logo-aws-marketplace.svg" alt="AWS Marketplace License" />
        )}
        {license.type === 'azure' && (
          <img src="/images/logo-azure-marketplace.svg" alt="Microsoft Azure Marketplace License" />
        )}
        {license.type !== 'azure' && license.type !== 'aws' && (
          <img src="/images/logo-barracuda-primary-2020.svg" alt="Barracuda License" />
        )}
      </li>
      <li className={classes.serial}>
        {license.type === 'TRIAL' ? (
          <Typography variant="h2">TRIAL</Typography>
        ) : (
          <>
            <Typography variant="h2">{license.serial}</Typography>
            <span>Serial Number</span>
          </>
        )}
      </li>
      <li className={classes.timeInfo}>
        <span>Status</span>
        <StatusLabel padding={false} status={statusTypes[license.status] || 'default'}>
          {license.status === 'TRIAL' ? 'ACTIVE' : license.status}
        </StatusLabel>
        <span>Added</span>
        <span>
          <Timestamp ts={license.addedAt} tsFormat="ISO" />
        </span>
        <span>Expiration</span>
        <span>
          {license.expiresAt ? <Timestamp ts={license.expiresAt} tsFormat="ISO" /> : 'NA'}
        </span>
      </li>
    </ul>
  );
}
