import { DateTime } from 'luxon';
import qs from 'qs';

import { PartnerInfo, SerialInfo } from 'types';

const readLocalStorage = (key: string): string | undefined =>
  localStorage.getItem(key) || undefined;

const readSessionStorage = (key: string): string | undefined =>
  sessionStorage.getItem(key) || undefined;

const parseQS = (): qs.ParsedQs => {
  return qs.parse(window.location.search, { ignoreQueryPrefix: true });
};

const parseParams = (): void => {
  const parsedQS = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  const local = DateTime.local();
  const expiry = local.plus({ days: 15 });

  ['partner_id', 'partner_email', 'campaign_id', 'pid', 'vid'].forEach(field => {
    if (parsedQS?.[field]) {
      localStorage.setItem(field, parsedQS?.[field] as unknown as string);
      localStorage.setItem('csg-fields-expiry', String(expiry.toMillis()));
    }
  });

  ['serial', 'linking_code'].forEach(field => {
    if (parsedQS?.[field]) {
      sessionStorage.setItem(field, parsedQS?.[field] as unknown as string);
    }
  });
};

const expired = (): boolean => {
  const expiry = Number(readLocalStorage('csg-fields-expiry') ?? 0);
  const local = DateTime.local();

  return !expiry || local.toMillis() > expiry;
};

const getSerialInfo = (): SerialInfo => {
  if (expired()) {
    removeSerialInfo();
    return {};
  }

  return {
    serial: readSessionStorage('serial'),
    linkingCode: readSessionStorage('linking_code'),
  };
};

const removeSerialInfo = (): void => {
  sessionStorage.removeItem('serial');
  sessionStorage.removeItem('linking_code');
};

const getPartnerInfo = (): PartnerInfo => {
  if (expired()) {
    removePartnerInfo();
    return {};
  }

  return {
    partnerId: readLocalStorage('partner_id'),
    partnerEmail: readLocalStorage('partner_email'),
    campaignId: readLocalStorage('campaign_id'),
    pid: readLocalStorage('pid'),
    vid: readLocalStorage('vid'),
  };
};

const removePartnerInfo = (): void => {
  localStorage.removeItem('partner_id');
  localStorage.removeItem('partner_email');
  localStorage.removeItem('campaign_id');
  localStorage.removeItem('pid');
  localStorage.removeItem('vid');
};

export { parseQS, getPartnerInfo, getSerialInfo, parseParams, removePartnerInfo, removeSerialInfo };
