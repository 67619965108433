import { saveAs } from 'file-saver';

import { CloudType, FindingSeverity, RiskDetailsInfo } from 'types';
import rules from 'utils/rules.json';

const providers = {
  aws: 'AWS',
  azure: 'Azure',
};
const fullName = {
  'cis-aws': 'CIS Amazon Web Services Foundations Benchmark',
  'cis-azure': 'CIS Microsoft Azure Foundations Benchmark',
  'hipaa-aws': 'HIPAA Security Rule',
  'hipaa-azure': 'HIPAA Security Rule',
  'nist-aws': 'NIST Special Publication 800-53 Revision 4',
  'nist-azure': 'NIST Special Publication 800-53 Revision 4',
  'pci_dss-aws': 'PCI DSS',
  'pci_dss-azure': 'PCI DSS',
  'bsbp-aws': 'Barracuda Security Best Practices',
  'bsbp-azure': 'Barracuda Security Best Practices',
};
const controlName = {
  pci_dss: 'PCI DSS',
  nist: 'NIST',
  hipaa: 'HIPAA',
  cis: 'CIS',
  bsbp: 'Barracuda',
};

export const controlFromGeneratorId = (
  generatorId = '',
  format = ['standard', 'version', 'ruleId'],
): string => {
  const [control, provider, version, ruleId] = generatorId.split(':');

  if (!generatorId) {
    return 'N/A';
  }

  const values = {
    fullName: `${fullName?.[`${control}-${provider}`]}`,
    standard: `${control === 'bsbp' ? '' : `${providers?.[provider]} `}${controlName?.[control]}`,
    version,
    ruleId: `${ruleId?.[0]?.toUpperCase()}${ruleId?.slice(1)}`,
    control: `${controlName?.[control]}`,
  };

  return format
    .filter(f => {
      if (!(control === 'cis' || control === 'bsbp') && f === 'version') {
        return false;
      }
      return true;
    })
    .map(f => values?.[f])
    .join(' ');
};

export const policyControlTitle = (generatorId: string): string => {
  const [control] = generatorId.split(':');

  if (control === 'bsbp') {
    return 'Barracuda Security Best Practices';
  }

  return controlFromGeneratorId(generatorId, ['standard', 'version']);
};

export const exportToJSON = (data = []): void => {
  const blob = new Blob([JSON.stringify(data)], { type: 'application/json;charset=utf-8' });
  saveAs(blob, 'security_findings.json');
};

export const severityList: { type: string; name: string }[] = [
  {
    type: 'CRITICAL',
    name: 'Critical',
  },
  {
    type: 'HIGH',
    name: 'High',
  },
  {
    type: 'MEDIUM',
    name: 'Medium',
  },
  {
    type: 'LOW',
    name: 'Low',
  },
  {
    type: 'INFORMATIONAL',
    name: 'Info',
  },
];

export const statusList: { type: string; name: string }[] = [
  {
    type: 'NEW',
    name: 'New',
  },
  {
    type: 'SUPPRESSED',
    name: 'Suppressed',
  },
  {
    type: 'RESOLVED',
    name: 'Resolved',
  },
];

export const sourceList: { type: string; name: string }[] = [
  {
    type: 'Barracuda Compliance Scanner',
    name: 'Barracuda Compliance Scanner',
  },
  {
    type: 'Azure Security Center',
    name: 'Azure Security Center',
  },
  {
    type: 'AWS Security Hub',
    name: 'AWS Security Hub',
  },
];

export const resultList: { type: string; name: string }[] = [
  {
    type: 'PASSED',
    name: 'Passed',
  },
  {
    type: 'FAILED',
    name: 'Failed',
  },
  // {
  //   type: 'WARNING',
  //   name: 'Warning',
  // },
  // {
  //   type: 'NOT_AVAILABLE',
  //   name: 'Not Available',
  // },
];

export const riskInfoFromFinding = (finding: {
  generatorId?: string;
  source?: string;
  title?: string;
  severity?: FindingSeverity;
}): RiskDetailsInfo => {
  const { source = '', generatorId = '', title, severity } = finding;

  let rule;
  if (['Azure Security Center', 'AWS Security Hub'].includes(source)) {
    rule = {
      title,
      severity,
      source,
      generatorId,
      provider: source === 'Azure Security Center' ? 'azure' : 'aws',
    };
  } else {
    const [standard, provider, version, control] = generatorId.split(':');

    rule = {
      ...(rules?.[standard]?.[provider]?.[version]?.[control] ?? {}),
      severity,
      generatorId,
      source: 'Barracuda Compliance Scanner',
      provider,
    };
  }

  return rule;
};

export const sourceFromFinding = (finding: { source?: string }): string => {
  const { source } = finding;

  return source || 'Barracuda Compliance Scanner';
};

export const typeToIcon: Record<CloudType, Record<string, string | Record<string, string>>> = {
  azure: {
    'microsoft.hdinsight/clusters': 'analytics-142-HD-Insight-Clusters.svg',
    'microsoft.datalakeanalytics/accounts': 'analytics-143-Data-Lake-Analytics.svg',
    'microsoft.eventhub/namespaces': 'analytics-144-Event-Hubs.svg',
    'microsoft.kusto/clusters': 'analytics-145-Azure-Data-Explorer-Clusters.svg',
    'microsoft.datafactory/datafactories': 'databases-126-Data-Factory.svg',
    'microsoft.streamanalytics/streamingjobs': 'analytics-147-Stream-Analytics.svg',
    'microsoft.analysisservices/servers': 'analytics-148-Analysis-Services.svg',
    'microsoft.eventhub/clusters': 'analytics-149-Event-Hub-Clusters.svg',
    'microsoft.datalakestore/accounts': 'storage-90-Data-Lake-Storage-Gen1.svg',
    'microsoft.operationalinsights/workspaces': 'analytics-151-Log-Analytics-Workspaces.svg',
    'microsoft.powerbidedicated/capacities': 'analytics-152-power-bi.svg',
    'microsoft.databricks/workspaces': 'analytics-153-databricks.svg',
    'microsoft.compute/virtualmachines': 'compute-21-Virtual-Machine.svg',
    'microsoft.classiccompute/virtualmachines': 'compute-28-Virtual-Machines-(Classic).svg',
    'microsoft.compute/virtualmachinescalesets': 'compute-34-VM-Scale-Sets.svg',
    'microsoft.containerservice/containerservices':
      'containers-107-Container-Services-(Deprecated).svg',
    '': 'security-572-ExtendedSecurityUpdates.svg',
    'microsoft.servicefabricmesh/applications': 'compute-24-Mesh-Applications.svg',
    'microsoft.classiccompute/domainnames': 'compute-30-Cloud-Services-(Classic).svg',
    'microsoft.compute/availabilitysets': 'compute-25-Availability-Sets.svg',
    'microsoft.compute/disks': 'compute-32-Disks.svg',
    'microsoft.classicstorage/storageaccounts/disks': 'compute-32-Disks',
    'microsoft.compute/snapshots': 'compute-26-Disks-Snapshots.svg',
    'microsoft.compute/images': 'compute-33-Images.svg',
    'microsoft.compute/galleries/images': 'compute-37-Image-Definitions.svg',
    'microsoft.compute/galleries/images/versions': 'compute-38-Image-Versions.svg',
    'microsoft.compute/galleries': 'compute-39-Shared-Image-Galleries.svg',
    'microsoft.classicstorage/storageaccounts/osimages': 'compute-27-OS-Images-(Classic).svg',
    'microsoft.classicstorage/storageaccounts/vmimages': 'compute-40-VM-Images-(Classic).svg',
    'microsoft.containerservice/managedclusters': 'containers-101-Kubernetes-Services.svg',
    'microsoft.batch/batchaccounts': 'containers-102-Batch-Accounts.svg',
    'microsoft.web/sites': {
      app: 'web-41-App-Services.svg',
      default: 'web-41-App-Services.svg',
      functionapp: 'compute-29-Function-Apps.svg',
    },
    'microsoft.containerinstance/containergroups': 'containers-104-Container-Instances.svg',
    'microsoft.containerregistry/registries': 'containers-105-Container-Registries.svg',
    'microsoft.servicefabric/clusters': 'containers-106-Service-Fabric-Clusters.svg',
    'microsoft.documentdb/databaseaccounts': 'databases-121-Azure-Cosmos-DB.svg',
    'microsoft.sql/azuresql': 'databases-130-SQL-Database.svg',
    'microsoft.sql/servers/databases': 'databases-130-SQL-Database.svg',
    'microsoft.dbformysql/servers': 'databases-122-Azure-Database-MySQL-Server.svg',
    'microsoft.dbforpostgresql/servers': 'databases-131-Azure-Database-PostgreSQL-Server.svg',
    'microsoft.dbformariadb/servers': 'databases-123-Azure-Database-MariaDB-Server.svg',
    'microsoft.sql/servers': 'databases-132-SQL-Server.svg',
    'microsoft.sql/servers/databases/kind/v12.0%2cuser%2cdatawarehouse':
      'databases-138-Azure-Synapse.svg',
    'microsoft.datamigration/services': 'databases-133-Azure-Database-Migration-Services.svg',
    'microsoft.cache/redis': 'databases-137-Cache-Redis.svg',
    'microsoft.sql/servers/databases/kind/v12.0%2cuser%2cstretch':
      'integration-208-SQL-Server-Stretch-Databases.svg',
    'microsoft.sql/servers/elasticpools': 'databases-134-SQL-Elastic-Pools.svg',
    'microsoft.sql/virtualclusters': 'databases-127-Virtual-Clusters.svg',
    'microsoft.sql/managedinstances/databases': 'databases-135-Managed-Database.svg',
    'microsoft.sql/servers/jobagents': 'databases-128-Elastic-Job-Agents.svg',
    'microsoft.sql/managedinstances': 'databases-136-SQL-Managed-Instance.svg',
    'microsoft.sqlvirtualmachine/sqlvirtualmachines': 'databases-124-Azure-SQL-VM.svg',
    'microsoft.azuredata/sqlserverregistrations': 'other-351-SQL-Server-Registries.svg',
    'microsoft.subscription/subscriptions': 'general-2-Subscriptions.svg',
    'microsoft.subscription/locations': 'general-2-Subscriptions.svg',
    user: 'identity-230-Users.svg',
    'microsoft.authorization/roledefinitions': 'intune-340-Roles.svg',
    'microsoft.network/virtualnetworks': 'networking-61-Virtual-Networks.svg',
    'microsoft.network/loadbalancers': 'networking-62-Load-Balancers.svg',
    'microsoft.network/virtualnetworkgateways': 'networking-63-Virtual-Network-Gateways.svg',
    'microsoft.network/localnetworkgateways': 'networking-77-Local-Network-Gateways.svg',
    'microsoft.cdn/profiles': 'web-43-CDN-Profiles.svg',
    'microsoft.network/expressroutecircuits': 'networking-79-ExpressRoute-Circuits.svg',
    'microsoft.network/networkwatchers': 'networking-66-Network-Watcher.svg',
    'microsoft.network/networksecuritygroups': 'networking-67-Network-Security-Groups.svg',
    'microsoft.network/networkinterfaces': 'networking-80-Network-Interfaces.svg',
    'microsoft.network/publicipaddresses': 'networking-69-Public-IP-Addresses.svg',
    'microsoft.network/publicipprefixes': 'networking-372-Public-IP-Prefixes.svg',
    'microsoft.network/connections': 'networking-81-Connections.svg',
    'microsoft.web/connectiongateways': 'networking-70-On-Premises-Data-Gateways.svg',
    'microsoft.network/routetables': 'networking-82-Route-Tables.svg',
    'microsoft.network/routefilters': 'networking-71-Route-Filters.svg',
    'microsoft.network/applicationsecuritygroups': 'networking-83-Application-Security-Groups.svg',
    'microsoft.network/ddosprotectionplans': 'networking-72-DDoS-Protection-Plans.svg',
    'microsoft.network/serviceendpointpolicies': 'networking-85-Service-Endpoint-Policies.svg',
    'microsoft.network/privatednszones': 'networking-64-DNS-Zones.svg',
    'microsoft.network/frontdoors/frontendendpoints': 'networking-73-Front-Doors.svg',
    'microsoft.network/applicationgateways': 'networking-76-Application-Gateways.svg',
    'microsoft.network/frontdoorwebapplicationfirewallpolicies':
      'networking-362-FrontDoor-WAF-Policies.svg',
    'microsoft.network/privatelinkservices': 'networking-private-link.svg',
    'microsoft.network/virtualwans': 'networking-74-Virtual-WAN-Gateway.svg',
    'microsoft.network/bastionhosts': 'networking-bastion.svg',
    'microsoft.network/dnszones': 'networking-64-DNS-Zones.svg',
    'microsoft.security/autoprovisioningsettings': 'security-241-Security-Center.svg',
    'microsoft.keyvault/vaults': 'security-245-Key-Vaults.svg',
    'microsoft.keyvault/vaults/secrets': 'security-245-Key-Vaults.svg',
    'microsoft.keyvault/vaults/keys': 'security-245-Key-Vaults.svg',
    'microsoft.storage/storageaccounts': 'storage-86-Storage-Accounts.svg',
    'microsoft.storage/storageaccounts/blobservices/containers': 'storage-86-Storage-Accounts.svg',
    'microsoft.classicstorage/storageaccounts': 'storage-87-Storage-Accounts-(Classic).svg',
    'microsoft.recoveryservices/vaults': 'storage-88-Recovery-Services-Vaults.svg',
    'microsoft.storsimple/managers': 'storage-89-StorSimple-Device-Managers.svg',
    'microsoft.hybriddata/datamanagers': 'storage-92-StorSimple-Data-Managers.svg',
    'microsoft.storagesync/storagesyncservices': 'storage-93-Storage-Sync-Services.svg',
    'microsoft.databox/jobs': 'storage-94-Data-Box.svg',
    'microsoft.databoxedge/databoxedgedevices': 'storage-95-Data-Box-Edge.svg',
    'microsoft.netapp/netappaccounts': 'storage-96-Azure-NetApp-Files.svg',
    'microsoft.datashare/accounts': 'storage-98-Data-Shares.svg',
    'microsoft.importexport/jobs': 'storage-100-Import-Export-Jobs.svg',
    'microsoft.storagecache/caches': 'storage-776-AzureHCPCache.svg',
    'microsoft.apimanagement/service': 'web-42-API-Management-Services.svg',
    'microsoft.search/searchservices': 'web-44-Search-Services.svg',
    'microsoft.notificationhubs/namespaces/notificationhubs': 'web-45-Notification-Hubs.svg',
    'microsoft.notificationhubs/namespaces': 'web-53-Notification-Hub-Namespaces.svg',
    'microsoft.web/serverfarms': {
      app: 'web-46-App-Service-Plans.svg',
      default: 'web-46-App-Service-Plans.svg',
    },
    'microsoft.web/hostingenvironments': 'web-47-App-Service-Environments.svg',
    'microsoft.certificateregistration/certificateorders': 'web-49-App-Service-Certificates.svg',
    'microsoft.domainregistration/domains': 'web-50-App-Service-Domains.svg',
    'microsoft.media/mediaservices': 'web-51-Media-Services.svg',
    'microsoft.signalrservice/signalr': 'web-52-SignalR.svg',

    'microsoft.network/dnszones/a': 'networking-64-DNS-Zones.svg',
    'microsoft.network/dnszones/aaaa': 'networking-64-DNS-Zones.svg',
    'microsoft.network/dnszones/cname': 'networking-64-DNS-Zones.svg',
  },
  aws: {
    securitygroup: 'Security-Identity-and-Compliance.svg',
    vpc: 'Amazon-VPC.svg',

    's3:bucket': 'Amazon-S3-Bucket.svg',
    'elasticloadbalancing:loadbalancer': 'Elastic-Load-Balancing.svg',
    'elasticbeanstalk:application': 'Elastic-Beanstalk-container_light-bg.svg',
    'ec2:vpc': 'Amazon-VPC.svg',
    'ec2:security-group': 'Amazon-VPC.svg',
    'ec2:network-interface': 'Amazon-VPC_Elastic-Network-Interface_light-bg.svg',
    'ec2:instance': 'Amazon-EC2_light-bg.svg',

    kmskey: 'Arch_AWS-Key-Management-Services_64.svg',

    apigateway: 'Amazon-API-Gateway_light-bg.svg',
    route53: 'Amazon-Route-53_light-bg.svg',
    cloudfront: 'Amazon-CloudFront_light-bg.svg',

    // 'redshift:cluster':
    // 'rds:db':
  },
};

const delimiters = {
  azure: '/',
  aws: ':',
};

export const nameFromId = (id = '', provider): string => {
  try {
    let split = [];
    if (provider === 'aws') {
      const splitLabel = id.split(delimiters?.[provider] ?? ':');
      split = splitLabel[splitLabel.length - 1].split('/');
    } else if (provider === 'azure') {
      split = id.split(delimiters?.[provider] ?? ':');
    }

    return split[split.length - 1];
  } catch (e) {
    return id;
  }
};

export const nameFromResource = (
  resource: {
    type: string;
    resourceId: string;
    id: string;
    name?: string;
    displayName?: string;
    details?: {
      name?: string;
      displayName?: string;
    };
  },
  provider: 'aws' | 'azure',
): string => {
  const label =
    resource?.displayName ||
    resource?.name ||
    nameFromId(resource?.resourceId || resource?.id, provider);

  return label;
};

const statusLabels = {
  NEW: 'New',
  SUPPRESSED: 'Suppressed',
  RESOLVED: 'Resolved',
};

export const workflowStatus = (status: 'NEW' | 'SUPPRESSED' | 'RESOLVED'): string =>
  statusLabels?.[status] || '-';
