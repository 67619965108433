import React from 'react';

import { useMutation, gql } from '@apollo/client';

import { UserFormDialog } from 'components/Users';
import { CREATE_USER } from 'graphql/Users';
import useRequest from 'hooks/useRequest';
import { User } from 'types';

interface UserAddDialogProps {
  user: User;
  open: boolean;
  handleClose: () => void;
}

export default function UserAddDialog(props: UserAddDialogProps): React.ReactElement {
  const { user, open, handleClose } = props;

  const [userStatus, userStarted, userSucceeded, userFailed] = useRequest();

  const [createUser] = useMutation(CREATE_USER, {
    context: { clientName: 'accounts' },
    update(cache, { data: { createUser: created } }) {
      cache.modify({
        fields: {
          users(existing = []): User[] {
            const newUserRef = cache.writeFragment({
              data: created,
              fragment: gql`
                fragment NewUser on User {
                  id
                  email
                  role
                  pending
                }
              `,
            });
            return existing.concat(newUserRef);
          },
        },
      });
    },
  });

  const handleConfirm = async (newUser: User): Promise<void> => {
    userStarted();
    try {
      const { data } = await createUser({
        variables: {
          input: {
            email: newUser.email.trim(),
            role: newUser.role,
          },
        },
      });

      if (data?.createUser === null) {
        throw new Error('Failed to create user');
      }

      userSucceeded();
      handleClose();
    } catch (e) {
      userFailed(e?.networkError?.result?.message || e?.message || 'Failed to create user');
    }
  };

  return (
    <UserFormDialog
      user={user}
      open={open}
      handleClose={handleClose}
      status={userStatus}
      handleConfirm={handleConfirm}
      loadStatus={{
        succeeded: true,
      }}
    />
  );
}
