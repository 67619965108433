import React from 'react';

import { useMutation } from '@apollo/client';
import { TextField } from '@cuda-networks/bds-core';
import { ErrorMessage } from '@hookform/error-message';
import { FormHelperText } from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';

import FormDialog from 'components/common/FormDialog';
import { UPDATE_CONNECTION } from 'graphql/Accounts';
import useRequest from 'hooks/useRequest';
import { Connection, UpdateConnectionData, UpdateConnectionInput } from 'types';

interface EditConnectionDialogProps {
  connection: Connection;
  open: boolean;
  handleClose(): void;
}

export default function EditConnectionDialog(props: EditConnectionDialogProps): JSX.Element {
  const { connection, open, handleClose } = props;

  const { handleSubmit, control, errors, formState } = useForm({
    defaultValues: {
      name: connection.name,
    },
  });
  const { isDirty } = formState;

  const [updateConnection] = useMutation<UpdateConnectionData, UpdateConnectionInput>(
    UPDATE_CONNECTION,
    {
      context: { clientName: 'accounts' },
    },
  );
  const [
    updateConnectionStatus,
    updateConnectionStarted,
    updateConnectionSucceeded,
    updateConnectionFailed,
  ] = useRequest();
  const onSubmit = async (data: { id: string; name: string }): Promise<void> => {
    updateConnectionStarted();

    try {
      await updateConnection({
        variables: {
          input: {
            connectionId: connection.id,
            name: data.name,
          },
        },
      });

      updateConnectionSucceeded();
      handleClose();
    } catch (e) {
      updateConnectionFailed(
        e?.networkError?.result?.message || e?.message || 'Failed to update cloud connection.',
      );
    }
  };

  return (
    <FormDialog
      title="Edit Cloud Connection"
      submitLabel="Save Changes"
      handleConfirm={handleSubmit(onSubmit)}
      status={updateConnectionStatus}
      valid={isDirty}
      maxWidth="sm"
      fullWidth
      open={open}
      handleClose={handleClose}
    >
      <Controller
        as={
          <TextField
            label="Connection Alias"
            required
            disabled={updateConnectionStatus.fetching}
            fullWidth
            margin="none"
            name="name"
            error={Boolean(errors?.name)}
          />
        }
        name="name"
        control={control}
        rules={{ required: 'Required' }}
      />
      <ErrorMessage as={<FormHelperText error />} errors={errors} name="name" />
    </FormDialog>
  );
}
