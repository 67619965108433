import React, { useState } from 'react';

import {
  Button,
  Checkbox,
  IconButton,
  Typography,
  Divider,
  Tooltip,
} from '@cuda-networks/bds-core';
import { FormControlLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import { FixedSizeTree } from 'react-vtree';

const useStyles = makeStyles(
  theme => ({
    row: {
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
      },
    },
    checkbox: {
      display: 'flex',
      alignItems: 'center',
      flex: 1,
      '& > span': {
        marginRight: '5px',
        padding: 0,
      },
    },
    optionWrapper: {
      padding: '6px 16px',
      width: '100%',
      display: 'flex',
    },
    optionValue: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      '&:hover': {
        '& > $onlyButton': {
          visibility: 'visible',
        },
      },
      '& > $onlyButton': {
        visibility: 'hidden',
        minWidth: 'unset',
        // padding: '1px',
        lineHeight: '1rem',
      },
    },
    onlyButton: {},
  }),
  {
    name: 'StandardTree',
  },
);

export default function StandardTree({
  tree,
  selectedMap,
  setSelectedMap,
  handleClose,
  height = 400,
  width = 450,
  openByDefault = false,
  updateParentsOnSelect = true,
  disableChildren = false,
}): JSX.Element {
  const classes = useStyles();

  const [disabledMap, setDisabledMap] = useState({});

  function* treeWalker(refresh) {
    const stack = [];

    stack.push({
      nestingLevel: 0,
      node: tree,
    });

    while (stack.length !== 0) {
      const {
        node: { children = [], id, name, disabled, disabledReason },
        nestingLevel,
      } = stack.pop();

      const isOpened = yield refresh
        ? {
            // The only difference VariableSizeTree `treeWalker` has comparing to
            // the FixedSizeTree is the `defaultHeight` property in the data
            // object.
            defaultHeight: 42,
            id,
            isLeaf: children.length === 0,
            isOpenByDefault: openByDefault || id === 'root', // false,
            name,
            disabled,
            disabledReason,
            nestingLevel,
          }
        : id;

      if (children.length !== 0 && isOpened) {
        for (let i = children.length - 1; i >= 0; i--) {
          stack.push({
            nestingLevel: nestingLevel + 1,
            node: children[i],
          });
        }
      }
    }
  }
  const getNodeById = (node, id) => {
    if (node.id === id) {
      return node;
    }
    if (Array.isArray(node.children)) {
      for (let i = 0; i < node.children.length; ++i) {
        const child = getNodeById(node.children[i], id);

        if (child) {
          return child;
        }
      }
    }
  };

  const updateChildren = (node, obj, checked, disable = false) => {
    let children = {};
    if (Array.isArray(node.children)) {
      children = node.children.reduce((cur, n) => updateChildren(n, cur, checked, checked), {});
    }

    return {
      ...obj,
      ...children,
      ...(!node.disabled && { [node.id]: checked ? 'checked' : 'unchecked' }),
    };
  };

  const selectedChildren = (node, selected, checked) => {
    if (node?.children) {
      return node.children.some(n => selectedChildren(n, selected, checked));
    }

    return checked ? selected?.[node.id] === 'checked' : selected?.[node.id] !== 'checked';
  };

  const updateParents = (id, selected) => {
    const node = getNodeById(tree, id);
    const selectedChild = selectedChildren(node, selected, true);
    const unselectedChild = selectedChildren(node, selected, false);

    let value;
    if (selectedChild && unselectedChild) {
      value = 'indeterminate';
    } else if (updateParentsOnSelect) {
      value = selectedChild ? 'checked' : 'unchecked';
    } else {
      return;
    }

    if (node?.parent) {
      return updateParents(node.parent, {
        ...selected,
        [id]: value,
      });
    }

    return {
      ...selected,
      [id]: value,
    };
  };

  const handleChange = (checked, id, only = false): void => {
    const node = getNodeById(tree, id);
    const existing = only ? {} : selectedMap;

    const updated = updateChildren(node, {}, checked);

    if (disableChildren) {
      setDisabledMap({
        ...disabledMap,
        ...Object.keys(updated).reduce((obj, key) => {
          if ((node.parent === 'root' || !node.parent) && checked) {
            return obj;
          }

          return {
            ...obj,
            [key]: key !== id && checked && node.parent !== 'root',
          };
        }, {}),
      });
    }

    if (node?.parent && updateParentsOnSelect) {
      setSelectedMap(updateParents(node.parent, { ...existing, ...updated }));
    } else {
      setSelectedMap({ ...existing, ...updated });
    }

    if (only) {
      handleClose();
    }
  };

  // Node component receives current node height as a prop
  const Node = ({
    data: { isLeaf, name, id, nestingLevel, disabled, disabledReason },
    height,
    isOpen,
    style,
    toggle,
  }): JSX.Element => {
    const result = (
      <div
        className={id !== 'root' ? classes.row : undefined}
        style={{
          ...style,
          width: '100%',
          alignItems: 'center',
          display: 'flex',
        }}
      >
        {!isLeaf && id !== 'root' && (
          <IconButton
            onClick={toggle}
            size="small"
            style={{
              marginRight: '12px',
              padding: '0px',
              marginLeft: (nestingLevel === 1 ? 1 : nestingLevel) * 16 + (isLeaf ? 46 : 0) || 0,
            }}
          >
            {isOpen ? <ExpandMoreIcon /> : <ChevronRightIcon />}
          </IconButton>
        )}
        {id === 'root' ? (
          <div style={{ display: 'flex', margin: '8px' }}>
            <Button
              variant="text"
              size="small"
              onClick={() => handleChange(true, id)}
              style={{ minWidth: 'auto' }}
            >
              All
            </Button>
            <Divider orientation="vertical" flexItem />
            <Button
              variant="text"
              size="small"
              onClick={() => handleChange(false, id)}
              style={{ minWidth: 'auto' }}
            >
              None
            </Button>
          </div>
        ) : (
          <span
            className={clsx(classes.optionValue, classes.optionWrapper)}
            style={{
              flex: 1,
              marginLeft: isLeaf
                ? (nestingLevel === 1 ? 1 : nestingLevel) * 16 + (isLeaf ? 25 : 0)
                : -11,
            }}
          >
            <span className={classes.checkbox}>
              <FormControlLabel
                style={{ width: '100%' }}
                control={
                  <Checkbox
                    color="primary"
                    checked={selectedMap?.[id] === 'checked'}
                    indeterminate={selectedMap?.[id] === 'indeterminate'}
                    onChange={e => handleChange(e.currentTarget.checked, id)}
                    disabled={disabled || disabledMap?.[id]}
                    onClick={e => e.stopPropagation()}
                  />
                }
                label={<Typography variant="body2">{name}</Typography>}
              />
            </span>
            {!(disabled || disabledMap?.[id]) && (
              <Button
                className={classes.onlyButton}
                variant="text"
                name="only"
                onClick={e => handleChange(true, id, true)}
                style={{ zIndex: 1 }}
              >
                <span name="only">Only</span>
              </Button>
            )}
          </span>
        )}
      </div>
    );

    return disabled ? <Tooltip title={disabledReason}>{result}</Tooltip> : result;
  };

  return (
    <FixedSizeTree treeWalker={treeWalker} itemSize={42} height={height} width={width}>
      {Node}
    </FixedSizeTree>
  );
}
