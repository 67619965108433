import React, { useCallback, useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import { Button, CircularProgress, TextField } from '@cuda-networks/bds-core';
import { makeStyles } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import clsx from 'clsx';

import { ResourceDisplay } from 'components/Resources';
import { FILTER_RESOURCES } from 'graphql/SecurityFindings';
import useCloudNames from 'hooks/useCloudNames';
import { Connection, FindingResource, ResourceFilter } from 'types';

const debounce = require('lodash.debounce');

const allOption = {
  type: 'all',
  id: 'all',
  name: 'All',
};

const useStyles = makeStyles(
  {
    checkbox: {
      display: 'flex',
      alignItems: 'center',
      flex: 1,
      '& > span': {
        marginRight: '5px',
        padding: 0,
      },
    },
    optionWrapper: {
      padding: '6px 16px',
      width: '100%',
      display: 'flex',
    },
    optionValue: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      '&:hover': {
        '& > $onlyButton': {
          visibility: 'visible',
        },
      },
      '& > $onlyButton': {
        visibility: 'hidden',
        minWidth: 'unset',
        // padding: '1px',
        lineHeight: '1rem',
      },
    },
    onlyButton: {},
    listbox: {
      paddingTop: 0,
      paddingBottom: 0,
      '& > ul': {
        margin: 0,
      },
    },
    paper: {
      marginTop: 0,
    },
    option: {
      padding: '0 !important',
      '&[aria-selected="true"]': {
        backgroundColor: '#FFFFFF',
      },
      '&[data-focus="true"]': {
        backgroundColor: '#FFFFFF',
        '& > $optionWrapper': {
          backgroundColor: 'rgba(0, 0, 0, 0.08)',
        },
      },
    },
    tag: {
      margin: '0 3px',
      height: '22px',
      '& > svg': {
        height: '18px',
        width: '18px',
      },
    },
    input: {
      paddingRight: '28px',
    },
    inputRoot: {
      padding: 8,
    },
  },
  {
    name: 'Autocomplete',
  },
);

interface ResourcesAutocompleteProps {
  selected: FindingResource;
  setSelected: (selected: FindingResource) => void;
  selectedAccounts: Connection[];
  disabled?: boolean;
}

interface AutocompleteFindingResource extends FindingResource {
  cloudName: string;
}

export default function ResourcesAutocomplete(
  props: ResourcesAutocompleteProps,
): React.ReactElement {
  const { selected, setSelected, selectedAccounts, disabled = false } = props;
  const classes = useStyles();
  const [options, setOptions] = useState<{ type: string; name: string; id: string }[]>([selected]);
  const cloudNames = useCloudNames();

  const [getResources, { loading }] = useLazyQuery(FILTER_RESOURCES, {
    fetchPolicy: 'cache-first',
    context: { clientName: 'findings' },
    onCompleted: (resources: { resources: FindingResource[] }) => {
      setOptions([
        allOption,
        ...resources.resources
          .map(a => ({
            ...a,
            cloudName: cloudNames?.[a.cloudId]?.name || a.cloudId,
            name: a?.displayName || a?.name || '',
          }))
          .sort((a, b) => a.cloudName.localeCompare(b.cloudName)),
      ]);
    },
  });

  const update = useCallback(
    (inputValue: string) => {
      if (!selectedAccounts) {
        return;
      }
      const where: ResourceFilter[] = [
        {
          name: 'cloudId',
          value: (selectedAccounts ?? []).map(a => a.cloudId),
        },
      ];

      if (inputValue) {
        where.push({
          name: 'resource.name',
          value: inputValue,
          operator: 'contains',
        });
      }

      getResources({
        variables: {
          cursor: { offset: 0, limit: 100 },
          filter: {
            orderBy: ['name ASC'],
            where,
          },
        },
      });
    },
    [getResources, selectedAccounts],
  );

  const debouncedUpdate = useCallback(
    debounce(update, 1000, {
      leading: true,
    }),
    [selectedAccounts],
  );

  useEffect(() => {
    if (!selected.type || !selected.name) {
      return;
    }
    debouncedUpdate(selected.type === 'all' ? '' : selected.name);
    debouncedUpdate.flush();
  }, [debouncedUpdate, selected.name, selected.type]);

  const [inputValue, setInputValue] = useState('');

  return (
    <Autocomplete
      id="ruleset-combo"
      classes={{
        listbox: classes.listbox,
        paper: classes.paper,
        tag: classes.tag,
        option: classes.option,
        input: classes.inputRoot,
      }}
      options={options}
      disabled={disabled}
      loading={loading && !options.length}
      getOptionLabel={(option): string => option?.name || ''}
      getOptionSelected={(option, value): boolean => option.id === value.id}
      disableClearable
      onInputChange={(_event: unknown, newInputValue: string): void => {
        debouncedUpdate(newInputValue);
        setInputValue(newInputValue);
      }}
      groupBy={(option: AutocompleteFindingResource): string => option.cloudName}
      renderInput={(params): React.ReactElement => (
        <TextField
          {...params}
          ref={params.InputProps.ref}
          variant="outlined"
          label="Resource"
          margin="none"
          inputProps={{
            ...(params.inputProps || {}),
            style: {
              padding: '8px',
            },
          }}
          InputLabelProps={{
            shrink: true,
            // style: {},
          }}
          autoFocus
          InputProps={{
            ...(params.InputProps || {}),
            notched: false,
            startAdornment: (params.InputProps || {}).startAdornment,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
            style: {
              paddingTop: '0',
              paddingBottom: '0',
              minHeight: '32px',
            },
          }}
          data-test-id="dropdown-selected"
        />
      )}
      inputValue={inputValue}
      value={selected}
      renderOption={(option: AutocompleteFindingResource): React.ReactElement | string => {
        if (option.type === 'all') {
          return (
            <div style={{ display: 'flex', margin: '8px' }}>
              <Button
                variant="text"
                size="small"
                style={{ minWidth: 'auto', pointerEvents: 'none' }}
              >
                All
              </Button>
            </div>
          );
        }
        return (
          <span className={clsx(classes.optionValue, classes.optionWrapper)}>
            <ResourceDisplay provider={option.provider} resource={option} />
          </span>
        );
      }}
      onChange={(e, value): void => {
        setSelected(value);
      }}
      onOpen={(): void => {
        update('');
        setInputValue('');
      }}
      style={{
        width: 300,
      }}
    />
  );
}
