import { useEffect } from 'react';

import { QueryResult } from '@apollo/client';

import { RequestStatus } from 'types';

import useRequest from './useRequest';

export default function useQueryRequest(response: QueryResult): [RequestStatus] {
  const [status, start, succeeded, failed, paginate] = useRequest({
    fetching: true,
  });

  useEffect(() => {
    const { networkStatus, error } = response;

    if (!status.paginating && (networkStatus === 1 || networkStatus === 2 || networkStatus === 4)) {
      start();
    } else if (networkStatus === 3) {
      paginate();
    } else if (networkStatus === 7) {
      succeeded();
    } else if (networkStatus === 8) {
      failed('Request failed');
    }
  }, [response.networkStatus, status.paginating]);

  return [status];
}
