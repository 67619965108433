// @ts-nocheck
import { useCallback, useEffect, useMemo, useState } from 'react';

import { useQuery } from '@apollo/client';
import lunr from 'lunr';

import { severityList, resultList, statusList, sourceList } from 'components/SecurityFindings/lib';
import { RESOURCE_TYPES } from 'graphql/SecurityFindings';
import useAccountsFilter from 'hooks/useAccountsFilter';
import useCompressedFilter from 'hooks/useCompressedFilter';
import useRules from 'hooks/useRules';
import { assetNames } from 'lib';
import { AutocompleteOption } from 'types';
import rules from 'utils/rules.json';

const idx = lunr(function parseLunr() {
  this.ref('rule_id');
  this.field('title');
  this.field('description');

  Object.values(rules.cis.aws['v1.2.0']).forEach(doc => {
    this.add(doc);
  }, this);
  Object.values(rules.cis.azure['v1.1.0']).forEach(doc => {
    this.add(doc);
  }, this);
  Object.values(rules.nist.aws['v1.0.0']).forEach(doc => {
    this.add(doc);
  }, this);
  Object.values(rules.nist.azure['v1.0.0']).forEach(doc => {
    this.add(doc);
  }, this);
  Object.values(rules.pci_dss.aws['v1.0.0']).forEach(doc => {
    this.add(doc);
  }, this);
  Object.values(rules.pci_dss.azure['v1.0.0']).forEach(doc => {
    this.add(doc);
  }, this);
  Object.values(rules.hipaa.aws['v1.0.0']).forEach(doc => {
    this.add(doc);
  }, this);
  Object.values(rules.hipaa.azure['v1.0.0']).forEach(doc => {
    this.add(doc);
  }, this);
});

export type Standard =
  | 'cis:aws:v1.2.0'
  | 'cis:azure:v1.1.0'
  | 'hipaa:aws:v1.0.0'
  | 'hipaa:azure:v1.0.0'
  | 'nist:aws:v1.0.0'
  | 'nist:azure:v1.0.0'
  | 'pci_dss:aws:v1.0.0'
  | 'pci_dss:azure:v1.0.0'
  | 'bsbp:aws:v1.0.0'
  | 'bsbp:azure:v1.0.0';

export interface SelectedRule {
  standard: string;
  provider: string;
  version: string;
  indexes?: number[];
  notIndexes?: number[];
}

export interface FindingsFilters {
  query?: any;
  accounts?: any;
  filters?: any;
  severity?: any;
  result?: any;
  status?: any;
  source?: any;
  resourceType?: any;
  resource?: any;
  resourceQuery?: string;
  selectedRules?: SelectedRule[];
}

export const filtersFromSelectedControls = controls => {
  const complianceCounts: { [s in Standard]?: number } = {};
  const compliance = controls
    .filter(c => Boolean(c))
    .reduce(
      (obj, c) => {
        const [control, provider, version, id] = c.split(':');
        const key: Standard = `${control}:${provider}:${version}`;
        const rule = rules?.[control]?.[provider]?.[version]?.[id];

        if (!complianceCounts[key]) {
          complianceCounts[key] = Object.values(rules?.[control]?.[provider]?.[version]).filter(
            r => !r.unsupported,
          ).length;
        }

        if (!rule) {
          return obj;
        }
        return {
          ...obj,
          [key]: [...(obj[key] || []), rule.index],
        };
      },
      {
        'cis:aws:v1.2.0': [],
        'cis:azure:v1.1.0': [],
        'hipaa:aws:v1.0.0': [],
        'hipaa:azure:v1.0.0': [],
        'nist:aws:v1.0.0': [],
        'nist:azure:v1.0.0': [],
        'pci_dss:aws:v1.0.0': [],
        'pci_dss:azure:v1.0.0': [],
        'bsbp:aws:v1.0.0': [],
        'bsbp:azure:v1.0.0': [],
      },
    );

  const filters = Object.entries(compliance)
    .filter(([, indexes]) => indexes.length)
    .map(([key, indexes]) => {
      const [standard, provider, version] = key.split(':');

      let indexesClause = {};
      if (indexes.length !== complianceCounts[key]) {
        if (indexes.length >= Math.floor(complianceCounts[key] / 2)) {
          const notIndexes = Object.values(rules?.[standard]?.[provider]?.[version] || {})
            .map(r => r.index)
            .filter(i => !indexes.includes(i));

          indexesClause = { notIndexes };
        } else {
          indexesClause = { indexes };
        }
      }

      return {
        standard,
        provider,
        version,
        ...indexesClause,
      };
    });

  return filters;
};

export default function useFindingsFilter(enabledFilters): any {
  const [filter, storeFilter] = useCompressedFilter('csg-findings-filter');

  const [rulesList] = useRules();

  const [initialized, setInitialized] = useState(false);

  // accounts
  const { accountsList } = useAccountsFilter();
  const [selectedAccounts, setAccounts] = useState([]);
  // control query
  const [query, setQuery] = useState<string>('');
  // rules
  const [selectedRules, setRules] = useState<string[]>([]);
  // severities
  const [selectedSeverity, setSeverity] = useState<AutocompleteOption[]>([]);
  // results
  const [selectedResults, setResults] = useState<AutocompleteOption[]>([]);
  // status
  const [selectedStatus, setStatus] = useState<AutocompleteOption[]>([]);
  // resource types
  const [selectedResourceTypes, setResourceTypes] = useState([]);
  const [resourceTypesList, setResourceTypesList] = useState([]);
  // resource
  const [selectedResource, setResource] = useState({});
  // sources
  const [selectedSources, setSources] = useState<AutocompleteOption[]>([]);

  const { data: resourceTypesData, error: resourceTypesError } = useQuery(RESOURCE_TYPES, {
    fetchPolicy: 'cache-first',
    context: { clientName: 'findings' },
    variables: {
      filter: {
        where: [
          {
            name: 'cloudId',
            value: (accountsList ?? []).map(a => a.type),
          },
        ],
      },
    },
  });

  useEffect(() => {
    if ((!resourceTypesData && !resourceTypesError) || !accountsList) {
      return;
    }

    const updateState = (storedFilter: FindingsFilters = {}): void => {
      // accounts
      setAccounts(
        storedFilter?.accounts?.value
          ? accountsList.filter(a => filter?.accounts?.value.includes(a.type))
          : accountsList,
      );
      // control query
      setQuery(storedFilter?.query || '');
      // rules
      setRules(
        storedFilter?.selectedRules
          ? (storedFilter?.selectedRules || []).reduce((arr, control) => {
              const { standard, provider, version } = control;
              const rulesByIndex = Object.values(rules?.[standard]?.[provider]?.[version]);

              const response = [...arr];

              if (control?.indexes) {
                control.indexes.forEach(i => response.push(rulesByIndex[i].rule_id));
              } else if (control?.notIndexes) {
                rulesByIndex.forEach(r => {
                  if (!r.unsupported && !control.notIndexes.includes(r.index)) {
                    response.push(r.rule_id);
                  }
                });
              } else {
                rulesByIndex.forEach(r => {
                  if (!r.unsupported) {
                    response.push(r.rule_id);
                  }
                });
              }

              return response;
            }, [])
          : [...rulesList],
      );
      // severities
      setSeverity(
        storedFilter?.severity?.value
          ? severityList.filter(v => storedFilter?.severity?.value?.includes(v.type))
          : severityList,
      );
      // results
      setResults(
        storedFilter?.result?.value
          ? resultList.filter(v => storedFilter?.result?.value?.includes(v.type))
          : resultList,
      );
      // status
      setStatus(
        storedFilter?.status?.value
          ? statusList.filter(v => storedFilter?.status?.value?.includes(v.type))
          : statusList,
      );
      // sources
      let filterSources: string[] = [];
      if (storedFilter?.source?.operator === 'NOT IN') {
        filterSources = sourceList
          .map(s => s.type)
          .filter(s => !(storedFilter?.source?.value || []).includes(s));
      } else {
        filterSources = storedFilter?.source?.value || sourceList.map(s => s.type);
      }
      setSources(sourceList.filter(s => filterSources.includes(s.type)));

      const filteredResourceTypes = (resourceTypesData?.resourceTypes || []).filter(
        r => !(r.provider === 'azure' && !r.type.startsWith('microsoft')),
      );

      // resource types
      const typesList = filteredResourceTypes
        .map(r => ({
          type: r.type,
          cloud: r.provider === 'azure' ? 'Microsoft Azure' : 'Amazon Web Services',
          name: assetNames?.[r.type] || r.type,
        }))
        .sort((a, b) => {
          if (a.cloud === b.cloud) {
            return a.name.localeCompare(b.name);
          }
          return a.cloud.localeCompare(b.cloud);
        });
      setResourceTypesList(typesList);

      setResourceTypes(
        (storedFilter?.resourceType
          ? filteredResourceTypes.filter(r =>
              (storedFilter?.resourceType?.value || []).includes(r.type),
            )
          : filteredResourceTypes
        ).map(r => ({
          type: r.type,
          name: assetNames?.[r.type] || r.type,
          cloud: r.provider === 'azure' ? 'Microsoft Azure' : 'Amazon Web Services',
        })),
      );

      // resource
      setResource(
        storedFilter?.resource
          ? {
              id: storedFilter?.resource?.value,
              name: storedFilter?.resourceQuery,
            }
          : {
              type: 'all',
              name: 'All',
            },
      );
      setInitialized(true);
    };

    try {
      updateState(filter);
    } catch (e) {
      updateState();
    }
  }, [resourceTypesData, resourceTypesError, accountsList, rulesList, filter]);

  const filterClauses = useMemo(() => {
    if (
      (enabledFilters.resourceTypes && !resourceTypesData && !resourceTypesError) ||
      !initialized
    ) {
      return;
    }
    let searchControls: string[];
    if (query) {
      const result = idx.search(query);
      searchControls = result.map(r => r.ref);
    } else {
      searchControls = [];
    }

    const controls = searchControls.length
      ? (selectedRules || []).filter(r => searchControls.includes(r))
      : selectedRules || [];

    const clauses: {
      accounts: any;
      compliance?: any;
      severity?: any;
      result?: any;
      status?: any;
      resourceType?: any;
      resource?: any;
      source?: any;
    } = {
      accounts: {
        name: 'cloudId',
        value: (selectedAccounts || []).map(a => a.type),
      },
    };

    const response = {};

    if (
      controls.length &&
      controls.length !== (rulesList || []).length &&
      enabledFilters.controls
    ) {
      response.filters = filtersFromSelectedControls(controls);
    }

    if (selectedSeverity.length !== severityList.length && enabledFilters.severities) {
      clauses.severity = {
        name: 'severity.label',
        value: (selectedSeverity || []).map(v => v.type),
      };
    }
    if (selectedResults.length !== resultList.length && enabledFilters.results) {
      clauses.result = {
        name: 'compliance.status',
        value: (selectedResults || []).map(v => v.type),
      };
    }
    if (selectedStatus.length !== statusList.length && enabledFilters.statuses) {
      clauses.status = {
        name: 'workflow.status',
        value: (selectedStatus || []).map(v => v.type),
      };
    }
    if (selectedSources.length !== sourceList.length && enabledFilters.sources) {
      const selected = selectedSources.map(s => s.type);
      if (selected.includes('Barracuda Compliance Scanner')) {
        clauses.source = {
          name: 'source',
          value: sourceList.filter(s => !selected.includes(s.type)).map(s => s.type),
          operator: 'NOT IN',
        };
      } else {
        clauses.source = {
          name: 'source',
          value: selected,
        };
      }
    }

    const filteredResourceTypes = (resourceTypesData?.resourceTypes || []).filter(
      r => !(r.provider === 'azure' && !r.type.startsWith('microsoft')),
    );
    if (
      resourceTypesData &&
      selectedResourceTypes?.length !== filteredResourceTypes?.length &&
      enabledFilters.resourceTypes
    ) {
      clauses.resourceType = {
        name: 'resource.type',
        value: (selectedResourceTypes || []).map(t => t.type),
      };
    }
    if (selectedResource && selectedResource?.type !== 'all' && enabledFilters.resources) {
      clauses.resource = {
        name: 'resource.id',
        value: selectedResource.id,
      };
    }

    storeFilter({
      ...clauses,
      ...(selectedResource && { resourceQuery: selectedResource.name }),
      ...(query && { query }),
      ...(selectedRules?.length && { selectedRules: filtersFromSelectedControls(selectedRules) }),
    });

    response.filter = Object.values(clauses);

    return response;
  }, [
    enabledFilters.resourceTypes,
    enabledFilters.controls,
    enabledFilters.severities,
    enabledFilters.results,
    enabledFilters.statuses,
    enabledFilters.sources,
    enabledFilters.resources,
    resourceTypesData,
    resourceTypesError,
    initialized,
    query,
    selectedRules,
    selectedAccounts,
    rulesList,
    selectedSeverity,
    selectedResults,
    selectedStatus,
    selectedSources,
    selectedResourceTypes,
    selectedResource,
    storeFilter,
  ]);

  const resetFilters = useCallback(() => {
    setQuery('');
    setRules([...rulesList]);
    setAccounts([...accountsList]);
    setSeverity([...severityList]);
    setResults([...resultList]);
    setStatus([...statusList]);
    setSources([...sourceList]);
    setResourceTypes([...resourceTypesList]);
    setResource({
      type: 'all',
      name: 'All',
    });
  }, [accountsList, resourceTypesList, rulesList]);

  return {
    selectedSeverity,
    setSeverity,
    selectedRules,
    setRules,
    selectedResults,
    setResults,
    selectedStatus,
    setStatus,
    selectedResourceTypes,
    setResourceTypes,
    selectedAccounts,
    setAccounts,
    query,
    setQuery,
    selectedResource,
    setResource,
    selectedSources,
    setSources,

    initialized,

    resourceTypes: resourceTypesList,

    resetFilters,
    filterClauses,
  };
}
