import React, { useState } from 'react';

import {
  AppBar,
  Button,
  Drawer,
  Hidden,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  IconButton,
} from '@cuda-networks/bds-core';
import { makeStyles } from '@material-ui/core/styles';
import AccountIcon from '@material-ui/icons/AccountCircle';
import LockIcon from '@material-ui/icons/LockOpen';
import MenuIcon from '@material-ui/icons/Menu';
import { useHistory, Switch, Route } from 'react-router-dom';

import { Accounts, Serials } from 'components/Admin';
import NavDrawer from 'components/NavDrawer';
import Page from 'components/Page';
import useAuth from 'hooks/useAuth';
import { adminNav } from 'navigation';

const drawerWidth = 320;

const useStyles = makeStyles(
  theme => ({
    wrapper: {
      display: 'flex',
      height: '100%',
    },
    loading: {
      flexDirection: 'column',
      alignItems: 'center',
    },
    toolbar: {
      paddingLeft: 0,
    },
    appBar: {
      marginLeft: drawerWidth,
      backgroundColor: theme.palette.primary.main,
      boxShadow: 'none',
    },
    drawer: {
      [theme.breakpoints.up('md')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
      position: 'absolute',
      top: '52px',
      bottom: 0,
    },
    content: {
      [theme.breakpoints.up('md')]: {
        marginLeft: drawerWidth,
      },
      flexGrow: 1,
      marginTop: '52px',
      padding: '15px',
      position: 'relative',
      overflowY: 'auto',
    },
    menuButton: {
      marginRight: 20,
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    menuSettingsIcon: {
      height: '42px',
      width: '42px',
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'rgba(0, 0, 0, 0.54)',
    },
    drawerPaper: {
      width: drawerWidth,
      position: 'absolute',
      top: 0,
      bottom: 0,
      [theme.breakpoints.down('sm')]: {
        top: 52,
      },
    },

    productTitle: {
      flex: '1 1',
      [theme.breakpoints.up('md')]: {
        marginLeft: 20,
      },
    },
    toolbarButton: {
      color: '#FFFFFF',
      textTransform: 'none',
      margin: '8px',
      '& svg': {
        marginRight: 5,
      },
      '&:hover': {
        backgroundColor: 'rgba(158,158,158,0.2)',
      },
    },
    userMenu: {
      '& svg': {
        height: '42px',
        width: '42px',
        padding: '9px 9px 9px 0',
      },
    },
  }),
  {
    name: 'Main',
  },
);

export default function Admin(): JSX.Element {
  const history = useHistory();
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { account, signOut } = useAuth();

  const handleDrawerToggle = (): void => {
    setDrawerOpen(!drawerOpen);
  };

  const [anchorEls, setAnchorEls] = React.useState({});

  const openMenu = menu => event => setAnchorEls({ [menu]: event.currentTarget });
  const handleClose = () => setAnchorEls({});

  return (
    <div className={classes.wrapper}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbar} variant="dense">
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>

          <Typography variant="body1" color="inherit" noWrap className={classes.productTitle}>
            Barracuda Cloud Security Guardian - Admin Tools
          </Typography>

          <Button
            variant="text"
            aria-controls="user-menu"
            aria-haspopup="true"
            onClick={openMenu('user')}
            className={classes.toolbarButton}
          >
            <AccountIcon />
            {account.name}
          </Button>
          <Menu
            id="user-menu"
            anchorEl={anchorEls.user}
            keepMounted
            open={Boolean(anchorEls.user)}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                signOut();
                handleClose();
                history.push('/');
              }}
              className={classes.userMenu}
            >
              <LockIcon />
              <Typography variant="body1">Log Out</Typography>
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <div className={classes.drawer}>
        <Hidden mdUp>
          <Drawer
            variant="temporary"
            anchor="left"
            open={drawerOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <NavDrawer open={drawerOpen} handleClick={handleDrawerToggle} nav={adminNav} />
          </Drawer>
        </Hidden>
        <Hidden smDown>
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            <NavDrawer open nav={adminNav} />
          </Drawer>
        </Hidden>
      </div>
      <div className={classes.content}>
        <Switch>
          <Route
            exact
            path={['/admin', '/admin/accounts']}
            render={() => (
              <Page page="/admin/accounts">
                <Accounts />
              </Page>
            )}
          />
          <Route
            exact
            path="/admin/serials"
            render={() => (
              <Page page="/admin/serials">
                <Serials />
              </Page>
            )}
          />
        </Switch>
      </div>
    </div>
  );
}
