import React from 'react';

import { TablePagination } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import PaginatorActions from './PaginatorActions';

const useStyles = makeStyles(
  {
    toolbar: {
      height: '48px',
      minHeight: '48px',
    },
    paginateRoot: {
      border: 0,
    },
  },
  {
    name: 'SeparatePaginator',
  },
);

export default function SeparatePaginator({
  rowsPerPageOptions,
  rowsPerPage,
  page,
  count,
  handleChangePage,
  handleChangeRowsPerPage,
}): JSX.Element {
  const classes = useStyles();
  return (
    <>
      <TablePagination
        classes={{
          toolbar: classes.toolbar,
          root: classes.paginateRoot,
        }}
        colSpan={1}
        count={count}
        disabled
        component="div"
        rowsPerPageOptions={rowsPerPageOptions}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          'aria-label': 'Previous Page',
          'data-test-id': 'previous-page',
        }}
        nextIconButtonProps={{
          'aria-label': 'Next Page',
          'data-test-id': 'next-page',
        }}
        SelectProps={{
          'data-test-id': 'selections-wrapper',
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        ActionsComponent={PaginatorActions}
      />
    </>
  );
}
SeparatePaginator.propTypes = {
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  rowsPerPage: PropTypes.number,
  page: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
};
SeparatePaginator.defaultProps = {
  rowsPerPageOptions: [20, 50, 100],
  rowsPerPage: 50,
};
