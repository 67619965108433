import React, { useEffect, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import {
  Button,
  Grid,
  Typography,
  DialogActions,
  DialogContent,
  CircularProgress,
} from '@cuda-networks/bds-core';
import { makeStyles } from '@material-ui/core/styles';
import LaunchIcon from '@material-ui/icons/Launch';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import StatusMessage from 'components/common/StatusMessage';
import { CREATE_TEMPLATE, LIST_CONNECTIONS } from 'graphql/Accounts';
import useMixpanel from 'hooks/useMixpanel';
import useOnboard from 'hooks/useOnboard';
import useRequest from 'hooks/useRequest';
import { ContentCopyIcon } from 'icons';
import { Connection, CreateTemplateData, ListConnectionsData } from 'types';

import { DeployCloudHeader } from '.';

const useStyles = makeStyles(
  {
    container: {
      '& > div': {
        marginBottom: '20px',
      },
    },
    text: {
      fontSize: '1.2rem',
    },
    list: {
      padding: '0 0 0 24px',
      margin: 0,
      fontSize: '.75rem',
      '& li': {
        marginBottom: 8,
      },
      '& li > p': {
        margin: 0,
        '&$listItem': {
          margin: 0,
        },
      },
    },
    dialogActions: {
      justifyContent: 'space-between',
      padding: '8px 24px',
    },
  },
  {
    name: 'ChooseProvider',
  },
);

interface DeployAWSProps {
  handleClose(): void;
}

export default function DeployAWS(props: DeployAWSProps): JSX.Element {
  const { handleClose } = props;
  const classes = useStyles();
  const mixpanel = useMixpanel();

  const [templateUrl, setTemplateUrl] = useState<string>();
  const [startingConns, setStartingConns] = useState<string[] | null>(null);
  const [pollInterval, setPollInterval] = useState<number>(0);
  const [addCloudStatus, addCloudStarted, addCloudSucceeded, addCloudFailed] = useRequest();
  const [submitScans] = useOnboard();
  const [started, setStarted] = useState<boolean>(false);

  const [createTemplate, { data }] = useMutation<CreateTemplateData>(CREATE_TEMPLATE, {
    context: { clientName: 'accounts' },
  });
  const { data: connectionsData } = useQuery<ListConnectionsData>(LIST_CONNECTIONS, {
    fetchPolicy: 'network-only',
    context: { clientName: 'accounts' },
    pollInterval,
  });

  useEffect(() => {
    setTemplateUrl(data?.createTemplate || '');
  }, [data]);

  useEffect(() => {
    createTemplate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!connectionsData) {
      return;
    }
    const awsConns: Connection[] = (connectionsData?.listConnections?.connections || []).filter(
      (c: Connection) => c.provider === 'aws',
    );
    if (startingConns === null) {
      setStartingConns(awsConns.map(c => c.cloudId));
    } else if (startingConns !== null && pollInterval && awsConns.length > startingConns.length) {
      setPollInterval(0);
      addCloudSucceeded();
      const onboarded = awsConns.map(c => c.cloudId).filter(c => !startingConns.includes(c));
      submitScans(onboarded);
      mixpanel.track('Successfully found new connection', {
        connectionIds: onboarded,
      });
    }
  }, [addCloudSucceeded, connectionsData, pollInterval, startingConns, submitScans]);

  function startPoll(): void {
    setStarted(true);
    addCloudStarted();
    setPollInterval(5000);
    setTimeout(() => {
      setPollInterval(0);
      addCloudFailed('Timed out');
      mixpanel.track('Timed out checking for new connection');
    }, 300000);
  }

  function launchStack(): void {
    mixpanel.track('Launched stack url');
    window.open(templateUrl, '_blank');
    startPoll();
  }

  function copyCode(): void {
    mixpanel.track('Copied stack url');
    startPoll();
  }

  function testConnection(): void {
    mixpanel.track('Clicked "Test Connection"');
    startPoll();
  }

  const instructions = [
    <>
      Click <strong>Launch Stack</strong> to open the AWS Management Console. If needed, log into
      the target AWS subscription.
      <br />
      Or click <strong>Copy Link</strong> to copy and send a link to an AWS admin who can perform
      these steps.
      <div style={{ marginTop: 8 }}>
        <Button
          variant="text"
          onClick={launchStack}
          style={{ marginRight: '5px' }}
          disabled={addCloudStatus.succeeded}
        >
          <LaunchIcon />
          Launch Stack
        </Button>
        or
        <CopyToClipboard text={templateUrl}>
          <Button
            variant="text"
            style={{ marginLeft: '5px' }}
            onClick={copyCode}
            disabled={addCloudStatus.succeeded}
          >
            <ContentCopyIcon />
            Copy Link
          </Button>
        </CopyToClipboard>
      </div>
    </>,
    <>
      At the bottom of the <strong>Quick create stack</strong> page, select the checkbox in the{' '}
      <strong>Capabilities</strong> section to acknowledge creation of the cross-account IAM role.
      Then click <strong>Create stack.</strong>
      <img
        src="/images/aws_stack_capabilties.png"
        alt="AWS create stack"
        style={{ width: '100%', marginTop: 8 }}
      />
    </>,
    'Wait for the stack creation to complete.',
    'Return to this page to confirm the connection.',
  ];

  return (
    <>
      <DialogContent>
        <Grid container className={classes.container}>
          <Grid item xs={12}>
            <DeployCloudHeader cloudType="aws" />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" style={{ marginBottom: 10 }}>
              Follow these steps to give Barracuda Cloud Security Guardian read-only access to your
              account. Barracuda will not have permission to make changes or deploy any resources.
            </Typography>
            <ol className={classes.list}>
              {instructions.map(i => (
                <li>
                  <Typography variant="body2">{i}</Typography>
                </li>
              ))}
            </ol>
          </Grid>
        </Grid>
        {addCloudStatus?.succeeded && <StatusMessage message="Connection successful!" />}
        {addCloudStatus?.error && <Button onClick={testConnection}>Test Connection</Button>}
        {addCloudStatus?.fetching && (
          <Grid item container xs={12} alignItems="center">
            <CircularProgress size={20} style={{ marginRight: 5 }} />
            Checking for connection...
          </Grid>
        )}
      </DialogContent>
      <DialogActions classes={{ root: classes.dialogActions }}>
        <a
          href="https://campus.barracuda.com/doc/78155906/"
          rel="noopener noreferrer"
          target="_blank"
        >
          Learn how to add an AWS account.
        </a>
        <Button onClick={handleClose} color="secondary" variant="contained">
          {started ? 'Close' : 'Cancel'}
        </Button>
      </DialogActions>
    </>
  );
}
