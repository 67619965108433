import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import StatusLabel from 'components/common/StatusLabel';
import { DisplayStatus, FindingSeverity } from 'types';

const useStyles = makeStyles(
  {
    label: {
      textTransform: 'uppercase',
      fontSize: '12px',
      lineHeight: '16px',
      height: '16px',
      padding: '0 4px',
      display: 'inline-block',
    },
    noUpperCase: {
      textTransform: 'none',
    },
    noRounding: {
      borderRadius: 0,
    },
  },
  {
    name: 'FindingLabel',
  },
);

const statusTypes: Record<FindingSeverity, DisplayStatus> = {
  CRITICAL: 'error',
  HIGH: 'error',
  MEDIUM: 'warning',
  LOW: 'default',
  INFO: 'info',
  INFORMATIONAL: 'default',
};

interface FindingLabelProps {
  className: string;
  severity: FindingSeverity;
  value: string;
  noRounding?: boolean;
  noUpperCase?: boolean;
}

export default function FindingLabel(props: FindingLabelProps): JSX.Element {
  const { className, severity, value, noRounding, noUpperCase, ...other } = props;
  const classes = useStyles();

  const severityText = severity === 'INFORMATIONAL' ? 'INFO' : severity;

  return (
    <StatusLabel
      className={clsx(className, classes.label, {
        [classes.noRounding]: noRounding,
        [classes.noUpperCase]: noUpperCase,
      })}
      status={statusTypes[severity] || 'default'}
      {...other}
    >
      {value || severityText}
    </StatusLabel>
  );
}

FindingLabel.propTypes = {
  severity: PropTypes.oneOf(['CRITICAL', 'HIGH', 'MEDIUM', 'LOW', 'INFO']),
  noRounding: PropTypes.bool,
  className: PropTypes.string,
  value: PropTypes.string,
  noUpperCase: PropTypes.bool,
};

FindingLabel.defaultProps = {
  severity: 'CRITICAL',
  noRounding: false,
  className: undefined,
  value: undefined,
  noUpperCase: false,
};
