import { gql } from '@apollo/client';

export const LIST_ACCOUNTS = gql`
  query accounts {
    accounts {
      id
      name
      setup
    }
  }
`;

export const LIST_CONNECTIONS = gql`
  query listConnections($input: ListConnectionsInput) {
    listConnections(input: $input) {
      connections {
        id
        name
        cloudId
        health
        createdAt
        updatedAt
        addedBy
        provider
      }
    }
  }
`;

export const CREATE_CONNECTION = gql`
  mutation createConnection($input: CreateConnectionInput!) {
    createConnection(input: $input) {
      id
      name
      cloudId
      health
      createdAt
      updatedAt
      addedBy
      provider
    }
  }
`;

export const UPDATE_CONNECTION = gql`
  mutation updateConnection($input: UpdateConnectionInput!) {
    updateConnection(input: $input) {
      id
      name
    }
  }
`;
export const DELETE_CONNECTION = gql`
  mutation deleteConnection($input: DeleteConnectionInput!) {
    deleteConnection(input: $input) {
      id
    }
  }
`;

export const CREATE_AUTH_TOKEN = gql`
  mutation createAuthToken {
    createAuthToken {
      token
    }
  }
`;
export const CREATE_TEMPLATE = gql`
  mutation createTemplate {
    createTemplate
  }
`;

export const SETUP_ACCOUNT = gql`
  mutation setupAccount($input: SetupAccountInput!) {
    setupAccount(input: $input) {
      id
    }
  }
`;

export const ADD_PARTNER = gql`
  mutation Partner($input: JSON!) {
    addPartner(input: $input)
  }
`;

export const SUBMIT_MARKETO = gql`
  mutation submitMarketo($input: SubmitMarketoInput!) {
    submitMarketo(input: $input)
  }
`;

export const LIST_SERIALS = gql`
  query serials {
    serials {
      id
      serial
      type
      addedAt
      expiresAt
      status
    }
  }
`;
export const ADD_SERIAL = gql`
  mutation addSerial($input: AddSerialInput!) {
    addSerial(input: $input) {
      id
      serial
      type
      addedAt
      expiresAt
      status
    }
  }
`;
