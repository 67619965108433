import React, { useEffect, useState } from 'react';

import { useQuery, useLazyQuery } from '@apollo/client';
import { Card, CardContent, Grid } from '@cuda-networks/bds-core';

import PageTitle from 'components/common/PageTitle';
import StatusMessage from 'components/common/StatusMessage';
import { SecurityFindingsTable, FindingsFilters } from 'components/SecurityFindings';
import { FindingsGraphQLFilters } from 'components/SecurityFindings/FindingsFilters';
import { exportToJSON as handleExportToJSON } from 'components/SecurityFindings/lib';
import { FINDINGS, RESOURCE_TYPES } from 'graphql/SecurityFindings';
import useAccountsFilter from 'hooks/useAccountsFilter';
import usePagination from 'hooks/usePagination';
import useRules from 'hooks/useRules';
import useTableSort from 'hooks/useTableSort';

export default function SecurityFindings(): JSX.Element {
  const [, rules] = useRules();

  const { accountsList } = useAccountsFilter();
  const [processing, setProcessing] = useState(true);
  const [filters, setFilters] = useState<FindingsGraphQLFilters | null>(null);
  const [filterId, setFilterId] = useState(Date.now().toString());
  const [pagination, handlePaginate] = usePagination('page');
  const [sort, handleSort] = useTableSort(
    {
      orderBy: 'lastObservedAt',
      order: 'desc',
    },
    'csg-findings-sort',
  );

  const {
    loading: findingsLoading,
    data: findingsData,
    networkStatus,
    fetchMore,
    error: findingsError,
  } = useQuery(FINDINGS, {
    skip: !filters,
    variables: {
      cursor: { limit: 50, offset: pagination.page * pagination.size },
      filter: {
        orderBy: sort.orderBy.split(',').map(o => `${o} ${sort.order.toUpperCase()}`),
        id: filterId,
        ...(filters?.filter?.length && { where: filters.filter }),
      },
      ...(Boolean(filters?.filters) && { filters: filters?.filters }),
    },
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-only',
    notifyOnNetworkStatusChange: true,
    context: { clientName: 'findings' },
  });

  const [loadResourceTypes] = useLazyQuery(RESOURCE_TYPES, {
    fetchPolicy: 'network-only',
    context: { clientName: 'findings' },
    variables: {
      filter: {
        where: [
          {
            name: 'cloudId',
            value: (accountsList ?? []).map(a => a.cloudId),
          },
        ],
      },
    },
  });

  const handleRefresh = () => {
    setProcessing(true);
    setFilterId(Date.now().toString());
    loadResourceTypes();
  };

  useEffect(() => {
    // variables changed or refresh called - reset pagination
    if (networkStatus === 2 || networkStatus === 4) {
      handlePaginate({
        page: 0,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [networkStatus]);

  const [findings, setFindings] = useState([]);

  useEffect(() => {
    setProcessing(true);

    if (!findingsData || findingsLoading) {
      return;
    }

    const data = (findingsData?.findings ?? []).slice(
      pagination.page * pagination.size,
      pagination.page * pagination.size + pagination.size,
    );

    if (data.some(d => !d && d !== undefined)) {
      return;
    }

    setFindings(data);
    setProcessing(false);
  }, [findingsData, findingsLoading, pagination]);

  useEffect(() => {
    if (findingsLoading) {
      setProcessing(true);
    }
  }, [findingsLoading]);

  const [exporting, setExporting] = useState(false);
  const [exportData, setExportData] = useState([]);

  // deal with jsonloading failing
  const [getJSONData] = useLazyQuery(FINDINGS, {
    fetchPolicy: 'no-cache',
    context: { clientName: 'findings' },
    notifyOnNetworkStatusChange: true,
    variables: {
      cursor: { limit: 100, offset: 0 },
      filter: {
        orderBy: sort.orderBy.split(',').map(o => `${o} ${sort.order.toUpperCase()}`),
        id: filterId,
        ...(filters?.filter?.length && { where: filters.filter }),
      },
      ...(Boolean(filters?.filters) && { filters: filters?.filters }),
    },
    onCompleted: jsonData => {
      const newData = exportData.concat(jsonData.findings);
      if (newData.length >= findingsData?.count) {
        handleExportToJSON(newData);
        setExportData([]);
        setExporting(false);
      } else {
        setExportData(newData);
        getJSONData({
          variables: {
            cursor: { limit: 100, offset: newData.length },
            filter: {
              orderBy: sort.orderBy.split(',').map(o => `${o} ${sort.order.toUpperCase()}`),
              id: filterId,
              ...(filters?.filter?.length && { where: filters.filter }),
            },
            filters: filters.filters,
          },
        });
      }
    },
  });

  const exportToJSON = () => {
    setExporting(true);
    setExportData([]);

    getJSONData();
  };

  const paginate = newPagination => {
    const offset = newPagination.page * newPagination.size;
    const limit = newPagination.size;
    const count = findingsData.findings.length;
    const { count: total } = findingsData;

    handlePaginate(newPagination);

    if (
      count >= Math.min(total, offset + limit) &&
      findingsData.findings.slice(offset, offset + limit).every(a => Boolean(a))
    ) {
      return;
    }

    fetchMore({
      variables: {
        cursor: { limit: 50, offset },
      },
    });
  };

  const loading = (processing || findingsLoading) && !findingsError;

  return (
    <>
      <PageTitle title="Security Findings" />
      <Grid container spacing={3} style={{ padding: '15px' }}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <FindingsFilters
                updateFilters={setFilters}
                reset={() => handlePaginate({ page: 0 })}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          {findingsError && (
            <StatusMessage status="error" message="Failed to load findings" removable />
          )}
          <SecurityFindingsTable
            findings={findings}
            handlePaginate={paginate}
            pagination={{
              ...pagination,
              total: findingsData?.count ?? 0,
            }}
            handleSort={handleSort}
            sort={sort}
            rules={rules}
            loading={loading}
            exporting={exporting}
            handleExportToJSON={exportToJSON}
            handleRefresh={handleRefresh}
          />
        </Grid>
      </Grid>
    </>
  );
}
