import { gql } from '@apollo/client';

export const RESOURCES = gql`
  query resources($filter: FilterInput, $cursor: CursorInput) {
    resources(filter: $filter, cursor: $cursor) {
      id
      resourceId
      name
      location
      type
      cloudId
    }
    count(filter: $filter)
  }
`;

export const FILTER_RESOURCES = gql`
  query resources($filter: FilterInput, $cursor: CursorInput) {
    resources(filter: $filter, cursor: $cursor) {
      id
      resourceId
      name
      type
      cloudId
    }
  }
`;

export const RESOURCE = gql`
  query resource($id: String, $cloudId: String!, $resourceId: String) {
    resource(id: $id, cloudId: $cloudId, resourceId: $resourceId) {
      id
      cloudId
      resourceId
      name
      location
      type
      details
    }
  }
`;

export const SCAN_RESOURCES = gql`
  mutation Scan($cloudId: [String!]!) {
    response: scan(cloudId: $cloudId)
  }
`;

export const DASHBOARD_RESOURCES = gql`
  query dashboardResources($filter: FilterInput!) {
    locationCount(filter: $filter) {
      location
      count
      provider
    }
    typeCount(filter: $filter) {
      type
      count
      provider
    }
  }
`;
