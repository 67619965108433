import React from 'react';

import { Button, Grid, Typography, DialogContent, DialogActions } from '@cuda-networks/bds-core';
import { makeStyles } from '@material-ui/core/styles';

import { AWSIcon, AzureIcon } from 'icons';

const useStyles = makeStyles(
  theme => ({
    providerButton: {
      border: '2px solid transparent',
      cursor: 'pointer',
      padding: '8px',
      '&:hover': {
        borderRadius: '5px',
        borderColor: theme.palette.primary.main,
      },
    },
    container: {
      '& > div': {
        marginBottom: '20px',
      },
    },
    text: {
      fontSize: '1.2rem',
    },
  }),
  {
    name: 'ChooseProvider',
  },
);

export default function ChooseProvider({ handleNext, handleClose }): JSX.Element {
  const classes = useStyles();

  return (
    <>
      <DialogContent>
        <Grid container className={classes.container}>
          <Grid item xs={12}>
            <Typography variant="h2">Select your Cloud Service Provider</Typography>
          </Grid>
          <Grid
            item
            container
            xs={6}
            alignItems="center"
            // justify="center"
            direction="column"
            className={classes.providerButton}
            onClick={handleNext('aws')}
            onKeyDown={handleNext('aws')}
            role="button"
            tabIndex="0"
          >
            <AWSIcon style={{ height: '100px', width: 'fit-content' }} />
            <Typography variant="h2" className={classes.text} style={{ textAlign: 'center' }}>
              Get started with AWS
            </Typography>
          </Grid>
          <Grid
            item
            container
            xs={6}
            alignItems="center"
            // justify="center"
            direction="column"
            className={classes.providerButton}
            onClick={handleNext('azure')}
            onKeyDown={handleNext('azure')}
            role="button"
            tabIndex="0"
          >
            <AzureIcon style={{ height: '100px', width: 'fit-content' }} />
            <Typography variant="h2" className={classes.text} style={{ textAlign: 'center' }}>
              Get started with Azure
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ padding: '8px 24px' }}>
        <Button onClick={handleClose} color="secondary" variant="contained">
          Cancel
        </Button>
      </DialogActions>
    </>
  );
}
