import { useState, useEffect } from 'react';

import { useQuery } from '@apollo/client';

import { LIST_CONNECTIONS } from 'graphql/Accounts';
import { CloudType, Connection, ListConnectionsData } from 'types';

export default function useProviderTabs(): any {
  const [provider, setProvider] = useState<CloudType>();

  const { data: listConnectionsData } = useQuery<ListConnectionsData>(LIST_CONNECTIONS, {
    context: { clientName: 'accounts' },
  });

  useEffect(() => {
    if (provider) {
      return;
    }
    const conns: Connection[] = listConnectionsData?.listConnections?.connections || [];
    if (conns.some(c => c.provider === 'aws')) {
      setProvider('aws');
    } else if (conns.some(c => c.provider === 'azure')) {
      setProvider('azure');
    } else {
      setProvider('aws');
    }
  }, [listConnectionsData, provider]);

  return [provider, setProvider];
}
