import React, { useEffect } from 'react';

import { useLocation, Route, Redirect, Switch } from 'react-router-dom';

import Admin from 'components/Admin';
import { AzureConnector } from 'components/AzureConnection';
import LoadingScreen from 'components/LoadingScreen';
import Login from 'components/Login';
import Main from 'components/Main';
import Register from 'components/Register';
import useAuth from 'hooks/useAuth';
import useMixpanel from 'hooks/useMixpanel';
import { pages } from 'navigation';
import { parseQS } from 'utils/params';

const loginRedirect = (redirectPath?: string): React.ReactNode => {
  const params = parseQS();

  return (
    <Redirect
      to={{
        pathname: '/login',
        search: Object.entries(params)
          .filter(([key]) => key !== 'redirect')
          .concat([['redirect', redirectPath || window.location.pathname]])
          .map(([key, value]) => `${key}=${value}`)
          .join('&'),
      }}
    />
  );
};

function MainRouter(): JSX.Element {
  const location = useLocation();
  const mixpanel = useMixpanel();
  const { status } = useAuth();

  useEffect(() => {
    mixpanel.track(`Viewed ${pages?.[location.pathname]?.title || location.pathname}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const authenticated = status === 'authenticated';

  return status === 'loading' ? (
    <LoadingScreen status={{ fetching: true }} />
  ) : (
    <Switch>
      <Route
        exact
        path="/login"
        render={(): React.ReactNode => (authenticated ? <Redirect to="/dashboard" /> : <Login />)}
      />
      <Route
        path={['/setup']}
        render={(): React.ReactNode =>
          authenticated ? (
            <Redirect
              to={{
                pathname: '/accounts',
                search: window.location.search,
              }}
            />
          ) : (
            loginRedirect('/accounts')
          )
        }
      />
      <Route
        path={['/accounts', '/register']}
        render={(): React.ReactNode => (authenticated ? <Register /> : loginRedirect())}
      />
      <Route exact path="/aws" render={(): React.ReactNode => <Redirect to="/accounts/setup" />} />
      <Route path="/connectors/azure" render={(): React.ReactNode => <AzureConnector />} />
      <Route
        exact
        path="/admin"
        render={(): React.ReactNode =>
          authenticated ? <Redirect to="/admin/accounts" /> : loginRedirect('/admin/accounts')
        }
      />
      <Route
        path="/admin"
        render={(): React.ReactNode => (authenticated ? <Admin /> : loginRedirect())}
      />
      <Route
        path="/"
        render={(): React.ReactNode => (authenticated ? <Main /> : loginRedirect())}
      />
    </Switch>
  );
}

export default MainRouter;
