import React, { useMemo } from 'react';

import { Grid } from '@cuda-networks/bds-core';
import { Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

import StatusMessage from 'components/common/StatusMessage';
import { AzureIcon, AWSIcon } from 'icons';
import { assetNames } from 'lib';
import { RequestStatus, TypeCount } from 'types';

const useStyles = makeStyles(
  theme => ({
    table: {
      lineHeight: '16px',
      '& tr:last-child td': {
        borderBottom: 'none',
      },
      '& td': {
        fontSize: '12px',
      },
      '& th': {
        fontSize: '12px',
      },
    },
    row: {
      height: 26,
      '& > td': {
        '&:last-child': {
          paddingRight: '8px',
        },
        color: 'rgba(0,0,0,0.87)',
      },
      '& > th': {
        color: '#000000',
        textTransform: 'uppercase',
      },
    },
    striped: {
      '&:nth-of-type(even)': {
        backgroundColor: theme.palette.background.default,
      },
    },
    icon: {
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        marginRight: '5px',
      },
    },
  }),
  {
    name: 'AssetsTable',
  },
);

export interface AssetsTableProps {
  assets: TypeCount[];
  status: RequestStatus;
}
export default function AssetsTable(props: AssetsTableProps): JSX.Element {
  const { assets, status } = props;
  const classes = useStyles();

  const rows = useMemo(() => {
    return assets
      .slice()
      .sort((a, b) => b.count - a.count)
      .slice(0, 10);
  }, [assets]);

  return (
    <Grid container item xs={12} alignContent="space-between">
      {status.error ? (
        <StatusMessage status="error" message="Error loading asset data" />
      ) : (
        <>
          <Grid item xs={12}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow className={classes.row}>
                  <TableCell>Cloud Type</TableCell>
                  <TableCell>Asset Type</TableCell>
                  <TableCell>Count</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {status.fetching && (
                  <>
                    <TableRow className={clsx(classes.row, classes.striped)}>
                      <TableCell>
                        <Skeleton variant="text" width="100%" height={24} />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant="text" width="100%" height={24} />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant="text" width="100%" height={24} />
                      </TableCell>
                    </TableRow>
                    <TableRow className={clsx(classes.row, classes.striped)}>
                      <TableCell>
                        <Skeleton variant="text" width="100%" height={24} />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant="text" width="100%" height={24} />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant="text" width="100%" height={24} />
                      </TableCell>
                    </TableRow>
                    <TableRow className={clsx(classes.row, classes.striped)}>
                      <TableCell>
                        <Skeleton variant="text" width="100%" height={24} />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant="text" width="100%" height={24} />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant="text" width="100%" height={24} />
                      </TableCell>
                    </TableRow>
                  </>
                )}
                {status.succeeded && !rows?.length && (
                  <TableRow>
                    <TableCell colSpan={3}>
                      <StatusMessage status="info" message="No data available" />
                    </TableCell>
                  </TableRow>
                )}
                {status.succeeded &&
                  Boolean(rows?.length) &&
                  rows.map(row => (
                    <TableRow key={row.type} className={clsx(classes.row, classes.striped)}>
                      <TableCell>
                        {
                          {
                            aws: (
                              <div className={classes.icon}>
                                <AWSIcon />
                                AWS
                              </div>
                            ),
                            azure: (
                              <div className={classes.icon}>
                                <AzureIcon />
                                Azure
                              </div>
                            ),
                          }[row.provider]
                        }
                      </TableCell>
                      <TableCell>{assetNames?.[row.type] || row.type}</TableCell>
                      <TableCell>{row.count}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Grid>
          <Grid item container justify="flex-end" xs={12}>
            <Link to="/resources">All Resources</Link>
          </Grid>
        </>
      )}
    </Grid>
  );
}
