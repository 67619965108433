import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles(
  {
    well: {
      backgroundColor: '#fafafa',
      border: '1px solid #dadada',
      borderRadius: '3px',
      boxShadow: '0 1px 4px -3px rgba(0, 0, 0, 0.5) inset',
      marginBottom: '10px',
      padding: '10px',
    },
  },
  {
    name: 'Well',
  },
);

export default function Well({ children, className }): JSX.Element {
  const classes = useStyles();

  return <div className={clsx(classes.well, className)}>{children}</div>;
}
