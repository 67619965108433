import React from 'react';

import PropTypes from 'prop-types';

import ResultLabel from 'components/common/ResultLabel';

export default function ControlResult({ result }) {
  return (
    {
      PASSED: <ResultLabel result="success">Passed</ResultLabel>,
      FAILED: <ResultLabel result="failure">Failed</ResultLabel>,
      WARNING: <ResultLabel result="warning">Warning</ResultLabel>,
      NOT_AVAILABLE: <ResultLabel result="other">Not Available</ResultLabel>,
    }[result] ?? ''
  );
}

ControlResult.propTypes = {
  result: PropTypes.oneOf(['PASSED', 'FAILED', 'WARNING', 'NOT_AVAILABLE']),
};
