import React, { useState, useMemo, useEffect } from 'react';

import { useApolloClient, useMutation } from '@apollo/client';
import { Card, CardContent, Button, Grid, TextField } from '@cuda-networks/bds-core';
import { FormControl } from '@material-ui/core';

import { SerialsTable, SerialDetailsDialog } from 'components/Admin';
import PageTitle from 'components/common/PageTitle';
import TableFilter from 'components/common/TableFilter';
import { SERIALS, EXTEND_SERIAL } from 'graphql/Admin';
import useRequest from 'hooks/useRequest';
import { SerialInfo } from 'types';

export default function Serials(): JSX.Element {
  const [serial, setSerial] = useState('');
  const [detailsId, setDetailsId] = useState<string | null>(null);

  const client = useApolloClient();
  const [serials, setSerials] = useState<SerialInfo[]>([]);
  const [loadStatus, loadStarted, loadSucceeded, loadFailed] = useRequest();
  useEffect((): void => {
    loadStarted();
    async function fetchData(): void {
      try {
        await client.clearStore();
        const { data } = await client.query({
          query: SERIALS,
          // fetchPolicy: 'network-only',
          context: {
            clientName: 'accounts',
          },
        });

        setSerials(data?.serials ?? []);
        loadSucceeded();
      } catch (e) {
        loadFailed(e?.networkError?.result?.message || e?.message || 'Unknown error');
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const detailSerial = useMemo(() => {
    if (detailsId) {
      return (serials || []).find(s => s.serial === detailsId);
    }

    return null;
  }, [detailsId, serials]);

  const [extendSerial] = useMutation(EXTEND_SERIAL, { context: { clientName: 'accounts' } });
  const [extendStatus, extendStarted, extendSucceeded, extendFailed, , extendReset] = useRequest();
  const handleExtend = async (accountId, serial, days): Promise<void> => {
    extendStarted();

    try {
      const { data } = await extendSerial({
        variables: {
          input: {
            accountId,
            serial,
            days,
          },
        },
      });

      extendSucceeded(data?.extendSerial?.message);
    } catch (e) {
      extendFailed(e?.networkError?.result?.message || e?.message || 'Serial extension failed');
    }
  };

  const [pagination, setPagination] = useState({
    page: 0,
    size: 50,
  });
  const paginatedSerials = useMemo(() => {
    const { page, size } = pagination;
    const start = page * size;

    return (serials || []).slice(start, start + size);
  }, [pagination, serials]);
  const handlePaginate = newPagination => setPagination(newPagination);

  return (
    <>
      <PageTitle title="Serial Numbers" />
      <Grid container direction="column" spacing={3}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <TableFilter>
                <>
                  <TextField
                    label="Serial Number Lookup"
                    value={serial}
                    onChange={event => setSerial(event.target.value)}
                    margin="none"
                    style={{ minWidth: '300px' }}
                    disabled={loadStatus.fetching}
                  />
                  <FormControl>
                    <Button
                      color="primary"
                      disabled={!serial || loadStatus.fetching}
                      onClick={() => setDetailsId(serial)}
                    >
                      Lookup
                    </Button>
                  </FormControl>
                </>
              </TableFilter>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <SerialsTable
            serials={paginatedSerials}
            pagination={{
              ...pagination,
              total: serials?.length || 0,
            }}
            handlePaginate={handlePaginate}
            status={loadStatus}
            handleDetails={setDetailsId}
          />
        </Grid>
      </Grid>
      <SerialDetailsDialog
        open={Boolean(detailsId)}
        handleClose={(): void => {
          setDetailsId(null);
          extendReset();
        }}
        status={loadStatus}
        handleExtend={handleExtend}
        extendStatus={extendStatus}
        serial={detailSerial}
      />
    </>
  );
}
