import React, { useState, useEffect } from 'react';

import { useApolloClient, useQuery } from '@apollo/client';
import { Drawer, IconButton } from '@cuda-networks/bds-core';
import { makeStyles } from '@material-ui/core/styles';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import clsx from 'clsx';
import { useHistory, useRouteMatch } from 'react-router-dom';

import MainContent from 'components/MainContent';
import NavDrawer from 'components/NavDrawer';
import { LIST_ACCOUNTS, LIST_CONNECTIONS } from 'graphql/Accounts';
import useAccountSwitch from 'hooks/useAccountSwitch';
import useAuth from 'hooks/useAuth';
import useRequest from 'hooks/useRequest';
import { nav } from 'navigation';
import { ListConnectionsData, ListAccountsData, Account } from 'types';

import AppBar from './AppBar';
import LoadingScreen from './LoadingScreen';

const drawerWidth = 300;
const closedWidth = 72;

const useStyles = makeStyles(
  theme => ({
    wrapper: {
      display: 'flex',
      height: '100%',
    },
    button: {
      margin: '8px',
      '&:hover': {
        backgroundColor: 'rgba(158,158,158,0.2)',
      },
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      width: closedWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
  }),
  {
    name: 'Main',
  },
);

export default function Main(): JSX.Element {
  const history = useHistory();
  const classes = useStyles();
  const [open, setDrawerOpen] = useState(false);

  const client = useApolloClient();

  const handleDrawerToggle = (): void => setDrawerOpen(!open);
  const { signOut, account } = useAuth();

  const handleGetStarted = (): void => history.push('/cloud-connections?getStarted=true');

  const [accounts, setAccounts] = useState<Account[]>([]);

  const [switchAccount, switchAccountStatus] = useAccountSwitch();

  const [loadStatus, loadStarted, loadSucceeded, loadFailed] = useRequest({
    fetching: true,
  });
  useEffect((): void => {
    loadStarted();
    async function fetchData(): Promise<void> {
      try {
        await client.clearStore();
        const accountsData = await client.query<ListAccountsData>({
          query: LIST_ACCOUNTS,
          fetchPolicy: 'network-only',
          context: { clientName: 'accounts' },
        });
        await client.query<ListConnectionsData>({
          query: LIST_CONNECTIONS,
          fetchPolicy: 'network-only',
          context: { clientName: 'accounts' },
        });

        const accountsList = accountsData?.data?.accounts ?? [];

        if (!(account?.id && accountsList.find(a => a.id === account.id)?.setup)) {
          history.push('/accounts');
        }

        // if (!account?.setup) {
        //   history.push(`/setup?account_id=${account?.id}`);
        // }

        setAccounts(accountsList);
        loadSucceeded();
      } catch (e) {
        loadFailed(e?.networkError?.result?.message || e?.message || 'Unknown error');
      }
    }
    fetchData();
  }, [account, client, history, loadFailed, loadStarted, loadSucceeded]);

  const handleAccountSelect = (acct: Account): void => {
    if (!acct.setup) {
      history.push(`/setup?account_id=${acct.id}`);
    }
    switchAccount(acct.id);
  };

  const { data: listConnectionsData } = useQuery<ListConnectionsData>(LIST_CONNECTIONS, {
    fetchPolicy: 'cache-only',
    context: { clientName: 'accounts' },
  });

  const setup = Boolean((listConnectionsData?.listConnections?.connections || []).length);

  const match = useRouteMatch({
    path: '/cloud-connections',
    exact: true,
  });

  return loadStatus.succeeded && account ? (
    <div className={classes.wrapper}>
      <AppBar
        account={account}
        accounts={accounts}
        showGetStarted={!setup && !match}
        drawerOpen={open}
        switchAccountStatus={switchAccountStatus}
        handleAccountSelect={handleAccountSelect}
        handleGetStarted={handleGetStarted}
        handleDrawerToggle={handleDrawerToggle}
        handleSignOut={signOut}
        handleManageAccounts={(): void => {
          history.push('/accounts');
        }}
      />
      <Drawer
        variant="permanent"
        className={clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerToggle} className={classes.button}>
            <MenuOpenIcon />
          </IconButton>
        </div>
        <NavDrawer nav={nav} open={open} hideLabels drawerWidth={drawerWidth} />
      </Drawer>
      <MainContent setup={setup} />
    </div>
  ) : (
    <LoadingScreen status={loadStatus} />
  );
}
