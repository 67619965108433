import React from 'react';

import { Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PersonIcon from '@material-ui/icons/PersonOutline';

import ActionCell from 'components/common/ActionCell';
import { EditButton, DeleteButton } from 'components/common/Buttons';
import NoResults from 'components/common/table/NoResults';

const useStyles = makeStyles(
  {
    table: {
      '& td': {
        whiteSpace: 'nowrap',
      },
    },
    user: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
  },
  {
    name: 'UsersTable',
  },
);

export default function UsersTable({ users, currentUser, handleDelete, handleEdit }): JSX.Element {
  const classes = useStyles();

  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Email</TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {users.length ? (
          users.map(user => (
            <TableRow key={user.email}>
              <TableCell>
                {user.name}
                <span style={{ fontWeight: 'bold' }}>{currentUser === user.email && ' (You)'}</span>
              </TableCell>
              <TableCell>{`${user.email}${user.pending ? ' (Pending)' : ''}`}</TableCell>
              <ActionCell>
                {currentUser === user.email ? (
                  <div className={classes.user}>
                    <PersonIcon />
                    Your Account
                  </div>
                ) : (
                  <>
                    {currentUser !== user.email && (
                      <DeleteButton
                        onClick={handleDelete(user)}
                        actionLabel="remove users."
                        roles={['administrator']}
                        label="Remove"
                      />
                    )}
                    <EditButton
                      onClick={handleEdit(user)}
                      disabled={user.pending}
                      actionLabel="edit users."
                      roles={['administrator']}
                    />
                  </>
                )}
              </ActionCell>
            </TableRow>
          ))
        ) : (
          <NoResults colSpan={4} />
        )}
      </TableBody>
    </Table>
  );
}
