import { ManagementGroupsAPIModels } from '@azure/arm-managementgroups';
import { v4 as uuidv4 } from 'uuid';

import {
  Connection,
  ConnectionResult,
  ConnectionStatus,
  OnboardingStatus,
  TreeInput,
  TreeSelectNode,
} from 'types';

export const clientId = process.env.REACT_APP_AZURE_CLIENT_ID;
export const roleDefinitionId = 'acdd72a7-3385-48ef-bd42-f606fba81ae7';

export interface AzureTreeInput extends TreeInput {
  disabled: boolean;
  disabledReason?: string;
  children?: AzureTreeInput[];
}

interface ManagementGroup extends ManagementGroupsAPIModels.EntityInfo {
  parent: {
    id: string;
  };
  id: string;
  tenantId: string;
  type: string;
  displayName: string;
}

export async function onboardGroup(
  groupId: string,
  principalId: string,
  accessToken: string,
): Promise<ConnectionStatus> {
  const resp = await fetch(
    `https://management.azure.com${groupId}/providers/microsoft.authorization/roleassignments/${uuidv4()}?api-version=2015-07-01`,
    {
      method: 'PUT',
      body: JSON.stringify({
        properties: {
          roleDefinitionId: `${groupId}/providers/Microsoft.Authorization/roleDefinitions/${roleDefinitionId}`,
          principalId,
        },
      }),
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    },
  );

  if (resp.status === 201 || resp.status === 409) {
    return {
      status: 'succeeded',
    };
  }
  const json = await resp.json();

  return {
    status: 'failed',
    message: json?.error?.message || `Failed to create role for CSG on ${groupId}.`,
  };
}

export function getNodeById(node: AzureTreeInput, id: string): AzureTreeInput | null {
  if (node.id === id) {
    return node;
  }
  if (Array.isArray(node.children)) {
    for (let i = 0; i < node.children.length; i += 1) {
      const child = getNodeById(node.children[i], id);

      if (child) {
        return child;
      }
    }
  }
  return null;
}

export function getGroupsToOnboard(
  arr: string[],
  node: AzureTreeInput,
  selectedMap: TreeSelectNode,
): string[] {
  if (node.parent && selectedMap?.[node.id] === 'checked' && !node.disabled) {
    return [...arr, node.id];
  }

  let newArr = [...arr];

  (node.children || []).forEach(n => {
    newArr = getGroupsToOnboard(newArr, n, selectedMap);
  });

  return newArr;
}

export function subsUnderGroup(node: AzureTreeInput, selectedMap: TreeSelectNode): string[] {
  if (selectedMap?.[node.id] === 'checked' && node.id.startsWith('/subscriptions')) {
    return [node.id];
  }

  return (node.children || []).reduce(
    (cArr, c) => [...cArr, ...subsUnderGroup(c, selectedMap)],
    [],
  );
}

export function getDisabledReason(entity: ManagementGroup, connections: Connection[]): string {
  let disabledReason = '';
  if (entity.permissions === 'noaccess' && entity.inheritedPermissions === 'noaccess') {
    disabledReason =
      'You do not have access to grant permission to this subscription or management group.';
  } else if (
    connections.find(conn => `/subscriptions/${conn.cloudId.split(':')[1]}` === entity.id)
  ) {
    disabledReason = 'This Subscription is already connected to Barracuda Cloud Security Guardian.';
  }

  return disabledReason;
}

export function getResultMessage(results: Record<OnboardingStatus, string[]>): ConnectionResult {
  const resultTypeMap = {
    '/subscriptions': 'subscription(s)',
    '/tenant': 'tenant(s)',
    '/providers/Microsoft.Management': 'management group(s)',
  };
  const resultTypes = ['/subscriptions', '/tenant', '/providers/Microsoft.Management'];

  const successMsg = resultTypes
    .map(t => ({
      results: (results?.succeeded || []).filter(s => s.startsWith(t)),
      label: resultTypeMap[t],
    }))
    .filter(r => r.results.length)
    .map(r => `${r.results.length} ${r.label}`);

  if (successMsg.length > 1) {
    successMsg[successMsg.length - 1] = `and ${successMsg[successMsg.length - 1]}`;
  }
  const failureMsg = resultTypes
    .map(t => ({
      results: (results?.failed || []).filter(s => s.startsWith(t)),
      label: resultTypeMap[t],
    }))
    .filter(r => r.results.length)
    .map(r => `${r.results.length} ${r.label}`);

  if (failureMsg.length > 1) {
    failureMsg[failureMsg.length - 1] = `and ${failureMsg[failureMsg.length - 1]}`;
  }

  if (successMsg.length && !failureMsg.length) {
    return {
      status: 'success',
      message: `Successfully on-boarded ${successMsg.join(', ')}.`,
    };
  }
  if (successMsg.length) {
    return {
      status: 'warning',
      message: `Successfully on-boarded ${successMsg.join(
        ', ',
      )}, but failed to on-board ${failureMsg.join(', ')}.`,
    };
  }
  return {
    status: 'error',
    message: `Failed to on-board ${failureMsg.join(', ')}.`,
  };
}
