import React, { useMemo } from 'react';

import { Checkbox } from '@cuda-networks/bds-core';
import { Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Timestamp from 'components/common/Timestamp';
import { ResourceDisplay } from 'components/Resources';
import { ControlResult } from 'components/SecurityFindings';

import { workflowStatus } from './lib';
import { regionNames } from 'lib';

const useStyles = makeStyles(
  theme => ({
    account: {
      display: 'flex',
      padding: '8px 24px',
      alignItems: 'center',
      minHeight: '64px',
      justifyContent: 'space-between',
    },
    details: {
      '& > div': {
        padding: '16px 24px',
      },
      overflowY: 'auto',
    },

    rule: {
      marginRight: '4px',
      color: '#000000',
    },

    table: {
      '& td': {
        fontSize: '12px',
        border: 0,
        verticalAlign: 'text-top',
        '&$label': {
          fontSize: '14px',
        },
      },
      marginBottom: '24px',
    },

    label: {
      lineHeight: '32px',
      width: '100px',
    },
    largeLabel: {
      fontSize: '14px',
      lineHeight: '16px',
      fontWeight: 500,
      marginBottom: '4px',
    },
    value: {
      lineHeight: '14px',
      marginBottom: '14px',
    },
    largeValue: {
      fontSize: '12px',
      lineHeight: '20px',
      marginBottom: '24px',
      '&:last-child': {
        marginBottom: 0,
      },
    },

    findings: {
      '&>tbody': {
        '&>tr': {
          backgroundColor: '#FFFFFF',
        },
      },
    },
    checkboxCell: {
      width: 42,
      paddingLeft: '15px',
    },
    buttonCell: {
      paddingRight: '15px',
    },
  }),
  {
    name: 'RiskDetailsTable',
  },
);

const perControlCols = {};

export default function RiskDetailsTable({
  findings,
  selected,
  handleCheck,
  handleSelect,
  selectedCount,
  generatorId,
}): JSX.Element {
  const classes = useStyles();

  const allCheckedStatus = useMemo(() => {
    if (selectedCount === 0) {
      return 'unchecked';
    }
    if (selectedCount === findings.length) {
      return 'checked';
    }

    return 'indeterminate';
  }, [selectedCount, findings]);

  const allCheck = e =>
    handleCheck(
      findings.map(f => f.id),
      e.target.checked,
    );
  const check = id => e => handleCheck([id], e.target.checked);

  return (
    <Table className={classes.findings}>
      <TableHead>
        <TableRow>
          <TableCell className={classes.checkboxCell}>
            <Checkbox
              color="primary"
              checked={allCheckedStatus === 'checked'}
              indeterminate={allCheckedStatus === 'indeterminate'}
              onClick={allCheck}
              disabled={!findings.length}
              data-test-id="risks-checkbox-all"
            />
          </TableCell>
          <TableCell>Resource</TableCell>
          <TableCell>Region</TableCell>
          <TableCell>Result</TableCell>
          <TableCell align="center">Status</TableCell>
          {(perControlCols?.[generatorId] ?? []).map(c => (
            <TableCell key={c.field} {...(c.cellProps ?? {})}>
              {c.label}
            </TableCell>
          ))}
          <TableCell>Last Observed</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {findings.map((finding, index) => (
          <TableRow key={finding.id} data-test-id={`risk-finding-row-${index}`}>
            <TableCell className={classes.checkboxCell}>
              <Checkbox
                color="primary"
                checked={selected?.[finding.id]}
                onClick={check(finding.id)}
                value={finding?.id}
                data-test-id={`risk-finding-${index}`}
              />
            </TableCell>
            <TableCell>
              <ResourceDisplay
                provider={finding?.cloudProvider}
                resource={finding?.resource}
                onClick={() => handleSelect(finding)}
                noLeftPadding
              />
            </TableCell>
            <TableCell>
              {regionNames?.[finding?.resource?.region] ?? finding?.resource?.region}
            </TableCell>
            <TableCell>
              <ControlResult result={finding?.compliance?.status ?? ''} />
            </TableCell>
            <TableCell align="center">
              {workflowStatus(finding?.workflow?.status?.toUpperCase())}
            </TableCell>
            {(perControlCols?.[generatorId] ?? []).map(c => (
              <TableCell key={c.field} {...(c.cellProps ?? {})}>
                {c.render(finding)}
              </TableCell>
            ))}
            <TableCell>
              <Timestamp ts={finding?.lastObservedAt} tsFormat="ISO" />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

RiskDetailsTable.propTypes = {
  status: PropTypes.object,
};

RiskDetailsTable.defaultProps = {
  status: {},
};
