import { gql } from '@apollo/client';

export const CREATE_POLICY = gql`
  mutation create($policy: PolicyInput!) {
    createPolicy(input: $policy) {
      name
      provider
      id
      default
      policy
      scope
    }
  }
`;

export const UPDATE_POLICY = gql`
  mutation update($policy: PolicyInput!) {
    updatePolicy(input: $policy) {
      name
      provider
      id
      default
      policy
      scope
    }
  }
`;

export const DELETE_POLICY = gql`
  mutation delete($id: String!) {
    deletePolicy(id: $id)
  }
`;

export const LIST_POLICIES = gql`
  query listPolicies {
    listPolicies {
      name
      provider
      id
      default
      policy
      scope
    }
  }
`;
