import React, { useEffect } from 'react';

import { Button } from '@cuda-networks/bds-core';

import AccountFilter from 'components/common/AccountFilter';
import Autocomplete from 'components/common/Autocomplete';
import DebouncedTextField from 'components/common/DebouncedTextField';
import TableFilter from 'components/common/TableFilter';
import useAccountsFilter from 'hooks/useAccountsFilter';
import useResourcesFilter from 'hooks/useResourcesFilter';
import { resourceTypeList } from 'lib';

export type Filters = 'accounts' | 'resourceTypes' | 'resources';

export interface ResourceFilter {
  filter?: any[];
  filters?: any[];
}
export interface ResourceFiltersProps {
  loading?: boolean;
  updateFilters(filters: ResourceFilter): void;
  reset(): void;
  hideAccounts?: boolean;
  hideResourceTypes?: boolean;
  hideNameQuery?: boolean;
}

export default function ResourcesFilters(props: ResourceFiltersProps): React.ReactElement {
  const {
    loading = false,
    updateFilters,
    reset,
    hideAccounts = false,
    hideResourceTypes = false,
    hideNameQuery = false,
  } = props;
  const { accountsList } = useAccountsFilter();

  const {
    selectedResourceTypes,
    setResourceTypes,
    selectedAccounts,
    setAccounts,
    nameQuery,
    setNameQuery,

    initialized,

    resetFilters,
    filterClauses,
  } = useResourcesFilter({
    accounts: !hideAccounts,
    resourceTypes: !hideResourceTypes,
    nameQuery: !hideNameQuery,
  });

  useEffect(() => {
    updateFilters(filterClauses);
  }, [filterClauses, updateFilters]);

  return (
    <>
      <TableFilter>
        <>
          {!hideNameQuery && (
            <DebouncedTextField
              disabled={loading || !initialized}
              fullWidth
              label="Search"
              placeholder="Search resource name"
              margin="none"
              autoFocus
              style={{ width: '300px' }}
              defaultValue={nameQuery}
              onChange={setNameQuery}
            />
          )}
          {!hideAccounts && (
            <AccountFilter
              accounts={accountsList}
              selected={selectedAccounts}
              onChange={setAccounts}
              processing={false}
              disabled={loading || !initialized}
            />
          )}
          {!hideResourceTypes && (
            <Autocomplete
              multiple
              disabled={loading || !initialized}
              options={resourceTypeList}
              label="Resource Types"
              groupBy={option => option.cloud}
              selected={selectedResourceTypes}
              handleSelect={setResourceTypes}
              selectAll
            />
          )}
        </>
      </TableFilter>
      <Button
        variant="text"
        style={{ backgroundColor: 'transparent' }}
        color="primary"
        size="small"
        onClick={() => {
          resetFilters();
          reset();
        }}
      >
        Clear Filters
      </Button>
    </>
  );
}
