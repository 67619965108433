import React, { useState, useEffect } from 'react';

import { useQuery } from '@apollo/client';
import { Card, CardContent, CardHeader, Grid } from '@cuda-networks/bds-core';
import { makeStyles } from '@material-ui/core/styles';

import PageTitle from 'components/common/PageTitle';
import { License, AssetsTable, ComplianceGraph, RegionMap } from 'components/Dashboard';
import { DASHBOARD_RESOURCES } from 'graphql/Resources';
import { GROUPED_COUNT } from 'graphql/SecurityFindings';
import useAccountsFilter from 'hooks/useAccountsFilter';
import useQueryRequest from 'hooks/useQueryRequest';
import {
  DashboardResourcesData,
  DashboardResourcesVariables,
  FindingFilter,
  GraphInfo,
  GroupedCountsData,
  GroupedCountsVariables,
} from 'types';

const useStyles = makeStyles(
  {
    card: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
  },
  {
    name: 'Dashboard',
  },
);

export default function Dashboard(): JSX.Element {
  const classes = useStyles();
  const { accountsList } = useAccountsFilter();

  const resourcesQuery = useQuery<DashboardResourcesData, DashboardResourcesVariables>(
    DASHBOARD_RESOURCES,
    {
      skip: !accountsList?.length,
      context: { clientName: 'resources' },
      fetchPolicy: 'no-cache',
      variables: {
        filter: {
          where: [
            {
              name: 'cloudId',
              value: (accountsList || [])?.map(a => a.cloudId),
            },
          ],
        },
      },
    },
  );
  const [resourcesStatus] = useQueryRequest(resourcesQuery);

  const cloudsQuery = useQuery<GroupedCountsData, GroupedCountsVariables>(GROUPED_COUNT, {
    skip: !accountsList?.length,
    context: { clientName: 'findings' },
    fetchPolicy: 'no-cache',
    variables: {
      groupBy: ['cloudId'],
      filter: {
        where: [
          {
            name: 'cloudId',
            value: (accountsList || []).map(a => a.cloudId),
          },
          {
            name: 'compliance.status',
            value: 'FAILED',
          },
        ],
      },
    },
  });
  const [cloudsStatus] = useQueryRequest(cloudsQuery);

  const [graphInfo, setGraphInfo] = useState<GraphInfo>({});
  const [resultGraphInfo, setResultGraphInfo] = useState<GraphInfo>({});
  const generateFilter = (clouds: string[], failed = true): { where: FindingFilter[] } => ({
    where: [
      {
        name: 'cloudId',
        value: clouds,
      },
      ...(failed
        ? [
            {
              name: 'compliance.status',
              value: 'FAILED',
            },
            {
              name: 'workflow.status',
              value: 'NEW',
            },
          ]
        : []),
    ],
  });
  useEffect(() => {
    if (!cloudsQuery?.data || !accountsList) {
      return;
    }

    const top = [...(cloudsQuery?.data?.counts || [])]
      .sort((a, b) => b.count - a.count)
      .map(c => c.cloudId)
      .slice(0, 5);

    const topOrder = top.reduce(
      (obj, c, i) => ({
        ...obj,
        [c]: i,
      }),
      {},
    );
    const filter = generateFilter((accountsList || []).map(a => a.cloudId));
    const topFilter = generateFilter(top);

    setGraphInfo({
      topOrder,
      filter,
      topFilter,
    });

    const resultsFilter = generateFilter(
      (accountsList || []).map(a => a.cloudId),
      false,
    );
    const resultsTopFilter = generateFilter(top, false);

    setResultGraphInfo({
      topOrder,
      filter: resultsFilter,
      topFilter: resultsTopFilter,
    });
  }, [accountsList, cloudsQuery]);

  return (
    <>
      <PageTitle title="Dashboard" />
      <Grid container spacing={3} style={{ padding: '15px' }}>
        <Grid item xs={6} lg={4}>
          <Card className={classes.card}>
            <CardHeader title="Risks by Severity" />
            <CardContent>
              <ComplianceGraph graph="severity" graphInfo={graphInfo} cloudsStatus={cloudsStatus} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6} lg={4}>
          <Card className={classes.card}>
            <CardHeader title="Risks by Control" />
            <CardContent>
              <ComplianceGraph graph="control" graphInfo={graphInfo} cloudsStatus={cloudsStatus} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6} lg={4}>
          <Card className={classes.card}>
            <CardHeader title="Risks by Cloud" />
            <CardContent>
              <ComplianceGraph graph="provider" graphInfo={graphInfo} cloudsStatus={cloudsStatus} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6} lg={4}>
          <Card className={classes.card}>
            <CardHeader title="Findings by Result" />
            <CardContent>
              <ComplianceGraph
                graph="result"
                graphInfo={resultGraphInfo}
                cloudsStatus={cloudsStatus}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6} lg={4}>
          <Card style={{ height: '100%' }}>
            <CardHeader title="License" />
            <License />
          </Card>
        </Grid>
        <Grid item xs={6} lg={4}>
          <Card className={classes.card}>
            <CardHeader title="Assets" />
            <CardContent>
              <AssetsTable
                assets={resourcesQuery?.data?.typeCount || []}
                status={resourcesStatus}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardHeader title="Regions with Resources" />
            <CardContent>
              <RegionMap
                regions={resourcesQuery?.data?.locationCount || []}
                status={resourcesStatus}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
