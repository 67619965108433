import React from 'react';

import { Button, Grid, Typography } from '@cuda-networks/bds-core';
import { CloudSecurityGuardian } from '@cuda-networks/bds-core/dist/Logos/Products';
import { makeStyles } from '@material-ui/core/styles';

import useAuth from 'hooks/useAuth';

import LoginForm from './LoginForm';

const useStyles = makeStyles(
  theme => ({
    container: {
      minHeight: '100%',
      alignItems: 'stretch',
      marginBottom: 0,
      backgroundColor: 'rgb(35, 47, 62)',
    },
    leftContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      flex: '1',
    },
    rightContainer: {
      display: 'flex',
      flexFlow: 'column',
      backgroundColor: '#FFFFFF',
      minWidth: 350,
      flex: '0 0 400px',
    },
    upper: {
      padding: '35px',
      flex: '0 1 400px',
    },
    lower: {
      backgroundColor: theme.palette.background.default,
      padding: '15px 35px',
      minHeight: '150px',
      flex: '1 1 400px',
    },
    infoText: {
      marginBottom: '10px',
    },
    logo: {
      marginBottom: '20px',
      height: 48,
      display: 'flex',
    },
    infographic: {
      minWidth: '400px',
      width: '100%',
      maxHeight: '870px',
    },
    certs: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '30px',
    },
    logos: {
      height: 40,
      '& > img': {
        marginLeft: '20px',
      },
    },
  }),
  {
    name: 'Login',
  },
);

export default function Login(): JSX.Element {
  const classes = useStyles();
  const { signIn, status } = useAuth();

  return (
    <Grid spacing={0} className={classes.container} id="atp-dashboard" container>
      <Grid className={classes.leftContainer} item>
        <img alt="" src="/images/signin/infographic.svg" className={classes.infographic} />
        <div className={classes.certs}>
          <img alt="" src="/images/signin/cis_benchmark_certified.jpg" />
          <div className={classes.logos}>
            <img alt="" src="/images/signin/Azure-Logo.png" />
            <img alt="" src="/images/signin/AWS-Logo.png" />
          </div>
        </div>
      </Grid>
      <Grid item className={classes.rightContainer}>
        <div className={classes.upper}>
          <CloudSecurityGuardian className={classes.logo} />
          <LoginForm />
        </div>
        <div className={classes.lower}>
          <Typography variant="overline" display="block" className={classes.infoText}>
            Barracuda Cloud Security Guardian accelerates public cloud adoption by delivering
            visibility, continuous compliance, and automated remediation for AWS and Azure
            environments. It helps organizations build faster while staying secure.
          </Typography>
          <Button
            size="medium"
            disabled={status === 'loading'}
            color="primary"
            variant="contained"
            onClick={(): void => {
              sessionStorage.setItem('csg-trial', '1');
              signIn();
            }}
            data-test-id="login-free-trial"
          >
            Free Trial
          </Button>
        </div>
      </Grid>
    </Grid>
  );
}
