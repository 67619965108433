import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { DisplayStatus } from 'types';

const useStyles = makeStyles(
  {
    root: {
      backgroundColor: '#AAAAAA',
      borderRadius: '0.35em',
      lineHeight: '1.5',
      fontSize: '0.95em',
      color: '#FFFFFF',
      display: 'inline',
      textAlign: 'center',
      verticalAlign: 'baseline',
      whiteSpace: 'nowrap',
    },
    padding: {
      padding: '0.15em 0.4em',
    },
    capitalize: {
      textTransform: 'capitalize',
    },
    primary: {
      backgroundColor: '#428bca',
      '&[href]:hover': {
        backgroundColor: '#3071a9',
      },
    },
    success: {
      backgroundColor: '#5cb85c',
    },
    info: {
      backgroundColor: '#5bc0de',
    },
    warning: {
      backgroundColor: '#f0ad4e',
    },
    error: {
      backgroundColor: '#d9534f',
    },
    default: {
      backgroundColor: '#DDDDDD',
      color: '#000000',
    },
  },
  {
    name: 'StatusLabel',
  },
);

interface StatusLabelProps {
  children: React.ReactNode;
  className?: string;
  status: DisplayStatus;
  padding?: boolean;
  capitalize?: boolean;
}

export default function StatusLabel(props: StatusLabelProps): React.ReactElement {
  const { children, className, status, padding = true, capitalize = false } = props;
  const classes = useStyles();

  return (
    <span
      className={clsx(classes.root, className, {
        [classes[status]]: true,
        [classes.padding]: padding,
        [classes.capitalize]: capitalize,
      })}
    >
      {children}
    </span>
  );
}
