import React, { useMemo } from 'react';

import { useQuery } from '@apollo/client';
import { Card, CardContent, Grid } from '@cuda-networks/bds-core';

import PageTitle from 'components/common/PageTitle';
import ProviderTabs from 'components/common/ProviderTabs';
import { PolicyTable, AccountTable } from 'components/SecurityPolicy';
import { flattenRules } from 'components/SecurityPolicy/lib';
import { LIST_CONNECTIONS } from 'graphql/Accounts';
import { LIST_POLICIES } from 'graphql/SecurityPolicy';
import useProviderTabs from 'hooks/useProviderTabs';
import { Connection, ListConnectionsData } from 'types';
import rules from 'utils/rules.json';

export default function CompliancePolicy(): JSX.Element {
  const [provider, setProvider] = useProviderTabs();

  const { data: listConnectionsData } = useQuery<ListConnectionsData>(LIST_CONNECTIONS, {
    context: { clientName: 'accounts' },
  });

  const { loading: policiesLoading, data: policiesData } = useQuery(LIST_POLICIES, {
    context: { clientName: 'findings' },
    fetchPolicy: 'network-only',
  });

  const mappedBenchmarks = useMemo(
    (): void => ({
      aws: flattenRules(rules ?? {}, ['aws']),
      azure: flattenRules(rules ?? {}, ['azure']),
    }),
    [],
  );

  const policies = useMemo(() => {
    return (policiesData?.listPolicies ?? [])
      .map(policy => ({
        ...policy,
        policy: flattenRules(policy?.policy ?? {}),
      }))
      .sort((a, b): void => (a.default ? -1 : 1));
  }, [policiesData]);

  const mappedPolicies = useMemo(() => {
    const connections: Connection[] = listConnectionsData?.listConnections?.connections || [];

    const mappedAccounts = policies.reduce(
      (obj, policy): void => ({
        ...obj,
        ...policy.scope.reduce(
          (aObj, account): void => ({
            ...aObj,
            [account]: policy,
          }),
          {},
        ),
      }),
      {},
    );

    const defaultAWS = policies.find(policy => policy.provider === 'aws' && policy.default) ?? {};
    const defaultAzure =
      policies.find(policy => policy.provider === 'azure' && policy.default) ?? {};

    return {
      aws: connections
        .filter(c => c.provider === 'aws')
        .map(account => ({
          account,
          policy: mappedAccounts?.[account?.cloudId] ?? defaultAWS,
        })),
      azure: connections
        .filter(c => c.provider === 'azure')
        .map(account => ({
          account,
          policy: mappedAccounts?.[account?.cloudId] ?? defaultAzure,
        })),
    };
  }, [policies, listConnectionsData]);

  return (
    <>
      <PageTitle title="Security Policies" />
      <Grid container spacing={3} style={{ padding: '15px' }}>
        <Grid item xs={12}>
          <ProviderTabs provider={provider} setProvider={setProvider} />
        </Grid>
        {provider === 'aws' && (
          <>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <PolicyTable
                    policies={policies.filter(policy => policy.provider === 'aws')}
                    rules={mappedBenchmarks?.aws ?? {}}
                    provider="aws"
                    loading={policiesLoading}
                  />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <AccountTable
                    accounts={mappedPolicies?.aws ?? []}
                    provider="aws"
                    loading={policiesLoading}
                  />
                </CardContent>
              </Card>
            </Grid>
          </>
        )}
        {provider === 'azure' && (
          <>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <PolicyTable
                    policies={policies.filter(policy => policy.provider === 'azure')}
                    rules={mappedBenchmarks?.azure ?? {}}
                    provider="azure"
                    loading={policiesLoading}
                  />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <AccountTable
                    accounts={mappedPolicies?.azure ?? []}
                    provider="azure"
                    loading={policiesLoading}
                  />
                </CardContent>
              </Card>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}
