import React, { useMemo, useState } from 'react';

import { useQuery } from '@apollo/client';
import { Card, CardContent, Grid, Snackbar } from '@cuda-networks/bds-core';

import {
  CloudTable,
  EditConnectionDialog,
  OnboardCloudWizard,
  DeleteConnectionDialog,
} from 'components/CloudConnections';
import PageButtonBar from 'components/common/PageButtonBar';
import PageTitle from 'components/common/PageTitle';
import ProviderTabs from 'components/common/ProviderTabs';
import RBACButton from 'components/common/RBACButton';
import StatusMessage from 'components/common/StatusMessage';
import { LIST_CONNECTIONS } from 'graphql/Accounts';
import useDialog from 'hooks/useDialog';
import useProviderTabs from 'hooks/useProviderTabs';
import { Connection, ListConnectionsData } from 'types';
import { parseQS } from 'utils/params';

export default function CloudAccounts(): JSX.Element {
  const [provider, setProvider] = useProviderTabs();
  const [connection, setConnection] = useState<Connection>();

  const [deleteDialogOpen, openDeleteDialog, closeDeleteDialog] = useDialog();
  const handleDelete = (connection: Connection) => () => {
    setConnection(connection);
    openDeleteDialog();
  };

  const [editDialogOpen, openEditDialog, closeEditDialog] = useDialog();
  const handleEdit = (connection: Connection) => (): void => {
    setConnection(connection);
    openEditDialog();
  };

  const [onboardDialogOpen, openOnboardDialog, closeOnboardDialog] = useDialog(
    parseQS()?.getStarted === 'true',
  );
  const handleAdd = (): void => openOnboardDialog();

  const { data: listConnectionsData, error } = useQuery<ListConnectionsData>(LIST_CONNECTIONS, {
    context: { clientName: 'accounts' },
  });

  const cloudsList = useMemo(
    () =>
      (listConnectionsData?.listConnections?.connections || []).filter(
        c => c?.cloudId?.split(':')?.[0] === provider,
      ),
    [listConnectionsData, provider],
  );

  const [message, setMessage] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const handleSucceed = (provider: string): void => {
    closeOnboardDialog();
    setProvider(provider || 'aws');
    setMessage('Cloud connection added and resource and security scans submitted.');
    setSnackbarOpen(true);
  };
  const closeSnackbar = () => setSnackbarOpen(false);
  return (
    <>
      <PageTitle title="Cloud Connections">
        <div style={{ display: 'flex' }}>
          <RBACButton
            color="primary"
            onClick={handleAdd}
            actionLabel="add cloud connections."
            variant="contained"
          >
            Add Connection
          </RBACButton>
        </div>
      </PageTitle>
      <Grid container spacing={3} style={{ padding: '15px' }}>
        <Grid item xs={12}>
          <PageButtonBar style={{ justifyContent: 'space-between' }}>
            <ProviderTabs provider={provider} setProvider={setProvider} />
          </PageButtonBar>
        </Grid>
        {error && (
          <Grid item xs={12}>
            <StatusMessage status="error" message="Error loading connection information." />
          </Grid>
        )}
        <Grid item xs={12}>
          <Card style={{ overflowX: 'auto' }}>
            <CardContent>
              <CloudTable
                connections={cloudsList}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {deleteDialogOpen && connection && (
        <DeleteConnectionDialog
          connection={connection}
          open={deleteDialogOpen}
          handleClose={closeDeleteDialog}
        />
      )}
      {editDialogOpen && connection && (
        <EditConnectionDialog
          connection={connection}
          open={editDialogOpen}
          handleClose={closeEditDialog}
        />
      )}
      {onboardDialogOpen && (
        <OnboardCloudWizard
          open={onboardDialogOpen}
          handleClose={closeOnboardDialog}
          handleSucceed={handleSucceed}
        />
      )}
      <Snackbar open={snackbarOpen} onClose={closeSnackbar}>
        <StatusMessage
          status="success"
          variant="filled"
          message={message}
          removable
          handleDismiss={closeSnackbar}
        />
      </Snackbar>
    </>
  );
}
