import AccountDetailsDialog from './AccountDetailsDialog';
import Accounts from './Accounts';
import AccountsTable from './AccountsTable';
import Admin from './Admin';
import SerialDetailsDialog from './SerialDetailsDialog';
import Serials from './Serials';
import SerialsTable from './SerialsTable';

export default Admin;

export {
  AccountDetailsDialog,
  Accounts,
  AccountsTable,
  SerialDetailsDialog,
  Serials,
  SerialsTable,
};
