import React, { useState, useMemo } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  CircularProgress,
  Typography,
  Select,
  MenuItem,
} from '@cuda-networks/bds-core';
import { FormControl } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import DialogTitle from 'components/common/DialogTitle';
import JSONTree from 'components/common/JSONTree';
import StatusMessage from 'components/common/StatusMessage';

const useStyles = makeStyles(
  {
    dialog: {
      width: '70%',
    },
    extension: {
      display: 'flex',
      alignItems: 'center',
      '& > div': {
        marginRight: '5px',
      },
    },
  },
  {
    name: 'SerialDetailsDialog',
  },
);

export default function SerialDetailsDialog({
  open,
  handleClose,
  status,
  handleExtend,
  extendStatus,
  serial,
}): JSX.Element {
  const classes = useStyles();

  const [extension, setExtension] = useState('30');

  const jsonTree = useMemo(() => {
    if (!serial) {
      return;
    }
    return (
      <JSONTree
        rootName="Serial Details"
        data={serial ?? {}}
        initiallyExpanded
        style={{ marginBottom: '16px' }}
      />
    );
  }, [serial]);

  return (
    <Dialog
      open={open}
      maxWidth="md"
      classes={{
        paper: classes.dialog,
      }}
      onClose={handleClose}
    >
      <DialogTitle handleClose={handleClose}>Serial Details</DialogTitle>
      <DialogContent>
        {status.fetching && <CircularProgress />}
        {status.error && <StatusMessage status="error" message={status.error} removable={false} />}
        {status.succeeded && (
          <>
            {jsonTree}
            <Typography variant="h2">Update Serial</Typography>
            {extendStatus.error && <StatusMessage status="error" message={extendStatus.error} />}
            {extendStatus.succeeded && (
              <StatusMessage status="success" message={extendStatus.result} />
            )}
            <div className={classes.extension}>
              <Typography variant="subtitle1" component="div">
                Extend expiration by:
              </Typography>
              <FormControl disabled={status.fetching || extendStatus.fetching} margin="none">
                <Select value={extension} onChange={e => setExtension(e.target.value)}>
                  <MenuItem key="30" value="30">
                    30 days
                  </MenuItem>
                  <MenuItem key="90" value="90">
                    90 days
                  </MenuItem>
                  <MenuItem key="120" value="120">
                    120 days
                  </MenuItem>
                </Select>
              </FormControl>
              <Button
                color="secondary"
                onClick={() => handleExtend(serial.accountId, serial?.serial, extension)}
              >
                Go
              </Button>
            </div>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
