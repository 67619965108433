import React from 'react';

import { Select } from '@cuda-networks/bds-core';
import { InputLabel, FormControl } from '@material-ui/core';
import PropTypes from 'prop-types';

const MenuProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: -7,
    horizontal: 10,
  },
  getContentAnchorEl: null,
  MenuListProps: {
    disablePadding: true,
  },
};

export default function FormSelect({ children, label, required, disabled, fullWidth, ...other }) {
  return (
    <FormControl disabled={disabled} fullWidth={fullWidth} variant="outlined">
      {label && <InputLabel required={required}>{label}</InputLabel>}
      <Select disabled={disabled} required={required} MenuProps={MenuProps} {...other}>
        {children}
      </Select>
    </FormControl>
  );
}

FormSelect.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
};

FormSelect.defaultProps = {
  label: null,
  required: false,
  disabled: false,
  fullWidth: false,
};
