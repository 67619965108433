import React from 'react';

import { useQuery, useMutation } from '@apollo/client';
import { Card, CardContent, Grid, CircularProgress, Tooltip } from '@cuda-networks/bds-core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import clsx from 'clsx';

import PageTitle from 'components/common/PageTitle';
import { AddLicenseDialog, LicenseInfo } from 'components/License';
import { LIST_SERIALS, ADD_SERIAL } from 'graphql/Accounts';
import useAuth from 'hooks/useAuth';
import useDialog from 'hooks/useDialog';
import useRequest from 'hooks/useRequest';
import { AddSerial, AddSerialData, AddSerialInput, ListSerialsData, Serial } from 'types';

const useStyles = makeStyles(
  theme => ({
    card: {
      height: '100%',
    },
    addLicense: {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      fontSize: '2rem',
      color: theme.palette.primary.main,
      cursor: 'pointer',
      textTransform: 'uppercase',
      '&:hover': {
        border: '1px solid #0088ce',
      },
      '& svg': {
        fontSize: '2rem',
      },
    },
    disabled: {
      pointerEvents: 'none',
      opacity: '0.5',
    },
  }),
  {
    name: 'License',
  },
);

export default function License(): JSX.Element {
  const classes = useStyles();
  const { account } = useAuth();

  const {
    data: serialsData,
    refetch: refetchSerials,
    loading,
  } = useQuery<ListSerialsData>(LIST_SERIALS, {
    context: { clientName: 'accounts' },
    fetchPolicy: 'network-only',
  });

  const [addDialogOpen, openAddDialog, closeAddDialog] = useDialog();
  const [addSerial] = useMutation<AddSerialData, AddSerialInput>(ADD_SERIAL, {
    context: { clientName: 'accounts' },
  });
  const [addSerialStatus, addSerialStarted, addSerialSucceeded, addSerialFailed] = useRequest();
  const handleAddSerial = async (input: AddSerial): Promise<void> => {
    addSerialStarted();
    try {
      await addSerial({
        variables: {
          input: {
            ...input,
          },
        },
      });

      refetchSerials();
      closeAddDialog();
      addSerialSucceeded();
    } catch (e) {
      addSerialFailed(
        e?.networkError?.result?.message || e?.message || 'Failed to update password',
      );
    }
  };

  const serials: Serial[] = serialsData?.serials || [];

  const allowed = ['manager', 'administrator'].includes(
    (account?.role || 'readonly')?.toLowerCase(),
  );
  const addCard = (
    <Grid style={{ minWidth: '350px', minHeight: '250px' }} item xs={12} sm={4} md={3} lg={2}>
      <Card
        className={clsx(classes.addLicense, { [classes.disabled]: !allowed })}
        onClick={openAddDialog}
      >
        <CardContent>
          <AddIcon />
          Add License
        </CardContent>
      </Card>
    </Grid>
  );

  return (
    <>
      <PageTitle title="License Management" />
      <Grid container spacing={3} style={{ padding: '15px' }}>
        {!loading ? (
          <>
            {serials.map(license => (
              <Grid
                style={{ minWidth: '350px', minHeight: '250px' }}
                key={license.serial}
                item
                xs={12}
                sm={4}
                md={3}
                lg={2}
              >
                <Card className={classes.card}>
                  <LicenseInfo license={license} />
                </Card>
              </Grid>
            ))}
            {allowed ? (
              addCard
            ) : (
              <Tooltip
                title="You do not have sufficient permissions on this account to add a license."
                arrow
              >
                {addCard}
              </Tooltip>
            )}
          </>
        ) : (
          <Grid item>
            <CircularProgress />
          </Grid>
        )}
      </Grid>
      {addDialogOpen && (
        <AddLicenseDialog
          handleDialogClose={closeAddDialog}
          handleConfirm={handleAddSerial}
          open={addDialogOpen}
          status={addSerialStatus}
        />
      )}
    </>
  );
}
