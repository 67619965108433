import React from 'react';

import { Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import PropTypes from 'prop-types';

import PaginatedTable from 'components/common/PaginatedTable';
import StatusMessage from 'components/common/StatusMessage';
import NoResults from 'components/common/table/NoResults';
import Timestamp from 'components/common/Timestamp';

export default function AccountsTable({ accounts, status, ...other }): JSX.Element {
  return (
    <>
      {status.error && <StatusMessage status="error" message={status.error} removable={false} />}
      <PaginatedTable loading={status.fetching} {...other}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Account Name</TableCell>
              <TableCell>Account ID</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Date Added</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {accounts.length ? (
              accounts.map(account => (
                <TableRow key={account.accountId}>
                  <TableCell>{account.accountName}</TableCell>
                  <TableCell>{account.accountId}</TableCell>
                  <TableCell>
                    {
                      {
                        NO_ACTIVE_SERIALS: 'No active serials',
                        NO_SERIALS: 'No serials',
                        HEALTHY: 'Active',
                      }[account.health]
                    }
                  </TableCell>
                  <TableCell>
                    <Timestamp ts={account.dateAdded} tsFormat="ISO" />
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <NoResults colSpan={4} value="No accounts." />
            )}
          </TableBody>
        </Table>
      </PaginatedTable>
    </>
  );
}

AccountsTable.propTypes = {
  status: PropTypes.shape({
    fetching: PropTypes.bool,
    error: PropTypes.string,
    succeeded: PropTypes.bool,
  }).isRequired,
  accounts: PropTypes.arrayOf(
    PropTypes.shape({
      accountName: PropTypes.string,
      accountId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      dateAdded: PropTypes.string,
    }),
  ).isRequired,
  handleDetails: PropTypes.func.isRequired,
};
