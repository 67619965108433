// @ts-nocheck
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

import { controlFromGeneratorId } from 'components/SecurityFindings/lib';
import rules from 'utils/rules.json';

export default {
  generateDetails(info, details, graphSelector) {
    const that = this;

    return Promise.all([
      this.loadImage('images/default-logo.png'),
      info.provider === 'azure'
        ? this.loadImage('images/azure-logo-simple.png')
        : this.loadImage('images/aws-logo.png'),
      this.imageFromHTML('complianceScores'),
      this.imageFromHTML(graphSelector),
    ]).then(function (images) {
      const doc = new jsPDF({
        unit: 'pt',
        format: 'letter',
      });

      const title = info.provider === 'azure' ? 'Microsoft Azure' : 'Amazon Web Services';

      const docHeight = doc.internal.pageSize.height;
      const docWidth = doc.internal.pageSize.width;

      const margins = {
        left: 18,
        right: 18,
      };

      const logoHeight = 30 / images[1].ratio;

      doc
        .addImage(images[1].imgData, 'PNG', margins.left, 88 - logoHeight / 2, 30, logoHeight)
        .setFontSize(12)
        .text(title, 54, 93)
        .addImage(images[2], 'JPEG', margins.left - 3, 145 - 24, 210, 52, undefined, 'SLOW');
      // .setFontStyle('bold')
      // .setFontSize(10)
      // .text('What does this score mean?', margins.left, 204)
      // .setFontSize(8)
      // .setFontStyle('normal')
      // .text('Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sit amet ex eu nibh rhoncus tempus in sit amet neque. Sed eu odio sollicitudin, elementum enim ac ante.', margins.left, 218, { maxWidth: 210 });

      doc
        .setFontSize(14)
        // .setFontStyle('bold')
        .text(`${info.scanType.toUpperCase()} Scan Results`, docWidth / 2, 94);

      doc.addImage(images[3], 'JPEG', docWidth / 2, 100, 200, 200);

      const rows = [];
      let rule;
      let result;
      const fillColors = {
        PASSED: '#5cb85c',
        LOW: '#dddddd',
        CRITICAL: '#d9534f',
        HIGH: '#d9534f',
        MEDIUM: '#f0ad4e',
        SKIPPED: '#F7F7F7',
      };
      const textColors = {
        PASSED: '#FFFFFF',
        LOW: '#545454',
        CRITICAL: '#FFFFFF',
        HIGH: '#FFFFFF',
        MEDIUM: '#FFFFFF',
        SKIPPED: '#545454',
      };

      for (let i = 0; i < details.length; ++i) {
        rule = details[i];
        result = rule.result.toUpperCase() || 'LOW';

        rows.push([
          {
            content: result,
            styles: {
              fillColor: fillColors[result],
              textColor: textColors[result],
              halign: 'center',
            },
          },
          {
            content: `${rule.description} (${rule.ruleId})` || '',
            colSpan: 2,
          },
          { content: rule.failReason || '' },
        ]);

        if (Object.prototype.hasOwnProperty.call(rule, 'offenders')) {
          if (Object.keys(rule.offenders).length) {
            rows.push([
              { content: '' },
              {
                content: 'RULE OFFENDERS',
                styles: {
                  fillColor: '#DDDDDD',
                  fontSize: 6,
                  fontStyle: 'bold',
                },
              },
              { content: ' ', styles: { fillColor: '#DDDDDD' } },
              { content: ' ', styles: { fillColor: '#DDDDDD' } },
            ]);
          }
          for (const key of Object.keys(rule.offenders)) {
            const value = rule.offenders[key];
            for (let n = 0; n < value.length; ++n) {
              rows.push([
                { content: '' },
                { content: `Region : ${key === 'global' ? 'Global' : key}` },
                { content: value[n], colSpan: 2 },
              ]);
            }
          }
        }
      }

      if (!rows.length) {
        rows.push([{ content: 'No results', colSpan: 4, styles: { halign: 'center' } }]);
      }

      doc.autoTable({
        theme: 'plain',
        startY: 270,
        tableWidth: docWidth - margins.left - margins.right,
        showHead: 'firstPage',
        rowPageBreak: 'avoid',
        margin: {
          top: 70,
          right: margins.right,
          bottom: 80,
          left: margins.left,
        },
        styles: {
          valign: 'middle',
          fontSize: 8,
        },
        headStyles: {
          minCellHeight: 24,
          cellPadding: { top: 0, right: 10, bottom: 0, left: 10 },
          textColor: '#7F7F7F',
          fontStyle: 'normal',
        },
        bodyStyles: {
          minCellHeight: 20,
          cellPadding: { top: 3, right: 10, bottom: 3, left: 10 },
        },
        columnStyles: {
          0: { cellWidth: 60 },
          1: { cellWidth: 80 },
          2: { cellWidth: 150 },
          3: { cellWidth: 230 },
        },
        head: [
          [{ content: 'RESULT' }, { content: 'RULE' }, { content: ' ' }, { content: 'DETAILS' }],
        ],
        didDrawCell(data) {
          const index = parseInt(data.column.index, 10);
          const { content } = data.cell.raw;
          const { cell } = data;

          // borders
          doc.setDrawColor('#DDDDDD').setLineWidth(1);

          // top and bottom borders for all cells that are not empty indentations
          if (!(index === 0 && !content)) {
            doc
              .line(cell.x, cell.y, cell.x + cell.width, cell.y)
              .line(
                cell.x,
                cell.y + data.row.height,
                cell.x + cell.width,
                cell.y + data.row.height,
              );
          }
          // left border for first or second cell depending on whether it is an indented row
          if ((index === 0 && cell.raw.content) || (data.section === 'body' && index === 1)) {
            doc.line(cell.x, cell.y, cell.x, cell.y + data.row.height);
          }
          // right border for cells that span all the way to the right
          if (index + cell.colSpan === 4) {
            doc.line(cell.x + cell.width, cell.y, cell.x + cell.width, cell.y + data.row.height);
          }
        },
        body: rows,
      });

      const pageCount = doc.internal.getNumberOfPages();
      for (let i = 1; i <= pageCount; ++i) {
        doc.setPage(i);
        that.generateHeader(
          doc,
          docWidth,
          'Detailed Scan Report',
          that.formatDate(info.time),
          info.account,
          info.cloudId,
        );
        that.generateFooter(doc, docWidth, docHeight, images[0], i, pageCount);
      }

      doc.save(
        `Barracuda CSG Detailed ${info.scanType.toUpperCase()} Report ${that.formatDate(
          info.time,
        )}.pdf`,
      );
      doc.setProperties({
        title: `Barracuda CSG Detailed Report ${that.formatDate(info.time)}`,
      });
      window.open(doc.output('bloburl'), '_blank');
    });
  },

  generateComplianceSummary(info, aggregated = {}, graphSelector) {
    const that = this;

    return Promise.all([
      this.loadImage('images/default-logo.png'),
      info.provider === 'azure'
        ? this.loadImage('images/azure-logo-simple.png')
        : this.loadImage('images/aws-logo.png'),
      this.imageFromHTML(graphSelector),
      this.loadImage('images/pdf/check.svg'),
      this.loadImage('images/pdf/clear.svg'),
      this.loadImage('images/pdf/remove.svg'),
    ]).then(function (images) {
      const doc = new jsPDF({
        unit: 'pt',
        format: 'letter',
      });

      const title = info.provider === 'azure' ? 'Microsoft Azure' : 'Amazon Web Services';

      const docHeight = doc.internal.pageSize.height;
      const docWidth = doc.internal.pageSize.width;

      const margins = {
        left: 18,
        right: 18,
      };

      const logoHeight = 30 / images[1].ratio;

      doc
        .addImage(images[1].imgData, 'PNG', margins.left, 88 - logoHeight / 2, 30, logoHeight)
        .setFontSize(12)
        .text(title, 54, 93);

      doc
        .setFontSize(14)
        // .setFontStyle('bold')
        .text('Controls', docWidth / 2, 94);

      doc.addImage(images[2], 'JPEG', docWidth / 2, 100, 200, 200);

      const rows = Object.entries(aggregated).reduce((arr, [key, val]) => {
        const [standard, provider, version] = key.split(':');
        const controlRules = rules?.[standard]?.[provider]?.[version] || {};

        return [
          ...arr,
          [
            [
              {
                content: controlFromGeneratorId(key, ['fullName', 'version']),
                colSpan: 4,
              },
            ],
            [
              {
                content: 'CONTROL',
                styles: {
                  minCellHeight: 24,
                  cellPadding: { top: 0, right: 10, bottom: 0, left: 10 },
                  textColor: '#7F7F7F',
                  fontStyle: 'normal',
                  valign: 'middle',
                },
              },
              {
                content: 'TITLE',
                styles: {
                  minCellHeight: 24,
                  cellPadding: { top: 0, right: 10, bottom: 0, left: 10 },
                  textColor: '#7F7F7F',
                  fontStyle: 'normal',
                  valign: 'middle',
                },
              },
              {
                content: 'FINDINGS',
                styles: {
                  minCellHeight: 24,
                  cellPadding: { top: 0, right: 10, bottom: 0, left: 10 },
                  textColor: '#7F7F7F',
                  fontStyle: 'normal',
                  valign: 'middle',
                },
              },
              {
                content: 'RESULT',
                styles: {
                  minCellHeight: 24,
                  cellPadding: { top: 0, right: 10, bottom: 0, left: 10 },
                  textColor: '#7F7F7F',
                  fontStyle: 'normal',
                  valign: 'middle',
                },
              },
            ],
            ...Object.keys(val)
              .sort((aId, bId) => {
                return (controlRules?.[aId]?.index || 0) - (controlRules?.[bId]?.index || 0);
              })
              .map(id => {
                const results = val?.[id];

                return [
                  {
                    content: id,
                    styles: {
                      cellPadding: { top: 5, right: 10, bottom: 5, left: 25 },
                    },
                  },
                  {
                    content: controlRules?.[id]?.title || '',
                  },
                  ...(results?.skipped
                    ? [
                        {
                          content: 'Skipped due to policy',
                          styles: {
                            minCellHeight: 20,
                          },
                        },
                        {
                          content: 'Skipped',
                          styles: {
                            cellPadding: { top: 5, right: 10, bottom: 5, left: 20 },
                          },
                        },
                      ]
                    : [
                        {
                          content: '',
                          results,
                        },
                        {
                          content: results?.failed ? 'Noncompliant' : 'Compliant',
                          styles: {
                            cellPadding: { top: 5, right: 10, bottom: 5, left: 20 },
                          },
                        },
                      ]),
                ];
              }),
          ],
        ];
      }, []);

      if (!rows.length) {
        rows.push([[{ content: 'No results', colSpan: 3, styles: { halign: 'center' } }]]);
      }

      const colors = {
        passed: '#95CC66',
        failed: '#E34050',
        suppressed: '#DDDDDD',
      };

      const generateTable = (doc, rows, startY) => {
        doc.autoTable({
          theme: 'plain',
          startY,
          tableWidth: docWidth - margins.left - margins.right,
          showHead: 'never',
          rowPageBreak: 'avoid',
          margin: {
            top: 70,
            right: margins.right,
            bottom: 80,
            left: margins.left,
          },
          styles: {
            valign: 'middle',
            fontSize: 8,
          },
          headStyles: {
            minCellHeight: 24,
            cellPadding: { top: 0, right: 10, bottom: 0, left: 10 },
            textColor: '#7F7F7F',
            fontStyle: 'normal',
          },
          bodyStyles: {
            minCellHeight: 20,
            cellPadding: { top: 5, right: 10, bottom: 5, left: 10 },
          },
          columnStyles: {
            0: { cellWidth: 60, valign: 'top' },
            1: { cellWidth: 100, valign: 'top' },
            2: { cellWidth: 300, cellHeight: 60, minCellHeight: 50 },
            3: { cellWidth: 114, valign: 'middle' },
          },
          didDrawCell(data) {
            const column = parseInt(data.column.index, 10);
            const row = parseInt(data.row.index, 10);
            const { content } = data.cell.raw;
            const { cell } = data;

            if (column === 2) {
              const { results } = data.cell.raw;
              let curX = cell.x + 14;
              const startY = cell.y + cell.height / 2;

              if (results?.skipped) {
                // doc.text('Skipped due to policy', curX, startY + 2);
              } else if (results) {
                const total = Object.values(results).reduce((count, result) => count + result, 0);

                doc.setDrawColor('#DDDDDD').setLineWidth(1);
                const labelText: string[] = [];
                ['passed', 'failed', 'suppressed'].forEach(r => {
                  if (results?.[r]) {
                    const endX = curX + (results?.[r] / total) * 272;
                    doc
                      .setDrawColor(colors?.[r])
                      .setLineWidth(20)
                      .line(curX, startY, endX, startY)
                      .setTextColor('#FFFFFF')
                      .setFontSize(8);
                    // .text(String(results?.[r]), curX + (endX - curX) / 2 - 2, startY + 2);
                    curX = endX;
                    labelText.push(`${r.charAt(0).toUpperCase() + r.slice(1)}: ${results?.[r]}`);
                  }
                });

                doc
                  .setTextColor('#000000')
                  .setFontSize(6)
                  .text(labelText.join(', '), cell.x + 14, startY + 20);
              }
            }
            if (column === 3 && row >= 2) {
              const resultToImage = {
                Compliant: images[3].imgData,
                Noncompliant: images[4].imgData,
                Skipped: images[5].imgData,
              };
              doc.addImage(
                resultToImage[content],
                'SVG',
                cell.x + 6,
                cell.y + cell.height / 2 - 6,
                12,
                12,
                undefined,
                'SLOW',
              );
            }

            doc.setDrawColor('#DDDDDD').setLineWidth(1);

            // top and bottom borders for all cells that are not empty indentations
            if (!(column === 0 && !content)) {
              doc
                .line(cell.x, cell.y, cell.x + cell.width, cell.y)
                .line(
                  cell.x,
                  cell.y + data.row.height,
                  cell.x + cell.width,
                  cell.y + data.row.height,
                );
            }
            // left border
            doc.line(cell.x, cell.y, cell.x, cell.y + data.row.height);

            // right border for cells that span all the way to the right
            if (column + cell.colSpan === 4) {
              doc.line(cell.x + cell.width, cell.y, cell.x + cell.width, cell.y + data.row.height);
            }
          },
          body: rows,
        });
      };

      rows.forEach((row, i) => {
        if (i) {
          doc.addPage();
        }
        generateTable(doc, row, (i ? 50 : 230) + 20);
      });

      const pageCount = doc.internal.getNumberOfPages();
      for (let i = 1; i <= pageCount; ++i) {
        doc.setPage(i);
        that.generateHeader(
          doc,
          docWidth,
          'Compliance Summary',
          that.formatDate(info.time),
          info.account,
          info.cloudId,
        );
        that.generateFooter(doc, docWidth, docHeight, images[0], i, pageCount);
      }

      doc.save(`Barracuda CSG Compliance Summary Report ${that.formatDate(info.time)}.pdf`);
      doc.setProperties({
        title: `Barracuda CSG Compliance Summary Report ${that.formatDate(info.time)}`,
      });
      window.open(doc.output('bloburl'), '_blank');
    });
  },
  generateHeader(doc, width, title, time, account, resource) {
    doc
      // .setFontStyle('normal')
      .setTextColor('#000000')
      .setFontSize(18)
      .text(title, 16, 34)
      .setFontSize(10)
      .text(time, 18, 46)
      .setDrawColor('#DDDDDD')
      .setLineWidth(1)
      .line(18, 52.5, width - 18, 52.5);

    if (resource) {
      doc.setFontSize(10).text(account, 380, 36).setFontSize(8).text(resource, 380, 46);
    } else {
      doc.setFontSize(10).text(account, 380, 46);
    }

    return doc;
  },

  generateFooter(doc, width, height, logo, page, total) {
    return (
      doc
        // .setFontStyle('normal')
        .setFontSize(10)
        .setTextColor('#000000')
        .text('Page:', 18, height - 50)
        // .setFontStyle('bold')
        .text(`${page} of ${total}`, 50, height - 50)
        .setDrawColor('#DDDDDD')
        .setLineWidth(1)
        .line(18, height - 69.5, width - 18, height - 69.5)
        .addImage(logo.imgData, 'PNG', width - 100 - 18, height - 65, 100, 100 / logo.ratio)
    );
  },

  loadImage(src) {
    return new Promise(resolve => {
      const image = new Image();

      image.crossOrigin = 'anonymous';

      image.onload = function () {
        const canvas = document.createElement('canvas');
        canvas.width = this.naturalWidth;
        canvas.height = this.naturalHeight;

        const ctx = canvas.getContext('2d');
        ctx.fillStyle = '#fff'; /// set white fill style
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        canvas.getContext('2d').drawImage(this, 0, 0);

        resolve({
          imgData: canvas.toDataURL('image/jpeg'),
          ratio: this.naturalWidth / this.naturalHeight,
        });
      };

      image.src = src;
    });
  },

  imageFromHTML(selector) {
    return new Promise(resolve => {
      const input = document.getElementById(selector);
      html2canvas(input, {
        scale: 4,
        onclone(clonedDoc) {
          clonedDoc.getElementById(selector).style.display = 'block';
        },
      }).then(function (canvas) {
        resolve(canvas.toDataURL('image/jpeg'));
      });
    });
  },

  generateContainer(doc, title, params) {
    const realY = params.y + 0.5;
    const realX = params.x + 0.5;
    const headerHeight = 24.5;

    doc
      .setLineWidth(1)
      .setFillColor('#DDDDDD')
      .setDrawColor('#DDDDDD')
      .roundedRect(realX, realY, params.width, params.height + headerHeight - 0.5, 2, 2)
      .roundedRect(realX, realY, params.width, headerHeight, 2, 2, 'F')
      .setLineWidth(4)
      .line(
        params.x,
        realY + headerHeight - 2,
        params.x + params.width,
        realY + headerHeight - 2,
        'F',
      )
      .setFontSize(8)
      .setTextColor('#000000')
      // .setFontStyle('bold')
      .text(title.toUpperCase(), realX + 8, realY + 15);

    return {
      x: params.x + 1,
      y: realY + headerHeight,
      width: params.width - 1,
      height: params.height,
    };
  },

  generateComplianceRow(doc, key, value, x, y, width) {
    const barLength = width - 10 - 10 - 70;
    const scoreBarLength = value.total_score
      ? Math.round((value.score / value.total_score) * barLength + 1)
      : 0;
    doc
      .setLineWidth(1)
      // .setFontStyle('normal')
      .setFontSize(12)
      .text(key, x + 10, y)
      .setDrawColor('#DDDDDD')
      .roundedRect(x + 10, y + 3, barLength, 14, 2, 2);

    if (scoreBarLength) {
      doc
        .setFillColor('#3579BC')
        .roundedRect(x + 9.5, y + 2.5, scoreBarLength, 15, 2, 2, 'F')
        .setDrawColor('#3579BC')
        .setLineWidth(4)
        .line(
          x + 9.5 + scoreBarLength - 2,
          y + 2.5,
          x + 9.5 + scoreBarLength - 2,
          y + 2.5 + 15,
          'F',
        );
    }

    doc.text(`${value.score} / ${value.total_score}`, x + barLength + 10 + 10, y + 15);

    return doc;
  },

  formatDate(ts) {
    const d = new Date(ts);
    return `${d.getFullYear()}-${`00${d.getMonth() + 1}`.slice(-2)}-${`00${d.getDate()}`.slice(
      -2,
    )} ${`00${d.getHours()}`.slice(-2)}:${`00${d.getMinutes()}`.slice(
      -2,
    )}:${`00${d.getSeconds()}`.slice(-2)}`;
  },
};
