import React from 'react';

import { Button } from '@cuda-networks/bds-core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles(
  theme => ({
    container: {
      '& svg': {
        marginRight: '10px',
      },
      '&:hover': {
        backgroundColor: '#BFBFBF',
      },
    },
    selected: {
      backgroundColor: '#DDDDDD',
      '&:hover': {
        backgroundColor: '#DDDDDD',
      },
    },
  }),
  {
    name: 'ProviderButton',
  },
);

export default function ProviderButton({ children, name, selected, ...other }): JSX.Element {
  const classes = useStyles();
  return (
    <Button
      variant="text"
      className={clsx(classes.container, { [classes.selected]: selected })}
      data-test-id={`provider-button-${name}`}
      name={name}
      {...other}
    >
      {children}
    </Button>
  );
}

ProviderButton.propTypes = {
  selected: PropTypes.bool,
};

ProviderButton.defaultProps = {
  selected: false,
};
