import React from 'react';

import { Grid } from '@cuda-networks/bds-core';
import { makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
import { Link } from 'react-router-dom';
import {
  PieChart,
  Pie,
  Legend,
  Cell,
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
} from 'recharts';

import StatusMessage from 'components/common/StatusMessage';
import { GraphInfo, GraphTypes, RequestStatus } from 'types';

import { severityGraph, resultGraph, providerGraph, controlGraph } from './graphs';
import useDashboardGraph from './useDashboardGraph';

const graphs = {
  severity: {
    parser: severityGraph,
    groupBy: 'severity.label',
    link: 'risks',
  },
  control: {
    parser: controlGraph,
    groupBy: 'compliance.standard',
    link: 'risks',
  },
  provider: {
    parser: providerGraph,
    groupBy: 'compliance.provider',
    link: 'risks',
  },
  result: {
    parser: resultGraph,
    groupBy: 'compliance.status',
    link: 'findings',
  },
};

const useStyles = makeStyles(
  {
    item: {
      margin: 8,
    },
  },
  {
    name: 'ComplianceGraph',
  },
);

export interface ComplianceGraphProps {
  graph: GraphTypes;
  graphInfo: GraphInfo;
  cloudsStatus: RequestStatus;
}

export default function ComplianceGraph(props: ComplianceGraphProps): JSX.Element {
  const { graph, graphInfo, cloudsStatus } = props;
  const classes = useStyles();
  const { groupBy, parser, link } = graphs[graph];

  const { topOrder, filter, topFilter } = graphInfo;

  const [data, status] = useDashboardGraph(groupBy, parser, topOrder, filter, topFilter);

  const fetching = (cloudsStatus.fetching || status.fetching) && !cloudsStatus.error;
  return (
    <Grid container item xs={12} alignContent="space-between" style={{ minHeight: '402px' }}>
      {(cloudsStatus.error || status.error) && (
        <StatusMessage status="error" message="Error loading graph data" />
      )}
      <Grid container item justify="space-evenly" className={classes.item}>
        {fetching && (
          <>
            <Skeleton variant="circle" width={200} height={200} />
            <Skeleton variant="rect" width={60} height={60} />
          </>
        )}
        {status.succeeded && !data?.total?.length && (
          <StatusMessage status="info" message="No data available" />
        )}
        {status.succeeded && Boolean(data?.total?.length) && (
          <div style={{ width: '100%', height: 200 }}>
            <ResponsiveContainer>
              <PieChart margin={{ top: 0, left: 0, right: 0, bottom: 0 }}>
                <Pie
                  isAnimationActive={false}
                  data={data.total}
                  dataKey="count"
                  nameKey="name"
                  innerRadius={0}
                  outerRadius={100}
                >
                  {data.total.map(entry => (
                    <Cell key={`${graph}-cell-${entry.name}`} fill={entry.color} />
                  ))}
                </Pie>
                <Legend align="right" layout="vertical" />
              </PieChart>
            </ResponsiveContainer>
          </div>
        )}
      </Grid>
      <Grid container item className={classes.item}>
        {fetching && <Skeleton variant="rect" width="100%" height={150} />}
        {status.succeeded && Boolean(data?.total?.length) && (
          <div style={{ width: '100%', height: 150 }}>
            <ResponsiveContainer>
              <BarChart data={data.top.data} layout="vertical">
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type="number" />
                <YAxis dataKey="name" type="category" width={150} interval={0} />
                {data.top.segments.map(s => (
                  <Bar
                    dataKey={s.key}
                    key={`${graph}-${s.key}`}
                    stackId="a"
                    fill={s.color}
                    maxBarSize={30}
                  />
                ))}
              </BarChart>
            </ResponsiveContainer>
          </div>
        )}
      </Grid>
      <Grid item container justify="flex-end" xs={12}>
        {link === 'risks' && <Link to="/security-risks">All Risks</Link>}
        {link === 'findings' && <Link to="/security-findings">All Findings</Link>}
      </Grid>
    </Grid>
  );
}
