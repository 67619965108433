import { useMemo } from 'react';

import { unsupported } from 'components/SecurityPolicy/lib';
import { CloudType, ComplianceType } from 'types';
import rules from 'utils/rules.json';

interface Rule {
  profile?: number;
  scored?: boolean;
  severity: string;
  covers?: string[];
  rule_id: string;
  id: string;
  title: string;
  description: string;
  rationale: string;
  remediation?: {
    console?: string;
    cli?: string;
  };
  index: number;
}

type Rules = Record<ComplianceType, Record<CloudType, Record<string, Record<string, Rule>>>>;

const formatRules = (controlRules: Record<string, Rule> = {}): string[] =>
  Object.values(controlRules)
    .filter(rule => !unsupported.includes(rule.rule_id))
    .map(rule => rule.rule_id);

const generateRulesList = (rulesList): string[] => [
  ...formatRules(rulesList?.cis?.aws?.['v1.2.0'] ?? {}),
  ...formatRules(rulesList?.nist?.aws?.['v1.0.0'] ?? {}),
  ...formatRules(rulesList?.hipaa?.aws?.['v1.0.0'] ?? {}),
  ...formatRules(rulesList?.pci_dss?.aws?.['v1.0.0'] ?? {}),
  ...formatRules(rulesList?.bsbp?.aws?.['v1.0.0'] ?? {}),
  ...formatRules(rulesList?.cis?.azure?.['v1.1.0'] ?? {}),
  ...formatRules(rulesList?.nist?.azure?.['v1.0.0'] ?? {}),
  ...formatRules(rulesList?.hipaa?.azure?.['v1.0.0'] ?? {}),
  ...formatRules(rulesList?.pci_dss?.azure?.['v1.0.0'] ?? {}),
  ...formatRules(rulesList?.bsbp?.azure?.['v1.0.0'] ?? {}),
];

export default function useRules(): [string[], Rules] {
  const rulesList = useMemo(() => generateRulesList(rules), []);

  return [rulesList, rules];
}
