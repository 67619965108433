import React from 'react';

import { useMutation } from '@apollo/client';

import { SUBMIT_SCANS } from 'graphql/SecurityFindings';
import useRequest from 'hooks/useRequest';

import ScanButton from './ScanButton';

export default function SecurityScanButton({ ...scanButtonProps }): JSX.Element {
  const [submit] = useMutation(SUBMIT_SCANS, {
    context: { clientName: 'findings' },
  });
  const [submitStatus, submitStarted, submitSucceeded, submitFailed] = useRequest();
  const handleScan = async (cloudList): void => {
    submitStarted();
    try {
      const { data } = await submit({
        variables: { cloudList },
      });

      if (data?.response?.error === 'None') {
        submitSucceeded('Security scan request submitted.');
      } else {
        submitFailed('Failed to submit request for security scan.');
      }
    } catch (e) {
      submitFailed('Failed to submit request for security scan.');
    }
  };

  return <ScanButton {...scanButtonProps} onScan={handleScan} status={submitStatus} />;
}
