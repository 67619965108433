import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import DownIcon from '@material-ui/icons/ArrowDropDown';
import RightIcon from '@material-ui/icons/ArrowRight';
import clsx from 'clsx';

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      fontSize: '12px',
      lineHeight: '20px',
      minWidth: '120px',
      '& > ul': {
        listStyle: 'none',
        margin: 0,
        paddingLeft: '30px',
        borderLeft: '1px solid #BFBFBF',
        '& > li:first-child': {
          marginLeft: '-31px',
          borderLeft: '1px solid #FFFFFF',
        },
      },
      wordBreak: 'break-word',
    },
    key: {
      color: theme.palette.primary.main,
      marginRight: '5px',
    },
    expander: {
      cursor: 'pointer',
      display: 'flex',
      '& > svg': {
        height: '20px',
        width: '20px',
        marginLeft: '-8px',
      },
    },
    expanded: {
      flexBasis: '100%',
    },
    preview: {
      maxWidth: '40%',
      overflow: 'hidden',
      height: '20px',
      wordBreak: 'break-all',
      display: 'inline-flex',
    },
  }),
  {
    name: 'JSONTree',
  },
);

export default function JSONTree({
  rootName,
  data: inputData,
  initiallyExpanded = false,
  ...others
}): JSX.Element {
  const classes = useStyles();

  const [expanded, setExpanded] = useState(initiallyExpanded);

  const toggleExpanded = (): void => {
    setExpanded(!expanded);
  };

  const { __typename, ...data } = inputData;

  return (
    <div className={classes.root} {...others}>
      {expanded ? (
        <ul>
          <li
            className={clsx(classes.key, classes.expander, {
              [classes.expanded]: expanded,
            })}
            onClick={toggleExpanded}
          >
            {expanded ? <DownIcon /> : <RightIcon />}
            {`${rootName}:`}
          </li>
          {Object.keys(data).map(key => (
            <li key={key}>
              {!data[key] ||
              typeof data[key] === 'number' ||
              typeof data[key] === 'string' ||
              typeof data[key] === 'boolean' ? (
                <>
                  <span className={classes.key}>{`${key}:`}</span>
                  <span>{data[key]}</span>
                </>
              ) : (
                <JSONTree classes={classes} rootName={key} data={data[key]} initiallyExpanded />
              )}
            </li>
          ))}
        </ul>
      ) : (
        <>
          <span
            className={clsx(classes.key, classes.expander, {
              [classes.expanded]: expanded,
            })}
            onClick={toggleExpanded}
          >
            {expanded ? <DownIcon /> : <RightIcon />}
            {`${rootName}:`}
          </span>
          <span className={classes.preview}>{JSON.stringify(data)}</span>
        </>
      )}
    </div>
  );
}
