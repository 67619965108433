import React from 'react';

import { Button, Grid, DialogActions, DialogContent } from '@cuda-networks/bds-core';
import PropTypes from 'prop-types';

export default function Compliance({ account, handleNext }) {
  return (
    <>
      <DialogContent>
        <Grid container item>
          <Grid item xs={12} />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleNext} color="primary" autoFocus>
          Next
        </Button>
      </DialogActions>
    </>
  );
}

Compliance.propTypes = {
  account: PropTypes.string.isRequired,
  handleNext: PropTypes.func.isRequired,
};
