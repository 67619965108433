// @ts-nocheck
// helper functions
import { DateTime } from 'luxon';

import { CloudType, Location } from 'types';

export const generateJsonFromSchema = (schema, value) => {
  let obj = null;

  if (schema.type === 'object') {
    obj = {};

    Object.entries(schema.properties).forEach(([key, nestedSchema]) => {
      let nestedSchemaData = null;
      if (value !== undefined && value !== null && typeof value === 'object') {
        nestedSchemaData = value[key];
      }
      obj[key] = generateJsonFromSchema(nestedSchema, nestedSchemaData);
    });
  } else if (schema.type === 'array') {
    obj = [];
    if (value !== undefined && value !== null && Array.isArray(value) && value.length) {
      value.forEach(d => {
        const item = generateJsonFromSchema(schema.items, d);
        obj.push(item);
      });
    }
  } else if (schema.type === 'string') {
    obj = schema.default || '';
    if (value !== undefined && value !== null && typeof value === 'string') {
      obj = value;
    }
  } else if (schema.type === 'integer') {
    obj = schema.default || 0;
    if (value !== undefined && value !== null && typeof value === 'number') {
      obj = value;
    }
  } else if (schema.type === 'boolean') {
    obj = schema.default || false;
    if (value !== undefined && value !== null && typeof value === 'number') {
      obj = value;
    }
  } else {
    throw new Error(`Unknown type '${schema.type}'"`);
  }

  return obj;
};

export const hasProp = (obj, prop) => Object.prototype.hasOwnProperty.call(obj, prop);

export const providerFromCloudId = (cloudId = ''): CloudType => {
  if (cloudId.substr(0, 3) === 'aws') {
    return 'aws';
  }
  if (cloudId.substr(0, 5) === 'azure') {
    return 'azure';
  }
};

export const periodToRange = (period, timestamp = false) => {
  const local = DateTime.local();
  let start;
  let end = DateTime.local();

  switch (period) {
    case 'yesterday':
      start = local.minus({ days: 1 }).startOf('day');
      end = local.minus({ days: 1 }).endOf('day');
      break;
    case 'today':
      start = local.startOf('day');
      end = local.endOf('day');
      break;
    case 'last_24_hours':
      start = local.minus({ days: 1 });
      break;
    case 'last_7_days':
      start = local.minus({ days: 7 });
      break;
    case 'last_30_days':
      start = local.minus({ days: 30 });
      break;
    case 'last_60_days':
      start = local.minus({ days: 60 });
      break;
    case 'last_90_days':
      start = local.minus({ days: 90 });
      break;
    case 'last_120_days':
      start = local.minus({ days: 120 });
      break;
    case 'last_week':
      start = local.minus({ weeks: 1 }).startOf('week');
      end = local.minus({ weeks: 1 }).endOf('week');
      break;
    case 'this_week':
      start = local.startOf('week');
      end = local.endOf('week');
      break;
    case 'this_last_week':
      start = local.minus({ weeks: 1 }).startOf('week');
      end = local.endOf('week');
      break;
    case 'last_month':
      start = local.minus({ months: 1 }).startOf('month');
      end = local.minus({ months: 1 }).endOf('month');
      break;
    case 'this_month':
      start = local.startOf('month');
      end = local.endOf('month');
      break;
    case 'this_last_month':
      start = local.minus({ months: 1 }).startOf('month');
      end = local.endOf('month');
      break;
    case 'last_3_months':
      start = local.minus({ months: 3 }).startOf('month');
      end = local.minus({ months: 1 }).endOf('month');
      break;
    case 'last_6_months':
      start = local.minus({ months: 6 }).startOf('month');
      end = local.minus({ months: 1 }).endOf('month');
      break;
    default:
      start = local.minus({ days: 1 });
      break;
  }

  if (timestamp) {
    start = Math.floor(start.toSeconds());
    end = Math.floor(end.toSeconds());
  }

  return {
    start,
    end,
  };
};

export const countryCodes = [
  { label: 'United States', value: 'US' },
  { label: 'Afghanistan', value: 'AF' },
  { label: 'Aland Islands', value: 'AX' },
  { label: 'Albania', value: 'AL' },
  { label: 'Algeria', value: 'DZ' },
  { label: 'American Samoa', value: 'AS' },
  { label: 'Andorra', value: 'AD' },
  { label: 'Angola', value: 'AO' },
  { label: 'Anguilla', value: 'AI' },
  { label: 'Antarctica', value: 'AQ' },
  { label: 'Antigua and Barbuda', value: 'AG' },
  { label: 'Argentina', value: 'AR' },
  { label: 'Armenia', value: 'AM' },
  { label: 'Aruba', value: 'AW' },
  { label: 'Australia', value: 'AU' },
  { label: 'Austria', value: 'AT' },
  { label: 'Azerbaijan', value: 'AZ' },
  { label: 'Bahamas', value: 'BS' },
  { label: 'Bahrain', value: 'BH' },
  { label: 'Bangladesh', value: 'BD' },
  { label: 'Barbados', value: 'BB' },
  { label: 'Belarus', value: 'BY' },
  { label: 'Belgium', value: 'BE' },
  { label: 'Belize', value: 'BZ' },
  { label: 'Benin', value: 'BJ' },
  { label: 'Bermuda', value: 'BM' },
  { label: 'Bhutan', value: 'BT' },
  { label: 'Bolivia', value: 'BO' },
  { label: 'Bonaire, Sint Eustatius and Saba', value: 'BQ' },
  { label: 'Bosnia and Herzegovina', value: 'BA' },
  { label: 'Botswana', value: 'BW' },
  { label: 'Bouvet Island', value: 'BV' },
  { label: 'Brazil', value: 'BR' },
  { label: 'British Indian Ocean Territory', value: 'IO' },
  { label: 'British Virgin Islands', value: 'VG' },
  { label: 'Brunei Darussalam', value: 'BN' },
  { label: 'Bulgaria', value: 'BG' },
  { label: 'Burkina Faso', value: 'BF' },
  { label: 'Burundi', value: 'BI' },
  { label: 'Cambodia', value: 'KH' },
  { label: 'Cameroon', value: 'CM' },
  { label: 'Canada', value: 'CA' },
  { label: 'Cape Verde', value: 'CV' },
  { label: 'Cayman Islands', value: 'KY' },
  { label: 'Central African Republic', value: 'CF' },
  { label: 'Chad', value: 'TD' },
  { label: 'Chile', value: 'CL' },
  { label: 'China', value: 'CN' },
  { label: 'Chinese Taipei', value: 'TW' },
  { label: 'Christmas Island', value: 'CX' },
  { label: 'Cocos (Keeling) Islands', value: 'CC' },
  { label: 'Colombia', value: 'CO' },
  { label: 'Comoros', value: 'KM' },
  { label: 'Congo', value: 'CG' },
  { label: 'Congo, the Democratic Republic of the', value: 'CD' },
  { label: 'Cook Islands', value: 'CK' },
  { label: 'Costa Rica', value: 'CR' },
  { label: "Cote d'Ivoire", value: 'CI' },
  { label: 'Croatia', value: 'HR' },
  { label: 'Cuba', value: 'CU' },
  { label: 'CURACAO', value: 'CW' },
  { label: 'Cyprus', value: 'CY' },
  { label: 'Czech Republic', value: 'CZ' },
  { label: 'Denmark', value: 'DK' },
  { label: 'Djibouti', value: 'DJ' },
  { label: 'Dominica', value: 'DM' },
  { label: 'Dominican Republic', value: 'DO' },
  { label: 'Ecuador', value: 'EC' },
  { label: 'Egypt', value: 'EG' },
  { label: 'El Salvador', value: 'SV' },
  { label: 'Equatorial Guinea', value: 'GQ' },
  { label: 'Eritrea', value: 'ER' },
  { label: 'Estonia', value: 'EE' },
  { label: 'Ethiopia', value: 'ET' },
  { label: 'Falkland Islands (Malvinas)', value: 'FK' },
  { label: 'Faroe Islands', value: 'FO' },
  { label: 'Federated States of Micronesia', value: 'FM' },
  { label: 'Fiji', value: 'FJ' },
  { label: 'Finland', value: 'FI' },
  { label: 'France', value: 'FR' },
  { label: 'French Guiana', value: 'GF' },
  { label: 'French Polynesia', value: 'PF' },
  { label: 'French Southern Territories', value: 'TF' },
  { label: 'Gabon', value: 'GA' },
  { label: 'Gambia', value: 'GM' },
  { label: 'Georgia', value: 'GE' },
  { label: 'Germany', value: 'DE' },
  { label: 'Ghana', value: 'GH' },
  { label: 'Gibraltar', value: 'GI' },
  { label: 'Greece', value: 'GR' },
  { label: 'Greenland', value: 'GL' },
  { label: 'Grenada', value: 'GD' },
  { label: 'Guadeloupe', value: 'GP' },
  { label: 'Guam', value: 'GU' },
  { label: 'Guatemala', value: 'GT' },
  { label: 'Guernsey', value: 'GG' },
  { label: 'Guinea', value: 'GN' },
  { label: 'Guinea-Bissau', value: 'GW' },
  { label: 'Guyana', value: 'GY' },
  { label: 'Haiti', value: 'HT' },
  { label: 'Heard Island and McDonald Islands', value: 'HM' },
  { label: 'Holy See (Vatican City State)', value: 'VA' },
  { label: 'Honduras', value: 'HN' },
  { label: 'Hong Kong', value: 'HK' },
  { label: 'Hungary', value: 'HU' },
  { label: 'Iceland', value: 'IS' },
  { label: 'India', value: 'IN' },
  { label: 'Indonesia', value: 'Id' },
  { label: 'Iran', value: 'IR' },
  { label: 'Iraq', value: 'IQ' },
  { label: 'Ireland', value: 'IE' },
  { label: 'Isle of Man', value: 'IM' },
  { label: 'Israel', value: 'IL' },
  { label: 'Italy', value: 'IT' },
  { label: 'Jamaica', value: 'JM' },
  { label: 'Japan', value: 'JP' },
  { label: 'Jersey', value: 'JE' },
  { label: 'Jordan', value: 'JO' },
  { label: 'Kazakhstan', value: 'KZ' },
  { label: 'Kenya', value: 'KE' },
  { label: 'Kiribati', value: 'KI' },
  { label: "Korea, Democratic People's Republic of", value: 'KP' },
  { label: 'Korea, Republic of', value: 'KR' },
  { label: 'Kosovo', value: 'XK' },
  { label: 'Kuwait', value: 'KW' },
  { label: 'Kyrgyzstan', value: 'KG' },
  { label: 'LAOS', value: 'LA' },
  { label: 'Latvia', value: 'LV' },
  { label: 'Lebanon', value: 'LB' },
  { label: 'Lesotho', value: 'LS' },
  { label: 'Liberia', value: 'LR' },
  { label: 'Libyan Arab Jamahiriya', value: 'LY' },
  { label: 'Liechtenstein', value: 'LI' },
  { label: 'Lithuania', value: 'LT' },
  { label: 'Luxembourg', value: 'LU' },
  { label: 'Macao', value: 'MO' },
  { label: 'Macedonia, the former Yugoslav Republic of', value: 'MK' },
  { label: 'Madagascar', value: 'MG' },
  { label: 'Malawi', value: 'MW' },
  { label: 'Malaysia', value: 'MY' },
  { label: 'Maldives', value: 'MV' },
  { label: 'Mali', value: 'ML' },
  { label: 'Malta', value: 'MT' },
  { label: 'Marshall Islands', value: 'MH' },
  { label: 'Martinique', value: 'MQ' },
  { label: 'Mauritania', value: 'MR' },
  { label: 'Mauritius', value: 'MU' },
  { label: 'Mayotte', value: 'YT' },
  { label: 'Mexico', value: 'MX' },
  { label: 'Moldova, Republic of', value: 'MD' },
  { label: 'Monaco', value: 'MC' },
  { label: 'Mongolia', value: 'MN' },
  { label: 'Montenegro', value: 'ME' },
  { label: 'Montserrat', value: 'MS' },
  { label: 'Morocco', value: 'MA' },
  { label: 'Mozambique', value: 'MZ' },
  { label: 'Myanmar', value: 'MM' },
  { label: 'Namibia', value: 'NA' },
  { label: 'Nauru', value: 'NR' },
  { label: 'Nepal', value: 'NP' },
  { label: 'Netherlands', value: 'NL' },
  { label: 'Netherlands Antilles', value: 'AN' },
  { label: 'New Caledonia', value: 'NC' },
  { label: 'New Zealand', value: 'NZ' },
  { label: 'Nicaragua', value: 'NI' },
  { label: 'Niger', value: 'NE' },
  { label: 'Nigeria', value: 'NG' },
  { label: 'Niue', value: 'NU' },
  { label: 'Norfolk Island', value: 'NF' },
  { label: 'Northern Mariana Islands', value: 'MP' },
  { label: 'Norway', value: 'NO' },
  { label: 'Oman', value: 'OM' },
  { label: 'Pakistan', value: 'PK' },
  { label: 'Palestinian Territory, Occupied', value: 'PS' },
  { label: 'Panama', value: 'PA' },
  { label: 'Papua New Guinea', value: 'PG' },
  { label: 'Paraguay', value: 'PY' },
  { label: 'Peru', value: 'PE' },
  { label: 'Philippines', value: 'PH' },
  { label: 'Pitcairn', value: 'PN' },
  { label: 'Poland', value: 'PL' },
  { label: 'Portugal', value: 'PT' },
  { label: 'Qatar', value: 'QA' },
  { label: 'Reunion', value: 'RE' },
  { label: 'Romania', value: 'RO' },
  { label: 'Russian Federation', value: 'RU' },
  { label: 'Rwanda', value: 'RW' },
  { label: 'Saint Barthélemy', value: 'BL' },
  { label: 'Saint Helena, Ascension and Tristan da Cunha', value: 'SH' },
  { label: 'Saint Kitts and Nevis', value: 'KN' },
  { label: 'Saint Lucia', value: 'LC' },
  { label: 'Saint Martin (French part)', value: 'MF' },
  { label: 'Saint Pierre and Miquelon', value: 'PM' },
  { label: 'Saint Vincent and the Grenadines', value: 'VC' },
  { label: 'Samoa', value: 'WS' },
  { label: 'San Marino', value: 'SM' },
  { label: 'Sao Tome and Principe', value: 'ST' },
  { label: 'Saudi Arabia', value: 'SA' },
  { label: 'Senegal', value: 'SN' },
  { label: 'Serbia', value: 'RS' },
  { label: 'Seychelles', value: 'SC' },
  { label: 'Sierra Leone', value: 'SL' },
  { label: 'Singapore', value: 'SG' },
  { label: 'Sint Maarten (Dutch part)', value: 'SX' },
  { label: 'Slovakia', value: 'SK' },
  { label: 'Slovenia', value: 'SI' },
  { label: 'Solomon Islands', value: 'SB' },
  { label: 'Somalia', value: 'SO' },
  { label: 'South Africa', value: 'ZA' },
  { label: 'South Georgia and the South Sandwich Islands', value: 'GS' },
  { label: 'South Sudan', value: 'SS' },
  { label: 'Spain', value: 'ES' },
  { label: 'Sri Lanka', value: 'LK' },
  { label: 'Sudan', value: 'SD' },
  { label: 'Suriname', value: 'SR' },
  { label: 'Svalbard and Jan Mayen', value: 'SJ' },
  { label: 'Swaziland', value: 'SZ' },
  { label: 'Sweden', value: 'SE' },
  { label: 'Switzerland', value: 'CH' },
  { label: 'Syrian Arab Republic', value: 'SY' },
  { label: 'Taiwan', value: 'TX' },
  { label: 'Tajikistan', value: 'TJ' },
  { label: 'Tanzania, United Republic of', value: 'TZ' },
  { label: 'Thailand', value: 'TH' },
  { label: 'Timor-Leste', value: 'TL' },
  { label: 'Togo', value: 'TG' },
  { label: 'Tokelau', value: 'TK' },
  { label: 'Tonga', value: 'TO' },
  { label: 'Trinidad and Tobago', value: 'TT' },
  { label: 'Tunisia', value: 'TN' },
  { label: 'Turkey', value: 'TR' },
  { label: 'Turkmenistan', value: 'TM' },
  { label: 'Turks and Caicos Islands', value: 'TC' },
  { label: 'Tuvalu', value: 'TV' },
  { label: 'Uganda', value: 'UG' },
  { label: 'Ukraine', value: 'UA' },
  { label: 'United Arab Emirates', value: 'AE' },
  { label: 'United Kingdom', value: 'GB' },
  { label: 'Uruguay', value: 'UY' },
  { label: 'Uzbekistan', value: 'UZ' },
  { label: 'Vanuatu', value: 'VU' },
  { label: 'Venezuela', value: 'VE' },
  { label: 'Vietnam', value: 'VN' },
  { label: 'Wallis and Futuna', value: 'WF' },
  { label: 'Western Sahara', value: 'EH' },
  { label: 'Yemen', value: 'YE' },
  { label: 'Zambia', value: 'ZM' },
  { label: 'Zimbabwe', value: 'ZW' },
];
export const states = {
  CA: [
    { label: 'Alberta', value: 'AB' },
    { label: 'British Columbia', value: 'BC' },
    { label: 'Eskasoni', value: 'ES' },
    { label: 'Manitoba', value: 'MB' },
    { label: 'New Brunswick', value: 'NB' },
    { label: 'Newfoundland and Labrador', value: 'NF' },
    { label: 'Newfoundland and Labrador', value: 'NL' },
    { label: 'Nova Scotia', value: 'NS' },
    { label: 'Northwest Territories', value: 'NT' },
    { label: 'Nunavut', value: 'NU' },
    { label: 'Ontario', value: 'ON' },
    { label: 'Prince Edward Island', value: 'PE' },
    { label: 'Quebec', value: 'QC' },
    { label: 'Saskatchewan', value: 'SK' },
    { label: 'Yukon Territories', value: 'YT' },
    { label: 'Yukon', value: 'YUK' },
  ],
  US: [
    { label: 'Alaska', value: 'AK' },
    { label: 'Alabama', value: 'AL' },
    { label: 'Arkansas', value: 'AR' },
    { label: 'American Samoa', value: 'AS' },
    { label: 'Arizona', value: 'AZ' },
    { label: 'California', value: 'CA' },
    { label: 'Colorado', value: 'CO' },
    { label: 'Connecticut', value: 'CT' },
    { label: 'District of Columbia', value: 'DC' },
    { label: 'Delaware', value: 'DE' },
    { label: 'Florida', value: 'FL' },
    { label: 'Federated Micronesia', value: 'FM' },
    { label: 'Georgia', value: 'GA' },
    { label: 'Guam', value: 'GU' },
    { label: 'Hawaii', value: 'HI' },
    { label: 'Iowa', value: 'IA' },
    { label: 'Idaho', value: 'Id' },
    { label: 'Illinois', value: 'IL' },
    { label: 'Greater Illinois ', value: 'L2' },
    { label: 'Indiana', value: 'IN' },
    { label: 'Kansas', value: 'KS' },
    { label: 'Kentucky', value: 'KY' },
    { label: 'Louisiana', value: 'LA' },
    { label: 'Massachusetts', value: 'MA' },
    { label: 'Maryland', value: 'MD' },
    { label: 'Maine', value: 'ME' },
    { label: 'Marshall Islands', value: 'MH' },
    { label: 'Michigan', value: 'MI' },
    { label: 'Minnesota', value: 'MN' },
    { label: 'Missouri', value: 'MO' },
    { label: 'Northern Mariana Islands', value: 'MP' },
    { label: 'Mississippi', value: 'MS' },
    { label: 'Montana', value: 'MT' },
    { label: 'North Carolina', value: 'NC' },
    { label: 'North Dakota', value: 'ND' },
    { label: 'Nebraska', value: 'NE' },
    { label: 'New Hampshire', value: 'NH' },
    { label: 'New Jersey', value: 'NJ' },
    { label: 'New Mexico', value: 'NM' },
    { label: 'Nevada', value: 'NV' },
    { label: 'New York', value: 'NY' },
    { label: 'Ohio', value: 'OH' },
    { label: 'Oklahoma', value: 'OK' },
    { label: 'Oregon', value: 'OR' },
    { label: 'Pennsylvania', value: 'PA' },
    { label: 'Puerto Rico', value: 'PR' },
    { label: 'Palau', value: 'PW' },
    { label: 'Rhode Island', value: 'RI' },
    { label: 'South Carolina', value: 'SC' },
    { label: 'South Dakota', value: 'SD' },
    { label: 'Tennessee', value: 'TN' },
    { label: 'Texas', value: 'TX' },
    { label: 'United States Minor Outlying Islands', value: 'UM' },
    { label: 'Utah', value: 'UT' },
    { label: 'Virginia', value: 'VA' },
    { label: 'Virgin Islands', value: 'VI' },
    { label: 'Vermont', value: 'VT' },
    { label: 'Washington', value: 'WA' },
    { label: 'Wisconsin', value: 'WI' },
    { label: 'West Virginia', value: 'WV' },
    { label: 'Wyoming', value: 'WY' },
    { label: 'Armed Forces Americas', value: 'AA' },
    { label: 'Armed Forces Europe', value: 'AE' },
    { label: 'Armed Forces Pacific', value: 'AP' },
  ],
};
export type CAStateCodes = typeof states['CA'][number]['value'];
export type USStateCodes = typeof states['US'][number]['value'];
export type StateCodes = CAStateCodes | USStateCodes;

const RAD2DEG = 180 / Math.PI;
const PI_4 = Math.PI / 4;

export function y2lat(y: number): number {
  return (Math.atan(Math.exp(y / RAD2DEG)) / PI_4 - 1) * 90;
}
export function x2lon(x: number): number {
  return x;
}
export function lat2y(lat: number): number {
  return Math.log(Math.tan((lat / 90 + 1) * PI_4)) * RAD2DEG;
}
export function lon2x(lon: number): number {
  return lon;
}

export const zeroes = {
  x: lon2x(-168) + 179,
  y: lat2y(-56) + 90,
};

export const regionNames = {
  // AWS
  'us-east-2': 'US East (Ohio)',
  'us-east-1': 'US East (N. Virginia)',
  'us-west-1': 'US West (N. California)',
  'us-west-2': 'US West (Oregon)',
  'af-south-1': 'Africa (Cape Town)',
  'ap-east-1': 'Asia Pacific (Hong Kong)',
  'ap-southeast-3': 'Asia Pacific (Jakarta)',
  'ap-south-1': 'Asia Pacific (Mumbai)',
  'ap-northeast-3': 'Asia Pacific (Osaka)',
  'ap-northeast-2': 'Asia Pacific (Seoul)',
  'ap-southeast-1': 'Asia Pacific (Singapore)',
  'ap-southeast-2': 'Asia Pacific (Sydney)',
  'ap-northeast-1': 'Asia Pacific (Tokyo)',
  'ca-central-1': 'Canada (Central)',
  'eu-central-1': 'Europe (Frankfurt)',
  'eu-west-1': 'Europe (Ireland)',
  'eu-west-2': 'Europe (London)',
  'eu-south-1': 'Europe (Milan)',
  'eu-west-3': 'Europe (Paris)',
  'eu-north-1': 'Europe (Stockholm)',
  'me-south-1': 'Middle East (Bahrain)',
  'sa-east-1': 'South America (São Paulo)',
  'us-gov-east-1': 'AWS GovCloud (US-East)',
  'us-gov-west-1': 'AWS GovCloud (US-West)',
  'cn-north-1': 'China (Beijing)',
  'cn-northwest-1': 'China (Ningxia)',

  // AZURE
  eastus: 'East US',
  eastus2: 'East US 2',
  southcentralus: 'South Central US',
  westus2: 'West US 2',
  westus3: 'West US 3',
  australiaeast: 'Australia East',
  southeastasia: 'Southeast Asia',
  northeurope: 'North Europe',
  swedencentral: 'Sweden Central',
  uksouth: 'UK South',
  westeurope: 'West Europe',
  centralus: 'Central US',
  northcentralus: 'North Central US',
  westus: 'West US',
  southafricanorth: 'South Africa North',
  centralindia: 'Central India',
  eastasia: 'East Asia',
  japaneast: 'Japan East',
  jioindiawest: 'Jio India West',
  koreacentral: 'Korea Central',
  canadacentral: 'Canada Central',
  francecentral: 'France Central',
  germanywestcentral: 'Germany West Central',
  norwayeast: 'Norway East',
  switzerlandnorth: 'Switzerland North',
  uaenorth: 'UAE North',
  brazilsouth: 'Brazil South',
  centralusstage: 'Central US (Stage)',
  eastusstage: 'East US (Stage)',
  eastus2stage: 'East US 2 (Stage)',
  northcentralusstage: 'North Central US (Stage)',
  southcentralusstage: 'South Central US (Stage)',
  westusstage: 'West US (Stage)',
  westus2stage: 'West US 2 (Stage)',
  asia: 'Asia',
  asiapacific: 'Asia Pacific',
  australia: 'Australia',
  brazil: 'Brazil',
  canada: 'Canada',
  europe: 'Europe',
  global: 'Global',
  india: 'India',
  japan: 'Japan',
  uk: 'United Kingdom',
  unitedstates: 'United States',
  eastasiastage: 'East Asia (Stage)',
  southeastasiastage: 'Southeast Asia (Stage)',
  centraluseuap: 'Central US EUAP',
  eastus2euap: 'East US 2 EUAP',
  westcentralus: 'West Central US',
  southafricawest: 'South Africa West',
  australiacentral: 'Australia Central',
  australiacentral2: 'Australia Central 2',
  australiasoutheast: 'Australia Southeast',
  japanwest: 'Japan West',
  jioindiacentral: 'Jio India Central',
  koreasouth: 'Korea South',
  southindia: 'South India',
  westindia: 'West India',
  canadaeast: 'Canada East',
  francesouth: 'France South',
  germanynorth: 'Germany North',
  norwaywest: 'Norway West',
  swedensouth: 'Sweden South',
  switzerlandwest: 'Switzerland West',
  ukwest: 'UK West',
  uaecentral: 'UAE Central',
  brazilsoutheast: 'Brazil Southeast',
};

interface LocationInfo {
  label: string;
  lat: number;
  lon: number;
}
export const regions: Record<CloudType, Record<Location, LocationInfo>> = {
  azure: {
    // Americas
    centralus: {
      label: 'Central US',
      lat: 41.5908,
      lon: -93.6208,
    },
    eastus2: {
      label: 'East US 2',
      lat: 36.6681,
      lon: -78.3889,
    },
    eastus: {
      label: 'East US',
      lat: 37.3719,
      lon: -79.8164,
    },
    northcentralus: {
      label: 'North Central US',
      lat: 41.8819,
      lon: -87.6278,
    },
    southcentralus: {
      label: 'South Central US',
      lat: 29.4167,
      lon: -98.5,
    },
    westus2: {
      label: 'West US 2',
      lat: 47.233,
      lon: -119.852,
    },
    westcentralus: {
      label: 'West Central US',
      lat: 40.89,
      lon: -110.234,
    },
    westus: {
      label: 'West US',
      lat: 37.783,
      lon: -122.417,
    },
    canadacentral: {
      label: 'Canada Central',
      lat: 43.653,
      lon: -79.383,
    },
    canadaeast: {
      label: 'Canada East',
      lat: 46.817,
      lon: -71.217,
    },
    brazilsouth: {
      label: 'Brazil South',
      lat: -23.55,
      lon: -46.633,
    },
    brazilsoutheast: {
      label: 'Brazil Southeast',
      lat: -22.90278,
      lon: -43.2075,
    },

    // Europe
    northeurope: {
      label: 'North Europe',
      lat: 53.3478,
      lon: -6.2597,
    },
    westeurope: {
      label: 'West Europe',
      lat: 52.3667,
      lon: 4.9,
    },
    francecentral: {
      label: 'France Central',
      lat: 46.3772,
      lon: 2.373,
    },
    francesouth: {
      label: 'France South',
      lat: 43.8345,
      lon: 2.1972,
    },
    uksouth: {
      label: 'UK South',
      lat: 50.941,
      lon: -0.799,
    },
    ukwest: {
      label: 'UK West',
      lat: 53.427,
      lon: -3.084,
    },
    germanycentral: {
      label: 'Germany Central',
      lat: 50.110924,
      lon: 8.682127,
    },
    germanynortheast: {
      label: 'Germany Northeast',
      lat: 52.1205,
      lon: 11.6276,
    },
    germanywestcentral: {
      label: 'Germany West Central',
      lat: 50.110924,
      lon: 8.682127,
    },
    germanynorth: {
      label: 'Germany North',
      lat: 53.073635,
      lon: 8.806422,
    },
    switzerlandnorth: {
      label: 'Switzerland North',
      lat: 47.451542,
      lon: 8.564572,
    },
    switzerlandwest: {
      label: 'Switzerland West',
      lat: 46.204391,
      lon: 6.143158,
    },
    norwaywest: {
      label: 'Norway West',
      lat: 58.97,
      lon: 5.7331,
    },
    norwayeast: {
      label: 'Norway East',
      lat: 59.9139,
      lon: 10.7522,
    },

    // Asia Pacific
    eastasia: {
      label: 'East Asia',
      lat: 22.267,
      lon: 114.188,
    },
    southeastasia: {
      label: 'Southeast Asia',
      lat: 1.283,
      lon: 103.833,
    },
    australiacentral: {
      label: 'Australia Central',
      lat: -35.3075,
      lon: 149.1244,
    },
    australiacentral2: {
      label: 'Australia Central 2',
      lat: -35.3075,
      lon: 149.1244,
    },
    australiaeast: {
      label: 'Australia East',
      lat: -33.86,
      lon: 151.2094,
    },
    australiasoutheast: {
      label: 'Australia Southeast',
      lat: -37.8136,
      lon: 144.9631,
    },
    chinaeast: {
      label: 'China East',
      lat: 31.2304,
      lon: 121.4737,
    },
    chinanorth: {
      label: 'China North',
      lat: 39.9042,
      lon: 116.4074,
    },
    chinaeast2: {
      label: 'China East 2',
      lat: 31.2304,
      lon: 121.4737,
    },
    chinanorth2: {
      label: 'China North 2',
      lat: 39.9042,
      lon: 116.4074,
    },
    centralindia: {
      label: 'Central India',
      lat: 18.5822,
      lon: 73.9197,
    },
    southindia: {
      label: 'South India',
      lat: 12.9822,
      lon: 80.1636,
    },
    westindia: {
      label: 'West India',
      lat: 19.088,
      lon: 72.868,
    },
    japaneast: {
      label: 'Japan East',
      lat: 35.68,
      lon: 139.77,
    },
    japanwest: {
      label: 'Japan West',
      lat: 34.6939,
      lon: 135.5022,
    },
    koreacentral: {
      label: 'Korea Central',
      lat: 37.5665,
      lon: 126.978,
    },
    koreasouth: {
      label: 'Korea South',
      lat: 35.1796,
      lon: 129.0756,
    },

    // Middle East and Africa
    southafricanorth: {
      label: 'South Africa North',
      lat: -25.73134,
      lon: 28.21837,
    },
    southafricawest: {
      label: 'South Africa West',
      lat: -34.075691,
      lon: 18.843266,
    },
    uaecentral: {
      label: 'UAE Central',
      lat: 24.466667,
      lon: 54.366669,
    },
    uaenorth: {
      label: 'UAE North',
      lat: 25.266666,
      lon: 55.316666,
    },
  },
  azureGeographies: {
    brazilsouth: {
      id: 'brazilsouth',
      style: {
        left: '33%',
        top: '80%',
      },
      label: 'Brazil South',
    },
    asia: {
      id: 'asia',
      label: 'Asia',
      style: {
        left: '84%',
        top: '35%',
      },
      tooltipPosition: 'right',
      regions: [
        {
          id: 'eastasia',
          label: 'East Asia',
        },
        {
          id: 'koreacentral',
          label: 'Korea Central',
        },
        {
          id: 'koreasouth',
          label: 'Korea South',
        },
        {
          id: 'japanwest',
          label: 'Japan West',
        },
        {
          id: 'japaneast',
          label: 'Japan East',
        },
        {
          id: 'southeastasia',
          label: 'Southeast Asia',
        },
      ],
    },
    au: {
      id: 'au',
      label: 'Australia',
      style: {
        left: '84.6%',
        top: '80%',
      },
      regions: [
        {
          id: 'australiaeast',
          label: 'Australia East',
        },
        {
          id: 'australiasoutheast',
          label: 'Australia Southeast',
        },
        {
          id: 'australiacentral',
          label: 'Australia Central',
        },
        {
          id: 'australiacentral2',
          label: 'Australia Central 2',
        },
      ],
    },
    europe: {
      id: 'europe',
      label: 'Europe',
      style: {
        left: '53%',
        top: '33%',
      },
      tooltipPosition: 'left',
      regions: [
        {
          id: 'northeurope',
          label: 'North Europe',
        },
        {
          id: 'westeurope',
          label: 'West Europe',
        },
        {
          id: 'francecentral',
          label: 'France Central',
        },
        {
          id: 'francesouth',
          label: 'France South',
        },
        {
          id: 'uksouth',
          label: 'UK South',
        },
        {
          id: 'ukwest',
          label: 'UK West',
        },
        {
          id: 'germanywestcentral',
          label: 'Germany West Central',
        },
        {
          id: 'germanynorth',
          label: 'Germany North',
        },
        {
          id: 'switzerlandnorth',
          label: 'Switzerland North',
        },
        {
          id: 'switzerlandwest',
          label: 'Switzerland West',
        },
        {
          id: 'norwayeast',
          label: 'Norway East',
        },
        {
          id: 'norwaywest',
          label: 'Norway West',
        },
      ],
    },
    india: {
      id: 'india',
      label: 'India',
      style: {
        left: '68.7%',
        top: '50%',
      },
      regions: [
        {
          id: 'southindia',
          label: 'South India',
        },
        {
          id: 'centralindia',
          label: 'Central India',
        },
        {
          id: 'westindia',
          label: 'West India',
        },
      ],
    },
    af: {
      id: 'me',
      label: 'Africa',
      style: {
        left: '53.7%',
        top: '80%',
      },
      regions: [
        {
          id: 'southafricanorth',
          label: 'South Africa North',
        },
        {
          id: 'southafricawest',
          label: 'South Africa West',
        },
      ],
    },
    me: {
      id: 'me',
      label: 'Middle East',
      style: {
        left: '62.7%',
        top: '52%',
      },
      tooltipPosition: 'left',
      regions: [
        {
          id: 'uaecentral',
          label: 'UAE Central',
        },
        {
          id: 'uaenorth',
          label: 'UAE North',
        },
      ],
    },
    na: {
      id: 'na',
      label: 'North America',
      style: {
        left: '19.8%',
        top: '41.3%',
      },
      regions: [
        {
          id: 'canadacentral',
          label: 'Canada Central',
        },
        {
          id: 'canadaeast',
          label: 'Canada East',
        },
        {
          id: 'eastus',
          label: 'East US',
        },
        {
          id: 'eastus2',
          label: 'East US 2',
        },
        {
          id: 'northcentralus',
          label: 'North Central US',
        },
        {
          id: 'southcentralus',
          label: 'South Central US',
        },
        {
          id: 'westcentralus',
          label: 'West Central US',
        },
        {
          id: 'centralus',
          label: 'Central US',
        },
        {
          id: 'westus',
          label: 'West US',
        },
        {
          id: 'westus2',
          label: 'West US 2',
        },
      ],
    },
  },
  aws: {
    // US West
    'us-west-1': {
      label: 'US West (California)',
      lat: 37.35,
      lon: -121.96,
      tooltipPosition: 'bottom',
    },
    'us-west-2': {
      label: 'US West (Oregon)',
      lat: 46.15,
      lon: -123.88,
      tooltipPosition: 'left',
    },
    // aws gov-cloud us-west

    // US East
    'us-east-1': {
      label: 'US East (Virginia)',
      lat: 38.13,
      lon: -78.45,
      tooltipPosition: 'bottom',
    },
    'us-east-2': {
      label: 'US East (Ohio)',
      lat: 39.96,
      lon: -83,
      tooltipPosition: 'left',
    },
    // aws gov-cloud us-east

    // Canada
    'ca-central-1': {
      label: 'Canada (Canada Central)',
      lat: 45.5,
      lon: -73.6,
      tooltipPosition: 'right',
    },

    // South America
    'sa-east-1': {
      label: 'South America (São Paulo)',
      lat: -23.34,
      lon: -46.38,
      tooltipPosition: 'top',
    },

    // Europe
    'eu-north-1': {
      label: 'Europe (Stockholm)',
      lat: 59.3293,
      lon: 18.0686,
      tooltipPosition: 'top',
    },
    'eu-west-1': {
      label: 'Europe (Ireland)',
      lat: 53,
      lon: -8,
      tooltipPosition: 'left',
    },
    'eu-west-2': {
      label: 'Europe (London)',
      lat: 51,
      lon: -0.1,
      tooltipPosition: 'top',
    },
    'eu-west-3': {
      label: 'Europe (Paris)',
      lat: 48.86,
      lon: 2.35,
      tooltipPosition: 'bottom',
    },
    'eu-central-1': {
      label: 'Europe (Frankfurt)',
      lat: 50,
      lon: 8,
      tooltipPosition: 'right',
    },
    'eu-south-1': {
      label: 'Europe (Milan)',
      lat: 45.4642,
      lon: 9.19,
      tooltipPosition: 'top',
    },
    // spain added

    'af-south-1': {
      label: 'Africa (Cape Town)',
      lat: 33.9249,
      lon: 18.4241,
      tooltipPosition: 'top',
    },

    // Middle East
    'me-south-1': {
      label: 'Middle East (Bahrain)',
      lat: 26.0667,
      lon: 50.5577,
      tooltipPosition: 'right',
    },

    // Asia Pacific
    'ap-south-1': {
      label: 'Asia Pacific (Mumbai)',
      lat: 19.08,
      lon: 72.88,
      tooltipPosition: 'right',
    },
    'ap-southeast-1': {
      label: 'Asia Pacific (Singapore)',
      lat: 1.37,
      lon: 103.8,
      tooltipPosition: 'bottom',
    },
    'ap-southeast-2': {
      label: 'Asia Pacific (Sydney)',
      lat: -33.86,
      lon: 151.2,
      tooltipPosition: 'top',
    },
    'ap-northeast-1': {
      label: 'Asia Pacific (Tokyo)',
      lat: 35.41,
      lon: 139.42,
      tooltipPosition: 'bottom',
    },
    'ap-northeast-2': {
      label: 'Asia Pacific (Seoul)',
      lat: 37.56,
      lon: 126.98,
      tooltipPosition: 'top',
    },
    'ap-east-1': {
      label: 'Asia Pacific (Hong Kong)',
      lat: 22.3193,
      lon: 114.1694,
      tooltipPosition: 'right',
    },

    // China
    'cn-north-1': {
      label: 'China (Beijing)',
      lat: 39.9042,
      lon: 116.4074,
      tooltipPosition: 'right',
    },
    'cn-northwest-1': {
      label: 'China (Ningxia)',
      lat: 37.1987,
      lon: 106.1581,
      tooltipPosition: 'right',
    },
  },
};
export const assetNames: { [key: string]: string } = {
  'microsoft.sql/servers/databases': 'SQL Database',
  'microsoft.compute/disks': 'Disk',
  'microsoft.web/sites': 'Web Site',
  'microsoft.keyvault/vaults': 'Key Vault',
  // storage accounts
  'microsoft.storage/storageaccounts': 'Storage Account',
  // virtual machines
  'microsoft.compute/virtualmachines': 'Virtual Machine',
  // virtual networks
  'microsoft.network/virtualnetworks': 'Virtual Network',
  // network interfaces
  'microsoft.network/networkinterfaces': 'Network Interface',
  // security groups
  'microsoft.network/networksecuritygroups': 'Network Security Group',
  // load balancers
  'microsoft.network/loadbalancers': 'Load Balancer',
  // databases
  'microsoft.documentdb/databaseaccounts': 'Cosmos DB',
  'microsoft.sql/managedinstances': 'Managed SQL Server',
  'microsoft.sql/servers': 'SQL Server',
  'microsoft.dbforpostgresql/servers': 'PostgreSQL Server',
  'microsoft.dbformariadb/servers': 'MariaDB Server',
  // containers
  'microsoft.containerinstance/containergroups': 'Container Instance',
  'microsoft.containerservice/managedclusters': 'Managed Cluster',

  'microsoft.subscription/subscriptions': 'Azure Subscription',
  'microsoft.storage/storageaccounts/blobservices/containers': 'Blob Storage Container',
  'microsoft.authorization/roledefinitions': 'Role Definition',
  'microsoft.keyvault/vaults/keys': 'Key Vault Key',
  'microsoft.keyvault/vaults/secrets': 'Key Vault Secret',
  'microsoft.subscription/locations': 'Azure Location',
  'microsoft.directoryservice/users': 'Azure AD User',

  'microsoft.network/frontdoors/frontendendpoints': 'Front Door Endpoint',
  'microsoft.network/applicationgateways': 'Application Gateway',
  'microsoft.apimanagement/service': 'API Management',

  'microsoft.network/dnszones/a': 'Azure DNS A Record',
  'microsoft.network/dnszones/aaaa': 'Azure DNS AAAA Record',
  'microsoft.network/dnszones/cname': 'Azure DNS CNAME Record',

  // s3 buckets
  's3:bucket': 'S3 Bucket',
  // kms key
  'kms:key': 'KMS Key',
  // cloudtrail
  'cloudtrail:trail': 'CloudTrail',
  // beanstalk application
  'elasticbeanstalk:application': 'Beanstalk Application',
  // ec2 instance
  'ec2:instance': 'EC2 Instance',
  // vpc
  'ec2:vpc': 'VPC',
  // security group
  'ec2:security-group': 'Security Group',
  // network interface
  'ec2:network-interface': 'Network Interface',
  // route tables
  'ec2:route-table': 'Route Table',
  // classic elastic load balancer
  'elasticloadbalancing:loadbalancer-classic': 'Elastic Load Balancer Classic',
  // elastic load balancer v2
  'elasticloadbalancing:loadbalancer': 'Elastic Load Balancer',
  // sql db
  'rds:db': 'RDS DB',
  // redshift
  'redshift:cluster': 'Redshift Cluster',

  'aws:account': 'AWS Account',
  'iam:user': 'IAM User',
  'iam:policy': 'IAM Policy',
  'aws:region': 'AWS Region',

  apigateway: 'API Gateway',
  route53: 'Route 53',
  cloudfront: 'CloudFront',
};
export const assetTypes = new Set([
  'microsoft.sql/servers/databases',
  'microsoft.compute/disks',
  'microsoft.web/sites',
  'microsoft.keyvault/vaults',
  // storage accounts
  'microsoft.storage/storageaccounts',
  // virtual machines
  'microsoft.compute/virtualmachines',
  // virtual networks
  'microsoft.network/virtualnetworks',
  // network interfaces
  'microsoft.network/networkinterfaces',
  // security groups
  'microsoft.network/networksecuritygroups',
  // load balancers
  'microsoft.network/loadbalancers',
  // databases
  'microsoft.documentdb/databaseaccounts',
  'microsoft.sql/managedinstances',
  'microsoft.sql/servers',
  'microsoft.dbforpostgresql/servers',
  'microsoft.dbformariadb/servers',
  // containers
  'microsoft.containerinstance/containergroups',

  // s3 buckets
  's3:bucket',
  // kms key
  'kms:key',
  // cloudtrail
  'cloudtrail:trail',
  // beanstalk application
  'elasticbeanstalk:application',
  // ec2 instance
  'ec2:instance',
  // vpc
  'ec2:vpc',
  // security group
  'ec2:security-group',
  // network interface
  'ec2:network-interface',
  // route tables
  'ec2:route-table',
  // classic elastic load balancer
  'elasticloadbalancing:loadbalancer-classic',
  // elastic load balancer v2
  'elasticloadbalancing:loadbalancer',
  // sql db
  'rds:db',
  // redshift
  'redshift:cluster',
]);

export const resourceTypeList = Array.from(assetTypes)
  .map(type => ({
    name: assetNames?.[type] || type,
    type,
    cloud: type.startsWith('microsoft') ? 'Microsoft Azure' : 'Amazon Web Services',
  }))
  .sort((a, b) => {
    if (a.cloud === b.cloud) {
      return a.name.localeCompare(b.name);
    }
    return a.cloud.localeCompare(b.cloud);
  });
