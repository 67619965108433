import React, { useMemo, ReactElement, ChangeEvent } from 'react';

import {
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@cuda-networks/bds-core';
import { Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { FindingLabel } from 'components/SecurityFindings';
import { Rule } from 'types';

const useStyles = makeStyles(
  theme => ({
    table: {
      '& thead': {
        backgroundColor: theme.palette.background.default,
      },
      '& td': {
        verticalAlign: 'top',
      },
    },
    checkboxCell: {
      padding: '6px 0',
      verticalAlign: 'top',
      '& > span': {
        padding: 0,
      },
    },
    json: {
      fontSize: '11px',
      fontFamily: '"Lucida Console", Monaco, monospace',
    },
    title: {
      backgroundColor: theme.palette.primary.main,
      color: '#FFFFFF',
      '& > h2': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
      '& button': {
        color: '#FFFFFF',
      },
    },

    accordionSummaryContent: {
      margin: 0,
      '&$expanded': {
        margin: 0,
      },
    },

    accordionSummary: {
      minHeight: 24,
      padding: '0 16px 0 0',
      '&$expanded': {
        minHeight: 24,
      },
    },

    accordionDetails: {
      padding: '8px 24px 8px 0',
    },

    expandIcon: {
      padding: 0,
      alignSelf: 'flex-start',
    },

    accordion: {
      boxShadow: 'none',
      '&:not(:last-child)': {
        borderBottom: 0,
      },
      '&:before': {
        display: 'none',
      },
      // '&$expanded': {
      //   border: '1px solid rgba(0, 0, 0, .125)',
      //   margin: 'auto',

      // },
    },
    expanded: {},
  }),
  {
    name: 'PolicyCatSelector',
  },
);

interface Props {
  rules: Rule[];
  policy: Record<string, boolean>;
  handlePolicyUpdate(ruleIds: string[], checked: boolean): void;
  selectedCount: number;
  disabled?: boolean;
}

export default function PolicyCatSelector({
  rules,
  policy,
  handlePolicyUpdate,
  selectedCount,
  disabled = false,
}: Props): ReactElement {
  const classes = useStyles();

  const allCheckedStatus = useMemo(() => {
    if (selectedCount === 0) {
      return 'unchecked';
    }
    if (selectedCount === rules.length) {
      return 'checked';
    }

    return 'indeterminate';
  }, [selectedCount, rules]);

  const handleAllCheck = (e): void =>
    handlePolicyUpdate(
      rules.map(r => r.rule_id),
      e.target.checked,
    );
  const handleCheck =
    (rule: Rule) =>
    (event: ChangeEvent<HTMLInputElement>): void =>
      handlePolicyUpdate([rule.rule_id], event.target.checked);

  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow className={classes.row}>
          <TableCell className={classes.checkboxCell}>
            <Checkbox
              color="primary"
              checked={allCheckedStatus === 'checked'}
              indeterminate={allCheckedStatus === 'indeterminate'}
              onChange={handleAllCheck}
              disabled={disabled}
              inputProps={{
                'aria-label': 'primary checkbox',
              }}
            />
          </TableCell>
          <TableCell>Benchmark</TableCell>
          <TableCell>Title</TableCell>
          <TableCell>Severity</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {(rules ?? []).map(rule => (
          <TableRow key={rule.rule_id}>
            <TableCell className={classes.checkboxCell}>
              <Checkbox
                color="primary"
                checked={policy?.[rule.rule_id]}
                onChange={handleCheck(rule)}
                value={rule.rule_id}
                disabled={disabled}
                inputProps={{
                  'aria-label': 'primary checkbox',
                }}
              />
            </TableCell>
            <TableCell style={{ textTransform: 'capitalize', lineHeight: '24px' }}>
              {rule.rule_id.split(':')[0] === 'bsbp'
                ? rule.rule_id.split(':')[3]
                : `${rule.rule_id.split(':')[0].toUpperCase()} ${rule.rule_id.split(':')[3]}`}
            </TableCell>
            <TableCell>
              {rule.description ? (
                <Accordion square classes={{ root: classes.accordion, expanded: classes.expanded }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    classes={{
                      root: classes.accordionSummary,
                      content: classes.accordionSummaryContent,
                      expanded: classes.expanded,
                      expandIcon: classes.expandIcon,
                    }}
                  >
                    {rule.title}
                  </AccordionSummary>
                  <AccordionDetails
                    classes={{
                      root: classes.accordionDetails,
                    }}
                  >
                    <Typography variant="caption">{rule.description}</Typography>
                  </AccordionDetails>
                </Accordion>
              ) : (
                rule.title
              )}
              {/* {rule.require_params && ( */}
              {/*   <TextField */}
              {/*     label="JSON Parameters" */}
              {/*     value={policy.params[rule.rule_id] || ''} */}
              {/*     multiline */}
              {/*     disabled={!policy.rules[ruleType][rule.rule_id]} */}
              {/*     InputProps={{ */}
              {/*       classes: { */}
              {/*         root: classes.json, */}
              {/*       }, */}
              {/*     }} */}
              {/*     onChange={handleParamUpdate(rule.rule_id)} */}
              {/*     fullWidth */}
              {/*   /> */}
              {/* )} */}
            </TableCell>
            <TableCell style={{ textTransform: 'capitalize' }}>
              <FindingLabel noRounding severity={rule.severity} />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
