import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  {
    formRow: {
      display: 'flex',
      alignItems: 'flex-start',
      marginBottom: '24px',
      '& > svg': {
        margin: '4px 8px 4px 0',
      },
      '& svg': {
        color: '#5F5F5F',
      },
    },
    help: {
      width: '32px',
      height: '32px',
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
    },
  },
  {
    name: 'FormRow',
  },
);

interface FormRowProps {
  children: React.ReactNode;
  help?: React.ReactElement;
  noHelp?: boolean;
}

export default function FormRow(props: FormRowProps): JSX.Element {
  const { children, help, noHelp = false } = props;
  const classes = useStyles();

  return (
    <span className={classes.formRow}>
      {children}
      {!noHelp && <div className={classes.help}>{help}</div>}
    </span>
  );
}
